import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DetailRowService, FilterService, GridAllModule, GridModule, GroupService, PageService, SortService } from "@syncfusion/ej2-angular-grids";
import { DatePickerAllModule, DateTimePickerAllModule } from "@syncfusion/ej2-angular-calendars";
import { AccordionAllModule, TreeViewModule } from "@syncfusion/ej2-angular-navigations";
import { CheckBoxSelectionService } from '@syncfusion/ej2-angular-dropdowns';
import { CheckBoxModule, RadioButtonAllModule } from '@syncfusion/ej2-angular-buttons';
import { NumericTextBoxAllModule, MaskedTextBoxAllModule, ColorPickerAllModule, TextBoxAllModule } from "@syncfusion/ej2-angular-inputs";
import {
  DropDownListAllModule,
  DropDownListModule,
  ListBoxAllModule,
  AutoCompleteAllModule,
  MultiSelectModule
} from "@syncfusion/ej2-angular-dropdowns";
import { GridsterModule } from 'angular-gridster2';
import { ModalComponent } from "./modals.component";
import { ConfirmModalComponent } from './confirm-modals.component';
import { AddMemberModalComponent } from "./add-member-modals/add-member-modals.component";
import { MeetingDetailNewComponent } from "./meetingdetailnew/meeting-detailnew.component";
import { TooltipAllModule } from "@syncfusion/ej2-angular-popups";
import { AddDelegateModalComponent } from "./add-delegate-modals/add-delegate-modals.component";
import { MeetingDetailHappeningComponent } from "./meetingdetailhappening/meeting-detailhappening.component";

import { ShowModalComponent } from "./show-modals.component";
import { HighchartsChartModule } from "highcharts-angular";
import { QRCodeModule } from 'angularx-qrcode';
import { KanbanAllModule } from '@syncfusion/ej2-angular-kanban';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import {NgxPrintModule} from 'ngx-print';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropDownListAllModule,
    FormsModule,
    GridAllModule,
    DatePickerAllModule,
    TreeViewModule,
    CheckBoxModule,
    TextBoxAllModule,
    NumericTextBoxAllModule,
    DropDownListModule,
    ListBoxAllModule,
    AutoCompleteAllModule,
    MultiSelectModule,
    DateTimePickerAllModule,
    TooltipAllModule,
    RadioButtonAllModule,
    GridsterModule,
    HighchartsChartModule,
    QRCodeModule,
    KanbanAllModule,
    PdfViewerModule,
    NgxDocViewerModule,
    AccordionAllModule,
    CKEditorModule,
    NgxPrintModule,
    GridModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [ModalComponent, ConfirmModalComponent, AddMemberModalComponent, MeetingDetailNewComponent, AddDelegateModalComponent, MeetingDetailHappeningComponent, ShowModalComponent],
  exports: [ModalComponent, ConfirmModalComponent, AddMemberModalComponent, MeetingDetailNewComponent, AddDelegateModalComponent, MeetingDetailHappeningComponent, ShowModalComponent],
  providers: [
    DetailRowService,
    PageService,
    SortService,
    FilterService,
    GroupService,
  ]
})
export class ModalModule { }

import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  Inject,
  ElementRef,
  Input,
  AfterViewInit,
  ViewContainerRef,
} from "@angular/core";
import { Subject } from "rxjs";
import { Router, ActivatedRoute, Params } from "@angular/router";

// Service Translate
import { TranslationLoaderService } from "src/app/common/translation-loader.service";
import { TranslateService } from "@ngx-translate/core";
// Import the locale files
import { locale as english } from "./i18n/en";
import { locale as vietnam } from "./i18n/vi";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// Globals File
import { Globals } from "src/app/common/globals";
import { Configs } from "src/app/common/configs";
import { Notification } from "src/app/common/notification";

import { L10n, setCulture } from "@syncfusion/ej2-base";
import {
  FilterService,
  GridComponent,
  VirtualScrollService,
  GridModel,
  IGrid,
} from "@syncfusion/ej2-angular-grids";
import { ToolbarItem, ToolbarInterface } from "src/app/_models/index";

import { CoreService } from "src/app/_services/core.service";
import { ConfigService } from "src/app/_services/config.service";
import { FormBuilder, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { ModalService } from "src/app/_services/modal.service";
import { Query, Predicate, DataManager } from "@syncfusion/ej2-data";
import { FieldSettingsModel } from "@syncfusion/ej2-dropdowns";
import { TreeView } from "@syncfusion/ej2-navigations";
import * as $ from "jquery";
import * as moment from "moment";
import * as async from "async";
import * as _ from "lodash";
import { v4 as uuidv4 } from 'uuid';
import { Meeting, MeetingContent, MeetingVoting } from "src/app/_models/business/Meeting";
import { saveAs } from 'file-saver';
import { MeetRoom } from "src/app/_models/masterdata/MeetRoom";
import { Location } from "@angular/common";
setCulture("vi");

@Component({
  selector: "app-meeting-detailnew",
  templateUrl: "./meeting-detailnew.component.html",
  styleUrls: ["./meeting-detailnew.component.scss"],
  providers: [FilterService, VirtualScrollService],
  encapsulation: ViewEncapsulation.None,
})
export class MeetingDetailNewComponent implements OnInit, AfterViewInit {
  // Varriable Language
  @Input("flagStateParam") flagStateParam: string;
  @Input("paramId") paramId: string;
  @Input("screen") screen: string;
  modelOrigin: Meeting = new Meeting();
  model: Meeting = new Meeting();
  userId;
  languages: any;
  selectedLanguage: any;
  editForm: FormGroup;
  votingForm: FormGroup;

  flagStateModal = "new";
  flagState = "";
  return_reason = "";
  isDoubleClickHandled = false;
  public minDateContent: Date = new Date(1970, 1, 1);

  // vi tri focus
  public query = new Query();
  public fields: FieldSettingsModel = { text: "name", value: "id" };
  // Toolbar Item

  @ViewChild("memberGrid", { static: false })
  public memberGrid: GridComponent;

  @ViewChild("dataMemberContentGrid", { static: false })
  public dataMemberContentGrid: GridComponent;

  @ViewChild("dataChooseMemberGrid", { static: false })
  public dataChooseMemberGrid: GridComponent;

  @ViewChild("guestGrid", { static: false })
  public guestGrid: GridComponent;

  @ViewChild("dataGuestModalGrid", { static: false })
  public dataGuestModalGrid: GridComponent;

  @ViewChild("contentGrid", { static: false })
  public contentGrid: GridComponent;

  @ViewChild("contentMemberGrid", { static: false })
  public contentMemberGrid: GridComponent;

  @ViewChild("contentGuestGrid", { static: false })
  public contentGuestGrid: GridComponent;

  @ViewChild("contentShareGrid", { static: false })
  public contentShareGrid: GridComponent;

  @ViewChild("contentShareListGrid", { static: false })
  public contentShareListGrid: GridComponent;

  @ViewChild("contentPresentMemberGrid", { static: false })
  public contentPresentMemberGrid: GridComponent;

  @ViewChild("contentPresentOrgGrid", { static: false })
  public contentPresentOrgGrid: GridComponent;

  @ViewChild("documentGrid", { static: false })
  public documentGrid: GridComponent;

  @ViewChild("votingGrid", { static: false })
  public votingGrid: GridComponent;

  @ViewChild("votingMemberGrid", { static: false })
  public votingMemberGrid: GridComponent;


  lstMeetTypes = [];
  lstMeetGroups = [];
  lstMeetRooms = [];
  lstChairUsers = [];
  lstApproveUsers = [];
  lstSecretaryUsers = [];
  lstTrackingUsers = [];
  lstOrgs = [];
  lstOrgPresents = [];
  dataDisabled: any

  lstSelectPresents = [{
    id: 'org',
    name: "Đơn vị trình bày"
  }, {
    id: 'user',
    name: "Đại biểu trình bày"
  }];

  select_present = "org";

  dataGuestModals = [];
  lstDelegateContents = [];
  grantType = "";
  dataGrant = null;

  private _unsubscribeAll: Subject<any>;
  username: string;
  userIds = [];

  // Content
  contentIndex = -1;
  dataContentUpdate = new MeetingContent();
  lstContentDocuments = [];
  lstContentDelegates = [];
  lstContentGuests = [];
  lstContentShareLists = [];
  lstContentShares = [];
  lstContentPresentOrgs = [];
  lstContentPresentMembers = [];

// Thêm mới
tabActive = "infoTab";
lstContents = [];
searchContentTabRoom = null;
public fieldContents: FieldSettingsModel = { text: "content", value: "id" };
lstRoomPositions = [];
lstWaitingPositions = [];
lstMemberWaitings = [];
lstBlankSeats = [];
dataItem = null;
modelPosition = new MeetRoom();
flagShowItem = false;
lstRoomPositionOrigins = [];

// Voting
lstVotings = [];
modelVoting = new MeetingVoting();
lstDelegateVotings = [];


  groupOptions: {
    showDropArea: boolean;
    columns: string[];
    captionTemplate: string;
  };
  seatsMapWidth: number;
  seatsMapHeight: number;
  seatsMapStartX: number;
  seatsMapStartY: number;

  expandAllData: any
  /**
   * Constructor
   *
   */
  constructor(
    private _coreService: CoreService,
    private modalService: ModalService,
    private notification: Notification,
    public globals: Globals,
    public configs: Configs,
    public router: Router,
    private _formBuilder: FormBuilder,
    public activatedRoute: ActivatedRoute,
    private _translateService: TranslateService,
    private _configService: ConfigService,
    private _tlaTranslationLoaderService: TranslationLoaderService,
    private el: ElementRef,
    private location: Location,
    @Inject(ViewContainerRef)
    private viewContainerRef: ViewContainerRef
  ) {
    // Set language
    this.languages = this.globals.languages;

    this._configService._configSubject.next("true");
    // Load file language
    this._tlaTranslationLoaderService.loadTranslations(vietnam, english);

    this.editForm = this._formBuilder.group({
      name: [
        "",
        [
          Validators.required,
          Validators.maxLength(255),
          this.globals.noWhitespaceValidator,
        ],
      ],
      meet_type_id: [""],
      meet_group_id: [""],
      meet_room_id: ["", Validators.required],
      user_chair_id: ["", Validators.required],
      user_approve_id: ["", Validators.required],
      user_secretary_id: ["", Validators.required],
      user_follow_id: [""],
      from_date: ["", Validators.required],
      to_date: [""]
    });

    this.votingForm = this._formBuilder.group({
      content: ["", [Validators.required, Validators.maxLength(2000)]],
      minutes: ["", [Validators.required, Validators.maxLength(2000)]],
    });

    // Set the private defaults
    this._unsubscribeAll = new Subject();
    L10n.load(this.configs.languageGrid);
  }

  /**
   * On init
   */
  ngOnInit(): void {
    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, {
      id: this._translateService.currentLang,
    });
    this._translateService.use(this.selectedLanguage.id);
    this.flagState = this.flagStateParam;
    const tdElement = document.getElementById("contentPresentMemberGridcaptioncell");
if (tdElement) {
  tdElement.removeAttribute("title");
}


  }


  changeMeetGroup = (event) => {
    if (event.isInteracted) {
      setTimeout(() => {
        if (this.model && this.model.meet_group_id) {
          this._coreService.Get("/meetgroup/" + this.model.meet_group_id).subscribe((res) => {
            if (res && res.code === "200" && res.data && res.data.list_items && res.data.list_items.length > 0) {
              this.model.list_delegates = res.data.list_items;
            }
          })
        }
      }, 50);
    }
  }

  changeMeetRoom = (event) => {
    if (event.isInteracted) {
      setTimeout(() => {
        if (this.model && this.model.meet_room_id) {
          this._coreService.Get("/meetroom/" + this.model.meet_room_id).subscribe((res) => {
            if (res && res.code === "200") {
              this.modelPosition = res.data;
              let lstRoomPositions = _.sortBy(_.filter(this.modelPosition.list_items, (item: any) => {
                return item.status == 1;
              }), "table_number");
              this.lstRoomPositionOrigins = _.cloneDeep(lstRoomPositions);
            }
          })
        }
      }, 50);
    }
  }

  changeChair = (event) => {
    if (event.isInteracted) {
      setTimeout(() => {
        if (this.model.user_chair_id) {
          let objChair = _.find(this.lstChairUsers, (item: any) => {
            return item.id == this.model.user_chair_id;
          });

          if (objChair) {
            this.model.user_chair_name = objChair.name;
          }
        }

      })
    }
  }

  changeSecretary = (event) => {
    if (event.isInteracted) {
      setTimeout(() => {
        if (this.model.user_secretary_id) {
          let objUser = _.find(this.lstSecretaryUsers, (item: any) => {
            return item.id == this.model.user_secretary_id;
          });

          if (objUser) {
            this.model.user_secretary_name = objUser.name;
          }
        }

      })
    }
  }

  changeFollow = (event) => {
    if (event.isInteracted) {
      setTimeout(() => {
        if (this.model.user_follow_id) {
          let objUser = _.find(this.lstTrackingUsers, (item: any) => {
            return item.id == this.model.user_follow_id;
          });

          if (objUser) {
            this.model.user_follow_name = objUser.name;
          }
        }

      })
    }
  }

  // Lưu thông tin
  // saveData(saveType) {
  //   this._configService.loadingSubject.next("true");
  //   if (!this.editForm.valid) {
  //     for (const key of Object.keys(this.editForm.controls)) {
  //       if (this.editForm.controls[key].invalid) {
  //         const invalidControl = this.el.nativeElement.querySelector(
  //           '[formcontrolname="' + key + '"]'
  //         );
  //         if (invalidControl) {
  //           if (invalidControl.querySelector("input")) {
  //             invalidControl.querySelector("input").focus();
  //           } else {
  //             invalidControl.focus();
  //           }
  //           break;
  //         }
  //       }
  //     }
  //     this.notification.warning("notify.EDIT_ERROR");
  //     this.editForm.markAllAsTouched();
  //     this._configService.loadingSubject.next("false");
  //     return;
  //   } else {
  //     if (!this.model.list_delegates || this.model.list_delegates.length == 0) {
  //       this._configService.loadingSubject.next("false");
  //       return this.notification.warning("Bạn chưa chọn đại biểu!");
  //     }
  //     if (!this.model.list_contents || this.model.list_contents.length == 0) {
  //       this._configService.loadingSubject.next("false");
  //       return this.notification.warning("Bạn chưa nhập nội dung họp!");
  //     }

  //     for (let i = 0; i < this.model.list_contents.length; i++) {
  //       if (!this.model.list_contents[i].content) {
  //         this._configService.loadingSubject.next("false");
  //         return this.notification.warning("Bạn chưa nhập đủ nội dung họp dòng thứ " + (i + 1));
  //       }

  //       // if (moment(this.globals.convertDateStringFull(this.model.list_contents[i].meeting_time2), "DD/MM/YYYY HH:mm:ss").isBefore(moment(this.globals.convertDateStringFull(this.model.from_date), "DD/MM/YYYY HH:mm:ss"))
  //       //   || moment(this.globals.convertDateStringFull(this.model.list_contents[i].meeting_time2), "DD/MM/YYYY HH:mm:ss").isAfter(moment(this.globals.convertDateStringFull(this.model.to_date), "DD/MM/YYYY HH:mm:ss"))) {
  //       //   this._configService.loadingSubject.next("false");
  //       //   return this.notification.warning("Thời gian nội dung không nằm trong thời gian họp dòng thứ " + (i + 1));
  //       // }
  //     }

  //     const modelRequest = this.prepareModelBeforeSave();

  //     modelRequest.save_type = saveType;
  //     const url =
  //       this.flagState && this.flagState === "new"
  //         ? "/meeting/create"
  //         : "/meeting/update";
  //         if (url == "/meeting/update") {
  //           for (var i = 0; i < modelRequest.list_contents.length; i++) {
  //             if (modelRequest.list_contents[i].content_childs != null) {
  //               for (var j = 0; j < modelRequest.list_contents[i].content_childs.length; j++) {
  //                 if (modelRequest.list_contents[i].content_childs[j].meeting_time2 != null) {
  //                   const date = (moment(modelRequest.list_contents[i].content_childs[j].meeting_time2)).format('DD/MM/YYYY HH:mm:ss');
  //                   if (date != "Invalid date") {
  //                     modelRequest.list_contents[i].content_childs[j].meeting_time2 = date
  //                   }
  //                 }
  //               }
  //             }
  //           }
  //         }
  //     this._coreService.Post(url, modelRequest).subscribe(
  //       (res) => {
  //         if (res && res.code == "200") {
  //           this.notification.success(this.flagState && this.flagState === "new" ? "Đăng ký lịch họp thành công!" : "Cập nhật thành công!");
  //           this._configService.loadingSubject.next("false");
  //           this.router.navigate(['/cms/meeting/meetingnew'], {
  //             queryParams: { data: JSON.stringify(modelRequest.user_chair_id) }
  //           });
  //         } else {
  //           this._configService.loadingSubject.next("false");
  //           this.notification.warning(`${res.error}!`);
  //         }
  //       },
  //       (error) => {
  //         this._configService.loadingSubject.next("false");
  //         this.notification.warning(`Lỗi hệ thống!`);
  //       }
  //     );
  //   }
  // }

  saveData(saveType) {
    this._configService.loadingSubject.next("true");
    if (!this.editForm.valid) {
      for (const key of Object.keys(this.editForm.controls)) {
        if (this.editForm.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector(
            '[formcontrolname="' + key + '"]'
          );
          if (invalidControl) {
            if (invalidControl.querySelector("input")) {
              invalidControl.querySelector("input").focus();
            } else {
              invalidControl.focus();
            }
            break;
          }
        }
      }
      this.notification.warning("notify.EDIT_ERROR");
      this.editForm.markAllAsTouched();
      this._configService.loadingSubject.next("false");
      return;
    } else {
      if (!this.model.list_delegates || this.model.list_delegates.length == 0) {
        this._configService.loadingSubject.next("false");
        return this.notification.warning("Bạn chưa chọn đại biểu!");
      }
      if (!this.model.list_contents || this.model.list_contents.length == 0) {
        this._configService.loadingSubject.next("false");
        return this.notification.warning("Bạn chưa nhập nội dung họp!");
      }

      for (let i = 0; i < this.model.list_contents.length; i++) {
        if (!this.model.list_contents[i].content) {
          this._configService.loadingSubject.next("false");
          return this.notification.warning("Bạn chưa nhập đủ nội dung họp dòng thứ " + (i + 1));
        }

        // if (moment(this.globals.convertDateStringFull(this.model.list_contents[i].meeting_time2), "DD/MM/YYYY HH:mm:ss").isBefore(moment(this.globals.convertDateStringFull(this.model.from_date), "DD/MM/YYYY HH:mm:ss"))
        //   || moment(this.globals.convertDateStringFull(this.model.list_contents[i].meeting_time2), "DD/MM/YYYY HH:mm:ss").isAfter(moment(this.globals.convertDateStringFull(this.model.to_date), "DD/MM/YYYY HH:mm:ss"))) {
        //   this._configService.loadingSubject.next("false");
        //   return this.notification.warning("Thời gian nội dung không nằm trong thời gian họp dòng thứ " + (i + 1));
        // }
      }

      const modelRequest = this.prepareModelBeforeSave();

      modelRequest.save_type = saveType;
      const url =
        this.flagState && this.flagState === "new"
          ? "/meeting/create"
          : "/meeting/update";
      if (url == "/meeting/update") {
        for (var i = 0; i < modelRequest.list_contents.length; i++) {
          if (modelRequest.list_contents[i].content_childs != null) {
            for (var j = 0; j < modelRequest.list_contents[i].content_childs.length; j++) {
              if (modelRequest.list_contents[i].content_childs[j].meeting_time2 != null) {
                const date = (moment(modelRequest.list_contents[i].content_childs[j].meeting_time2)).format('DD/MM/YYYY HH:mm:ss');
                if (date != "Invalid date") {
                  modelRequest.list_contents[i].content_childs[j].meeting_time2 = date
                }
              }
            }
          }
        }
      }
      this._coreService.Post(url, modelRequest).subscribe(
        (res) => {
          if (res && res.code == "200") {
            this.notification.success(this.flagState && this.flagState === "new" ? "Đăng ký lịch họp thành công!" : "Cập nhật thành công!");
            this._configService.loadingSubject.next("false");
            this.router.navigate(["/cms/meeting/meetingnew"]);
          } else {
            this._configService.loadingSubject.next("false");
            this.notification.warning(`${res.error}!`);
          }
        },
        (error) => {
          this._configService.loadingSubject.next("false");
          this.notification.warning(`Lỗi hệ thống!`);
        }
      );
    }
  }

  prepareModelBeforeSave = () => {
    let objAPI = Object.assign({}, this.model);
    if (objAPI.from_date) {
      objAPI.from_date = this.globals.convertDateStringFull(objAPI.from_date);
    }

    if (objAPI.to_date) {
      objAPI.to_date = this.globals.convertDateStringFull(objAPI.to_date);
    }

    // objAPI.list_contents = _.sortBy(objAPI.list_contents, "meeting_time2");
    let objContent1, objContent2;
    if (objAPI.list_contents && objAPI.list_contents.length > 0) {
      for (let i = 0; i < objAPI.list_contents.length; i++) {
        objAPI.list_contents[i].orders = i + 1;
        if (objAPI.list_contents[i].meeting_time2) {
          objAPI.list_contents[i].meeting_time2 = this.globals.convertDateStringFull(objAPI.list_contents[i].meeting_time2);
        }

        if (i == 0) {
          objContent1 = _.cloneDeep(objAPI.list_contents[i]);
        }

        if (i == (objAPI.list_contents.length - 1)) {
          objContent2 = _.cloneDeep(objAPI.list_contents[i]);
        }

        if (objAPI.list_contents[i].content_childs && objAPI.list_contents[i].content_childs.length > 0) {
          for (let j = 0; j < objAPI.list_contents[i].content_childs.length; j++) {
            if (objAPI.list_contents[i].content_childs[j].meeting_time2) {
              objAPI.list_contents[i].content_childs[j].meeting_time2 = this.globals.convertDateStringFull(objAPI.list_contents[i].content_childs[j].meeting_time2);
            }
          }
        }
      }
    }

    objAPI.list_contents.unshift({
      content: "Khai mạc",
      meeting_time2: null,
      orders: 0,
      list_members: objContent1 ? objContent1.list_members : [],
      list_guests: objContent1 ? objContent1.list_guests : []
    });

    objAPI.list_contents.push({
      content: "Bế mạc",
      meeting_time2: null,
      orders: 99,
      list_members: objContent2 ? objContent2.list_members : [],
      list_guests: objContent2 ? objContent2.list_guests : []
    });

    Object.keys(objAPI).map(
      (k) =>
      (objAPI[k] =
        typeof objAPI[k] == "string" ? objAPI[k].trim() : objAPI[k])
    );

    return objAPI;
  };

  back = () => {
    if (this.screen) {
      if (this.screen == 'MEET_NEW') {
        this.router.navigate(["/cms/meeting/meetingnew"]);
      } else if (this.screen == 'MEET_WAITING') {
        this.router.navigate(["/cms/meeting/meetingwaiting"]);
      } else {
        this.router.navigate(["/cms/meeting/meetingnew"]);
      }
    } else {
      this.router.navigate(["/cms/meeting/meetingnew"]);
    }
  }

  // filter status
  public onFiltering(e, lst) {
    e.preventDefaultProvince = true;
    const predicate = new Predicate("name", "contains", e.text, true, true);
    this.query = new Query();
    this.query = e.text !== "" ? this.query.where(predicate) : this.query;
    e.updateData(lst, this.query);
  }

  checkDateVsDateNow = (event: any, modelName) => {

    setTimeout(() => {
      //Convert current time to moment object with utc time
      let from_date = this.model.from_date ? moment(this.model.from_date, "DD/MM/YYYY HH:mm:ss") : null;
      let to_date = this.model.to_date ? moment(this.model.to_date, "DD/MM/YYYY HH:mm:ss") : null;
      let dateNow = moment(new Date(), "DD/MM/YYYY HH:mm:ss");
      if (
        this.flagState !== "view" &&
        dateNow &&
        from_date &&
        dateNow.isAfter(from_date)
        && modelName === 'from_date'
      ) {
        const control = this.editForm.get("from_date");
        const err: ValidationErrors = { 'incorrectVsDateNow': true };
        setTimeout(() => {
          control.markAsDirty();
          control.markAsTouched();
          control.setErrors(err);
          $(`#${modelName}`).focus();
        }, 300)
        return;
      }

      // if (modelName == "from_date") {
      //   if (event && event.isInteracted && !to_date) {
      //     this.model.to_date = from_date.format("DD/MM/YYYY HH:mm:ss");
      //   }
      //   this.minDateContent = new Date(parseInt(from_date.format("YYYY")) + "-" + parseInt(from_date.format("MM")) + "-" + parseInt(from_date.format("DD")));
      // }

      if (this.flagState !== "view" &&
        from_date &&
        to_date &&
        from_date.isAfter(to_date)) {
        const control = this.editForm.get("to_date");
        const err: ValidationErrors = { 'incorrectVsFromDate': true };
        setTimeout(() => {
          control.markAsDirty();
          control.markAsTouched();
          control.setErrors(err);
          $("#to_date").focus();
        }, 300)
        return;
      }
    }, 300)
  }

  uploadFile(files: FileList) {
    setTimeout(() => {
      if (files.length > 0) {
        this._configService.loadingSubject.next("true");
        const allowedExtensions = ['.pdf', '.doc', '.docx', '.ppt', '.pptx', '.xlsx', '.xls'];
        const file = files[0];
        const fileName = file.name;
        const fileExtension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
          this.notification.warning("Không thể tải tài liệu lên");
          this._configService.loadingSubject.next("false");
          return;
        }

        for (let i = 0; i < files.length; i++) {
          // check file > 5MB
          const fsize = files.item(i).size;
          const fileSizeInKB = Math.round(fsize / 1024);

          if (fileSizeInKB > 5120) {
            this._configService.loadingSubject.next("false");
            this.notification.warning("Vui lòng tải File < 5MB");
            let x: any = document.getElementById("avatar");
            x.value = null;
            return;
          }
        }

        let data = new FormData();
        data.append("files", files[0]);
        this._coreService.uploadFile(data).subscribe((res) => {
          if (res && res.status && res.status == 200) {
            this.model.invite_files = res.data[0].url;
            this._configService.loadingSubject.next("false");
          } else {
            this._configService.loadingSubject.next("false");
            this.notification.warning("Tải files không thành công");
          }
        }, (err) => {
          this._configService.loadingSubject.next("false");
          this.notification.warning("Tải files không thành công");
        });
      }
    }, 200);
  }

  removeFile = (model) => {
    this.model[model] = "";
    if (model == 'invite_files') {
      this.model.invite_files = "";
    }

    const avatarInput = document.getElementById('files') as HTMLInputElement;
    avatarInput.value = '';
  };

  clickSubToolbarDelegate = (buttonId) => {
    switch (buttonId) {
      case ToolbarItem.ADD:
        this.userIds = [];
        if (this.model.list_delegates && this.model.list_delegates.length > 0) {
          for (let i = 0; i < this.model.list_delegates.length; i++) {
            this.userIds.push({
              id: this.model.list_delegates[i].user_id,
              full_name: this.model.list_delegates[i].full_name,
              title_name: this.model.list_delegates[i].title_name,
              org_name: this.model.list_delegates[i].org_name,
              org_id: this.model.list_delegates[i].org_id,
            })
          }
        }
        this.modalService.open("add-delegate-modal");
        break;
      default:
        break;
    }
  }

  // Cập nhật đồng chủ trì
  updateDelegateRow = (index, model, dataColumn) => {
    setTimeout(() => {
      this.model.list_delegates[index][model] = dataColumn[model];
    }, 50);
  }

  // Gán đại biểu vào nội dung
  grantDelegateContent = (data) => {
    this.dataGrant = data;
    if (this.model.list_contents && this.model.list_contents.length > 0) {
      if (this.dataGrant.list_content_not && this.dataGrant.list_content_not.length > 0) {
        this.lstDelegateContents = _.filter(this.model.list_contents, (item) => {
          return this.dataGrant.list_content_not.indexOf(item.id) == -1;
        });
      } else {
        this.lstDelegateContents = this.model.list_contents;
      }
      let rows = [];
      for (let i = 0; i < this.lstDelegateContents.length; i++) {
        if (this.lstDelegateContents[i].list_members && this.lstDelegateContents[i].list_members.length > 0) {
          let index = _.findIndex(this.lstDelegateContents[i].list_members, (item: any) => {
            return item.user_id == data.user_id;
          });

          if (index > -1) {
            rows.push(i);
          }
        } else {
          continue;
        }
      }

      this.grantType = "DELEGATE";
      this.dataMemberContentGrid.dataSource = new DataManager(this.lstDelegateContents);
      this.modalService.open("grant-delegate-content-modal");
      setTimeout(() => {
        if (rows.length > 0) {
          this.dataMemberContentGrid.selectRows(rows);
        }
      }, 150);
    } else {
      this.notification.warning("Bạn chưa thêm nội dung họp!");
    }
  }

  // Thêm nhanh đại biểu cuộc họp
  closeGrantDelegate = (status) => {
    if (status === "cancel") {
      this.modalService.close("grant-delegate-content-modal");
    } else {
      let selectRecords = this.dataMemberContentGrid.getSelectedRecords();

      if (this.grantType === "DELEGATE") {
        for (let i = 0; i < this.model.list_contents.length; i++) {
          let index = _.findIndex(selectRecords, (item: any) => {
            return item.content == this.model.list_contents[i].content
          });

          if (index > -1) {
            let index2 = _.findIndex(this.model.list_contents[i].list_members, (item: any) => {
              return item.user_id == this.dataGrant.user_id;
            });

            if (index2 == -1) {
              this.model.list_contents[i].list_members.push(this.dataGrant);
            }
          } else {
            let index2 = _.findIndex(this.model.list_contents[i].list_members, (item: any) => {
              return item.user_id == this.dataGrant.user_id;
            });

            if (index2 > -1) {
              let indexDelegate = _.findIndex(this.model.list_delegates, (item) => {
                return item.user_id == this.dataGrant.user_id;
              });

              if (!this.model.list_delegates[indexDelegate].list_content_not) {
                this.model.list_delegates[indexDelegate].list_content_not = [];
              }
              // this.model.list_delegates[indexDelegate].list_content_not.push(this.model.list_contents[i].id);

              this.memberGrid.dataSource = new DataManager(this.model.list_delegates);

              this.model.list_contents[i].list_members.splice(index2, 1);
              this.contentGrid.dataSource = new DataManager(this.model.list_contents);
            }
          }
        }
      } else {
        for (let i = 0; i < this.model.list_contents.length; i++) {
          let index = _.findIndex(selectRecords, (item: any) => {
            return item.content == this.model.list_contents[i].content
          });

          if (index > -1) {
            let index2 = _.findIndex(this.model.list_contents[i].list_guests, (item: any) => {
              return item.phone == this.dataGrant.phone;
            });

            if (index2 == -1) {
              this.model.list_contents[i].list_guests.push(this.dataGrant);
            }
          } else {
            let index2 = _.findIndex(this.model.list_contents[i].list_guests, (item: any) => {
              return item.phone == this.dataGrant.phone;
            });

            let indexGuest = _.findIndex(this.model.list_guests, (item) => {
              return item.phone == this.dataGrant.phone;
            });

            if (!this.model.list_guests[indexGuest].list_content_not) {
              this.model.list_guests[indexGuest].list_content_not = [];
            }
            //this.model.list_guests[indexGuest].list_content_not.push(this.model.list_contents[i].id);

            this.guestGrid.dataSource = new DataManager(this.model.list_guests);

            if (index2 > -1) {
              this.model.list_contents[i].list_guests.splice(index2, 1);
            }
          }
        }
      }
      this.modalService.close("grant-delegate-content-modal");
      this.notification.success("Lưu thành công");
    }
  }

  // Xóa đại biểu khỏi cuộc họp
  deleteRecordDelegate = (data) => {
    let index = _.findIndex(this.model.list_delegates, (item) => {
      return item.user_id == data.user_id;
    });

    if (index > -1) {
      this.model.list_delegates.splice(index, 1);

      if (this.model.list_contents && this.model.list_contents.length > 0) {
        for (let j = 0; j < this.model.list_contents.length; j++) {
          if (!this.model.list_contents[j].list_members) {
            this.model.list_contents[j].list_members = [];
          }

          let indexMember = _.findIndex(this.model.list_contents[j].list_members, (item) => {
            return item.user_id == data.user_id;
          });

          if (indexMember > -1) {
            this.model.list_contents[j].list_members.splice(indexMember, 1);
          }
        }
      }

    };

    this.memberGrid.dataSource = new DataManager(this.model.list_delegates);
  }

  clickSubToolbarGuest = (buttonId) => {
    switch (buttonId) {
      case ToolbarItem.ADD:
        this.dataGuestModals = _.cloneDeep(this.model.list_guests ? this.model.list_guests : []);
        this.dataGuestModalGrid.dataSource = new DataManager(this.dataGuestModals);
        this.modalService.open("add-guest-modal");
        break;
      default:
        break;
    }
  }

  deleteRecordGuest = (data) => {
    let index = _.findIndex(this.model.list_guests, (item) => {
      return item.phone == data.phone;
    });

    if (index > -1) {
      this.model.list_guests.splice(index, 1);
    };

    this.guestGrid.dataSource = new DataManager(this.model.list_guests);
  }

  clickSubToolbarGuestModal = (buttonId) => {
    switch (buttonId) {
      case ToolbarItem.ADD:
        this.dataGuestModals.push({
          id: uuidv4(),
          full_name: "",
          phone: "",
          email: "",
          org_name: "",
        });

        this.dataGuestModalGrid.dataSource = new DataManager(this.dataGuestModals);
        break;
      default:
        break;
    }
  }

  deleteRecordGuestModal = (data) => {
    let index = _.findIndex(this.dataGuestModals, (item) => {
      return item.phone == data.phone;
    });

    if (index > -1) {
      this.dataGuestModals.splice(index, 1);
    };

    this.dataGuestModalGrid.dataSource = new DataManager(this.dataGuestModals);
  }

  closeGuestModal = () => {
    this.modalService.close("add-guest-modal");
  }

  saveGuestModal = () => {
    let list_guests = [];
    let is_valid_guest = true;
    if (this.dataGuestModals.length > 0) {
      for (let i = 0; i < this.dataGuestModals.length; i++) {
        if (this.dataGuestModals[i].phone) {
          const validMobile = this.globals.checkMobile(this.dataGuestModals[i].phone);
          const validMail = this.globals.validateEmail(this.dataGuestModals[i].email);

          if(!this.dataGuestModals[i].full_name) {
            this.notification.warning("Bạn chưa nhập họ tên tại dòng" + (i+1)+ "!");
            return;
          } else if (!validMobile) {
            this.notification.warning("Số điện thoại không đúng định dạng tại dòng " + (i + 1) + "!");
            return;
          } else if (this.dataGuestModals[i].email && !validMail) {
            this.notification.warning("Email không đúng định dạng tại dòng " + (i + 1) + "!");
            return;
          } else {
            let index = _.findIndex(list_guests, (item) => {
              return item.phone == this.dataGuestModals[i].phone;
            });

            if (index == -1) {
              list_guests.push(this.dataGuestModals[i]);
            }
            // Nếu có nội dung
            if (this.model.list_contents && this.model.list_contents.length > 0) {
              for (let j = 0; j < this.model.list_contents.length; j++) {
                let index = _.findIndex(this.model.list_contents[j].list_guests, (item) => {
                  return item.phone == this.dataGuestModals[i].phone;
                });

                if (index == -1) {
                  if (!this.model.list_contents[j].list_guests) {
                    this.model.list_contents[j].list_guests = [];
                  }
                  this.model.list_contents[j].list_guests.push(this.dataGuestModals[i]);

                  this.contentGrid.dataSource = new DataManager(this.model.list_contents);
                }
              }
            }
          }
        } else {
          this.notification.warning("Bạn chưa nhập đủ thông tin tại dòng " + (i + 1) + "!");
          return;
        }
      }
    } else {
      list_guests = [];
    }

    if (is_valid_guest) {
      this.model.list_guests = list_guests;
      this.resetContent();
    } else {
      this.notification.warning("Bạn chưa nhập đủ thông tin khách mời!");
      return;
    }
    this.modalService.close("add-guest-modal");
  }

  updateGuestModalRow = (index, model, dataColumn) => {
    setTimeout(() => {
      this.dataGuestModals[index][model] = dataColumn[model];
    }, 50);
  }

  grantGuestContent = (data) => {
    this.dataGrant = data;
    if (this.model.list_contents && this.model.list_contents.length > 0) {
      if (this.dataGrant.list_content_not && this.dataGrant.list_content_not.length > 0) {
        this.lstDelegateContents = _.filter(this.model.list_contents, (item) => {
          return this.dataGrant.list_content_not.indexOf(item.id) == -1;
        });
      } else {
        this.lstDelegateContents = this.model.list_contents;
      }
      let rows = [];
      for (let i = 0; i < this.lstDelegateContents.length; i++) {
        if (this.lstDelegateContents[i].list_guests && this.lstDelegateContents[i].list_guests.length > 0) {
          let index = _.findIndex(this.lstDelegateContents[i].list_guests, (item: any) => {
            return item.phone == data.phone;
          });

          if (index > -1) {
            rows.push(i);
          }
        } else {
          continue;
        }
      }

      this.dataMemberContentGrid.dataSource = new DataManager(this.lstDelegateContents);
      this.grantType = "GUEST";
      this.modalService.open("grant-delegate-content-modal");
      setTimeout(() => {
        if (rows.length > 0) {
          this.dataMemberContentGrid.selectRows(rows);
        }
      }, 150);
    } else {
      this.notification.warning("Bạn chưa thêm nội dung họp!");
    }
  }

  deleteRecordDocument = (data) => {
    let index = _.findIndex(this.model.list_documents, (item) => {
      return item.file_name == data.file_name;
    });

    if (index > -1) {
      this.model.list_documents.splice(index, 1);
    };

    this.documentGrid.refreshColumns();
  }
  // Tài liệu
  uploadDocument(files: FileList) {
    setTimeout(() => {
      if (files.length > 0) {
        this._configService.loadingSubject.next("true");
        const allowedExtensions = ['.pdf', '.doc', '.docx', '.ppt', '.pptx', '.xlsx', '.xls'];
        const file = files[0];
        const fileName = file.name;
        const fileExtension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
          this.notification.warning("Không thể tải tài liệu lên");
          this._configService.loadingSubject.next("false");
          return;
        }
        for (let i = 0; i < files.length; i++) {
          // check file > 5MB
          const fsize = files.item(i).size;
          const file = Math.round(fsize / 1024);
        }
        let data = new FormData();
        data.append("files", files[0]);
        this._coreService.uploadFile(data).subscribe((res) => {
          if (res && res.status && res.status == 200) {
            if (!this.model.list_documents) {
              this.model.list_documents = [];
            }
            this.model.list_documents.push({
              file_name: res.data[0].name,
              links: res.data[0].url
            });
            this.documentGrid.dataSource = new DataManager(this.model.list_documents);
            this.documentGrid.refresh();
            this._configService.loadingSubject.next("false");
            this.notification.success("Tải files thành công");
          } else {
            this._configService.loadingSubject.next("false");
            this.notification.warning("Tải files không thành công");
          }
          let x: any = document.getElementById("documents");
          x.value = null;
          return;
        }, (err) => {
          this._configService.loadingSubject.next("false");
          this.notification.warning("Tải files không thành công");
        });
      }
    }, 200);
  }

  updateDocumentRow = (index, model, dataColumn) => {
    setTimeout(() => {
      this.model.list_documents[index][model] = dataColumn[model];
    }, 50);
  }

  clickSubToolbarContent = (buttonId) => {
    switch (buttonId) {
      case ToolbarItem.ADD:
        if (!this.model.list_contents) {
          this.model.list_contents = [];
        }

        let objContent = {
          id: uuidv4(),
          meeting_time2: this.model.from_date ? moment(this.model.from_date).format("DD/MM/YYYY HH:mm:ss") : null,
          content: "",
          list_documents: [],
          list_guests: _.cloneDeep(this.model.list_guests),
          list_members: _.cloneDeep(this.model.list_delegates),
          list_shares: [],
          content_childs: [],
        };

        this.model.list_contents.push(objContent);

        this.contentGrid.dataSource = new DataManager(this.model.list_contents);
        break;
      default:
        break;
    }
  }

  deleteRecordContent = (data: any) => {
    this.model.list_contents = this.model.list_contents.filter(item => item.id != data.id);
    this.contentGrid.dataSource = new DataManager(this.model.list_contents);
    this.contentGrid.refresh();
  }



  updateContentRow = (index, model, dataColumn) => {
    setTimeout(() => {
      if (model == "content") {
        this.model.list_contents[index][model] = dataColumn[model];
      } else if (model == "meeting_time2") {
        this.model.list_contents[index][model] = this.globals.convertDateStringFull(dataColumn[model]);
        setTimeout(() => {
          // this.model.list_contents = _.sortBy(this.model.list_contents, "meeting_time2");
          this.contentGrid.dataSource = new DataManager(this.model.list_contents);
        }, 0);
      }


    }, 50);
  }


  testXepghe: boolean = false
  objContentCopy: any = {};
  clickContentRecord = (data, action_id) => {
    this.contentIndex = Number(data.index);
    // this.dataContentUpdate = data;
    let objContent = _.find(this.model.list_contents, (item) => {
      return item.id == data.id;
    });
    if (objContent) {
      this.dataContentUpdate = objContent;
    }

    switch (action_id) {
      case "document":
        this.lstContentDocuments = this.dataContentUpdate && this.dataContentUpdate.list_documents ? _.cloneDeep(data.list_documents) : [];
        this.modalService.open("content-document-modal");
    this._configService.loadingSubject.next("false");
        break;

      case "present":
  const updatePresentData = async () => {
    this.lstOrgPresents = [];
    this.dataContentUpdate.list_members = this.dataContentUpdate.list_members;

    if (this.dataContentUpdate.list_members && this.dataContentUpdate.list_members.length > 0) {
      for (let i = 0; i < this.dataContentUpdate.list_members.length; i++) {
        let indexOrg = _.findIndex(this.lstOrgPresents, (item) => {
          return item.id == this.dataContentUpdate.list_members[i].org_id;
        });

        if (indexOrg == -1) {
          this.lstOrgPresents.push({
            id: this.dataContentUpdate.list_members[i].org_id,
            name: this.dataContentUpdate.list_members[i].org_name
          });
        }
      }
    }

    if (this.contentPresentOrgGrid) {
      this.contentPresentOrgGrid.dataSource = new DataManager(this.lstOrgPresents);
    }

    this.dataContentUpdate.list_presents = this.dataContentUpdate.list_presents;

    if (this.dataContentUpdate && this.dataContentUpdate.list_presents && this.dataContentUpdate.list_presents.length > 0) {
      this.select_present = this.dataContentUpdate.list_presents[0].type_id == 1 ? "org" : "user";
      if (this.select_present == "org") {
        this.lstContentPresentOrgs = this.dataContentUpdate.list_presents;
      } else {
        this.lstContentPresentMembers = this.dataContentUpdate.list_presents;
      }

      this.changePresent();
    } else {
      this.select_present = "org";
      if (this.dataContentUpdate.list_members && this.dataContentUpdate.list_members.length > 0) {
        for (let i = 0; i < this.dataContentUpdate.list_members.length; i++) {
          let indexOrg = _.findIndex(this.lstOrgPresents, (item) => {
            return item.id == this.dataContentUpdate.list_members[i].org_id;
          });

          if (indexOrg == -1) {
            this.lstOrgPresents.push({
              id: this.dataContentUpdate.list_members[i].org_id,
              name: this.dataContentUpdate.list_members[i].org_name
            });
          }
        }
      }
      this.contentPresentOrgGrid.dataSource = new DataManager(this.lstOrgPresents);
    }

    await new Promise((resolve) => setTimeout(resolve, 0)); // Đợi cho việc cập nhật dữ liệu hoàn tất
    this.modalService.open("content-present-modal");
  };

  updatePresentData();
  break;
      case "config":
        this.lstContentShareLists = this.dataContentUpdate.list_members ? _.cloneDeep(this.dataContentUpdate.list_members) : [];
        this.contentShareListGrid.dataSource = new DataManager(this.lstContentShareLists);
        this.lstContentShares = this.dataContentUpdate && this.dataContentUpdate.list_shares ? _.cloneDeep(this.dataContentUpdate.list_shares) : [];
        // this.lstContentShares = this.lstContentShareLists
        this.contentShareGrid.dataSource = new DataManager(this.lstContentShares);
        setTimeout(() => {
          if (this.lstContentShares && this.lstContentShares.length > 0) {
            let ids = _.map(this.lstContentShares, "user_id");
            let indexs = [];
            let data = this.contentShareListGrid.currentViewData["records"];
            for (let i = 0; i < ids.length; i++) {
              let index = _.findIndex(this.contentShareListGrid.currentViewData["records"], (item) => {
                return item.user_id === ids[i];
              });


              if (index > -1) {
                indexs.push(index);
              }
            }
            if (indexs.length > 0) {
              this.contentShareListGrid.selectRows(indexs);
            }
          }
        }, 500);
        this.modalService.open("content-share-modal");
        break;
      case "member":
        this.lstContentDelegates = this.dataContentUpdate && this.dataContentUpdate.list_members ? _.cloneDeep(this.dataContentUpdate.list_members) : [];
        this.lstContentGuests = this.dataContentUpdate && this.dataContentUpdate.list_guests ? _.cloneDeep(this.dataContentUpdate.list_guests) : [];
        this.contentMemberGrid.dataSource = new DataManager(this.lstContentDelegates);
        this.contentGuestGrid.dataSource = new DataManager(this.lstContentGuests);
        this.modalService.open("content-member-modal");
        break;
        // case "roomPosition":
        // if (!this.editForm.valid) {
        //   return this.notification.warning("Bạn chưa nhập đủ thông tin cuộc họp!");
        // }

        // if (!this.lstContentDelegates ) {
        //   return this.notification.warning("Bạn chưa nhập thành phần tham gia nội dung họp!");
        // }
        // this.lstRoomPositions = this.dataContentUpdate.list_rooms && this.dataContentUpdate.list_rooms.length > 0 ? _.cloneDeep(this.dataContentUpdate.list_rooms) : _.cloneDeep(this.lstRoomPositionOrigins);
        // this.getWaitingPosition();
        // setTimeout(() => {
        //   this.modalService.open("room-position-modal");
        // }, 100);

        // break;
        case "roomPosition":
          if (!this.editForm.valid) {
            return this.notification.warning("Bạn chưa nhập đúng thông tin cuộc họp!");
          }

          if (!this.dataContentUpdate.list_members || this.dataContentUpdate.list_members.length == 0) {
            return this.notification.warning("Bạn chưa nhập thành phần tham gia nội dung họp!");
          }

          this.lstRoomPositions = this.dataContentUpdate.list_rooms && this.dataContentUpdate.list_rooms.length > 0 ? _.cloneDeep(this.dataContentUpdate.list_rooms) : _.cloneDeep(this.lstRoomPositionOrigins);
          this.getWaitingPosition();

          setTimeout(() => {
            this.modalService.open("room-position-modal");
          }, 100);
          break;

        case "voting":
          if (!this.dataContentUpdate.list_members || this.dataContentUpdate.list_members.length == 0) {
            return this.notification.warning("Bạn chưa nhập thành phần tham gia nội dung họp!");
          }
          this.lstVotings = this.dataContentUpdate.list_votings ? _.cloneDeep(this.dataContentUpdate.list_votings) : [];
          this.modalService.open("voting-modal");
          document.body.classList.add('modal-open');
          break;
        // case "copy":
        //   let objContentCopy = _.cloneDeep(this.dataContentUpdate);
        //   objContentCopy.id = uuidv4();
        //   const lstRoomData = _.cloneDeep(this.lstRoomPositions)
        //   objContentCopy = {...objContentCopy, list_rooms: lstRoomData, list_votings: _.cloneDeep(this.dataContentUpdate.list_votings) }
        //   objContentCopy.index = this.contentIndex + 1;
        //   // let currentIndex = parseInt(objContentCopy.index, 10); // Chuyển đổi index thành số nguyên
        //   // currentIndex++; // Tăng giá trị của currentIndex lên 1
        //   // objContentCopy.index = currentIndex.toString(); // Chuyển đổi currentIndex trở lại dạng chuỗi

        //   // objContentCopy.index = objContentCopy.index; // Gán giá trị mới cho index
        //   this.model.list_contents.push(objContentCopy);
        //   this.contentGrid.dataSource = new DataManager(this.model.list_contents);
        //   break;
        case "copy":
          let copyCount = 1
              this.objContentCopy = _.cloneDeep(this.dataContentUpdate);
              this.objContentCopy.id = uuidv4();
              const lstRoomData = _.cloneDeep(this.lstRoomPositions);
              this.objContentCopy.list_rooms = lstRoomData;
              this.objContentCopy.list_votings = _.cloneDeep(this.dataContentUpdate.list_votings);
              // this.objContentCopy.index = (copyCount).toString();

              if (this.objContentCopy.content_childs) {
                this.objContentCopy.content_childs = this.objContentCopy.content_childs.map((child) => {
                  let childCopy = _.cloneDeep(child);
                  childCopy.id = uuidv4();
                  return childCopy;
                });
              }

              this.model.list_contents.push(this.objContentCopy);
              this.contentGrid.dataSource = new DataManager(this.model.list_contents);
              copyCount++
              break;
      default:
        break;
    }
  }
  // Thêm biểu quyết
  addVoting = () => {
    this.modelVoting = new MeetingVoting();
    this.modelVoting.id = uuidv4();
    this.flagStateModal = "new";
    this.votingForm.enable();
    this.votingMemberGrid.refreshColumns();
    this.votingForm.markAsUntouched();
    this.lstDelegateVotings = _.cloneDeep(this.dataContentUpdate.list_members);
    this.votingMemberGrid.dataSource = new DataManager(this.lstDelegateVotings);

    this.modalService.open("add-voting-modal");
    this.votingMemberGrid.showColumns(["Chọn"]);

    this.votingMemberGrid.allowSelection = true;
    this.votingMemberGrid.refreshColumns();
  }

  clickVotingRecord = (data, status) => {
    if (status === "view") {
      this.flagStateModal = "view";
      this.modelVoting = data;
      this.votingForm.disable();
      let ids = _.map(this.modelVoting.list_items, "user_id");
      var indexs = [];

      let delegateVotings = _.cloneDeep(this.dataContentUpdate.list_members);
      this.lstDelegateVotings = _.filter(delegateVotings, (item) => {
        return ids.indexOf(item.user_id) > -1;
      });

      this.votingMemberGrid.dataSource = new DataManager(this.lstDelegateVotings);

      this.modalService.open("add-voting-modal");
      setTimeout(() => {
        this.votingMemberGrid.hideColumns(["Chọn"]);
        this.votingMemberGrid.allowSelection = false;
        this.votingMemberGrid.refreshColumns();
      }, 100);

    }

    if (status === "edit") {
      this.flagStateModal = "edit";
      this.modelVoting = data;
      this.votingForm.enable();
      let ids = _.map(this.modelVoting.list_items, "user_id");
      var indexs = [];
      this.votingMemberGrid.showColumns(["Chọn"]);
      this.votingMemberGrid.allowSelection = true;

      this.lstDelegateVotings = _.cloneDeep(this.dataContentUpdate.list_members);
      this.votingMemberGrid.dataSource = new DataManager(this.lstDelegateVotings);

      this.modalService.open("add-voting-modal");
      setTimeout(() => {
        let lstVotingMembers = this.votingMemberGrid.currentViewData["records"];

        for (let i = 0; i < ids.length; i++) {
          let index = _.findIndex(lstVotingMembers, (item: any) => {
            return item.user_id == ids[i];
          });

          if (index > -1) {
            indexs.push(index);
          }
        }

        if (indexs.length > 0) {
          this.votingMemberGrid.selectRows(indexs);
        }
      }, 200);
    }

    if (status === "delete") {
      this.modelVoting = data;
      this.modalService.open("confirm-delete-voting-modal");
    }
  }

  // Lưu biểu quyết
  saveVoting(status) {
    if (status == "cancel") {
      this.modelVoting = new MeetingVoting();
      this.flagStateModal = "new";

      this.modalService.close("add-voting-modal");
    } else {
      let selectMembers: any = this.votingMemberGrid.getSelectedRecords();

if (this.votingForm.invalid || selectMembers.length == 0) {
  this.notification.warning("Bạn chưa nhập đủ thông tin tạo biểu quyết");
  return;
}

let list_items = [];
for (let i = 0; i < selectMembers.length; i++) {
  list_items.push({
    user_id: selectMembers[i].user_id,
  });
}

this.modelVoting.list_items = list_items;

if (this.flagStateModal == "new") {
  // Thêm mới biểu quyết vào lstVotings
  this.lstVotings.unshift(this.modelVoting);
} else {
  let indexVoting = this.lstVotings.findIndex((item: any) => item.id === this.modelVoting.id);
  if (indexVoting > -1) {
    this.lstVotings[indexVoting] = this.modelVoting;
  }
}

this.votingGrid.dataSource = new DataManager({ json: this.lstVotings });
this.modelVoting = new MeetingVoting();
this.flagStateModal = "new";

this.modalService.close("add-voting-modal");
    }
  }

  // Xóa biểu quyết
  confirmDeleteVoting = (status) => {
    if (status == "cancel") {
      this.modalService.close("confirm-delete-voting-modal");
    } else {

      let indexVoting = _.findIndex(this.lstVotings, (item: any) => {
        return item.id == this.modelVoting.id;
      });

      if (indexVoting > -1) {
        this.lstVotings.splice(indexVoting, 1);
        this.votingGrid.dataSource = new DataManager(this.lstVotings);
        this.notification.success("Xóa biểu quyết thành công!");
        this.modalService.close("confirm-delete-voting-modal");
      }
    }
  }


  // Lưu biểu quyết chung
  saveContentVoting = (status) => {
    if (status == 'cancel') {
      this.lstVotings = [];
      this.votingGrid.dataSource = new DataManager(this.lstVotings);
      this.modalService.close("voting-modal");
      document.body.classList.remove('modal-open');
    } else {
      let indexContent = _.findIndex(this.model.list_contents, (item: any) => {
        return item.id == this.dataContentUpdate.id;
      });

      if (indexContent > -1) {
        this.model.list_contents[indexContent].list_votings = this.lstVotings;
      }
      this.lstVotings = [];
      this.votingGrid.dataSource = new DataManager(this.lstVotings);
      this.modalService.close("voting-modal");
      document.body.classList.remove('modal-open');
    }
  }

  // Lấy danh sách ghế chờ
  getWaitingPosition = () => {
    let rowWaiting = this.modelPosition.is_hall_room ? (this.modelPosition.vertical_number + this.modelPosition.horizontal_table_number + 3) : (this.modelPosition.vertical_number * 2 + this.modelPosition.horizontal_table_number + 4);
    let maxChairNumber = this.lstRoomPositions && this.lstRoomPositions.length > 0 ? _.maxBy(this.lstRoomPositions, "table_number").table_number : 1;
    maxChairNumber = maxChairNumber + 1;

    var lstDelegateSeats = _.map(_.filter(this.lstRoomPositions, (item: any) => {
      return item.user_id;
    }), "user_id");

    var lstDelegateGuestSeats = _.map(_.filter(this.lstRoomPositions, (item: any) => {
      return item.is_guest == true && item.full_name;
    }), "full_name");

    let lstDelegateContents = [];
    if (this.dataContentUpdate) {
      if (this.dataContentUpdate && this.dataContentUpdate.list_members) {
        lstDelegateContents = _.cloneDeep(this.dataContentUpdate.list_members);
        for (let i = 0; i < lstDelegateContents.length; i++) {
          var objMember = _.find(this.model.list_delegates, (item: any) => {
            return lstDelegateContents[i].user_id == item.user_id;
          });

          if (objMember) {
            if (objMember.is_same_chair) {
              lstDelegateContents[i].is_chair = true;
              lstDelegateContents[i].is_special_position = true;
            } else {
              lstDelegateContents[i].is_chair = false;
              lstDelegateContents[i].is_special_position = false;
            }
          }
        }

        if (this.dataContentUpdate.list_guests && this.dataContentUpdate.list_guests.length > 0) {
          let lstGuestContents = [];

          for (let i = 0; i < this.dataContentUpdate.list_guests.length; i++) {
            lstGuestContents.push({
              user_id: this.dataContentUpdate.list_guests[i].id,
              is_guest: true,
              is_chair: false,
              is_special_position: false,
              full_name: this.dataContentUpdate.list_guests[i].full_name,
              status: 29
            })
          }
          lstDelegateContents = lstDelegateContents.concat(lstGuestContents);
        }
      }

      if (this.model.user_follow_id) {
        let indexFollow = _.findIndex(lstDelegateContents, (item: any) => {
          return item.user_id == this.model.user_follow_id;
        });

        if (indexFollow == -1) {
          lstDelegateContents.unshift({
            user_id: this.model.user_follow_id,
            full_name: this.model.user_follow_name,
            meeting_status: 2,
            is_chair: false,
            is_special_position: false,
            status: 31
          });
        } else {
          lstDelegateContents[indexFollow].is_special_position = false;
        }
      }


      let indexSecretary = _.findIndex(lstDelegateContents, (item: any) => {
        return item.user_id == this.model.user_secretary_id;
      });

      if (indexSecretary == -1) {
        lstDelegateContents.unshift({
          user_id: this.model.user_secretary_id,
          full_name: this.model.user_secretary_name,
          is_chair: false,
          is_special_position: false,
          status: 31
        });
      } else {
        lstDelegateContents[indexSecretary].is_special_position = false;
      }

      let indexChair = _.findIndex(lstDelegateContents, (item: any) => {
        return item.user_id == this.model.user_chair_id;
      });

      if (indexChair == -1) {
        lstDelegateContents.unshift({
          user_id: this.model.user_chair_id,
          full_name: this.model.user_chair_name,
          is_chair: true,
          is_special_position: true,
          status: 31
        });
      } else {
        lstDelegateContents[indexChair].is_special_position = true;
      }
    }

    var lstDelegateWaitings = _.filter(lstDelegateContents, (item: any) => {
      return ((item.is_guest == false || !item.is_guest) && lstDelegateSeats.indexOf(item.user_id) == -1) || (item.is_guest == true && lstDelegateGuestSeats.indexOf(item.full_name) == -1);
    });

    const seatsPosition = this.lstRoomPositions.map(seat => {return {x: seat.x, y: seat.y}});
    const minSeatX = Math.min(...seatsPosition.map(seat => seat.x));
    const maxSeatX = Math.max(...seatsPosition.map(seat => seat.x));
    const minSeatY = Math.min(...seatsPosition.map(seat => seat.y));
    const maxSeatY = Math.max(...seatsPosition.map(seat => seat.y));
    this.seatsMapWidth = maxSeatX - minSeatX + 1;
    this.seatsMapHeight = maxSeatY - minSeatY + 1;
    this.seatsMapStartX = minSeatX;
    this.seatsMapStartY = minSeatY;

    if (lstDelegateWaitings.length > 0) {
      this.lstWaitingPositions = [];
      let x = 0;
      let y = rowWaiting;
      for (let i = 0; i < lstDelegateWaitings.length; i++) {
        x = x + 1;
        if(i % (this.seatsMapWidth + 1) == 0){
          x = 0;
          y = y + 1;
        }
        this.lstWaitingPositions.push({
          meeting_id: this.model && this.model.id ? this.model.id : null,
          content_id: this.dataContentUpdate.id,
          table_number: maxChairNumber + i,
          y: y,
          x: x,
          user_id: lstDelegateWaitings[i].user_id,
          full_name: lstDelegateWaitings[i].full_name,
          is_chair: lstDelegateWaitings[i].is_chair ? lstDelegateWaitings[i].is_chair : false,
          is_special_position: lstDelegateWaitings[i].is_special_position ? lstDelegateWaitings[i].is_special_position : (lstDelegateWaitings[i].is_same_chair ? true : false),
          is_guest: lstDelegateWaitings[i].is_guest ? lstDelegateWaitings[i].is_guest : false,
          meeting_status: lstDelegateWaitings[i].status == 31 ? 2 : (lstDelegateWaitings[i].status == 32 ? 3 : 1)
        });
      }
    } else {
      this.lstWaitingPositions = [];
    }

    // Lấy danh sách ghế trống
    this.lstBlankSeats = _.filter(this.lstRoomPositions, (item: any) => {
      return !item.user_id;
    })
  }

  // Tự động xếp ghế
  autoSaveRoomPosition = () => {
    let lstDelegateContents = [];
    if (this.dataContentUpdate) {
      if (this.dataContentUpdate.list_members) {
        lstDelegateContents = _.cloneDeep(this.dataContentUpdate.list_members);
        for (let i = 0; i < lstDelegateContents.length; i++) {
          var objMember = _.find(this.model.list_delegates, (item: any) => {
            return lstDelegateContents[i].user_id == item.user_id;
          });

          if (objMember) {
            if (objMember.is_same_chair) {
              lstDelegateContents[i].is_chair = true;
              lstDelegateContents[i].is_special_position = true;
            } else {
              lstDelegateContents[i].is_chair = false;
              lstDelegateContents[i].is_special_position = false;
            }
          }
        }
        if (this.dataContentUpdate.list_guests && this.dataContentUpdate.list_guests.length > 0) {
          let lstGuestContents = [];

          for (let i = 0; i < this.dataContentUpdate.list_guests.length; i++) {
            lstGuestContents.push({
              user_id: this.dataContentUpdate.list_guests[i].id,
              is_guest: true,
              is_chair: false,
              is_special_position: false,
              full_name: this.dataContentUpdate.list_guests[i].full_name,
              status: 31
            })
          }
          lstDelegateContents = lstDelegateContents.concat(lstGuestContents);
        }
      }

      if (this.model.user_follow_id) {
        let indexFollow = _.findIndex(lstDelegateContents, (item: any) => {
          return item.user_id == this.model.user_follow_id;
        });

        if (indexFollow == -1) {
          lstDelegateContents.unshift({
            user_id: this.model.user_follow_id,
            full_name: this.model.user_follow_name,
            meeting_status: 2,
            is_chair: false,
            is_special_position: false,
            is_guest: false
          });
        } else {
          lstDelegateContents[indexFollow].is_guest = false;
          lstDelegateContents[indexFollow].is_special_position = false;
        }
      }

      let indexSecretary = _.findIndex(lstDelegateContents, (item: any) => {
        return item.user_id == this.model.user_secretary_id;
      });

      if (indexSecretary == -1) {
        lstDelegateContents.unshift({
          user_id: this.model.user_secretary_id,
          full_name: this.model.user_secretary_name,
          meeting_status: 2,
          is_chair: false,
          is_special_position: false,
          is_guest: false
        });
      } else {
        lstDelegateContents[indexSecretary].is_guest = false;
        lstDelegateContents[indexSecretary].is_special_position = false;
      }

      let indexChair = _.findIndex(lstDelegateContents, (item: any) => {
        return item.user_id == this.model.user_chair_id;
      });

      if (indexChair == -1) {
        lstDelegateContents.unshift({
          user_id: this.model.user_chair_id,
          full_name: this.model.user_chair_name,
          meeting_status: 2,
          is_chair: true,
          is_special_position: true,
          is_guest: false
        });
      } else {
        lstDelegateContents[indexChair].is_guest = false;
        lstDelegateContents[indexChair].is_special_position = true;
      }
    }

    this.lstRoomPositions = _.sortBy(this.lstRoomPositions, "table_number")
    for (let i = 0; i < this.lstRoomPositions.length; i++) {
      this.lstRoomPositions[i].user_id = null;
      this.lstRoomPositions[i].full_name = null;
      this.lstRoomPositions[i].meeting_status = null;
      if (this.lstRoomPositions.length <= lstDelegateContents.length) {
        this.lstRoomPositions[i].user_id = lstDelegateContents[i].user_id;
        this.lstRoomPositions[i].full_name = lstDelegateContents[i].full_name;
        this.lstRoomPositions[i].meeting_status = lstDelegateContents[i].meeting_status || 1;
        this.lstRoomPositions[i].is_special_position = lstDelegateContents[i].is_special_position ? lstDelegateContents[i].is_special_position : (lstDelegateContents[i].is_same_chair ? true : false);
        this.lstRoomPositions[i].is_guest = lstDelegateContents[i].is_guest;
      }
    }

    if (this.lstRoomPositions.length > lstDelegateContents.length) {
      for (let i = 0; i < lstDelegateContents.length; i++) {
        this.lstRoomPositions[i].user_id = lstDelegateContents[i].user_id;
        this.lstRoomPositions[i].full_name = lstDelegateContents[i].full_name;
        this.lstRoomPositions[i].meeting_status = lstDelegateContents[i].meeting_status || 1;
        this.lstRoomPositions[i].is_special_position = lstDelegateContents[i].is_special_position ? lstDelegateContents[i].is_special_position : (lstDelegateContents[i].is_same_chair ? true : false);
        this.lstRoomPositions[i].is_guest = lstDelegateContents[i].is_guest;
      }
    }


    this.lstWaitingPositions = [];
    this.notification.success("Tự động xếp ghế thành công!");
  }

  // Làm mới sơ đồ phòng
  renewRoomPostion = () => {
    for (let i = 0; i < this.lstRoomPositions.length; i++) {
      this.lstRoomPositions[i].user_id = null;
      this.lstRoomPositions[i].full_name = null;
      this.lstRoomPositions[i].meeting_status = null;
      this.lstRoomPositions[i].is_guest = false;
      this.lstRoomPositions[i].is_special_position = false;
    }
    this.notification.success("Làm mới sơ đồ phòng thành công!");
    this.getWaitingPosition();
  }

   // Lưu sơ đồ phòng
   saveRoomPosition = () => {
    this.dataContentUpdate.list_rooms = this.lstRoomPositions;

    var index_content = _.findIndex(this.model.list_contents, (item: any) => {
      return item.id == this.dataContentUpdate.id;
    });

    if (index_content > -1) {
      this.model.list_contents[index_content].list_rooms = _.cloneDeep(this.lstRoomPositions);
    }

    this.notification.success("Lưu thông tin thành công!");
    this.modalService.close("room-position-modal");
  }

  // Chọn ghế
  choosePositionItem = (event, data) => {
    event.preventDefault();
    if (this.flagState == 'view') {
      return;
    }
    this.dataItem = data;
    this.flagShowItem = true;
  }

  chooseSeat = (waitingItem, blankSeatItem) => {
    let objRoom = _.find(this.lstRoomPositions, (item) => {
      return item.table_number == blankSeatItem.table_number;
    });

    if (objRoom) {
      objRoom.user_id = waitingItem.user_id;
      objRoom.full_name = waitingItem.full_name;
      objRoom.meeting_status = waitingItem.meeting_status;
      objRoom.is_chair = waitingItem.is_chair;
      objRoom.is_special_position = waitingItem.is_special_position;
      objRoom.is_guest = waitingItem.is_guest;
    }

    this.getWaitingPosition();
    this.dataItem = null;
    this.flagShowItem = false;
  }

  // Chọn đại biểu
  chooseDelegateItem = (event, data) => {
    event.preventDefault();
    if (this.flagState == 'view') {
      return;
    }
    if (data.user_id) {
      return;
    }
    this.dataItem = data;
    this.flagShowItem = true;
  }

  chooseDelegate = (blankSeatItem, waitingItem) => {
    blankSeatItem.user_id = waitingItem.user_id;
    blankSeatItem.full_name = waitingItem.full_name;
    blankSeatItem.meeting_status = waitingItem.meeting_status;
    blankSeatItem.is_special_position = waitingItem.is_special_position ? waitingItem.is_special_position : false;
    blankSeatItem.is_guest = waitingItem.is_guest ? waitingItem.is_guest : false;
    this.getWaitingPosition();
    this.dataItem = null;
    this.flagShowItem = false;
  }

  clearDelegateItem = (it) => {
    let index = _.findIndex(this.lstRoomPositions, (item: any) => {
      return item.table_number == it.table_number;
    });

    if (index > -1) {
      this.lstRoomPositions[index].user_id = null;
      this.lstRoomPositions[index].full_name = null;
      this.lstRoomPositions[index].status = null;
      this.lstRoomPositions[index].meeting_status = null;
      this.lstRoomPositions[index].is_special_position = false;
      this.lstRoomPositions[index].is_guest = false;
    }
    this.getWaitingPosition();
  }


  changePresent = () => {
    setTimeout(() => {
      // Check chọn bản ghi
      let indexs = [];
      if (this.select_present == "user") {
        for (let i = 0; i < this.dataContentUpdate.list_presents.length; i++) {
          let index = _.findIndex(this.contentPresentMemberGrid.currentViewData["records"], (item) => {
            return item.user_id === this.dataContentUpdate.list_presents[i].user_id;
          });

          if (index > -1) {
            indexs.push(index);
          }
        }
      } else {
        for (let i = 0; i < this.dataContentUpdate.list_presents.length; i++) {
          let index = _.findIndex(this.lstOrgPresents, (item) => {
            return item.id === this.dataContentUpdate.list_presents[i].org_id;
          });

          if (index > -1) {
            indexs.push(index);
          }
        }
      }

      setTimeout(() => {
        if (indexs.length > 0 && this.select_present == "user") {
          this.contentPresentMemberGrid.selectRows(indexs);
        } else if (indexs.length > 0 && this.select_present == "org") {
          this.contentPresentOrgGrid.selectRows(indexs);
        }
      }, 100);
    }, 100);
  }
  // Tài liệu

  uploadDocumentContent(files: FileList) {
    setTimeout(() => {
      if (files.length > 0) {
        const allowedExtensions = ['.pdf', '.doc', '.docx', '.ppt', '.pptx', '.xlsx', '.xls'];
        const file = files[0];
        const fileName = file.name;
        const fileExtension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
          this.notification.warning("Không thể tải tài liệu lên");
          return;
        }

        this._configService.loadingSubject.next("true");
        for (let i = 0; i < files.length; i++) {
          // check file > 5MB
          const fsize = files.item(i).size;
          const fileSize = Math.round(fsize / 1024);
        }
        let data = new FormData();
        data.append("files", files[0]);
        this._coreService.uploadFile(data).subscribe((res) => {
          if (res && res.status && res.status == 200) {
            if (!this.lstContentDocuments) {
              this.lstContentDocuments = [];
            }
            this.lstContentDocuments.push({
              file_name: res.data[0].name,
              links: res.data[0].url,
              orders: this.lstContentDocuments.length + 1,
              is_before_view: true,
              is_before_download: true,
              is_inmeet_view: true,
              is_inmeet_download: true,
              is_after_view: true,
              is_after_download: true
            });
            this.documentGrid.dataSource = new DataManager(this.model.list_documents);
            this._configService.loadingSubject.next("false");
          } else {
            this._configService.loadingSubject.next("false");
            this.notification.warning("Tải files không thành công");
          }
          let x: any = document.getElementById("documents");
          x.value = null;
          return;
        }, (err) => {
          this._configService.loadingSubject.next("false");
          this.notification.warning("Tải files không thành công");
        });
      }
    }, 200);
  }

  closeContentDocumentModal = () => {
    this.modalService.close("content-document-modal");
  }

  saveContentDocumentModal = () => {
    if (this.model.list_contents && this.model.list_contents[this.contentIndex] && this.lstContentDocuments && this.lstContentDocuments.length > 0) {
      this.model.list_contents[this.contentIndex].list_documents = this.lstContentDocuments;
    } else if (this.model.list_contents[this.contentIndex]) {
      this.model.list_contents[this.contentIndex].list_documents = [];
    }
    this.contentGrid.refreshColumns();
    this.modalService.close("content-document-modal");
  }

  deleteContentDocumentModal = (index: any) => {
    if (index > -1) {
      this.lstContentDocuments.splice(index, 1);
      // this.model.list_documents = this.lstContentDocuments;
    };
    const avatarInput = document.getElementById('documentcontents') as HTMLInputElement;
    avatarInput.value = '';
  }

  deleteContentDocument = (data, index) => {
    this.model.list_contents[data.index].list_documents.splice(index, 1);
    this.contentGrid.dataSource = new DataManager(this.model.list_contents);
  }

  updateContentDocumentRows = (index, rowIndex, model, dataColumn) => {
    setTimeout(() => {
      if (this.model.list_contents[index] && this.model.list_contents[index].list_documents && this.model.list_contents[index].list_documents[rowIndex]) {
        this.model.list_contents[index].list_documents[rowIndex][model] = dataColumn[model];
      }
    }, 50);
  }

  deleteContentDelegate = (data) => {
    let index = _.findIndex(this.lstContentDelegates, (item) => {
      return item.user_id == data.user_id;
    })

    if (index > -1) {
      this.lstContentDelegates.splice(index, 1);
    }
    this.contentMemberGrid.dataSource = new DataManager(this.lstContentDelegates);
  }

  deleteContentGuest = (data) => {
    let index = _.findIndex(this.lstContentGuests, (item) => {
      return item.phone == data.phone;
    })

    if (index > -1) {
      this.lstContentGuests.splice(index, 1);
    }
    this.contentGuestGrid.dataSource = new DataManager(this.lstContentGuests);
  }

  closeContentMemberModal = () => {
    this.modalService.close("content-member-modal");
  }

  saveContentMemberModal = () => {
    let index = _.findIndex(this.model.list_contents, (item) => {
      return item.id == this.dataContentUpdate.id;
    });
    if (index > -1) {
      this.model.list_contents[index].list_members = this.lstContentDelegates;
      this.model.list_contents[index].list_guests = this.lstContentGuests;
    }
    // this.contentGrid.dataSource = new DataManager(this.model.list_contents);
    this.contentShareListGrid.clearSelection();
    this.modalService.close("content-member-modal");
  }

  closeContentShareModal = () => {
    this.lstContentShares = [];
    this.contentShareListGrid.clearSelection();
    this.modalService.close("content-share-modal");
  }

  saveContentShareModal = () => {
    let index = _.findIndex(this.model.list_contents, (item) => {
      return item.id == this.dataContentUpdate.id;
    });

    let dataShares = _.cloneDeep(this.lstContentShares);

    if (index > -1) {
      this.model.list_contents[index].list_shares = dataShares;
    }
    this.contentGrid.dataSource = new DataManager(this.model.list_contents);
    this.lstContentShares = [];
    this.contentShareListGrid.clearSelection();
    this.modalService.close("content-share-modal");
  }

  selectShare = (event) => {
    setTimeout(() => {
      if ((event.name === "rowSelecting" && event.isCtrlPressed) || (event.name === "rowDeselecting" && event.isInteracted)) {
        if (event.name === "rowSelecting" && event.isCtrlPressed) {
          const rowSelects: any = this.contentShareListGrid.getSelectedRecords();
          if (rowSelects && rowSelects.length > 0) {
            for (let i = 0; i < rowSelects.length; i++) {
              let index = _.findIndex(this.lstContentShares, (item) => {
                return item.user_id == rowSelects[i].user_id;
              });

              if (index == -1) {
                this.lstContentShares.push(rowSelects[i]);
              }
            }
          }
        } else if (event.name === "rowDeselecting" && event.isInteracted) {
          if (event.data && event.data.length > 0) {
            for (let i = 0; i < event.data.length; i++) {
              let index = _.findIndex(this.lstContentShares, (item) => {
                return item.user_id == event.data[i].user_id;
              });

              if (index > -1) {
                this.lstContentShares.splice(index, 1);
              }
            }
          }
        }
      } else if (event.name === "rowDeselecting" && !event.isInteracted) {
        if (event.data && event.data.length > 0) {
          let rowDeselect = event.data;
          for (let i = 0; i < rowDeselect.length; i++) {
            let index = _.findIndex(this.lstContentShares, (item) => {
              return item.user_id == rowDeselect[i].user_id;
            });

            if (index > -1) {
              this.lstContentShares.splice(index, 1);
            }
          }
        }
      }
      this.contentShareGrid.dataSource = new DataManager(this.lstContentShares);
    }, 50);
  };

  closeContentPresentModal = () => {
    if (this.contentPresentMemberGrid) {
      this.contentPresentMemberGrid.clearSelection();
    }
    if (this.contentPresentOrgGrid) {
      this.contentPresentOrgGrid.clearSelection();
    }
    this.modalService.close("content-present-modal");
  }

  // saveContentPresentModal = () => {
  //   this.model.list_contents[this.contentIndex].list_presents = [];
  //   if (this.select_present == "org") {
  //     this.lstContentPresentOrgs = this.contentPresentOrgGrid.getSelectedRecords();
  //     for (let i = 0; i < this.lstContentPresentOrgs.length; i++) {
  //       this.model.list_contents[this.contentIndex].list_presents.push({
  //         type_id: 1,
  //         org_id: this.lstContentPresentOrgs[i].id
  //       })
  //     }
  //   } else {
  //     this.lstContentPresentMembers = this.contentPresentMemberGrid.getSelectedRecords();
  //     for (let i = 0; i < this.lstContentPresentMembers.length; i++) {
  //       this.model.list_contents[this.contentIndex].list_presents.push({
  //         type_id: 2,
  //         user_id: this.lstContentPresentMembers[i].user_id
  //       })
  //     }
  //   }

  //   this.contentGrid.dataSource = new DataManager(this.model.list_contents);
  //   if (this.contentPresentMemberGrid) {
  //     this.contentPresentMemberGrid.clearSelection();
  //   }
  //   if (this.contentPresentOrgGrid) {
  //     this.contentPresentOrgGrid.clearSelection();
  //   }
  //   this.modalService.close("content-present-modal");
  // }

  saveContentPresentModal = () => {
    let index = _.findIndex(this.model.list_contents, (item) => {
      return item.id == this.dataContentUpdate.id;
    });
    this.model.list_contents[index].list_presents = [];
    if (this.select_present == "org") {
      this.lstContentPresentOrgs = this.contentPresentOrgGrid.getSelectedRecords();
      for (let i = 0; i < this.lstContentPresentOrgs.length; i++) {
        this.model.list_contents[index].list_presents.push({
          type_id: 1,
          org_id: this.lstContentPresentOrgs[i].id
        })
      }
    } else {
      this.lstContentPresentMembers = this.contentPresentMemberGrid.getSelectedRecords();
      for (let i = 0; i < this.lstContentPresentMembers.length; i++) {
        this.model.list_contents[index].list_presents.push({
          type_id: 2,
          user_id: this.lstContentPresentMembers[i].user_id
        })
      }
    }

    this.contentGrid.dataSource = new DataManager(this.model.list_contents);
    if (this.contentPresentMemberGrid) {
      this.contentPresentMemberGrid.clearSelection();
    }
    if (this.contentPresentOrgGrid) {
      this.contentPresentOrgGrid.clearSelection();
    }
    this.modalService.close("content-present-modal");
  }


  deniedMeeting = () => {
    this.return_reason = "";
    this.modalService.open("confirm-denied-modal");
  }

  approveMeeting = () => {
    this.modalService.open("confirm-approve-modal");
  }

  confirmApprove = (status): void => {
    if (status === "cancel") {
      this.modalService.close("confirm-approve-modal");
    } else {
      this._configService.loadingSubject.next("true");
      if (!this.editForm.valid) {
        for (const key of Object.keys(this.editForm.controls)) {
          if (this.editForm.controls[key].invalid) {
            const invalidControl = this.el.nativeElement.querySelector(
              '[formcontrolname="' + key + '"]'
            );
            if (invalidControl) {
              if (invalidControl.querySelector("input")) {
                invalidControl.querySelector("input").focus();
              } else {
                invalidControl.focus();
              }
              break;
            }
          }
        }
        this.notification.warning("notify.EDIT_ERROR");
        this.editForm.markAllAsTouched();
        this.modalService.close("confirm-approve-modal");
        this._configService.loadingSubject.next("false");
        return;
      } else {
        if (!this.model.list_delegates || this.model.list_delegates.length == 0) {
          this._configService.loadingSubject.next("false");
          this.modalService.close("confirm-approve-modal");
          return this.notification.warning("Bạn chưa chọn đại biểu!");
        }
        if (!this.model.list_contents || this.model.list_contents.length == 0) {
          this._configService.loadingSubject.next("false");
          return this.notification.warning("Bạn chưa nhập nội dung họp!");
        }

        for (let i = 0; i < this.model.list_contents.length; i++) {
          if (!this.model.list_contents[i].content) {
            this._configService.loadingSubject.next("false");
            this.modalService.close("confirm-approve-modal");
            return this.notification.warning("Bạn chưa nhập đủ nội dung họp dòng thứ " + (i + 1));
          }
        }

        const modelRequest = this.prepareModelBeforeSave();
        console.log(modelRequest)
      const testurl = window.location.href;
        modelRequest.save_type = "SAVE_APPROVE";
        const url =
          this.flagState && this.flagState === "new"
            ? "/meeting/create"
            : "/meeting/update";
        this._coreService.Post(url, modelRequest).subscribe(
          (res) => {
            if (res && res.code == "200") {
              this._coreService.Post("/meeting/approve", {
                id: this.model.id,
                url: testurl
              }).subscribe(
                (res) => {
                  if (res.code === "200") {
                    this.notification.updateSuccess();
                    this.modalService.close("confirm-approve-modal");
                    this.router.navigate(["/cms/meeting/meetingwaiting"]);
                  }  else if (res.error == "ERROR_STATUS_NOT_UPDATE") {
                    this.notification.warning("Trạng thái cuộc họp đã được thay đổi, vui lòng kiểm tra lại trước khi thực hiện thao tác mới!");
                  }
 else {
                    this.notification.warning("Cập nhật thất bại!");
                  }
                  this._configService.loadingSubject.next("false");
                },
                (err) => {
                  this.notification.warning("Cập nhật thất bại!");
                }
              );
            } else {
              this.modalService.close("confirm-approve-modal");
              this._configService.loadingSubject.next("false");
              this.notification.warning(`${res.error}!`);
            }
          },
          (error) => {
            this.modalService.close("confirm-approve-modal");
            this._configService.loadingSubject.next("false");
            this.notification.warning(`Lỗi hệ thống!`);
          }
        );
      }



    }
  };

  confirmDenied = (status): void => {
    if (status === "cancel") {
      this.modalService.close("confirm-denied-modal");
    } else {
      if (!this.return_reason) {
        this.notification.warning("Bạn chưa nhập lý do trả lại!");
        return;
      }
      this._coreService.Post("/meeting/return", {
        id: this.model.id,
        return_reason: this.return_reason
      }).subscribe(
        (res) => {
          if (res.code === "200") {
            this.notification.updateSuccess();
            this.modalService.close("confirm-denied-modal");
            this.router.navigate(["/cms/meeting/meetingwaiting"]);
          } else if (res.error == "ERROR_STATUS_NOT_UPDATE") {
            this.notification.warning("Trạng thái cuộc họp đã được thay đổi, vui lòng kiểm tra lại trước khi thực hiện thao tác mới!");
          }
 else {
            this.notification.warning("Cập nhật thất bại!");
          }
        },
        (err) => {
          this.notification.warning("Cập nhật thất bại!");
        }
      );
    }
  };

  openFile = (file) => {
    let file_name = this.globals.replaceFileLink(file);
    let file_name_array = file.split(".");
    let file_type_origin = file_name_array[file_name_array.length - 1];
    let file_type = "";
    if (file_type_origin == "xlsx") {
      file_type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    } else if (file_type_origin == "xls") {
      file_type = "application/vnd.ms-excel";
    } else if (file_type_origin == "doc") {
      file_type = "application/msword";
    } else if (file_type_origin == "docx") {
      file_type = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    } else if (file_type_origin == "ppt") {
      file_type = "application/vnd.ms-powerpoint";
    } else if (file_type_origin == "pptx") {
      file_type = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
    } else if (file_type_origin == "pdf") {
      file_type = "application/pdf";
    } else if (file_type_origin == "png") {
      file_type = "image/png";
    } else if (file_type_origin == "jpeg") {
      file_type = "image/jpeg";
    } else if (file_type_origin == "gif") {
      file_type = "image/gif";
    }
    this._coreService.PostExport("/upload/downloadFile", {
      file_name: file_name,
      file_type: file_type
    }).subscribe((data) => {
      const blob = new Blob([data],
        { type: file_type });

      saveAs(blob, file_name);
    })
  }

  // formatStt = (string) => {
  //   return (parseInt(string) + 1).toString().padStart(2, '0');
  // }

  formatStt = (index: string) => {
    return (
      Number(index) + 1
    );
  };

  downloadFile = (file) => {
    if (this.userId && this.flagState == "edit" && this.modelOrigin.status == 21 && this.userId == this.modelOrigin.user_approve_id) {
      let file_name_array = file.split(".");
      let file_type_origin = file_name_array[file_name_array.length - 1];
      let file_type = "";
      if (file_type_origin == "xlsx") {
        file_type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      } else if (file_type_origin == "xls") {
        file_type = "application/vnd.ms-excel";
      } else if (file_type_origin == "doc") {
        file_type = "application/msword";
      } else if (file_type_origin == "docx") {
        file_type = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      } else if (file_type_origin == "ppt") {
        file_type = "application/vnd.ms-powerpoint";
      } else if (file_type_origin == "pptx") {
        file_type = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      } else if (file_type_origin == "pdf") {
        file_type = "application/pdf";
      } else if (file_type_origin == "png") {
        file_type = "image/png";
      } else if (file_type_origin == "jpeg") {
        file_type = "image/jpeg";
      } else if (file_type_origin == "gif") {
        file_type = "image/gif";
      }
      this._coreService.PostExport("/upload/downloadFile", {
        file_name: file,
        file_type: file_type
      }).subscribe((data) => {
        const blob = new Blob([data],
          { type: file_type });

        saveAs(blob, file);
      })
    }
  }

  closeModelXepGhe() {
    this.modalService.close("room-position-modal")
  }

  hideXepghe() {
    this.flagShowItem = false
  }

  getListContent = (meeting_id) => {
    this._coreService.Get("/meeting/content/" + meeting_id).subscribe((res) => {
      if (res && res.code && res.code == "200") {
        this.model.list_contents = res.data;
        async.each(this.model.list_contents, (item, callback) => {
          this._coreService.Post("/meetingposition/detailRoomPosition", {
            meeting_id: meeting_id,
            content_id: item.id
          }).subscribe((res) => {
            if (res && res.code && res.code == "200") {
              item.list_rooms = res.data.data;
            }
            return callback();
          });
        });
      }
    })
  }

   // Nội dung cha con - start
   showNewTable: boolean = false;
  addNewContent(data: any) {
    const index = this.model.list_contents.findIndex(x => x.id == data.id);
    let objContent = {
      //id: uuidv4(),
      meeting_time2: this.globals.convertDateStringFull(data.meeting_time2),
      content: "",
      list_documents: [],
      list_guests: data.list_guests,
      list_members: data.list_members,
      list_shares: [],
      list_presents: [],
      // parent_id: "",
    };
    this.model.list_contents[index].content_childs.push(objContent);
    this.contentGrid.dataSource = new DataManager(this.model.list_contents);
    setTimeout(() => {
      (this.gridContent as GridComponent).detailRowModule.expand(index);
      // (this.gridContent as GridComponent).detailRowModule.expandAll();

    }, 50);
  }

   @ViewChild('contentGrid', { static: false })
   public gridContent: GridComponent;
   public childGrid: GridModel;

   detailDataBound({ data, childGrid }) {
     const parentIndex = this.model.list_contents.indexOf(data);
     var childData = [];
     for (var i = 0; i < data.content_childs.length; i++) {
       childData.push({
         index: i,
         parentIndex: parentIndex,
         content: data.content_childs[i].content,
       })
     }
     (childGrid as IGrid).query = new Query();
     (childGrid as IGrid).dataSource = childData;
   }

   @ViewChild("childtemplateIndex", { static: true })
   public childtemplateIndex?: any;

   @ViewChild("childtemplate", { static: true })
   public childtemplate?: any;

   @ViewChild("childtemplateaction", { static: true })
   public childtemplateAction?: any;


   ngAfterViewInit() {
     this.childtemplateIndex.elementRef.nativeElement._viewContainerRef = this.viewContainerRef;
     this.childtemplateIndex.elementRef.nativeElement.propName = 'template';
     this.childtemplate.elementRef.nativeElement._viewContainerRef = this.viewContainerRef;
     this.childtemplate.elementRef.nativeElement.propName = 'template';
     this.childtemplateAction.elementRef.nativeElement._viewContainerRef = this.viewContainerRef;
     this.childtemplateAction.elementRef.nativeElement.propName = 'template';


     this.modalService.modalStatus.subscribe(data => {
      if (data && data.type === "addMemberGrid") {
        if (data.objMemberGrid && data.objMemberGrid.length > 0) {
          if (!this.model.list_delegates) {
            this.model.list_delegates = [];
          }
          let lstDelegates = _.cloneDeep(this.model.list_delegates);
          let lstDelegateChoose = [];
          for (let i = 0; i < data.objMemberGrid.length; i++) {
            lstDelegateChoose.push({
              user_id: data.objMemberGrid[i].id,
              full_name: data.objMemberGrid[i].full_name,
              title_name: data.objMemberGrid[i].title_name,
              org_name: data.objMemberGrid[i].org_name,
              org_id: data.objMemberGrid[i].org_id,
              is_same_chair: false
            })
          }

          if (this.model.list_contents && this.model.list_contents.length > 0) {
            for (let j = 0; j < this.model.list_contents.length; j++) {
              this.model.list_contents[j].list_members = [];
              this.model.list_contents[j].list_members = lstDelegateChoose;
            }
          }

          this.contentGrid.dataSource = new DataManager(this.model.list_contents);

          if (this.dataContentUpdate) {
            let objContent = _.find(this.model.list_contents, (item: any) => {
              return item.id == this.dataContentUpdate.id;
            });

            if (objContent && this.contentMemberGrid) {
              this.contentMemberGrid.dataSource = new DataManager(objContent.list_members);
              this.lstContentDelegates = objContent.list_members;
            }
          }

          this.model.list_delegates = lstDelegateChoose;
          this.memberGrid.dataSource = new DataManager(this.model.list_delegates);
        } else {
          let lstDelegateChoose = [];
          if (this.model.list_contents && this.model.list_contents.length > 0) {
            for (let j = 0; j < this.model.list_contents.length; j++) {
              this.model.list_contents[j].list_members = [];
              this.model.list_contents[j].list_members = lstDelegateChoose;
            }
          }

          this.contentGrid.dataSource = new DataManager(this.model.list_contents);

          if (this.dataContentUpdate) {
            let objContent = _.find(this.model.list_contents, (item: any) => {
              return item.id == this.dataContentUpdate.id;
            });

            if (objContent && this.contentMemberGrid) {
              this.contentMemberGrid.dataSource = new DataManager(objContent.list_members);
              this.lstContentDelegates = objContent.list_members;
            }
          }

          this.model.list_delegates = lstDelegateChoose;
          this.memberGrid.dataSource = new DataManager(this.model.list_delegates);
        }
      }
    });

    let modelInit = null;
    // Build toolbar
    async.waterfall(
      [
        (cb) => {
          if (this.flagState != "new") {
            this._coreService
              .Get("/meeting/detail/" + this.paramId)
              .subscribe((res) => {
                if (res.code == "200") {
                  modelInit = _.cloneDeep(res.data);
                  this.expandAllData = _.cloneDeep(res.data);
                  (this.gridContent as GridComponent).detailRowModule.expandAll();
                  if (modelInit && modelInit.status) {
                    if (modelInit.status == 22 || modelInit.status == 23 || modelInit.status == 24) {
                      let objParamAdd = { id: modelInit.id, type: "edit" };
                      let paramAdd = window.btoa(JSON.stringify(objParamAdd));
                      window.location.href = '/cms/meeting/meetingupcomming/detail/' + paramAdd;
                    }
                  }
                  var userId = localStorage.getItem("userId").toString();
                  if (this.screen == "MEET_WAITING" && modelInit.user_approve_id == userId && modelInit.status == 20) {
                    window.location.href = '/cms/meeting/meetingupcomming';
                  }
                  cb();
                }
              });
          } else {
            cb();
          }
        },
        (cb) => {
          async.parallel([(cb1) => {
            this._coreService
              .Get("/dropdown/meettype")
              .subscribe((res) => {
                if (res.code == "200") {
                  this.lstMeetTypes = res.data;
                  return cb1();
                }
              }, (err) => {
                return cb1();
              });
          }, (cb1) => {
            this._coreService
              .Get("/dropdown/organization")
              .subscribe((res) => {
                if (res.code == "200") {
                  this.lstOrgs = _.filter(res.data, (item: any) => {
                    return item.is_org == true;
                  });
                  return cb1();
                }
              }, (err) => {
                return cb1();
              });
          }, (cb1) => {
            this._coreService
              .Get("/dropdown/meetgroup")
              .subscribe((res) => {
                if (res.code == "200") {
                  this.lstMeetGroups = res.data;
                  return cb1();
                }
              }, (err) => {
                return cb1();
              });
          }, (cb1) => {
            this._coreService
              .Get("/dropdown/meetroom")
              .subscribe((res) => {
                if (res.code == "200") {
                  this.lstMeetRooms = res.data;
                  return cb1();
                }
              }, (err) => {
                return cb1();
              });
          }, (cb1) => {
            this._coreService
              .Get("/dropdown/userByPermission/CHAIR")
              .subscribe((res) => {
                if (res.code == "200") {
                  this.lstChairUsers = res.data;
                  return cb1();
                }
              }, (err) => {
                return cb1();
              });
          }, (cb1) => {
            this._coreService
              .Get("/dropdown/userByPermission/APPROVE_USER")
              .subscribe((res) => {
                if (res.code == "200") {
                  this.lstApproveUsers = res.data;
                  return cb1();
                }
              }, (err) => {
                return cb1();
              });
          }, (cb1) => {
            this._coreService
              .Get("/dropdown/userByPermission/SECRETARY")
              .subscribe((res) => {
                if (res.code == "200") {
                  this.lstSecretaryUsers = res.data;
                  return cb1();
                }
              }, (err) => {
                return cb1();
              });
          }, (cb1) => {
            this._coreService
              .Get("/dropdown/userByPermission/TRACKING_SPECIALIST")
              .subscribe((res) => {
                if (res.code == "200") {
                  this.lstTrackingUsers = res.data;
                  return cb1();
                }
              }, (err) => {
                return cb1();
              });
          }, (cb1) => {
            if (modelInit && modelInit.meet_room_id) {
              this._coreService.Get("/meetroom/" + modelInit.meet_room_id).subscribe((res) => {
                if (res && res.code == "200") {
                  this.modelPosition = res.data;
                  let lstRoomPositions = _.sortBy(_.filter(this.modelPosition.list_items, (item: any) => {
                    return item.status == 1;
                  }), "table_number");
                  this.lstRoomPositionOrigins = _.cloneDeep(lstRoomPositions);
                }
                return cb1();
              })
            } else {
              return cb1();
            }
          }], (err, result) => {
            return cb();
          })
        },

      ],
      (err, ok) => {
        this.userId = localStorage.getItem("userId");
        if (this.flagState != 'new') {
          this.model = _.cloneDeep(modelInit);
          this.modelOrigin = _.cloneDeep(modelInit);
          this.getListContent(this.model.id);
        }
      }
    );
    if (this.flagState == "view") {
      this.editForm.disable();
    }

    this.groupOptions = {
      showDropArea: false,
      columns: ["org_name"],
      captionTemplate: '<span style="color:black">${key}</span>',
    };
    // content cha con - start
    this.childGrid = {
      load() {
        this.registeredTemplate = {};
      },
      //toolbar: ['Add'],
      editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true },
      columns: [
        { headerText: 'STT', textAlign: 'Center', width: 40, template: this.childtemplateIndex, allowEditing: false },
        { headerText: "Nội dung", textAlign: "Center", width: 300, template: this.childtemplate },
        { headerText: "Thao tác", textAlign: "Center", width: 90, template: this.childtemplateAction, allowEditing: false },
      ],
    }; // end

    this.resetContent();

   }

   subcontents(event: any, data: any) {
     this.model.list_contents[data.parentIndex].content_childs[Number(data.index)].content = event.target.value
   }

   dltSubcontents(data: any) {
     this.model.list_contents[data.parentIndex].content_childs.splice(Number(data.index), 1);
     this.contentGrid.dataSource = new DataManager(this.model.list_contents);
     setTimeout(() => {
       (this.gridContent as GridComponent).detailRowModule.expand(data.parentIndex);
     }, 50);
   } // end

   resetContent = () => {
    if (this.model.list_contents && this.model.list_contents.length > 0) {
      for (let i = 0; i < this.model.list_contents.length - 1; i++) {
        this.model.list_contents[i].list_members = _.cloneDeep(this.model.list_delegates);
        this.model.list_contents[i].list_guests = _.cloneDeep(this.model.list_guests);
        this.model.list_contents[i].list_shares = [];
        this.model.list_contents[i].list_presents = [];
        this.model.list_contents[i].list_rooms = [];
        this.model.list_contents[i].list_votings = [];
      }
      this.contentGrid.dataSource = new DataManager(this.model.list_contents);
    }
  }

  resetRoomContent = () => {
    if (this.model.list_contents && this.model.list_contents.length > 0) {
      for (let i = 0; i < this.model.list_contents.length - 1; i++) {
        this.model.list_contents[i].list_rooms = [];
      }
      this.contentGrid.dataSource = new DataManager(this.model.list_contents);
    }
  }

  dataBoundHandler() {
    if(Array.isArray(this.model.list_contents)) {
      this.model.list_contents.forEach((item, index) => {
        if (item.content_childs && item.content_childs.length > 0) {
          setTimeout(() => {
            if (this.contentGrid && this.contentGrid.detailRowModule) {
              this.contentGrid.detailRowModule.expand(index);
            } else {
              console.error('contentGrid or detailRowModule is not ready');
            }
          }, 0);
        }
      });
    }
  }

}

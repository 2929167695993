import { Component, ElementRef, Renderer2, ViewChild, ViewEncapsulation } from "@angular/core";
import { AuthService } from "src/app/common/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CoreService } from "src/app/_services/core.service";
import { Globals } from "src/app/common/globals";
import { ModalService } from "src/app/_services/modal.service";
import { Notification } from "src/app/common/notification";
import * as $ from "jquery";
import * as async from "async";
import { NavigationService } from "src/app/_services/navigation.service";
import * as _ from 'lodash';
import { SocketService } from "src/app/_services/socket.service";

@Component({
  selector: "app-toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent {
  navigation: any;
  subNavigation: any;
  _router: any;

  username = "";
  position = "";
  profileId: any;
  avatar: any;
  //eye
  tooglePassWord1 = false;
  tooglePassWord2 = false;
  tooglePassWord3 = false;
  passwordForm: FormGroup;
  model: ChangePassword = new ChangePassword();
  confirmFlag = false;
  flagOldPassword = false;
  lstNotis = [];
  countUnread: any;
  dataInvite = null;

  isReaded: boolean = false;
  @ViewChild('toggleButton', {static: false}) toggleButton: ElementRef;
  @ViewChild('menu', {static: false}) menu: ElementRef;
  public isMenuOpen: boolean = false;
  /**
   * Constructor
   */
  constructor(
    private _authService: AuthService,
    private router: Router,
    private _coreService: CoreService,
    private modalService: ModalService,
    private globals: Globals,
    private _formBuilder: FormBuilder,
    private navigationService: NavigationService,
    private _socketService: SocketService,
    private notification: Notification,
    private renderer: Renderer2,
  ) {
    this._router = router;
    let isAdmin: any = localStorage.getItem('is_sysadmin');
    let navigations = this.navigationService.getCurrentNavigation();
    let mainNavigation = [];
    let username: any = localStorage.getItem("username");
    for (let i = 0; i < navigations.length; i++) {
      mainNavigation.push(navigations[i]);
    }
    this.router.events.subscribe((event) => {
      if(this.isMenuOpen)
        this.isMenuOpen = false;
    })

    this.renderer.listen('window', 'click',(e:Event)=>{
      if(this.menu){
        if(!this.toggleButton.nativeElement.contains(e.target) && !this.menu.nativeElement.contains(e.target)) {
          this.isMenuOpen=false;
      }
      }
 });

    // form doi mat khau
    this.passwordForm = _formBuilder.group({
      ip_address: [""],
      channel_code: [],
      username: [""],
      old_password: [
        "",
        [
          Validators.required,
          this.globals.noWhitespaceValidator,
        ],
      ],
      new_password: [
        "",
        [
          Validators.required,
          this.globals.noWhitespaceValidator,
          Validators.minLength(8)
        ],
      ],
      confirmPassword: [
        "",
        [
          Validators.required,
          this.globals.noWhitespaceValidator,
          Validators.minLength(8)
        ],
      ],

    });
  }

  ngOnInit(): void {
    this.profileId = Number(localStorage.getItem("id"));
    this.getProfile();

    let index = _.findIndex(this.navigation, (item: any) => {
      return this._router.url.indexOf(item.url) === 0;
    });

    if (index > -1) {
      if (this.navigation[index].type === "group") {
        this.showSubNavigation(this.navigation[index]);
      }
    }

    // Đại biểu kmời họp
    this._socketService.onStartMeeting().subscribe((data) => {
      let url_not_invite = ["/cms/meeting/meetinghappening/detail", "/cms/meeting/meetingupcomming/detail"];
      let url = this._router.url;
      let index = _.findIndex(url_not_invite, (item) => {
        return url.indexOf(item) > -1;
      });

      // Nếu không nằm trong màn chi tiết thì mời họp
      if (index === -1) {
        let userIds = data && data.data && data.data.userIds ? data.data.userIds : [];
        let userId = localStorage.getItem("userId");
        let indexUser = _.findIndex(userIds, (item) => {
          return item == userId;
        });

        if (indexUser > -1) {
          this.dataInvite = data && data.data && data.data.modelMeeting ? data.data.modelMeeting : null;
          this.modalService.open("invite-meeting-modal");
        }
      }
    });

    this.getListNotification();
    setInterval(() => {
      this.getListNotification();
    }, 60000);
  }

  confirmInviteMeeting(status) {
    if (status === "cancel") {
      this.modalService.close("invite-meeting-modal");
    } else {
      this.modalService.close("invite-meeting-modal");
      const objParamAdd = { id: this.dataInvite.id, attendance_status: "1", type: "edit" };
      const paramAdd = window.btoa(JSON.stringify(objParamAdd));
      this.router.navigate(["/cms/meeting/meetinghappening/detail", paramAdd]);


    }
  }

  checkContainParent = (function_code) => {
    let permissions = localStorage.getItem('permissions');

    permissions = JSON.parse(permissions);
    let lstFunctionCodes = _.map(permissions, 'function_code');

    var index = _.findIndex(lstFunctionCodes, (item) => {
      return item.indexOf(function_code) > -1;
    });

    if (index > -1) {
      return true;
    } else {
      return false;
    }
  }

  showSubNavigation = (item: any) => {
    this.subNavigation = item.children;
  }

  getProfile = () => {
    async.parallel(
      [
        (cb) => {
          this._coreService
            .Get("/auth/adminGetUserInfo")
            .subscribe((res) => {
              this.model = res && res.data && res.code == "200" ? res.data : this.model;
              this.avatar = res.data.avatar ? res.data.avatar : "/assets/images/avatar.jpg";
              this.username = res && res.data && res.data.full_name ? res.data.full_name : "Admin";
              this.position = res && res.data && res.data.title_name ? res.data.title_name : "Quản trị viên";
              let list_org_manages=[]
              let org_id=[]
              if(res.data.list_org_manages.length >0){
                for(var i=0;i<res.data.list_org_manages.length;i++){
                  list_org_manages.push(res.data.list_org_manages[i].org_id)
                }
              }
              org_id.push(res.data.org_id)
              localStorage.setItem("list_org_manages",list_org_manages.toString())
              localStorage.setItem("org_id",org_id.toString())
              //localStorage.setItem("org_name_parent",res.data.org_name_parent)
            });
        },
      ],
      (err, result) => { }
    );
  };
  // Show profile
  showProfile = (): void => {
    this.router.navigate(["/cms/profile"]);
  };

  // Sign Out App
  signOut = (): void => {
    this._authService.logout();
  };
  // Change Password
  changePassword = () => {
    this.modalService.open("open-change-pasword");
    // Reset form khi chọn nút X ở Modal
    $(".btn-remove").click(() => {
      this.passwordForm.reset();
    });
  };
  checkPass = () => {
    if (this.model.new_password == this.model.confirmPassword) {
      this.passwordForm.get("confirmPassword").setErrors({ incorrect: true });
      return;
    }
  };

  // set default validate khi thay doi
  changeOldPass() {
    this.flagOldPassword = false;
    this.confirmFlag = false;
  }
  // Xác nhận đổi mật khẩu or không đổi mật khẩu
  confirmChoose(status) {
    if (status == "cancel") {
      // Reset trạng thái của mắt
      this.tooglePassWord3 = false;
      this.tooglePassWord2 = false;
      this.tooglePassWord1 = false;
      this.flagOldPassword = false;
      // Bỏ check không trùng mật khẩu
      this.confirmFlag = false;
      // Reset Form
      this.passwordForm.reset();
      this.modalService.close("open-change-pasword");
    } else {
      if (!this.passwordForm.valid) {
        this.passwordForm.markAsUntouched();
        this.passwordForm.markAsPristine();
        this.passwordForm.markAllAsTouched();
      } else {
        if (this.model.new_password !== this.model.confirmPassword) {
          this.confirmFlag = true;
        } else {
          this.saveData();
        }
      }
    }
  }
  // Lưu dữ liệu
  saveData() {
    this.model.username = localStorage.getItem("username");
    setTimeout(() => {
      this._coreService.Post("/auth/adminChangePass", this.model).subscribe(
        (res: any) => {
          if (res && res.code == "200") {
            this.notification.success("Đổi mật khẩu thành công!");
            this.modalService.close("open-change-pasword");
            this.signOut();

          } else {
            if (res && res.error) {
              if (res.error == "ERROR_OLD_PASSWORD_INCORRECT") {
                this.notification.warning("Mật khẩu cũ không chính xác!");
              }
            } else {
              this.notification.warning("Đổi mật khẩu không thành công!");
            }
          }
        },
        (error) => {
          // Lỗi mạng
          if (error.code !== "200") {
            this.notification.warning("Đổi mật khẩu thất bại!");
          }
        }
      );
    }, 200);
  }

  returnDashboard = () => {
    this.router.navigate(["/cms/dashboard"]);
  }

  getListNotification = () => {
    this._coreService.Get("/dropdown/notification").subscribe((res) => {
      if (res && res.code && res.code === "200") {
        this.lstNotis = res.data;
        this.countUnread = _.filter(this.lstNotis, (item: any) => {
          return !item.is_read;
        }).length;
      }
    })
  }

  // clickNotiRecord = (data) => {
  //   if (!data.is_read) {
  //     this._coreService.Get("/dropdown/notification/detail/" + data.id).subscribe((res) => {
  //       if (res && res.code && res.code === "200") {
  //         this.getListNotification();
  //       }
  //     })
  //   }

  //   setTimeout(() => {
  //     if (data.type_id) {
  //       // const objParamAdd = { id: data.meeting_id, type: "view" };
  //       const objParamAdd = { id: data.meeting_id, type: "edit" };
  //       const paramAdd = window.btoa(JSON.stringify(objParamAdd));
  //       if (data.type_id == 1) {
  //         //this.router.navigate(["/cms/meeting/meetingwaiting", paramAdd])
  //         this.router.navigateByUrl('/cms/meeting/meetingwaiting', { skipLocationChange: true }).then(() => {
  //           this.router.onSameUrlNavigation = 'reload';
  //           window.location.href = '/cms/meeting/meetingwaiting/' + paramAdd;
  //         });
  //       } else if (data.type_id == 2) {
  //         //this.router.navigate(["/cms/workcalendar/schedulecompany", paramAdd])
  //         this.router.navigateByUrl('/cms/workcalendar/schedulecompany', { skipLocationChange: true }).then(() => {
  //           this.router.onSameUrlNavigation = 'reload';
  //           window.location.href = '/cms/workcalendar/schedulecompany/' + paramAdd;
  //         });
  //       } else if (data.type_id == 3) {
  //         //this.router.navigate(["/cms/workcalendar/scheduleleader", paramAdd])
  //         this.router.navigateByUrl('/cms/workcalendar/scheduleleader', { skipLocationChange: true }).then(() => {
  //           this.router.onSameUrlNavigation = 'reload';
  //           window.location.href = '/cms/workcalendar/scheduleleader/' + paramAdd;
  //         });
  //       } else if (data.type_id == 4) {
  //         //this.router.navigate(["/cms/extension/taskmanager", paramAdd])
  //         this.router.navigateByUrl('/cms/extension/taskmanager', { skipLocationChange: true }).then(() => {
  //           this.router.onSameUrlNavigation = 'reload';
  //           window.location.href = '/cms/extension/taskmanager/' + paramAdd;
  //         });
  //       }
  //     }
  //   }, 100);
  // }

  clickNotiRecord = (data) => {
    if (!data.is_read) {
      this._coreService.Get("/dropdown/notification/detail/" + data.id).subscribe((res) => {
        if (res && res.code && res.code === "200") {
          this.getListNotification();
        }
      })
    }

    setTimeout(() => {
      if (data.type_id) {
        let objParamAdd = { id: data.meeting_id, type: "edit" };
        if (data.type_id == 1) {
          this._coreService
            .Get("/meeting/detail/" + data.meeting_id)
            .subscribe((res) => {
              if (res.code == "200") {
                let modelInit = res.data;
                if (modelInit && modelInit.status) {
                  if (data.title == "Thông báo phê duyệt lịch họp") {
                    if (modelInit.status == 22 || modelInit.status == 23 || modelInit.status == 24) {
                      objParamAdd = { id: data.meeting_id, type: "edit" };
                      let paramAdd = window.btoa(JSON.stringify(objParamAdd));
                      window.location.href = '/cms/meeting/meetingupcomming/detail/' + paramAdd;
                    } else if (modelInit.status == 21) {
                      objParamAdd = { id: data.meeting_id, type: "edit" };
                      let paramAdd = window.btoa(JSON.stringify(objParamAdd));
                      window.location.href = '/cms/meeting/meetingwaiting/' + paramAdd;
                    } else {
                      objParamAdd = { id: data.meeting_id, type: "view" };
                      let paramAdd = window.btoa(JSON.stringify(objParamAdd));
                      window.location.href = '/cms/meeting/meetingwaiting/' + paramAdd;
                    }
                  } else if (data.title == "Thông báo yêu cầu phê duyệt lịch họp") {
                    if (modelInit.status == 22 || modelInit.status == 23 || modelInit.status == 24) {
                      objParamAdd = { id: data.meeting_id, type: "edit" };
                      let paramAdd = window.btoa(JSON.stringify(objParamAdd));
                      window.location.href = '/cms/meeting/meetingupcomming/detail/' + paramAdd;
                    } else if (modelInit.status == 21) {
                      objParamAdd = { id: data.meeting_id, type: "edit" };
                      let paramAdd = window.btoa(JSON.stringify(objParamAdd));
                      window.location.href = '/cms/meeting/meetingwaiting/' + paramAdd;
                    } else {
                      objParamAdd = { id: data.meeting_id, type: "view" };
                      let paramAdd = window.btoa(JSON.stringify(objParamAdd));
                      window.location.href = '/cms/meeting/meetingwaiting/' + paramAdd;
                    }
                  } else if (data.title == "Thông báo thu hồi lịch họp") {
                    if (modelInit.status == 22 || modelInit.status == 23 || modelInit.status == 24) {
                      // objParamAdd = { id: data.meeting_id, type: "edit" };
                      // let paramAdd = window.btoa(JSON.stringify(objParamAdd));
                      // window.location.href = '/cms/meeting/meetingupcomming/detail/' + paramAdd;
                    } else if (modelInit.status == 21) {
                      objParamAdd = { id: data.meeting_id, type: "edit" };
                      let paramAdd = window.btoa(JSON.stringify(objParamAdd));
                      window.location.href = '/cms/meeting/meetingwaiting/' + paramAdd;
                    } else if (modelInit.status == 20) {
                      objParamAdd = { id: data.meeting_id, type: data.view_detail };
                      let paramAdd = window.btoa(JSON.stringify(objParamAdd));
                      window.location.href = '/cms/meeting/meetingwaiting/' + paramAdd;
                    } else {
                      objParamAdd = { id: data.meeting_id, type: "view" };
                      let paramAdd = window.btoa(JSON.stringify(objParamAdd));
                      window.location.href = '/cms/meeting/meetingwaiting/' + paramAdd;
                    }
                  } else if (data.title == "Thông báo trả lại lịch họp") {
                    if (modelInit.status == 22 || modelInit.status == 23 || modelInit.status == 24) {
                      objParamAdd = { id: data.meeting_id, type: "edit" };
                      let paramAdd = window.btoa(JSON.stringify(objParamAdd));
                      window.location.href = '/cms/meeting/meetingupcomming/detail/' + paramAdd;
                    } else if (modelInit.status == 21) {
                      objParamAdd = { id: data.meeting_id, type: "view" };
                      let paramAdd = window.btoa(JSON.stringify(objParamAdd));
                      window.location.href = '/cms/meeting/meetingwaiting/' + paramAdd;
                    } else if (modelInit.status == 20) {
                      objParamAdd = { id: data.meeting_id, type: "edit" };
                      let paramAdd = window.btoa(JSON.stringify(objParamAdd));
                      window.location.href = '/cms/meeting/meetingwaiting/' + paramAdd;
                    } else {
                      objParamAdd = { id: data.meeting_id, type: "view" };
                      let paramAdd = window.btoa(JSON.stringify(objParamAdd));
                      window.location.href = '/cms/meeting/meetingwaiting/' + paramAdd;
                    }
                  }
                  else if (data.title == "Thông báo hủy lịch họp") {
                    if (modelInit.status == 22 || modelInit.status == 23 || modelInit.status == 24) {
                      // objParamAdd = { id: data.meeting_id, type: "edit" };
                      // let paramAdd = window.btoa(JSON.stringify(objParamAdd));
                      // window.location.href = '/cms/meeting/meetingupcomming/detail/' + paramAdd;
                    } else if (modelInit.status == 21) {
                      objParamAdd = { id: data.meeting_id, type: "edit" };
                      let paramAdd = window.btoa(JSON.stringify(objParamAdd));
                      window.location.href = '/cms/meeting/meetingwaiting/' + paramAdd;
                    } else if (modelInit.status == 20) {
                      objParamAdd = { id: data.meeting_id, type: data.view_detail };
                      let paramAdd = window.btoa(JSON.stringify(objParamAdd));
                      window.location.href = '/cms/meeting/meetingwaiting/' + paramAdd;
                    } else {
                      objParamAdd = { id: data.meeting_id, type: "view" };
                      let paramAdd = window.btoa(JSON.stringify(objParamAdd));
                      window.location.href = '/cms/meeting/meetingwaiting/' + paramAdd;
                    }
                  }
                }
              }
            });
        } else if (data.type_id == 2) {
          this.router.navigate(["/cms/workcalendar/schedulecompany"], { queryParams: { view_type: 'APPROVE' } });
          // this._coreService
          //   .Get("/schedulecompany/" + data.meeting_id)
          //   .subscribe((res) => {
          //     if (res.code == "200") {
          //       let modelInit = res.data;
          //       if (modelInit.status == 18) {
          //         objParamAdd = { id: data.meeting_id, type: "view" };
          //         let paramAdd = window.btoa(JSON.stringify(objParamAdd));
          //         window.location.href = '/cms/workcalendar/schedulecompany/' + paramAdd;
          //       } else if (modelInit.status == 16 || modelInit.status == 17) {
          //         objParamAdd = { id: data.meeting_id, type: data.view_type };
          //         let paramAdd = window.btoa(JSON.stringify(objParamAdd));
          //         window.location.href = '/cms/workcalendar/schedulecompany/' + paramAdd;
          //       }
          //     }
          //   });
        } else if (data.type_id == 3) {
          this.router.navigate(["/cms/workcalendar/scheduleleader"], { queryParams: { view_type: 'APPROVE' } });
          // this._coreService
          //   .Get("/scheduleleader/" + data.meeting_id)
          //   .subscribe((res) => {
          //     if (res.code == "200") {
          //       let modelInit = res.data;
          //       if (modelInit.status == 18) {
          //         objParamAdd = { id: data.meeting_id, type: "view" };
          //         let paramAdd = window.btoa(JSON.stringify(objParamAdd));
          //         window.location.href = '/cms/workcalendar/scheduleleader/' + paramAdd;
          //       } else if (modelInit.status == 16 || modelInit.status == 17) {
          //         objParamAdd = { id: data.meeting_id, type: data.view_type };
          //         let paramAdd = window.btoa(JSON.stringify(objParamAdd));
          //         window.location.href = '/cms/workcalendar/scheduleleader/' + paramAdd;
          //       }
          //     }
          //   });
        } else if (data.type_id == 4) {
          objParamAdd = { id: data.meeting_id, type: "edit" };
          let paramAdd = window.btoa(JSON.stringify(objParamAdd));
          window.location.href = '/cms/extension/taskmanager/' + paramAdd;
        }
      }
    }, 100);
  }

  markAsReadAll() {
    this._coreService.Post("/dropdown/notification/readAll", {}).subscribe((res) => {
      if (res && res.code && res.code === "200") {
        this.getListNotification();
      }
    })
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

}

export class ChangePassword {
  username?: string;
  old_password?: string;
  password?: string;
  confirmPassword?: string;
  new_password?: string
}

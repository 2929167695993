// GLOBAL IMPORT
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import {
  DataStateChangeEventArgs,
  Sorts,
  DataResult,
} from "@syncfusion/ej2-angular-grids";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { Http, ResponseContentType } from "@angular/http";
import { Globals } from "../common/globals";
import { ExtraParams } from "../_models/index";
import { ConfigService } from "./config.service";
import { FileReaderCommon } from "../common/FileReaderCommon";
import { Consts } from "../common/const";
import { saveAs } from "file-saver";
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

export interface ImgurUploadResponse {
  link: string;
  deleteHash: string;
}

Injectable();
export class CoreService extends Subject<DataStateChangeEventArgs> {
  public loadCouncilSubject = new Subject<any>();
  public base_url = "https://voicemaster1.digipro.com.vn/";
  // Vĩnh phúc
  // public base_url = "https://voicemaster1.cnttvietnam.com.vn/";

  constructor(
    @Inject(Http) private http: Http,
    @Inject(Router) private router: Router,
    @Inject(Globals) private globals: Globals,
    @Inject(ConfigService) private configService: ConfigService
  ) {
    super();
  }

  public execute(
    state: any,
    url: string,
    extraParams?: Array<ExtraParams>
  ): void {
    this.GetAll(state, url, extraParams).subscribe((x) => super.next(x));
  }
  public executeNonPaging(
    state: any,
    url: string,
    extraParams?: Array<ExtraParams>
  ): void {
    this.GetAllNonePaging(state, url, extraParams).subscribe((x) =>
      super.next(x)
    );
  }

  // Function GetAll
  GetAll = (
    state: DataStateChangeEventArgs,
    url: string,
    extraParams?: any
  ): Observable<DataStateChangeEventArgs> => {
    const url_request = this.globals.apiURL + url;
    const body = this.globals.getCommonBodyGetAll(state, extraParams);
    const options = this.globals.getCommonOptionsWithAuth(url_request, body);
    this.configService.loadingSubject.next("true");
    return this.http
      .post(url_request, body, options)
      .pipe(
        map((res: any) => {
          res = JSON.parse(res._body);
          let result = null;
          result = res.data;
          return {
            result: result && result.data ? result.data : [],
            count: result && result.total_elements ? result.total_elements : 0,
          } as DataResult;
        })
      )
      .pipe((data: any) => {
        this.configService.loadingSubject.next("false");
        return data;
      });
  };

  // Function create Model
  Create = (url: string, body: any) => {
    const url_request = this.globals.apiURL + url;
    const options = this.globals.getCommonOptionsWithAuth(url_request, body);

    return this.http.post(url_request, body, options);
  };

  // Function update Model
  Update = (url: string, body: any) => {
    const url_request = this.globals.apiURL + url;
    const options = this.globals.getCommonOptionsWithAuth(url_request, body);

    return this.http.post(url_request, body, options);
  };

  // Function Delete Model
  Delete = (url: string, body: Array<any>) => {
    const url_request = this.globals.apiURL + url;
    const options = this.globals.getCommonOptionsWithAuth(url_request, body);

    return this.http.post(url_request, body, options);
  };

  // SERVICE POST
  Post = (url: string, body: any) => {
    const url_request = this.globals.apiURL + url;
    const options = this.globals.getCommonOptionsWithAuth(url_request, body);
    return this.http.post(url_request, body, options).pipe(
      map((response: any) => {
        response = JSON.parse(response._body);
        return response;
      })
    );
  };
  PostReport = (url: string, body: any, options: any) => {
    const url_request = this.globals.apiURL + url;
    const requestOptions = this.globals.getCommonOptionsWithAuth(url_request, body);
    const mergedOptions = { ...requestOptions, ...options }; // Merge the options

    return this.http.post(url_request, body, mergedOptions).pipe(
      map((response: any) => {
        response = response._body;
        return response;
      })
    );
  };

  PostExport = (url: string, body: any) => {
    const url_request = this.globals.apiURL + url;
    const options = this.globals.getCommonExportOptionsWithAuth(
      url_request,
      body
    );

    return this.http.post(url_request, body, options).pipe(
      map((response: any) => {
        return response._body;
      })
    );
  };

  // SERVICE PUT
  Put = (url: string, body: any) => {
    const url_request = this.globals.apiURL + url;
    const options = this.globals.getCommonOptionsWithAuth(url_request, body);

    return this.http.put(url_request, body, options).pipe(
      map((response: any) => {
        response = JSON.parse(response._body);
        return response;
      })
    );
  };

  // SERVICE DEL
  Del = (url: string, body: any) => {
    const url_request = this.globals.apiURL + url;
    const options = this.globals.getCommonOptionsWithAuth(url_request, body);

    return this.http.post(url_request, options).pipe(
      map((response: any) => {
        response = JSON.parse(response._body);
        return response;
      })
    );
  };

  // SERVICE GET
  Get = (url: string) => {
    const url_request = this.globals.apiURL + url;
    const options = this.globals.getCommonOptionsWithAuth(url_request);

    return this.http.get(url_request, options).pipe(
      map((res: any) => {
        res = JSON.parse(res._body);
        return res;
      })
    );
  };
  // GetMeetView = (url: string) => {
  //   const url_request = this.globals.baseURL + url;
  //   return this.http.get(url_request).pipe(
  //     map((res: any) => {
  //       res = JSON.parse(res._body);
  //       return res;
  //     })
  //   );
  // };

  uploadFile = (formData: any): Observable<any> => {
    const url = this.globals.apiURL + "/upload/uploadFile";
    return this.http.post(url, formData).pipe(
      map((response: any) => {
        return response.json();
      })
    );
  };
  // huy - add upload file vào folder tài liệu hdsd
  uploadFileHDSD = (formData: any): Observable<any> => {
    const url = this.globals.apiURL + "/instructiondocument/uploadfile";
    return this.http.post(url, formData).pipe(
      map((response: any) => {
        return response.json();
      })
    );
  };

  // Hoan them
  uploadFileDoc = (formData: any): Observable<any> => {
    // sua duong dan
    const url = this.globals.apiURL + "/DocumentEditor/Import";
    // const options = this.globals.getCommonOptionsWithAuth(url);
    return this.http.post(url, formData).pipe(
      map((response: any) => {
        return response.json();
      })
    );
  };

  importFile = (url2: string, formData: any): Observable<any> => {
    const url = this.globals.apiURL + url2;
    return this.http.post(url, formData).pipe(
      map((response: any) => {
        return response.json();
      })
    );
  };

  GetOutside = (url): Observable<any> => {
    return this.http.get(url).pipe(
      map((response: any) => {
        return response.json();
      })
    );
  };

  // Function GetAll
  GetAllNonePaging = (
    state: DataStateChangeEventArgs,
    url: string,
    extraParams?: any
  ): Observable<DataStateChangeEventArgs> => {
    const url_request = this.globals.getCommonURLGetAll(
      state,
      url,
      extraParams
    );

    const options = this.globals.getCommonOptionsWithAuth(url_request);
    return this.http
      .get(url_request, options)
      .pipe(map((response: any) => response.json()));
  };

  // haohv
  postRecordFile = (headers, formData: any): Observable<any> => {
    const url = this.globals.apiURL + '/readrecord/uploadRecordFiles';
    return this.http
      .post(url, formData, headers)
      .pipe(map((response: any) => {
        return response.json();
      }));
  }

  // export
  public exportFileWord(state: DataStateChangeEventArgs, fileName: string, body: any): Promise<string> {
    const url_request = this.globals.apiURL + '/readrecord/exportWord';
    const options = this.globals.getCommonOptionsWithAuthForWord(url_request);
    const bodyData = this.globals.getCommonBodyGetAll(state, body);
    return new Promise<string>((resolve, reject) => {
      try {
        this.http.post(url_request,bodyData, {...options, responseType: ResponseContentType.Blob}).subscribe((res: any) => {
          let disposition = res.headers.get('Content-Disposition');
          let blob = res._body;

          fileName += ".docx";
          let saveAs = require('file-saver')
          saveAs(blob, fileName);
        });
      } catch (error) {
        alert(error);
      }
    });
  }
  // export - hoan
  exportFileExcel(fileName:any, date_start: any, date_end: any, data: any): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      try {
        const exportUrl = date_start && date_end
          ? `/meetingreport/exportExcel?date_start=${date_start}&date_end=${date_end}`
          : '/meetingreport/exportExcel'
          const url_request = this.globals.apiURL + exportUrl;
        const options = this.globals.getCommonOptionsWithAuthForWord(url_request);

        this.PostReport(exportUrl, data,  {...options, responseType: ResponseContentType.Blob}).subscribe((res) => {
          if (res) {
            const blob = new Blob([res],
              { type: 'application/vnd.ms-excel' });

            saveAs(blob, fileName);

          }
        });
      } catch (error) {
        alert(error);
      }
    });
  }
  getSK(url, id_meetting, token : any) {
    const url_request = this.base_url + url + `?meetingid=${id_meetting}&Authorization=${token}`;
    const options = this.globals.getCommonOptionsWithAuth(url_request);

    return this.http.get(url_request, options).pipe(
      map((res: any) => {
        res = JSON.parse(res._body);
        return res;
      })
    );
  }

  CrSession(url, data: any,token : any) {
    const url_request = this.base_url + url +`?Authorization=${token}`;
    const options = this.globals.getCommonOptionsWithAuth(url_request, data);
    return this.http.post(url_request, data, options).pipe(
      map((response: any) => {
        response = JSON.parse(response._body);
        return response;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }
  stopSK(url, id_meetting, session,token) {
    const url_request =
      this.base_url + url + `?meetingid=${id_meetting}&session=${session}&Authorization=${token}`;
    const options = this.globals.getCommonOptionsWithAuth(url_request);

    return this.http.get(url_request, options).pipe(
      map((res: any) => {
        res = JSON.parse(res._body);
        return res;
      })
    );
  }

  stopMeetting(url, id_meetting,token) {
    const url_request = this.base_url + url + `?meetingid=${id_meetting}&Authorization=${token}`;
    const options = this.globals.getCommonOptionsWithAuth(url_request);

    return this.http.get(url_request, options).pipe(
      map((res: any) => {
        res = JSON.parse(res._body);
        return res;
      })
    );
  }

  getVoice2Text(url, id_meetting, user_id, getall, session,token) {
    const url_request =
      this.base_url +
      url +
      `?meetingid=${id_meetting}&userid=${user_id}&getall=${getall}&session=${session}&Authorization=${token}`;
    const options = this.globals.getCommonOptionsWithAuth(url_request);

    return this.http.get(url_request, options).pipe(
      map((res: any) => {
        res = JSON.parse(res._body);
        return res;
      })
    );
  }

  ViewLog(url) {
    const url_request = this.base_url + url;
    const options = this.globals.getCommonOptionsWithAuth(url_request);

    return this.http.get(url_request, options).pipe(
      map((res: any) => {
        res = JSON.parse(res._body);
        return res;
      })
    );
  }

  getMedia(url, sessionId, type) {
    const url_request =
      this.globals.apiURL + url + `?SessionId=${sessionId}&Type=${type}`;
    const options = this.globals.getCommonOptionsWithAuth(url_request);

    return this.http.get(url_request, options).pipe(
      map((res: any) => {
        res = JSON.parse(res._body);
        return res;
      })
    );
  }

  CancelSession(url: any, body: any,token) {
    const url_request = this.base_url + url `?Authorization=${token}`;
    const options = this.globals.getCommonOptionsWithAuth(url_request, body);
    return this.http.post(url_request, body, options).pipe(
      map((response: any) => {
        response = JSON.parse(response._body);
        return response;
      })
    );
  }

  GetMeetView = (url: string,token : any) => {
    const url_req = this.globals.baseURL + url +`?Authorization=${token}`;
    const options = this.globals.getCommonOptionsWithAuth(url_req);
    return this.http.get(url_req,options).pipe(
      map((res: any) => {
        res = JSON.parse(res._body);
        return res;
      })
    );
  };
   // Dừng cuộc họp
   StopMeetView = (url: string,token : any) => {
    const url_req = this.globals.baseURL + url +`&Authorization=${token}`;
    const options = this.globals.getCommonOptionsWithAuth(url_req);
    return this.http.get(url_req,options).pipe(
      map((res: any) => {
        res = JSON.parse(res._body);
        return res;
      })
    );
  };
}

export const locale = {
  lang: "vi",
  data: {
    NAV: {
      DASHBOARDS: "Trang tổng quan",
      MASTER_DATA: "Danh mục",
      MEETING: "Cuộc họp",
      AUTHEN: "Hệ thống",
      EXTENSION: "Tiện ích",
      READ_RECORD: "Gỡ băng",
      REPORT: "Báo cáo",
      WORKCALENDAR: "Lịch công tác",
      MEETING_LIST: {
        REGISTER: "Đăng ký cuộc họp",
        MEET_NEW: "Cuộc họp chờ gửi duyệt",
        MEET_WAITING: "Cuộc họp chờ duyệt",
        MEET_UPCOMMING: "Cuộc họp sắp tới",
        MEET_HAPPENING: "Cuộc họp đang diễn ra",
        MEET_CLOSED: "Cuộc họp đã kết thúc",
        MEET_CANCEL: "Cuộc họp bị hủy"
      },
      MASTER_DATA_LIST: {
        PROVINCE: "Danh mục Tỉnh/Thành Phố",
        DISTRICT: "Danh mục Quận/Huyện/Thị Xã",
        WARD: "Danh mục Xã/Phường/Thị Trấn",
        ORGANIZATION: "Đơn vị/Phòng ban",
        TITLE: "Chức vụ",
        MEET_ROOM: "Phòng họp",
        TABLE_TYPE: "Loại hình bàn họp",
        MEET_GROUP: "Nhóm họp",
        MEET_TYPE: "Loại hình cuộc họp",
        NOTIFICATION_TEMPLATE: "Mẫu thông báo",
        WORD_TEMPLATE: "Mẫu file xuất word"
      },
      EXTENSION_LIST: {
        SEARCH_MEETING: "Tra cứu lịch theo phòng họp",
        READ_RECORD: "Gỡ băng cuộc họp", // haohv
        WORKING: "Giao việc",
        DOCUMENT: "Tài liệu HDSD"
      },
      WORKCALENDAR_LIST: {
        SCHEDULE_PERSON: "Lịch cá nhân",
        SCHEDULE_COMPANY: "Lịch cơ quan",
        SCHEDULE_LEADER: "Lịch lãnh đạo"
      },
      AUTHEN_LIST: {
        MEET_STATISTICS:'Thông kê lịch họp',
        GROUP_USER: "Nhóm quyền người dùng",
        FUNCTION: "Chức năng",
        ACTION: "Hành động",
        USER: "Người dùng hệ thống",
        LOGGING_LOGIN: "Lịch sử đăng nhập",
        LOGGING_ACTION: "Lịch sử thao tác hành động",
        LOGGING_API: "Lịch sử thao tác API",
        MESSAGE: "Quản lý nhắc họp",
        CONFIG: "Cấu hình hệ thống"
      },
      REPORT_LIST: {
        REPORT_MEETING: "Báo cáo cuộc họp",
        REPORT_ASSIGN: "Báo cáo giao việc",
        MEET_STATISTICS: "Thống kê cuộc họp"

      },
    }
  }
};

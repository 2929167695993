import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  constructor(private socket: Socket) { }

  sendEmit(message: string, data: any) {
    this.socket.emit(message, data);
  }

  // Nhận mời họp
  onStartMeeting() {
    return this.socket.fromEvent('start-meeting-finish').pipe(map((data: any) => data));
  }

  // Cập nhật tài liệu
  updateDocument() {
    return this.socket.fromEvent('update-document-finish').pipe(map((data: any) => data));
  }

  // Cập nhật diễn biến
  updateContent() {
    return this.socket.fromEvent('update-content-finish').pipe(map((data: any) => data));
  }

  // Bắt đầu có ý kiến phát biểu
  startComment() {
    return this.socket.fromEvent('start-comment-finish').pipe(map((data: any) => data));
  }

  // Cập nhật realtime ý kiến phát biểu
  updateComment() {
    return this.socket.fromEvent('update-comment-finish').pipe(map((data: any) => data));
  }

  // Kết thúc ý kiến
  endComment() {
    return this.socket.fromEvent('end-comment-finish').pipe(map((data: any) => data));
  }

  // Bắt đầu biểu quyết
  startVoting() {
    return this.socket.fromEvent('start-voting-finish').pipe(map((data: any) => data));
  }

  // Biểu quyết
  updateVoting() {
    return this.socket.fromEvent('update-voting-finish').pipe(map((data: any) => data));
  }

  // Kết thúc biểu quyết
  endVoting() {
    return this.socket.fromEvent('end-voting-finish').pipe(map((data: any) => data));
  }

  // Kết thúc cuộc họp
  endMeeting() {
    return this.socket.fromEvent('end-meeting-finish').pipe(map((data: any) => data));
  }

  // Bắt đầu trong cuộc họp
  startInMeeting() {
    return this.socket.fromEvent('start-in-meeting-finish').pipe(map((data: any) => data));
  }

  // Cập nhật cuộc họp
  updateDelegate() {
    return this.socket.fromEvent('update-delegate-finish').pipe(map((data: any) => data));
  }

  // Cập nhật vị trí
  updatePosition() {
    return this.socket.fromEvent('update-position-finish').pipe(map((data: any) => data));
  }

  // Cập nhật giao việc
  updateWork() {
    return this.socket.fromEvent('update-work-finish').pipe(map((data: any) => data));
  }

  // Cập nhật ghi chú
  updateNote() {
    return this.socket.fromEvent('update-note-finish').pipe(map((data: any) => data));
  }
}
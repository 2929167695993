import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  Inject,
  ElementRef,
  Input,
  OnDestroy,
  ViewChildren,
  QueryList,
  HostListener,
  AfterViewInit,
  ChangeDetectorRef
} from "@angular/core";
import { Subject } from "rxjs";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { SafeResourceUrl, DomSanitizer } from "@angular/platform-browser";

// Service Translate
import { TranslationLoaderService } from "src/app/common/translation-loader.service";
import { TranslateService } from "@ngx-translate/core";
// Import the locale files
import { locale as english } from "./i18n/en";
import { locale as vietnam } from "./i18n/vi";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// Globals File
import { Globals } from "src/app/common/globals";
import { Configs } from "src/app/common/configs";
import { Notification } from "src/app/common/notification";

import { L10n, setCulture } from "@syncfusion/ej2-base";
import {
  FilterService,
  GridComponent,
  VirtualScrollService,
} from "@syncfusion/ej2-angular-grids";
import { ToolbarItem, ToolbarInterface, User } from "src/app/_models/index";

import { CoreService } from "src/app/_services/core.service";
import { ConfigService } from "src/app/_services/config.service";
import { FormBuilder, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { ModalService } from "src/app/_services/modal.service";
import { Query, Predicate, DataManager } from "@syncfusion/ej2-data";
import { FieldSettingsModel } from "@syncfusion/ej2-dropdowns";
import { TreeView } from "@syncfusion/ej2-navigations";
import * as $ from "jquery";
import * as moment from "moment";
import * as async from "async";
import * as _ from "lodash";
import { Meeting, MeetingContent, MeetingContentComment, MeetingContentDocument, MeetingContentNote, MeetingDocument, MeetingDocumentShare, MeetingVoting } from "src/app/_models/business/Meeting";
import { SocketService } from "src/app/_services/socket.service";
import * as Highcharts from 'highcharts';
import Recorder from 'recorder-js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MeetRoom } from "src/app/_models/masterdata/MeetRoom";
import { Consts } from "src/app/common/const";

import { KanbanComponent, CardSettingsModel, SwimlaneSettingsModel, ColumnsModel } from '@syncfusion/ej2-angular-kanban';
import { TaskManager } from "src/app/_models/extensions/TaskManager";
import { saveAs } from 'file-saver';
import { AccordionComponent } from "@syncfusion/ej2-angular-navigations";
import { PdfViewerComponent } from "ng2-pdf-viewer";
import { element } from "protractor";
import { DropDownListComponent, MultiSelectComponent, RemoveEventArgs, SelectEventArgs } from "@syncfusion/ej2-angular-dropdowns";
import { CKEditorComponent } from "@ckeditor/ckeditor5-angular";
import { ModalComponent } from "../modals.component";
import { Thickness } from "@syncfusion/ej2-angular-charts";
import { v4 as uuidv4 } from 'uuid';

declare var MediaRecorder: any;

setCulture("vi");

@Component({
  selector: "app-meeting-detailhappening",
  templateUrl: "./meeting-detailhappening.component.html",
  styleUrls: ["./meeting-detailhappening.component.scss"],
  providers: [FilterService, VirtualScrollService],
  encapsulation: ViewEncapsulation.None,
})
export class MeetingDetailHappeningComponent implements OnInit, OnDestroy, AfterViewInit {
  highcharts = Highcharts;
  // Varriable Language
  @Input("flagStateParam") flagStateParam: string;
  @Input("paramId") paramId: string;
  @Input("screen") screen: string;
  @Input("attendanceStatus") attendanceStatus: string;
  model: Meeting = new Meeting();
  languages: any;
  selectedLanguage: any;
  urlSafe: SafeResourceUrl;
  autoSlideComment = true;
  commentForm: FormGroup;
  documentCommentForm: FormGroup;
  votingForm: FormGroup;
  noteForm: FormGroup;
  contentForm: FormGroup;
  workingForm: FormGroup;
  tabActive = "infoTab";
  userIds = [];
  public selectAllText: string;
  public unSelectAllText: string;
  customMaximumMulti = 2000;
  public mode: string;

  @ViewChild("contentMemberGrid", { static: false })
  public contentMemberGrid: GridComponent;

  @ViewChild("contentGrid", { static: false })
  public contentGrid: GridComponent;

  @ViewChild("contentModalGrid", { static: false })
  public contentModalGrid: GridComponent;

  @ViewChild("contentHistoryGrid", { static: false })
  public contentHistoryGrid: GridComponent;

  @ViewChild("allCommentGrid", { static: false })
  public allCommentGrid: GridComponent;

  @ViewChild("myCommentGrid", { static: false })
  public myCommentGrid: GridComponent;

  @ViewChild("summaryCommentGrid", { static: false })
  public summaryCommentGrid: GridComponent;

  @ViewChild("documentContentGrid", { static: false })
  public documentContentGrid: GridComponent;

  @ViewChild("allDocumentCommentGrid", { static: false })
  public allDocumentCommentGrid: GridComponent;

  @ViewChild("myDocumentCommentGrid", { static: false })
  public myDocumentCommentGrid: GridComponent;

  @ViewChild("summaryDocumentCommentGrid", { static: false })
  public summaryDocumentCommentGrid: GridComponent;

  @ViewChild("privateDocumentGrid", { static: false })
  public privateDocumentGrid: GridComponent;

  @ViewChild("shareDocumentGrid", { static: false })
  public shareDocumentGrid: GridComponent;

  @ViewChild("memberShareGrid", { static: false })
  public memberShareGrid: GridComponent;

  @ViewChild("memberShareChooseGrid", { static: false })
  public memberShareChooseGrid: GridComponent;

  @ViewChild("guestDocumentGrid", { static: false })
  public guestDocumentGrid: GridComponent;

  @ViewChild("contentMemberModalGrid", { static: false })
  public contentMemberModalGrid: GridComponent;

  @ViewChild("contentGuestModalGrid", { static: false })
  public contentGuestModalGrid: GridComponent;
  @ViewChild("delegateGrid1", { static: false })
  public delegateGrid1: GridComponent;

  @ViewChild("contentMemberSummaryGrid", { static: false })
  public contentMemberSummaryGrid: GridComponent;

  @ViewChild("votingGrid", { static: false })
  public votingGrid: GridComponent;

  @ViewChild("votingMemberGrid", { static: false })
  public votingMemberGrid: GridComponent;

  @ViewChild("noteGrid", { static: false })
  public noteGrid: GridComponent;

  @ViewChild("kanbanObj", { static: false })
  public kanbanObj: KanbanComponent;

  @ViewChild("workingMemberGrid", { static: false })
  public workingMemberGrid: GridComponent;


  @ViewChild("workingOrgModalGrid", { static: false })
  public workingOrgModalGrid: GridComponent;

  @ViewChild("workingHistoryGrid", { static: false })
  public workingHistoryGrid: GridComponent;

  @ViewChild("workingCommentGrid", { static: false })
  public workingCommentGrid: GridComponent;

  @ViewChild("workingMemberModalGrid", { static: false })
  public workingMemberModalGrid: GridComponent;

  @ViewChild("workingChooseMemberModalGrid", { static: false })
  public workingChooseMemberModalGrid: GridComponent;
  @ViewChild('editor', { static: false }) ckeditor: CKEditorComponent;
  @ViewChild('editor', { static: false }) editor: CKEditorComponent;

  @ViewChild("delegateAccordion", { static: false })
  public delegateAccordion: AccordionComponent;

  public Editor = ClassicEditor;

  // Nội dung họp
  modelRealContent = new MeetingContent();
  lstContentHistorys = [];

  // Giao việc
  modelWorking = new TaskManager();
  modelOriginWorking = new TaskManager();
  lstWorkings = [];
  dataMemberWorking = [];
  dataChooseWorking = [];
  lstWorkingComments = [];
  lstWorkingHistorys = [];
  lstWorkingMeetings = [];
  lstWorkingMeetingContents = [];
  lstWorkingPrioritys = [];
  lstWorkingStatus = [];
  lstWorkingLeaders = [];
  lstWorkingFollowers = [];
  lstDelegateOrgs = [];
  lstDelegateOrgUsers = [];
  isDropDownEnabled:boolean = false

  public columns: any[] = [
    { headerText: 'Chỉ đạo mới', keyField: '37', allowToggle: true },
    { headerText: 'Từ chối', keyField: '40', allowToggle: true },
    { headerText: 'Đang xử lý', keyField: '38', allowToggle: true },
    { headerText: 'Hoàn thành', keyField: '39', allowToggle: true },
    { headerText: 'Đóng', keyField: '41', allowToggle: true }
  ];
  public cardSettings: CardSettingsModel = {
    headerField: 'id',
  };

  public swimlaneSettings: SwimlaneSettingsModel = { keyField: 'content_name' };
  reason_denied_working = "";
  reason_explanation = "";

  // Document
  lstContentDocuments = [];
  lstContentDocumentModals = [];
  lstDocuments = [];
  lstPrivateDocuments = [];
  lstShareDocuments = [];
  lstGuestDocuments = [];
  lstMemberShares = [];
  lstAllDocumentComments = [];
  lstMyDocumentComments = [];
  lstSummaryDocumentComments = [];
  lstShareChooses = [];
  searchContentAddDocument = null;
  modelContentDocumentAdd = new MeetingContent();
  searchSummaryDocument = {
    time_id: null,
    user_id: null,
    content_id: null
  }
  modelShare = new MeetingDocument();

  modelPosition = new MeetRoom();
  flagShowChart1 = false;
  flagShowChart2 = false;
  optionChart1 = {
    chart: {
      plotBorderWidth: null,
      plotShadow: false,
      style: {
        fontFamily: 'sans-serif'
      }
    },
    title: {
      text: 'THỐNG KÊ ĐIỂM DANH'
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          style: {
            color: 'black'
          }
        }
      }
    },
    series: [{
      type: 'pie',
      name: 'Thống kê điểm danh',
      data: [
      ]
    }]
  };

  optionChart2 = {
    chart: {
      plotBorderWidth: null,
      plotShadow: false,
      style: {
        fontFamily: 'sans-serif'
      }
    },
    title: {
      text: 'THỐNG KÊ ĐẠI BIỂU XÁC NHẬN'
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          style: {
            color: 'black'
          }
        }
      }
    },
    series: [{
      type: 'pie',
      name: 'Thống kê đại biểu xác nhận',
      data: [
      ]
    }]
  };

  lstWordTemplates = [];

  setIntervalVoting = null;

  groupMemberOptions: {
    showDropArea: boolean;
    columns: string[];
    captionTemplate: string;
  };

  groupOptions: {
    showDropArea: boolean;
    columns: string[];
    captionTemplate: string;
  };

  groupUserTypeOptions: {
    showDropArea: boolean;
    columns: string[];
    captionTemplate: string;
  };

  groupContentNameOptions: {
    showDropArea: boolean;
    columns: string[];
    captionTemplate: string;
  };

  // Ý kiến
  modelUser: any = null;
  modelRealtimeComment = new MeetingContentComment();
  modelEditComment = new MeetingContentComment();
  modelAddComment = new MeetingContentComment();
  modelAddComment2 = new MeetingContentComment();
  flagRealComment = false;
  flagSelfComment = false;
  lstAllComments = [];
  lstMyComments = [];
  lstSummaryComments = [];
  searchSummaryContent = {
    time_id: null,
    user_id: null,
    content_id: null
  }

  lstTimeComments = [{
    id: 0,
    name: "Tất cả"
  }, {
    id: 1,
    name: "Trước cuộc họp"
  }, {
    id: 2,
    name: "Trong cuộc họp"
  }];

  lstDelegateTabComments = [];
  lstDelegateTabCommentModals = [];

  // Đại biểu tham dự
  contentMemberIndex = -1;
  dataContentUpdateMember = null;
  lstSummaryMembers = [];

  // Sắp xếp chỗ ngồi
  lstMemberWaitings = [];
  lstBlankSeats = [];
  dataItem = null;
  flagShowItem = false;

  // Biểu quyết
  modelVoting = new MeetingVoting();
  modelRealVoting = new MeetingVoting();
  lstVotings = [];
  lstMemberVotings = [];
  flagStartVoting = false;
  flagIsVoting = false;
  countdownVoting = 0;
  minuteVoting = 0;
  sencondVoting = 0;
  minuteVotingDisplay = "";
  secondVotingDisplay = "";

  // Ghi chú
  lstNotes = [];
  modelNote = new MeetingContentNote();

  blobFile;
  recordAudio;
  sendObj = {
    audio: null
  };

  audioContext = new (AudioContext)({ sampleRate: 16000 });
  recorder = new Recorder(this.audioContext, {});

  // Lọc ở trang thông tin chung
  memberContentType = null;
  searchContentTabInfo = null;
  searchContentTabComment = null;

  searchContentTabRoom = null;

  flagState = "";
  lstRoomPositionOrigins = [];
  lstRoomPositionTabInfos = [];
  lstRoomPositionTabComments = [];

  lstRoomPositions = [];
  lstWaitingPositions = [];
  lstDelegates = [];
  lstDelegateSearchs = [];
  lstDelegateVotings = [];
  lstContents = [];
  lstContents1 = [];
  lstContentAttendances = [];
  lstContentTabComments = [];
  lstContentMembers = [];
  lstContentDelegates = [];
  lstContentGuests = [];
  lstContentReports = [];
  lstContentComments = [];
  lstComments = [];

  qrContentAttandance = "";
  qrContentDocument = "";

  total_users = 0;
  total_online = 0;
  total_offline = 0;
  total_grant = 0;
  // Trỏ interval
  public setInterval = null;
  // Cờ họp
  flagAttandance = false;
  flagCreateVoting = false;
  flagShowRealComment = true;
  // vi tri focus
  public query = new Query();
  public fields: FieldSettingsModel = { text: "name", value: "id" };
  public fieldContents: FieldSettingsModel = { text: "content", value: "id" };
  public fieldDelegates: FieldSettingsModel = { text: "full_name", value: "user_id" };

  // modelAdd = null;
  modelAdd: any = {
    id: null,
    content: "",
    content_name: "",
    user_id: null,
    content_id: null,
    files: "",
    links: "",
    file_type: "",
    url: null,
    record_file: null,
    full_name: "",
    title_name: "",
    is_before_view: false,
    is_inmeet_view: false,
    is_after_view: false,
    file_name: ""

  };

  modelDelete = null;
  modelName = null;
  flagStateModal = "";

  // FlagRole
  flagDelegate = false;
  flagChair = false;
  flagFollow = false;
  flagSecretary = false;
  isContent = false;
  isSave : boolean = false;
  lstContentUploads = [];

  // Added by Hoan
  centerX = 0;
  centerY = 0;
  testDefDel : boolean = false;
  private _unsubscribeAll: Subject<any>;

  ///Voicetotext
  public websocket: WebSocket;
  public check: boolean = true;
  public is_check: boolean = true;
  public interval = [];
  public setTime: any;
  public dataSession: any;

  public AudioContext: any;
  public context: AudioContext;
  public processor: ScriptProcessorNode;
  // public bufferSize = 8192;
  public bufferSize = 4096;
  public globalStream: MediaStream;
  public input: MediaStreamAudioSourceNode;
  public streamStreaming: any;

  public is_timeline : boolean;
  public file_path : any;
  public audioSource : any
  public audioSource2 : any
  public flagEnd : boolean = true;
  public flag_trans : boolean = true;
  public flag_save : boolean = true;
  public flag_close : boolean = true;
  public Key_type : any;

  // Added by Tung
  public seatsMapWidth: number = 0;
  public seatsMapHeight: number = 0;
  public seatsMapStartX: number = 0;
  public seatsMapStartY: number = 0;
  public truncateDistance: number = 0;
  // End Added by Tung

  /**
   * Constructor
   *
   */
  constructor(
    public sanitizer: DomSanitizer,
    private _coreService: CoreService,
    private modalService: ModalService,
    private notification: Notification,
    public globals: Globals,
    public configs: Configs,
    public router: Router,
    private _formBuilder: FormBuilder,
    public activatedRoute: ActivatedRoute,
    private _translateService: TranslateService,
    private _configService: ConfigService,
    private _socketService: SocketService,
    private _tlaTranslationLoaderService: TranslationLoaderService,
    private el: ElementRef,
    private cdr: ChangeDetectorRef
  ) {
    // Set language
    this.languages = this.globals.languages;

    this._configService._configSubject.next("true");
    // Load file language
    this._tlaTranslationLoaderService.loadTranslations(vietnam, english);

    this.commentForm = this._formBuilder.group({
      user_id: ["", [Validators.required]],
      content_id: ["", [Validators.required]],
      content: ["", [Validators.required, Validators.maxLength(2000)]]
    });

    this.documentCommentForm = this._formBuilder.group({
      content: ["", [Validators.required, Validators.maxLength(2000)]]
    });

    this.votingForm = this._formBuilder.group({
      content: ["", [Validators.required, Validators.maxLength(2000)]],
      content_id: ["", [Validators.required]],
      minutes: ["", [Validators.required, Validators.maxLength(2000)]],
    });

    this.noteForm = this._formBuilder.group({
      content_id: [""],
      note: ["", [Validators.required]]
    });

    this.contentForm = this._formBuilder.group({
      content_template_id: ["", [Validators.required]],
      content: ["", [Validators.required]]
    });

    this.workingForm = this._formBuilder.group({
      name: ["", [Validators.required]],
      meeting_id: [""],
      content_id: [""],
      priority_id: ["", [Validators.required]],
      status: ["", [Validators.required]],
      user_leader_id: ["", [Validators.required]],
      user_follow_id: ["", [Validators.required]],
      start_date: ["", [Validators.required]],
      deadline: ["", [Validators.required]],
      address: [""],
      description: ["", [Validators.required]],
      files: [""],
      date_created: [""],
      comment: [""]
    });


    // Set the private defaults
    this._unsubscribeAll = new Subject();
    L10n.load(this.configs.languageGrid);
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: BeforeUnloadEvent): boolean | undefined {
    if ( this.isSave == true) {
      event.preventDefault();
      window.addEventListener('unload', () => {
        if (!this.isSave) {
          const confirmationMessage = 'Bạn có chắc chắn muốn rời khỏi trang? Ghi âm chưa được lưu!';
          alert(confirmationMessage);
        }
      });
      return false;
    }
  }

  @HostListener('window:unload', ['$event'])
  onUnload(event: Event) {
    this.endRecordComment(this.Key_type)
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event: Event) {
    this.endRecordComment(this.Key_type)
  }

  // Added by tung
  @ViewChild("realTimeCommentTextArea", {static: false})
  public realTimeCommentTextArea: ElementRef<HTMLTextAreaElement>;

  idUserDelegate: any = localStorage.getItem('userId');
  /**
   * On init
   */
  ngOnInit(): void {
    this.modelAddComment.content = "";
    this.modelAddComment2.content = "";
    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, {
      id: this._translateService.currentLang,
    });
    this._translateService.use(this.selectedLanguage.id);
    this.flagState = this.flagStateParam;
    this.getListContent(this.paramId);
  }

  public setCKEditorData(data) {
    this.editor.editorInstance.setData(data);
    this.editor.editorInstance.model.change( writer => {
      writer.setSelection( writer.createPositionAt( this.editor.editorInstance.model.document.getRoot(), 'end' ) );
    } );
    const scrollHeight = this.editor.editorInstance.editing.view.domRoots.get('main').scrollHeight;
    this.editor.editorInstance.editing.view.domRoots.get('main').scrollTop = scrollHeight + 100;
  }

  ngOnDestroy(): void {
  }

  getComboBox = () => {
    return new Promise((resolve, reject) => {
      async.parallel([(cb) => {
        this._coreService.Get("/auth/adminGetUserInfo").subscribe((res) => {
          if (res && res.code && res.code == "200") {
            this.modelUser = res.data;
          }
          return cb();
        })
      }, (cb) => {
        this._coreService.Get("/dropdown/otherListByCode/TASK_STATUS").subscribe((res) => {
          if (res && res.code && res.code == "200") {
            this.lstWorkingStatus = res.data;
          }
          return cb();
        })
      }, (cb) => {
        this._coreService.Get("/dropdown/otherListByCode/TASK_PRIORITY").subscribe((res) => {
          if (res && res.code && res.code == "200") {
            this.lstWorkingPrioritys = res.data;
          }
          return cb();
        })
      }, (cb) => {
        this._coreService.Get("/dropdown/userByPermission/ALL").subscribe((res) => {
          if (res && res.code && res.code == "200") {
            this.lstWorkingLeaders = _.cloneDeep(res.data);
            this.lstWorkingFollowers = _.cloneDeep(res.data);
            this.dataMemberWorking = _.cloneDeep(res.data);
          }
          return cb();
        })
      }], (err, result) => {
        resolve(true);
      })
    })
  }
  public onFiltering(e, lst) {
    e.preventDefaultProvince = true;
    const predicate = new Predicate("name", "contains", e.text, true, true);
    this.query = new Query();
    this.query = e.text !== "" ? this.query.where(predicate) : this.query;
    e.updateData(lst, this.query);
  }

  public onFilteringContent(e, lst) {
    e.preventDefaultProvince = true;
    const predicate = new Predicate("content", "contains", e.text, true, true);
    this.query = new Query();
    this.query = e.text !== "" ? this.query.where(predicate) : this.query;
    e.updateData(lst, this.query);
  }

  public onFilteringDelegate(e, lst) {
    e.preventDefaultProvince = true;
    const predicate = new Predicate("full_name", "contains", e.text, true, true);
    this.query = new Query();
    this.query = e.text !== "" ? this.query.where(predicate) : this.query;
    e.updateData(lst, this.query);
  }

  defaultValue: any
  getMeetingInfoGeneral = () => {
    return new Promise((resolve, reject) => {
      this._coreService.Get("/meeting/detailHappening/" + this.paramId).subscribe((res) => {
        if (res && res.code === "200") {
          this.model = res.data;
          this.lstDelegates = this.model.list_delegates;
          this.lstDelegateSearchs = _.cloneDeep(this.model.list_delegates);
          if (this.model.user_follow_id) {
            var indexFollow = _.findIndex(this.lstDelegateSearchs, (item: any) => {
              return item.user_id == this.model.user_follow_id;
            });

            if (indexFollow == -1) {
              this.lstDelegateSearchs.unshift({
                user_id: this.model.user_follow_id,
                full_name: this.model.user_follow_name
              });
            }
          }

          var indexSecretary = _.findIndex(this.lstDelegateSearchs, (item: any) => {
            return item.user_id == this.model.user_secretary_id;
          });

          if (indexSecretary == -1) {
            this.lstDelegateSearchs.unshift({
              user_id: this.model.user_secretary_id,
              full_name: this.model.user_secretary_name
            });
          }

          var indexChair = _.findIndex(this.lstDelegateSearchs, (item: any) => {
            return item.user_id == this.model.user_chair_id;
          });

          if (indexChair == -1) {
            this.lstDelegateSearchs.unshift({
              user_id: this.model.user_chair_id,
              full_name: this.model.user_chair_name
            });
          }
          this.lstDelegateOrgs = [];
          if (this.model.list_delegates && this.model.list_delegates.length > 0) {
            for (let i = 0; i < this.model.list_delegates.length; i++) {
              let indexOrg = _.findIndex(this.lstDelegateOrgs, (item) => {
                return item.id == this.model.list_delegates[i].org_id;
              });

              if (indexOrg == -1) {
                this.lstDelegateOrgs.push({
                  id: this.model.list_delegates[i].org_id,
                  name: this.model.list_delegates[i].org_name
                });
              }
            }
          }

          this.lstDocuments = this.model.list_documents;
          this.lstContents = this.model.list_contents;

          this.lstContentAttendances = _.filter(this.lstContents, (item) => {
            return item.content !== "Khai mạc" && item.content !== "Bế mạc";
          });

          // this.lstContentTabComments = [];
          // for (let i = 0; i < this.lstContents.length; i++) {
          //   this.lstContents[i].list_documents = _.sortBy(this.lstContents[i].list_documents, "orders");

          //   this.lstContentTabComments.push(this.lstContents[i]);

          //   if (this.lstContents[i].content_childs && this.lstContents[i].content_childs.length > 0) {
          //     for (let j = 0; j < this.lstContents[i].content_childs.length; j++) {
          //       this.lstContents[i].content_childs[j].is_child = true;
          //       this.lstContentTabComments.push(this.lstContents[i].content_childs[j]);
          //     }
          //   }
          // }


          // let indexRealContent = _.findIndex(this.lstContents, (item) => {
          //   return item.status == 27;
          // });
          // console.log(indexRealContent)

          // if (indexRealContent > -1) {
          //   this.modelRealContent = this.lstContents[indexRealContent];
          //   this.getDataPositionTabInfo();
          //   let lst_delegates = this.modelRealContent.list_members;
          //   this.total_users = lst_delegates.length;
          //   this.total_online = _.filter(lst_delegates, (item) => {
          //     return item.attendance_status == true;
          //   }).length;
          //   this.total_offline = this.total_users - this.total_online;
          // } else {
          //   this.total_users = 0;
          //   this.total_online = 0;
          //   this.total_offline = 0;
          // }

          resolve(true);
        } else {
          reject(false);
        }
      }, (err) => {
        reject(false);
      })
    })
  }

  getMeetingInfo = () => {
    return new Promise((resolve, reject) => {
      async.waterfall([(cb) => {
        // Thông tin chung cuộc họp
        this.getMeetingInfoGeneral().then(() => {
          this.searchContentTabInfo = this.model.list_contents && this.model.list_contents.length > 0 ? this.model.list_contents[0].id : null;
          this.searchContentTabComment = this.model.list_contents && this.model.list_contents.length > 0 ? this.model.list_contents[0].id : null;
          this.searchContentTabRoom = this.model.list_contents && this.model.list_contents.length > 0 ? this.model.list_contents[0].id : null;

          // Join room họp
          if (this.flagState === "edit") {
            this._socketService.sendEmit("join-room-meeting", {
              room: this.model.id
            });
          }

          // Kiểm tra role
          let indexChair = _.findIndex(this.lstDelegates, (item) => {
            return item.user_id == this.modelUser.id && item.is_same_chair;
          });

          let indexDelegate = _.findIndex(this.lstDelegates, (item) => {
            return item.user_id == this.modelUser.id;
          });

          let indexAuthority = _.findIndex(this.model.list_authoritys, (item) => {
            return item.authority_user_id == this.modelUser.id;
          });

          this.flagChair = ((this.model.user_chair_id == this.modelUser.id) || (indexChair > -1)) ? true : false;
          this.flagFollow = (this.model.user_follow_id == this.modelUser.id) ? true : false;
          this.flagSecretary = (this.model.user_secretary_id == this.modelUser.id) ? true : false;
          this.flagDelegate = ((indexDelegate > -1) || indexAuthority > -1) ? true : false;

          if (!this.flagChair && this.tabActive == "infoTab") {
            this.tabActive = "operatingTab";
          }

          if (this.flagSecretary || this.flagFollow) {
            this.lstContentUploads = this.lstContents;
          } else {
            for (let i = 0; i < this.lstContents.length; i++) {
              if (this.lstContents[i] && this.lstContents[i].list_shares && this.lstContents[i].list_shares.length > 0) {
                for (let j = 0; j < this.lstContents[i].list_shares.length; j++) {
                  if (this.modelUser.id == this.lstContents[i].list_shares[j].user_id) {
                    this.lstContentUploads.push(this.lstContents[i]);
                    continue;
                  }
                }
              }
            }
          }


          return cb();
        }).catch(() => {
          return cb();
        })
      }, (cb) => {
        // Nếu chưa có lấy dữ liệu từ phòng
        this._coreService.Get("/meetroom/" + this.model.meet_room_id).subscribe((res) => {
          if (res && res.code && res.code == "200") {
            this.modelPosition = res.data;
            let lstRoomPositions = _.sortBy(_.filter(this.modelPosition.list_items, (item: any) => {
              return item.status == 1;
            }), "table_number");
            this.lstRoomPositionOrigins = _.cloneDeep(lstRoomPositions);
          }
          return cb();
        }, (error) => {
          return cb();
        })
      }, (cb) => {
        // Chi tiết Sơ đồ phòng kèm trạng thái chỗ ngồi
        this.getDataPosition().then(() => {
          return cb();
        })
      }, (cb) => {
        async.parallel([(cb1) => {
          this._coreService.Get("/dropdown/wordtemplate/46").subscribe((res) => {
            if (res && res.code && res.code == "200") {
              this.lstWordTemplates = res.data;
              if (!this.model.content_template_id) {
                let objDefault = _.find(this.lstWordTemplates, (item) => {
                  return item.is_default;
                });

                if (objDefault) {
                  this.model.content_template_id = objDefault.id;
                }
              }
            }
            return cb1();
          })
        }, (cb1) => {
          this._coreService.Post("/dropdown/userManageByOrgIds", {
            ids: _.map(this.lstDelegateOrgs, "id")
          }).subscribe((res) => {
            if (res && res.code && res.code == "200") {
              this.lstDelegateOrgUsers = res.data;
            }
            return cb1();
          })
        }], (err, result) => {
          return cb();
        })
      }], (err, result) => {
        resolve(false);
      })

    });
  }

  // Lấy dữ liệu cuộc họp cập nhật
  getDataMeeting = () => {
    return new Promise((resolve, reject) => {
      async.parallel([(cb) => {
        // Thông tin chung cuộc họp
        this._coreService.Get("/meeting/detailHappening/" + this.paramId).subscribe((res) => {
          if (res && res.code === "200") {
            this.lstContents = [];
            this.model = res.data;
            this.lstDelegates = this.model.list_delegates;
            this.lstDelegateSearchs = _.cloneDeep(this.model.list_delegates);
            if (this.model.user_follow_id) {
              var indexFollow = _.findIndex(this.lstDelegateSearchs, (item: any) => {
                return item.user_id == this.model.user_follow_id;
              });

              if (indexFollow == -1) {
                this.lstDelegateSearchs.unshift({
                  user_id: this.model.user_follow_id,
                  full_name: this.model.user_follow_name
                });
              }
            }

            var indexSecretary = _.findIndex(this.lstDelegateSearchs, (item: any) => {
              return item.user_id == this.model.user_secretary_id;
            });

            if (indexSecretary == -1) {
              this.lstDelegateSearchs.unshift({
                user_id: this.model.user_secretary_id,
                full_name: this.model.user_secretary_name
              });
            }

            var indexChair = _.findIndex(this.lstDelegateSearchs, (item: any) => {
              return item.user_id == this.model.user_chair_id;
            });

            if (indexChair == -1) {
              this.lstDelegateSearchs.unshift({
                user_id: this.model.user_chair_id,
                full_name: this.model.user_chair_name
              });
            }
            this.lstContents = _.cloneDeep(this.model.list_contents);
            this.lstContentAttendances = _.filter(this.lstContents, (item) => {
              return item.content !== "Khai mạc" && item.content !== "Bế mạc";
            });
            this.lstContentTabComments = [];
            for (let i = 0; i < this.lstContents.length; i++) {
              this.lstContents[i].list_documents = _.sortBy(this.lstContents[i].list_documents, "orders");

              this.lstContentTabComments.push(this.lstContents[i]);

              if (this.lstContents[i].content_childs && this.lstContents[i].content_childs.length > 0) {
                for (let j = 0; j < this.lstContents[i].content_childs.length; j++) {
                  this.lstContents[i].content_childs[j].is_child = true;
                  const item = _.cloneDeep(this.lstContents[i].content_childs[j]);
                  item.content = " - " + this.lstContents[i].content_childs[j].content
                  this.lstContentTabComments.push(item);
                }
              }
            }

            let indexRealContent = _.findIndex(this.lstContents, (item) => {
              return item.status == 27;
            });

            if (indexRealContent > -1) {
              this.modelRealContent = this.lstContents[indexRealContent];
              let lst_delegates = this.modelRealContent.list_members;
              this.total_users = lst_delegates.length;
              this.total_online = _.filter(lst_delegates, (item) => {
                return item.attendance_status == true;
              }).length;
              this.total_offline = this.total_users - this.total_online;
            } else {
              this.modelRealContent = new MeetingContent();
              this.total_users = 0;
              this.total_online = 0;
              this.total_offline = 0;
            }

            if (this.contentGrid) {
              this.contentGrid.dataSource = new DataManager(this.lstContents);
            }

            if (this.contentModalGrid) {
              this.contentModalGrid.dataSource = new DataManager(this.lstContents);
            }

            if (this.documentContentGrid) {
              this.documentContentGrid.dataSource = new DataManager(this.lstContents);
            }
          }
          return cb();
        }, (error) => {
          return cb();
        })

      }], (err, result) => {
        resolve(true);
      });
    })
  }

  // Xem diễ biến
  viewDevelopments = () => {
    this.contentModalGrid.refreshColumns();
    this.modalService.open("content-grid-modal");
  }

  // Lọc biểu đồ theo nội dung
  changeContentTabInfo(event) {
    if (event.isInteracted) {
      setTimeout(() => {
        if (this.searchContentTabInfo) {
          this.getDataChartAttendanceInfo();
        }
      }, 100);
    }
  }

  // Lấy dữ liệu điểm danh theo nội dung
  getDataChartAttendanceInfo = () => {
    this.flagShowChart1 = false;
    setTimeout(() => {
      var objContent = _.find(this.lstContents, (item) => {
        return item.id == this.searchContentTabInfo;
      });

      if (objContent && objContent.list_members) {
        var total_member = objContent.list_members.length;
        var total_online = _.filter(objContent.list_members, (item: any) => {
          return item.attendance_status == true;
        }).length;

        var total_offline = total_member - total_online;

        var lstChart1Options = [['Có mặt', total_online], ['Vắng mặt', total_offline]];

        this.optionChart1.series[0].data = lstChart1Options;

        this.flagShowChart1 = true;
      }
    }, 1000);
  }

  // Lấy dữ liệu xác nhận
  getDataChartAcceptInfo = () => {
    setTimeout(() => {
      var total_delegate = this.lstDelegates.length;
      var total_accept = _.filter(this.lstDelegates, (item: any) => {
        return item.status == 30;
      }).length;

      var total_denied = _.filter(this.lstDelegates, (item: any) => {
        return item.status == 31;
      }).length;

      var total_no_action = total_delegate - total_accept - total_denied;
      var lstChart2Options = [['Có tham dự', total_accept], ['Từ chối tham dự', total_denied], ['Không xác nhận', total_no_action]];

      this.optionChart2.series[0].data = lstChart2Options;

      this.flagShowChart2 = true;
    },1000)
  }

  // Xem thông tin đại biểu
  viewDelegateModal = (type) => {
    this.memberContentType = type;
    if (this.modelRealContent) {
      this.lstContentMembers = this.modelRealContent.list_members;

      if (type == "ALL") {
        this.contentMemberGrid.dataSource = new DataManager(this.lstContentMembers);
        this.modalService.open("content-member-modal");
      } else if (type == "ONLINE") {
        this.lstContentMembers = _.filter(this.lstContentMembers, (item: any) => {
          return item.attendance_status == true;
        });

        this.contentMemberGrid.dataSource = new DataManager(this.lstContentMembers);
        this.modalService.open("content-member-modal");
      } else if (type == "OFFLINE") {
        this.lstContentMembers = _.filter(this.lstContentMembers, (item: any) => {
          return !item.attendance_status;
        });

        this.contentMemberGrid.dataSource = new DataManager(this.lstContentMembers);
        this.modalService.open("content-member-modal");
      }
    } else {
      return;
    }
  }

  closeContentMemberModal = () => {
    this.modalService.close("content-member-modal");
  }

  // Chọn đại biểu
  chooseDelegateComment = (item) => {
    if (item && item.user_id && this.flagState !== 'view' && !this.flagRealComment && (this.flagChair || this.flagSecretary || this.flagFollow)) {
      this.modelAddComment.user_id = item.user_id;
      this.modelAddComment.full_name = item.full_name;
    }
  }
  // Ghi âm ý kiến
  async writeRecordComment(type : any) {
    this.check = true;
    this.is_check = true;
    this.streamStreaming = true;
    const AudioContext =
      (window as any).AudioContext || (window as any).webkitAudioContext;
    if (!this.context) {
      this.context = new AudioContext({
        latencyHint: "interactive",
      });
      this.processor = this.context.createScriptProcessor(
        this.bufferSize,
        1,
        1
      );
      this.processor.connect(this.context.destination);
      this.context.resume();
      let Json ={};
      if(type == 1){
         Json = {
          config: {
            meetingid: this.paramId,
            userid: this.modelUser.id,
            is_transcrible: this.dataSession.is_transcrible,
            worker_id: this.dataSession.worker_id,
            content_id: this.modelAddComment.content_ids[0],
            session: this.dataSession.session,
            type: this.dataSession.type,
          },
        };
      }
      else if(type == 2){
        Json = {
          config: {
            meetingid: this.paramId,
            userid: this.modelUser.id,
            is_transcrible: this.dataSession.is_transcrible,
            worker_id: this.dataSession.worker_id,
            content_id: this.modelContentDocumentAdd.id,
            session: this.dataSession.session,
            type: this.dataSession.type,
          },
        }
      }


      const jsonString = JSON.stringify(Json);

      this.websocket.send(jsonString);

      var handleSuccess = (stream) => {
        this.globalStream = stream;
        this.input = this.context.createMediaStreamSource(stream);
        this.input.connect(this.processor);

        this.processor.onaudioprocess = (e) => {
          var left = e.inputBuffer.getChannelData(0);
          var left16 = this.downsampleBuffer(left, 48000, 16000);
          this.websocket.send(left16);
        };
      };
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then(handleSuccess.bind(this));
    }
  }

  downsampleBuffer(buffer, sampleRate, outSampleRate) {
    if (sampleRate && sampleRate !== null) {
      if (outSampleRate === sampleRate) {
        return buffer;
      }
      const sampleRateRatio = sampleRate / outSampleRate;
      const newLength = Math.round(buffer.length / sampleRateRatio);
      const result = new Int16Array(newLength);
      let offsetResult = 0;
      let offsetBuffer = 0;
      while (offsetResult < result.length) {
        const nextOffsetBuffer = Math.round(
          (offsetResult + 1) * sampleRateRatio
        );
        let accum = 0;
        let count = 0;
        for (
          let i = offsetBuffer;
          i < nextOffsetBuffer && i < buffer.length;
          i++
        ) {
          accum += buffer[i];
          count++;
        }

        result[offsetResult] = Math.min(1, accum / count) * 0x7fff;
        offsetResult++;
        offsetBuffer = nextOffsetBuffer;
      }
      return result.buffer;
    }
  }


  // Lắng nghe socket voice to text để cập nhật data
  updateRecordComment = (text: string) => {
    // Chèn API voice to text

    let list_contents = [];
    for (let i = 0; i < this.modelAddComment.content_ids.length; i++) {
      list_contents.push({
        content_id: this.modelAddComment.content_ids[i]
      })
    }

    let content = "";
    list_contents.forEach(item => {
      var dataContent = this.lstContents.find((element: any) =>  element.id == item.content_id);
      if(content != ""){
        content =content + "," + " ";
        content = content.concat(dataContent.content  != undefined  ? dataContent.content : "" );
      }else{
        content = content.concat(dataContent.content  != undefined  ? dataContent.content : "" );
      }
    });
    this.modelRealtimeComment.content_name = content;
    this.modelAddComment.content_name = content;

    var data = this.lstDelegateTabComments.find((element: any) =>  element.user_id == this.modelAddComment.user_id);
    this.modelRealtimeComment.full_name =   data.full_name + " "  +(data.title_name  ? " - " + data.title_name : "") + " " +(data.org_name != undefined ? data.org_name: "");
    this.modelAddComment.full_name = this.modelRealtimeComment.full_name

    if(this.flag_close == true){
      this.modelAddComment.content = text;
    }

    if (this.modelAddComment.content_ids && this.modelAddComment.user_id) {
      // Gửi socket cập nhật ý kiến
        this._socketService.sendEmit("update-comment", {
          room: this.model.id,
          data: this.modelAddComment
        });
  }
  }

  // Kết thúc ghi âm
   endRecordComment(type : any) {
    if(type == 1){
      this._socketService.sendEmit("end-comment", {
        room: this.model.id,
        data: this.modelAddComment
      });
    }
    this.flagEnd = false;
    if(type == 1){
      document.getElementById("contentPar").innerHTML = " ";
    }
    else{
      document.getElementById("contentDocumentPart").innerHTML = " ";
    }

    this.check = false;
    this.streamStreaming = false;
    this.flagSelfComment = false;
    this.flagRealComment = false;
    this.isContent = false;
    this.interval.forEach((intervalId) => {
     clearInterval(intervalId);
    });

    if (this.globalStream) {
      let track = this.globalStream.getTracks()[0];
      track.stop();
    }

    if (this.input) {
      this.input.disconnect(this.processor);
    }

    if (this.processor) {
      this.processor.disconnect(this.context.destination);
    }

    if (this.context) {
      this.input = null;
      this.processor = null;

      this.context.close().then(() => {
        this.context = null;
      });
    }
    // await this.closeSS(type);
     this.closeSS(type);
  }

 async  closeSS(type : any) {
    let token  = localStorage.getItem("token");
    const Json = {
      eof: 1,
    };
    ///chuyển đổi đối tượng JSON thành JsonString trước khi gửi nó qua WebSocket
    const jsonString = JSON.stringify(Json);
    ///Gửi JsonString đến websocket
     await this.websocket.send(jsonString);
     this.websocket.send(jsonString);
    this.websocket.addEventListener("message", (event) => {
      const responseData = JSON.parse(event.data);
      this.closeSK(type,token);
    });
  }

    //API kết thúc socket
    async closeSK(type : any,token : any) {
      let url = "meeting/session/stop";
      this._coreService
        .stopSK(url, this.paramId, this.dataSession.session,token)
        .subscribe(({ data }) => {
          this.setTime = setInterval(() => {
            this.getVoice2Text(type);
          }, 2000);
          this.interval.push(this.setTime);
        });
    }

  changeModelComment = () => {
    setTimeout(() => {
      if (this.modelAddComment.content && this.modelAddComment.content_ids && this.modelAddComment.user_id) {
        this._socketService.sendEmit("update-comment", {
          room: this.model.id,
          data: this.modelAddComment
        });
      }
    }, 50);
  }

  // Mở modal real comment
  openRealComment = () => {
    this.flagShowRealComment = true;
    this.modalService.open("real-comment-modal");
  }

  closeRealComment = () => {
    this.flagShowRealComment = false;
    this.modalService.close("real-comment-modal");
  }
  // In QR
  printQRAttandance = () => {
    let printContents = "";
    let qrContents = "";
    let popupWin;

    let frontDiv = `
          <div class="card-item">
            <div class="card-detail testdiv">
                <p class="txtTitle">${'Phòng họp không giấy'}</p>
                <div class="testspan">
                  <p class="testcolor">${'Mã QRCode điểm danh'}</p>
                  <div id="qrcode" class="" style="">
                    <img class="app-logo" src="/assets/images/logo3.png">
                  </div>
                  <div>
                    <b>${this.model.name}</b>
                  </div>
                </div>
            </div>
          </div>`;

    printContents += frontDiv;

    let objQR = `$('#qrcode').qrcode({width: 150,height: 150, text: "${this.qrContentAttandance}"});`;

    qrContents += objQR;


    popupWin = window.open(
      "",
      "_blank",
      "top=50,left=50,height='100%',width=auto"
    );
    popupWin.document.open();

    // const headBody = "<body>";
    let headBody = '<body onload="window.print();window.close()">';
    popupWin.document.write(
      `
          <html>
          <head>
              <title></title>
              <style>
                  html,
                  body {
                      display: block;
                      width: 100%;
                      color: #fff important;
                      -webkit-print-color-adjust: exact;
                  }
                  .full-width {
                      display: block;
                      width: 100%;
                  }
                  .full-width.front-card {
                    page-break-after: always;
                  }
                  .card-item {
                      display: inline-block;
                      width: 100%;
                  }
                  .card-detail {
                      position: relative;
                      padding: 5px;
                      height: auto !important;
                      width: 200px !important;
                      border: 1px solid #ccc
                  }
                  .card-detail .card-front {
                    width: 100%;
                  }
                  .card-detail .qr-code {
                      position: absolute;
                      left: 50%;
                      height: 200px !important;
                      width: 200px !important;
                      top: 50%;
                      border: 1px solid white;
                  }

                  .testcolor {
                    color: blue;
                    text-align: center;
                    display: block;
                    margin: 0;
                    font-weight: 600;
                    font-size: 16px
                  }
                  .testspan div{
                    margin-top: 20px;
                  }

                  .testdiv b {
                    width: 100%;
                    flex-wrap: wrap;
                      text-align: center;
                  }

                  .testdiv {
                    width: 200px ;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    flex-direction: collumn;
                    flex-wrap: wrap;
                    padding: 15px;
                  }

                  .txtTitle {
                    font-weight: 600;
                    margin: 0 !important;
                    font-size: 18px;
                  }

                  .app-logo {
                    height: 50px !important;
                    width: 50px !important;
                  }

                  #qrcode img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 40px;
                    height: 40px;
                }
              </style>
          </head>` +
      headBody +
      `<div class="full-width front-card">${printContents}</div>
              <script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>
              <script>
                  (function(r) {
                      r.fn.qrcode = function(h) {
                          var s; function u(a) {this.mode = s; this.data = a} function o(a, c) {this.typeNumber = a; this.errorCorrectLevel = c; this.modules = null; this.moduleCount = 0; this.dataCache = null; this.dataList = []} function q(a, c) {if (void 0 == a.length) throw Error(a.length + "/" + c); for (var d = 0; d < a.length && 0 == a[d];)d++; this.num = Array(a.length - d + c); for (var b = 0; b < a.length - d; b++)this.num[b] = a[b + d]} function p(a, c) {this.totalCount = a; this.dataCount = c} function t() {this.buffer = []; this.length = 0} u.prototype = {
                              getLength: function() {return this.data.length},
                              write: function(a) {for (var c = 0; c < this.data.length; c++)a.put(this.data.charCodeAt(c), 8)}
                          }; o.prototype = {
                              addData: function(a) {this.dataList.push(new u(a)); this.dataCache = null}, isDark: function(a, c) {if (0 > a || this.moduleCount <= a || 0 > c || this.moduleCount <= c) throw Error(a + "," + c); return this.modules[a][c]}, getModuleCount: function() {return this.moduleCount}, make: function() {
                                  if (1 > this.typeNumber) {
                                      for (var a = 1, a = 1; 40 > a; a++) {
                                          for (var c = p.getRSBlocks(a, this.errorCorrectLevel), d = new t, b = 0, e = 0; e < c.length; e++)b += c[e].dataCount;
                                          for (e = 0; e < this.dataList.length; e++)c = this.dataList[e], d.put(c.mode, 4), d.put(c.getLength(), j.getLengthInBits(c.mode, a)), c.write(d); if (d.getLengthInBits() <= 8 * b) break
                                      } this.typeNumber = a
                                  } this.makeImpl(!1, this.getBestMaskPattern())
                              }, makeImpl: function(a, c) {
                                  this.moduleCount = 4 * this.typeNumber + 17; this.modules = Array(this.moduleCount); for (var d = 0; d < this.moduleCount; d++) {this.modules[d] = Array(this.moduleCount); for (var b = 0; b < this.moduleCount; b++)this.modules[d][b] = null} this.setupPositionProbePattern(0, 0); this.setupPositionProbePattern(this.moduleCount -
                                      7, 0); this.setupPositionProbePattern(0, this.moduleCount - 7); this.setupPositionAdjustPattern(); this.setupTimingPattern(); this.setupTypeInfo(a, c); 7 <= this.typeNumber && this.setupTypeNumber(a); null == this.dataCache && (this.dataCache = o.createData(this.typeNumber, this.errorCorrectLevel, this.dataList)); this.mapData(this.dataCache, c)
                              }, setupPositionProbePattern: function(a, c) {
                                  for (var d = -1; 7 >= d; d++)if (!(-1 >= a + d || this.moduleCount <= a + d)) for (var b = -1; 7 >= b; b++)-1 >= c + b || this.moduleCount <= c + b || (this.modules[a + d][c + b] =
                                      0 <= d && 6 >= d && (0 == b || 6 == b) || 0 <= b && 6 >= b && (0 == d || 6 == d) || 2 <= d && 4 >= d && 2 <= b && 4 >= b ? !0 : !1)
                              }, getBestMaskPattern: function() {for (var a = 0, c = 0, d = 0; 8 > d; d++) {this.makeImpl(!0, d); var b = j.getLostPoint(this); if (0 == d || a > b) a = b, c = d} return c}, createMovieClip: function(a, c, d) {a = a.createEmptyMovieClip(c, d); this.make(); for (c = 0; c < this.modules.length; c++)for (var d = 1 * c, b = 0; b < this.modules[c].length; b++) {var e = 1 * b; this.modules[c][b] && (a.beginFill(0, 100), a.moveTo(e, d), a.lineTo(e + 1, d), a.lineTo(e + 1, d + 1), a.lineTo(e, d + 1), a.endFill())} return a},
                              setupTimingPattern: function() {for (var a = 8; a < this.moduleCount - 8; a++)null == this.modules[a][6] && (this.modules[a][6] = 0 == a % 2); for (a = 8; a < this.moduleCount - 8; a++)null == this.modules[6][a] && (this.modules[6][a] = 0 == a % 2)}, setupPositionAdjustPattern: function() {for (var a = j.getPatternPosition(this.typeNumber), c = 0; c < a.length; c++)for (var d = 0; d < a.length; d++) {var b = a[c], e = a[d]; if (null == this.modules[b][e]) for (var f = -2; 2 >= f; f++)for (var i = -2; 2 >= i; i++)this.modules[b + f][e + i] = -2 == f || 2 == f || -2 == i || 2 == i || 0 == f && 0 == i ? !0 : !1} }, setupTypeNumber: function(a) {
                                  for (var c =
                                      j.getBCHTypeNumber(this.typeNumber), d = 0; 18 > d; d++) {var b = !a && 1 == (c >> d & 1); this.modules[Math.floor(d / 3)][d % 3 + this.moduleCount - 8 - 3] = b} for (d = 0; 18 > d; d++)b = !a && 1 == (c >> d & 1), this.modules[d % 3 + this.moduleCount - 8 - 3][Math.floor(d / 3)] = b
                              }, setupTypeInfo: function(a, c) {
                                  for (var d = j.getBCHTypeInfo(this.errorCorrectLevel << 3 | c), b = 0; 15 > b; b++) {var e = !a && 1 == (d >> b & 1); 6 > b ? this.modules[b][8] = e : 8 > b ? this.modules[b + 1][8] = e : this.modules[this.moduleCount - 15 + b][8] = e} for (b = 0; 15 > b; b++)e = !a && 1 == (d >> b & 1), 8 > b ? this.modules[8][this.moduleCount -
                                      b - 1] = e : 9 > b ? this.modules[8][15 - b - 1 + 1] = e : this.modules[8][15 - b - 1] = e; this.modules[this.moduleCount - 8][8] = !a
                              }, mapData: function(a, c) {for (var d = -1, b = this.moduleCount - 1, e = 7, f = 0, i = this.moduleCount - 1; 0 < i; i -= 2)for (6 == i && i--; ;) {for (var g = 0; 2 > g; g++)if (null == this.modules[b][i - g]) {var n = !1; f < a.length && (n = 1 == (a[f] >>> e & 1)); j.getMask(c, b, i - g) && (n = !n); this.modules[b][i - g] = n; e--; -1 == e && (f++ , e = 7)} b += d; if (0 > b || this.moduleCount <= b) {b -= d; d = -d; break} } }
                          }; o.PAD0 = 236; o.PAD1 = 17; o.createData = function(a, c, d) {
                              for (var c = p.getRSBlocks(a,
                                  c), b = new t, e = 0; e < d.length; e++) {var f = d[e]; b.put(f.mode, 4); b.put(f.getLength(), j.getLengthInBits(f.mode, a)); f.write(b)} for (e = a = 0; e < c.length; e++)a += c[e].dataCount; if (b.getLengthInBits() > 8 * a) throw Error("code length overflow. (" + b.getLengthInBits() + ">" + 8 * a + ")"); for (b.getLengthInBits() + 4 <= 8 * a && b.put(0, 4); 0 != b.getLengthInBits() % 8;)b.putBit(!1); for (; !(b.getLengthInBits() >= 8 * a);) {b.put(o.PAD0, 8); if (b.getLengthInBits() >= 8 * a) break; b.put(o.PAD1, 8)} return o.createBytes(b, c)
                          }; o.createBytes = function(a, c) {
                              for (var d =
                                  0, b = 0, e = 0, f = Array(c.length), i = Array(c.length), g = 0; g < c.length; g++) {var n = c[g].dataCount, h = c[g].totalCount - n, b = Math.max(b, n), e = Math.max(e, h); f[g] = Array(n); for (var k = 0; k < f[g].length; k++)f[g][k] = 255 & a.buffer[k + d]; d += n; k = j.getErrorCorrectPolynomial(h); n = (new q(f[g], k.getLength() - 1)).mod(k); i[g] = Array(k.getLength() - 1); for (k = 0; k < i[g].length; k++)h = k + n.getLength() - i[g].length, i[g][k] = 0 <= h ? n.get(h) : 0} for (k = g = 0; k < c.length; k++)g += c[k].totalCount; d = Array(g); for (k = n = 0; k < b; k++)for (g = 0; g < c.length; g++)k < f[g].length &&
                                      (d[n++] = f[g][k]); for (k = 0; k < e; k++)for (g = 0; g < c.length; g++)k < i[g].length && (d[n++] = i[g][k]); return d
                          }; s = 4; for (var j = {
                              PATTERN_POSITION_TABLE: [[], [6, 18], [6, 22], [6, 26], [6, 30], [6, 34], [6, 22, 38], [6, 24, 42], [6, 26, 46], [6, 28, 50], [6, 30, 54], [6, 32, 58], [6, 34, 62], [6, 26, 46, 66], [6, 26, 48, 70], [6, 26, 50, 74], [6, 30, 54, 78], [6, 30, 56, 82], [6, 30, 58, 86], [6, 34, 62, 90], [6, 28, 50, 72, 94], [6, 26, 50, 74, 98], [6, 30, 54, 78, 102], [6, 28, 54, 80, 106], [6, 32, 58, 84, 110], [6, 30, 58, 86, 114], [6, 34, 62, 90, 118], [6, 26, 50, 74, 98, 122], [6, 30, 54, 78, 102, 126], [6, 26, 52,
                                  78, 104, 130], [6, 30, 56, 82, 108, 134], [6, 34, 60, 86, 112, 138], [6, 30, 58, 86, 114, 142], [6, 34, 62, 90, 118, 146], [6, 30, 54, 78, 102, 126, 150], [6, 24, 50, 76, 102, 128, 154], [6, 28, 54, 80, 106, 132, 158], [6, 32, 58, 84, 110, 136, 162], [6, 26, 54, 82, 110, 138, 166], [6, 30, 58, 86, 114, 142, 170]], G15: 1335, G18: 7973, G15_MASK: 21522, getBCHTypeInfo: function(a) {for (var c = a << 10; 0 <= j.getBCHDigit(c) - j.getBCHDigit(j.G15);)c ^= j.G15 << j.getBCHDigit(c) - j.getBCHDigit(j.G15); return (a << 10 | c) ^ j.G15_MASK}, getBCHTypeNumber: function(a) {
                                      for (var c = a << 12; 0 <= j.getBCHDigit(c) -
                                          j.getBCHDigit(j.G18);)c ^= j.G18 << j.getBCHDigit(c) - j.getBCHDigit(j.G18); return a << 12 | c
                                  }, getBCHDigit: function(a) {for (var c = 0; 0 != a;)c++ , a >>>= 1; return c}, getPatternPosition: function(a) {return j.PATTERN_POSITION_TABLE[a - 1]}, getMask: function(a, c, d) {
                                      switch (a) {
                                          case 0: return 0 == (c + d) % 2; case 1: return 0 == c % 2; case 2: return 0 == d % 3; case 3: return 0 == (c + d) % 3; case 4: return 0 == (Math.floor(c / 2) + Math.floor(d / 3)) % 2; case 5: return 0 == c * d % 2 + c * d % 3; case 6: return 0 == (c * d % 2 + c * d % 3) % 2; case 7: return 0 == (c * d % 3 + (c + d) % 2) % 2; default: throw Error("bad maskPattern:" +
                                              a);
                                      }
                                  }, getErrorCorrectPolynomial: function(a) {for (var c = new q([1], 0), d = 0; d < a; d++)c = c.multiply(new q([1, l.gexp(d)], 0)); return c}, getLengthInBits: function(a, c) {
                                      if (1 <= c && 10 > c) switch (a) {case 1: return 10; case 2: return 9; case s: return 8; case 8: return 8; default: throw Error("mode:" + a);} else if (27 > c) switch (a) {case 1: return 12; case 2: return 11; case s: return 16; case 8: return 10; default: throw Error("mode:" + a);} else if (41 > c) switch (a) {
                                          case 1: return 14; case 2: return 13; case s: return 16; case 8: return 12; default: throw Error("mode:" +
                                              a);
                                      } else throw Error("type:" + c);
                                  }, getLostPoint: function(a) {
                                      for (var c = a.getModuleCount(), d = 0, b = 0; b < c; b++)for (var e = 0; e < c; e++) {for (var f = 0, i = a.isDark(b, e), g = -1; 1 >= g; g++)if (!(0 > b + g || c <= b + g)) for (var h = -1; 1 >= h; h++)0 > e + h || c <= e + h || 0 == g && 0 == h || i == a.isDark(b + g, e + h) && f++; 5 < f && (d += 3 + f - 5)} for (b = 0; b < c - 1; b++)for (e = 0; e < c - 1; e++)if (f = 0, a.isDark(b, e) && f++ , a.isDark(b + 1, e) && f++ , a.isDark(b, e + 1) && f++ , a.isDark(b + 1, e + 1) && f++ , 0 == f || 4 == f) d += 3; for (b = 0; b < c; b++)for (e = 0; e < c - 6; e++)a.isDark(b, e) && !a.isDark(b, e + 1) && a.isDark(b, e +
                                          2) && a.isDark(b, e + 3) && a.isDark(b, e + 4) && !a.isDark(b, e + 5) && a.isDark(b, e + 6) && (d += 40); for (e = 0; e < c; e++)for (b = 0; b < c - 6; b++)a.isDark(b, e) && !a.isDark(b + 1, e) && a.isDark(b + 2, e) && a.isDark(b + 3, e) && a.isDark(b + 4, e) && !a.isDark(b + 5, e) && a.isDark(b + 6, e) && (d += 40); for (e = f = 0; e < c; e++)for (b = 0; b < c; b++)a.isDark(b, e) && f++; a = Math.abs(100 * f / c / c - 50) / 5; return d + 10 * a
                                  }
                          }, l = {
                              glog: function(a) {if (1 > a) throw Error("glog(" + a + ")"); return l.LOG_TABLE[a]}, gexp: function(a) {for (; 0 > a;)a += 255; for (; 256 <= a;)a -= 255; return l.EXP_TABLE[a]}, EXP_TABLE: Array(256),
                              LOG_TABLE: Array(256)
                          }, m = 0; 8 > m; m++)l.EXP_TABLE[m] = 1 << m; for (m = 8; 256 > m; m++)l.EXP_TABLE[m] = l.EXP_TABLE[m - 4] ^ l.EXP_TABLE[m - 5] ^ l.EXP_TABLE[m - 6] ^ l.EXP_TABLE[m - 8]; for (m = 0; 255 > m; m++)l.LOG_TABLE[l.EXP_TABLE[m]] = m; q.prototype = {
                              get: function(a) {return this.num[a]}, getLength: function() {return this.num.length}, multiply: function(a) {for (var c = Array(this.getLength() + a.getLength() - 1), d = 0; d < this.getLength(); d++)for (var b = 0; b < a.getLength(); b++)c[d + b] ^= l.gexp(l.glog(this.get(d)) + l.glog(a.get(b))); return new q(c, 0)}, mod: function(a) {
                                  if (0 >
                                      this.getLength() - a.getLength()) return this; for (var c = l.glog(this.get(0)) - l.glog(a.get(0)), d = Array(this.getLength()), b = 0; b < this.getLength(); b++)d[b] = this.get(b); for (b = 0; b < a.getLength(); b++)d[b] ^= l.gexp(l.glog(a.get(b)) + c); return (new q(d, 0)).mod(a)
                              }
                          }; p.RS_BLOCK_TABLE = [[1, 26, 19], [1, 26, 16], [1, 26, 13], [1, 26, 9], [1, 44, 34], [1, 44, 28], [1, 44, 22], [1, 44, 16], [1, 70, 55], [1, 70, 44], [2, 35, 17], [2, 35, 13], [1, 100, 80], [2, 50, 32], [2, 50, 24], [4, 25, 9], [1, 134, 108], [2, 67, 43], [2, 33, 15, 2, 34, 16], [2, 33, 11, 2, 34, 12], [2, 86, 68], [4, 43, 27],
                          [4, 43, 19], [4, 43, 15], [2, 98, 78], [4, 49, 31], [2, 32, 14, 4, 33, 15], [4, 39, 13, 1, 40, 14], [2, 121, 97], [2, 60, 38, 2, 61, 39], [4, 40, 18, 2, 41, 19], [4, 40, 14, 2, 41, 15], [2, 146, 116], [3, 58, 36, 2, 59, 37], [4, 36, 16, 4, 37, 17], [4, 36, 12, 4, 37, 13], [2, 86, 68, 2, 87, 69], [4, 69, 43, 1, 70, 44], [6, 43, 19, 2, 44, 20], [6, 43, 15, 2, 44, 16], [4, 101, 81], [1, 80, 50, 4, 81, 51], [4, 50, 22, 4, 51, 23], [3, 36, 12, 8, 37, 13], [2, 116, 92, 2, 117, 93], [6, 58, 36, 2, 59, 37], [4, 46, 20, 6, 47, 21], [7, 42, 14, 4, 43, 15], [4, 133, 107], [8, 59, 37, 1, 60, 38], [8, 44, 20, 4, 45, 21], [12, 33, 11, 4, 34, 12], [3, 145, 115, 1, 146,
                              116], [4, 64, 40, 5, 65, 41], [11, 36, 16, 5, 37, 17], [11, 36, 12, 5, 37, 13], [5, 109, 87, 1, 110, 88], [5, 65, 41, 5, 66, 42], [5, 54, 24, 7, 55, 25], [11, 36, 12], [5, 122, 98, 1, 123, 99], [7, 73, 45, 3, 74, 46], [15, 43, 19, 2, 44, 20], [3, 45, 15, 13, 46, 16], [1, 135, 107, 5, 136, 108], [10, 74, 46, 1, 75, 47], [1, 50, 22, 15, 51, 23], [2, 42, 14, 17, 43, 15], [5, 150, 120, 1, 151, 121], [9, 69, 43, 4, 70, 44], [17, 50, 22, 1, 51, 23], [2, 42, 14, 19, 43, 15], [3, 141, 113, 4, 142, 114], [3, 70, 44, 11, 71, 45], [17, 47, 21, 4, 48, 22], [9, 39, 13, 16, 40, 14], [3, 135, 107, 5, 136, 108], [3, 67, 41, 13, 68, 42], [15, 54, 24, 5, 55, 25], [15,
                              43, 15, 10, 44, 16], [4, 144, 116, 4, 145, 117], [17, 68, 42], [17, 50, 22, 6, 51, 23], [19, 46, 16, 6, 47, 17], [2, 139, 111, 7, 140, 112], [17, 74, 46], [7, 54, 24, 16, 55, 25], [34, 37, 13], [4, 151, 121, 5, 152, 122], [4, 75, 47, 14, 76, 48], [11, 54, 24, 14, 55, 25], [16, 45, 15, 14, 46, 16], [6, 147, 117, 4, 148, 118], [6, 73, 45, 14, 74, 46], [11, 54, 24, 16, 55, 25], [30, 46, 16, 2, 47, 17], [8, 132, 106, 4, 133, 107], [8, 75, 47, 13, 76, 48], [7, 54, 24, 22, 55, 25], [22, 45, 15, 13, 46, 16], [10, 142, 114, 2, 143, 115], [19, 74, 46, 4, 75, 47], [28, 50, 22, 6, 51, 23], [33, 46, 16, 4, 47, 17], [8, 152, 122, 4, 153, 123], [22, 73, 45,
                              3, 74, 46], [8, 53, 23, 26, 54, 24], [12, 45, 15, 28, 46, 16], [3, 147, 117, 10, 148, 118], [3, 73, 45, 23, 74, 46], [4, 54, 24, 31, 55, 25], [11, 45, 15, 31, 46, 16], [7, 146, 116, 7, 147, 117], [21, 73, 45, 7, 74, 46], [1, 53, 23, 37, 54, 24], [19, 45, 15, 26, 46, 16], [5, 145, 115, 10, 146, 116], [19, 75, 47, 10, 76, 48], [15, 54, 24, 25, 55, 25], [23, 45, 15, 25, 46, 16], [13, 145, 115, 3, 146, 116], [2, 74, 46, 29, 75, 47], [42, 54, 24, 1, 55, 25], [23, 45, 15, 28, 46, 16], [17, 145, 115], [10, 74, 46, 23, 75, 47], [10, 54, 24, 35, 55, 25], [19, 45, 15, 35, 46, 16], [17, 145, 115, 1, 146, 116], [14, 74, 46, 21, 75, 47], [29, 54, 24, 19,
                              55, 25], [11, 45, 15, 46, 46, 16], [13, 145, 115, 6, 146, 116], [14, 74, 46, 23, 75, 47], [44, 54, 24, 7, 55, 25], [59, 46, 16, 1, 47, 17], [12, 151, 121, 7, 152, 122], [12, 75, 47, 26, 76, 48], [39, 54, 24, 14, 55, 25], [22, 45, 15, 41, 46, 16], [6, 151, 121, 14, 152, 122], [6, 75, 47, 34, 76, 48], [46, 54, 24, 10, 55, 25], [2, 45, 15, 64, 46, 16], [17, 152, 122, 4, 153, 123], [29, 74, 46, 14, 75, 47], [49, 54, 24, 10, 55, 25], [24, 45, 15, 46, 46, 16], [4, 152, 122, 18, 153, 123], [13, 74, 46, 32, 75, 47], [48, 54, 24, 14, 55, 25], [42, 45, 15, 32, 46, 16], [20, 147, 117, 4, 148, 118], [40, 75, 47, 7, 76, 48], [43, 54, 24, 22, 55, 25], [10,
                              45, 15, 67, 46, 16], [19, 148, 118, 6, 149, 119], [18, 75, 47, 31, 76, 48], [34, 54, 24, 34, 55, 25], [20, 45, 15, 61, 46, 16]]; p.getRSBlocks = function(a, c) {var d = p.getRsBlockTable(a, c); if (void 0 == d) throw Error("bad rs block @ typeNumber:" + a + "/errorCorrectLevel:" + c); for (var b = d.length / 3, e = [], f = 0; f < b; f++)for (var h = d[3 * f + 0], g = d[3 * f + 1], j = d[3 * f + 2], l = 0; l < h; l++)e.push(new p(g, j)); return e}; p.getRsBlockTable = function(a, c) {
                                  switch (c) {
                                      case 1: return p.RS_BLOCK_TABLE[4 * (a - 1) + 0]; case 0: return p.RS_BLOCK_TABLE[4 * (a - 1) + 1]; case 3: return p.RS_BLOCK_TABLE[4 *
                                          (a - 1) + 2]; case 2: return p.RS_BLOCK_TABLE[4 * (a - 1) + 3]
                                  }
                              }; t.prototype = {get: function(a) {return 1 == (this.buffer[Math.floor(a / 8)] >>> 7 - a % 8 & 1)}, put: function(a, c) {for (var d = 0; d < c; d++)this.putBit(1 == (a >>> c - d - 1 & 1))}, getLengthInBits: function() {return this.length}, putBit: function(a) {var c = Math.floor(this.length / 8); this.buffer.length <= c && this.buffer.push(0); a && (this.buffer[c] |= 128 >>> this.length % 8); this.length++}}; "string" === typeof h && (h = {text: h}); h = r.extend({}, {
                                  render: "canvas", width: 256, height: 256, typeNumber: -1,
                                  correctLevel: 2, background: "#ffffff", foreground: "#000000"
                              }, h); return this.each(function() {
                                  var a; if ("canvas" == h.render) {
                                      a = new o(h.typeNumber, h.correctLevel); a.addData(h.text); a.make(); var c = document.createElement("canvas"); c.width = h.width; c.height = h.height; for (var d = c.getContext("2d"), b = h.width / a.getModuleCount(), e = h.height / a.getModuleCount(), f = 0; f < a.getModuleCount(); f++)for (var i = 0; i < a.getModuleCount(); i++) {
                                          d.fillStyle = a.isDark(f, i) ? h.foreground : h.background; var g = Math.ceil((i + 1) * b) - Math.floor(i * b),
                                              j = Math.ceil((f + 1) * b) - Math.floor(f * b); d.fillRect(Math.round(i * b), Math.round(f * e), g, j)
                                      }
                                  } else {
                                      a = new o(h.typeNumber, h.correctLevel); a.addData(h.text); a.make(); c = r("<table></table>").css("width", h.width + "px").css("height", h.height + "px").css("border", "0px").css("border-collapse", "collapse").css("background-color", h.background); d = h.width / a.getModuleCount(); b = h.height / a.getModuleCount(); for (e = 0; e < a.getModuleCount(); e++) {
                                          f = r("<tr></tr>").css("height", b + "px").appendTo(c); for (i = 0; i < a.getModuleCount(); i++)r("<td></td>").css("width",
                                              d + "px").css("background-color", a.isDark(e, i) ? h.foreground : h.background).appendTo(f)
                                      }
                                  } a = c; jQuery(a).appendTo(this)
                              })
                      }
                  })(jQuery);
              </script>
              <script>

                 ${qrContents}
              </script>
          </body>

          </html>`
    );
    setTimeout(() => {
      popupWin.document.close();
    }, 1000);
  };

  // In QR tài liệu
  printQRDocument = () => {
    let printContents = "";
    let qrContents = "";
    let popupWin;

    let frontDiv = `
          <div class="card-item">
          <div class="card-detail testdiv">
              <p class="txtTitle">${'Phòng họp không giấy'}</p>
              <div class="testspan">
                <p class="testcolor">${'Mã QRCode tài liệu khách mời'}</p>
                <div id="qrcode" class="" style="">
                  <img class="app-logo" src="/assets/images/logo3.png">
                </div>
                <div>
                  <b>${this.model.name}</b>
                </div>
              </div>
          </div>
        </div>`;

    printContents += frontDiv;

    let objQR = `$('#qrcode').qrcode({width: 150,height: 150, text: "${this.qrContentDocument}"});`;

    qrContents += objQR;


    popupWin = window.open(
      "",
      "_blank",
      "top=0,left=0,height='100%',width=auto"
    );
    popupWin.document.open();

    // const headBody = "<body>";
    let headBody = '<body onload="window.print();window.close()">';
    popupWin.document.write(
      `
          <html>
          <head>
              <title></title>
              <style>
                  html,
                  body {
                      display: block;
                      width: 100%;
                      color: #fff important;
                      -webkit-print-color-adjust: exact;
                  }
                  .full-width {
                      display: block;
                      width: 100%;
                  }
                  .full-width.front-card {
                    page-break-after: always;
                  }
                  .card-item {
                      display: inline-block;
                      width: 100%;
                  }
                  .card-detail {
                      position: relative;
                      padding: 5px;
                      height: auto !important;
                      width: 200px !important;
                      border: 1px solid #ccc
                  }
                  .card-detail .card-front {
                    width: 100%;
                  }
                  .card-detail .qr-code {
                      position: absolute;
                      left: 50%;
                      height: 200px !important;
                      width: 200px !important;
                      top: 50%;
                      border: 1px solid white;
                  }

                  .testcolor {
                    color: blue;
                    text-align: center;
                    display: block;
                    margin: 0;
                    font-weight: 600;
                    font-size: 16px;
                  }
                  .testspan div{
                    margin-top: 20px;
                  }

                  .testdiv b {
                    width: 100%;
                    flex-wrap: wrap;
                      text-align: center;
                  }

                  .testdiv {
                    width: 200px ;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    flex-direction: collumn;
                    flex-wrap: wrap;
                    padding: 15px;
                  }

                  .txtTitle {
                    font-weight: 600;
                    margin: 0 !important;
                    font-size: 18px;
                  }

                  .app-logo {
                    height: 50px !important;
                    width: 50px !important;
                  }

                  #qrcode img {
                    position: absolute;
                    top: 55%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 40px;
                    height: 40px;
                }
              </style>
          </head>` +
      headBody +
      `<div class="full-width front-card">${printContents}</div>
              <script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>
              <script>
                  (function(r) {
                      r.fn.qrcode = function(h) {
                          var s; function u(a) {this.mode = s; this.data = a} function o(a, c) {this.typeNumber = a; this.errorCorrectLevel = c; this.modules = null; this.moduleCount = 0; this.dataCache = null; this.dataList = []} function q(a, c) {if (void 0 == a.length) throw Error(a.length + "/" + c); for (var d = 0; d < a.length && 0 == a[d];)d++; this.num = Array(a.length - d + c); for (var b = 0; b < a.length - d; b++)this.num[b] = a[b + d]} function p(a, c) {this.totalCount = a; this.dataCount = c} function t() {this.buffer = []; this.length = 0} u.prototype = {
                              getLength: function() {return this.data.length},
                              write: function(a) {for (var c = 0; c < this.data.length; c++)a.put(this.data.charCodeAt(c), 8)}
                          }; o.prototype = {
                              addData: function(a) {this.dataList.push(new u(a)); this.dataCache = null}, isDark: function(a, c) {if (0 > a || this.moduleCount <= a || 0 > c || this.moduleCount <= c) throw Error(a + "," + c); return this.modules[a][c]}, getModuleCount: function() {return this.moduleCount}, make: function() {
                                  if (1 > this.typeNumber) {
                                      for (var a = 1, a = 1; 40 > a; a++) {
                                          for (var c = p.getRSBlocks(a, this.errorCorrectLevel), d = new t, b = 0, e = 0; e < c.length; e++)b += c[e].dataCount;
                                          for (e = 0; e < this.dataList.length; e++)c = this.dataList[e], d.put(c.mode, 4), d.put(c.getLength(), j.getLengthInBits(c.mode, a)), c.write(d); if (d.getLengthInBits() <= 8 * b) break
                                      } this.typeNumber = a
                                  } this.makeImpl(!1, this.getBestMaskPattern())
                              }, makeImpl: function(a, c) {
                                  this.moduleCount = 4 * this.typeNumber + 17; this.modules = Array(this.moduleCount); for (var d = 0; d < this.moduleCount; d++) {this.modules[d] = Array(this.moduleCount); for (var b = 0; b < this.moduleCount; b++)this.modules[d][b] = null} this.setupPositionProbePattern(0, 0); this.setupPositionProbePattern(this.moduleCount -
                                      7, 0); this.setupPositionProbePattern(0, this.moduleCount - 7); this.setupPositionAdjustPattern(); this.setupTimingPattern(); this.setupTypeInfo(a, c); 7 <= this.typeNumber && this.setupTypeNumber(a); null == this.dataCache && (this.dataCache = o.createData(this.typeNumber, this.errorCorrectLevel, this.dataList)); this.mapData(this.dataCache, c)
                              }, setupPositionProbePattern: function(a, c) {
                                  for (var d = -1; 7 >= d; d++)if (!(-1 >= a + d || this.moduleCount <= a + d)) for (var b = -1; 7 >= b; b++)-1 >= c + b || this.moduleCount <= c + b || (this.modules[a + d][c + b] =
                                      0 <= d && 6 >= d && (0 == b || 6 == b) || 0 <= b && 6 >= b && (0 == d || 6 == d) || 2 <= d && 4 >= d && 2 <= b && 4 >= b ? !0 : !1)
                              }, getBestMaskPattern: function() {for (var a = 0, c = 0, d = 0; 8 > d; d++) {this.makeImpl(!0, d); var b = j.getLostPoint(this); if (0 == d || a > b) a = b, c = d} return c}, createMovieClip: function(a, c, d) {a = a.createEmptyMovieClip(c, d); this.make(); for (c = 0; c < this.modules.length; c++)for (var d = 1 * c, b = 0; b < this.modules[c].length; b++) {var e = 1 * b; this.modules[c][b] && (a.beginFill(0, 100), a.moveTo(e, d), a.lineTo(e + 1, d), a.lineTo(e + 1, d + 1), a.lineTo(e, d + 1), a.endFill())} return a},
                              setupTimingPattern: function() {for (var a = 8; a < this.moduleCount - 8; a++)null == this.modules[a][6] && (this.modules[a][6] = 0 == a % 2); for (a = 8; a < this.moduleCount - 8; a++)null == this.modules[6][a] && (this.modules[6][a] = 0 == a % 2)}, setupPositionAdjustPattern: function() {for (var a = j.getPatternPosition(this.typeNumber), c = 0; c < a.length; c++)for (var d = 0; d < a.length; d++) {var b = a[c], e = a[d]; if (null == this.modules[b][e]) for (var f = -2; 2 >= f; f++)for (var i = -2; 2 >= i; i++)this.modules[b + f][e + i] = -2 == f || 2 == f || -2 == i || 2 == i || 0 == f && 0 == i ? !0 : !1} }, setupTypeNumber: function(a) {
                                  for (var c =
                                      j.getBCHTypeNumber(this.typeNumber), d = 0; 18 > d; d++) {var b = !a && 1 == (c >> d & 1); this.modules[Math.floor(d / 3)][d % 3 + this.moduleCount - 8 - 3] = b} for (d = 0; 18 > d; d++)b = !a && 1 == (c >> d & 1), this.modules[d % 3 + this.moduleCount - 8 - 3][Math.floor(d / 3)] = b
                              }, setupTypeInfo: function(a, c) {
                                  for (var d = j.getBCHTypeInfo(this.errorCorrectLevel << 3 | c), b = 0; 15 > b; b++) {var e = !a && 1 == (d >> b & 1); 6 > b ? this.modules[b][8] = e : 8 > b ? this.modules[b + 1][8] = e : this.modules[this.moduleCount - 15 + b][8] = e} for (b = 0; 15 > b; b++)e = !a && 1 == (d >> b & 1), 8 > b ? this.modules[8][this.moduleCount -
                                      b - 1] = e : 9 > b ? this.modules[8][15 - b - 1 + 1] = e : this.modules[8][15 - b - 1] = e; this.modules[this.moduleCount - 8][8] = !a
                              }, mapData: function(a, c) {for (var d = -1, b = this.moduleCount - 1, e = 7, f = 0, i = this.moduleCount - 1; 0 < i; i -= 2)for (6 == i && i--; ;) {for (var g = 0; 2 > g; g++)if (null == this.modules[b][i - g]) {var n = !1; f < a.length && (n = 1 == (a[f] >>> e & 1)); j.getMask(c, b, i - g) && (n = !n); this.modules[b][i - g] = n; e--; -1 == e && (f++ , e = 7)} b += d; if (0 > b || this.moduleCount <= b) {b -= d; d = -d; break} } }
                          }; o.PAD0 = 236; o.PAD1 = 17; o.createData = function(a, c, d) {
                              for (var c = p.getRSBlocks(a,
                                  c), b = new t, e = 0; e < d.length; e++) {var f = d[e]; b.put(f.mode, 4); b.put(f.getLength(), j.getLengthInBits(f.mode, a)); f.write(b)} for (e = a = 0; e < c.length; e++)a += c[e].dataCount; if (b.getLengthInBits() > 8 * a) throw Error("code length overflow. (" + b.getLengthInBits() + ">" + 8 * a + ")"); for (b.getLengthInBits() + 4 <= 8 * a && b.put(0, 4); 0 != b.getLengthInBits() % 8;)b.putBit(!1); for (; !(b.getLengthInBits() >= 8 * a);) {b.put(o.PAD0, 8); if (b.getLengthInBits() >= 8 * a) break; b.put(o.PAD1, 8)} return o.createBytes(b, c)
                          }; o.createBytes = function(a, c) {
                              for (var d =
                                  0, b = 0, e = 0, f = Array(c.length), i = Array(c.length), g = 0; g < c.length; g++) {var n = c[g].dataCount, h = c[g].totalCount - n, b = Math.max(b, n), e = Math.max(e, h); f[g] = Array(n); for (var k = 0; k < f[g].length; k++)f[g][k] = 255 & a.buffer[k + d]; d += n; k = j.getErrorCorrectPolynomial(h); n = (new q(f[g], k.getLength() - 1)).mod(k); i[g] = Array(k.getLength() - 1); for (k = 0; k < i[g].length; k++)h = k + n.getLength() - i[g].length, i[g][k] = 0 <= h ? n.get(h) : 0} for (k = g = 0; k < c.length; k++)g += c[k].totalCount; d = Array(g); for (k = n = 0; k < b; k++)for (g = 0; g < c.length; g++)k < f[g].length &&
                                      (d[n++] = f[g][k]); for (k = 0; k < e; k++)for (g = 0; g < c.length; g++)k < i[g].length && (d[n++] = i[g][k]); return d
                          }; s = 4; for (var j = {
                              PATTERN_POSITION_TABLE: [[], [6, 18], [6, 22], [6, 26], [6, 30], [6, 34], [6, 22, 38], [6, 24, 42], [6, 26, 46], [6, 28, 50], [6, 30, 54], [6, 32, 58], [6, 34, 62], [6, 26, 46, 66], [6, 26, 48, 70], [6, 26, 50, 74], [6, 30, 54, 78], [6, 30, 56, 82], [6, 30, 58, 86], [6, 34, 62, 90], [6, 28, 50, 72, 94], [6, 26, 50, 74, 98], [6, 30, 54, 78, 102], [6, 28, 54, 80, 106], [6, 32, 58, 84, 110], [6, 30, 58, 86, 114], [6, 34, 62, 90, 118], [6, 26, 50, 74, 98, 122], [6, 30, 54, 78, 102, 126], [6, 26, 52,
                                  78, 104, 130], [6, 30, 56, 82, 108, 134], [6, 34, 60, 86, 112, 138], [6, 30, 58, 86, 114, 142], [6, 34, 62, 90, 118, 146], [6, 30, 54, 78, 102, 126, 150], [6, 24, 50, 76, 102, 128, 154], [6, 28, 54, 80, 106, 132, 158], [6, 32, 58, 84, 110, 136, 162], [6, 26, 54, 82, 110, 138, 166], [6, 30, 58, 86, 114, 142, 170]], G15: 1335, G18: 7973, G15_MASK: 21522, getBCHTypeInfo: function(a) {for (var c = a << 10; 0 <= j.getBCHDigit(c) - j.getBCHDigit(j.G15);)c ^= j.G15 << j.getBCHDigit(c) - j.getBCHDigit(j.G15); return (a << 10 | c) ^ j.G15_MASK}, getBCHTypeNumber: function(a) {
                                      for (var c = a << 12; 0 <= j.getBCHDigit(c) -
                                          j.getBCHDigit(j.G18);)c ^= j.G18 << j.getBCHDigit(c) - j.getBCHDigit(j.G18); return a << 12 | c
                                  }, getBCHDigit: function(a) {for (var c = 0; 0 != a;)c++ , a >>>= 1; return c}, getPatternPosition: function(a) {return j.PATTERN_POSITION_TABLE[a - 1]}, getMask: function(a, c, d) {
                                      switch (a) {
                                          case 0: return 0 == (c + d) % 2; case 1: return 0 == c % 2; case 2: return 0 == d % 3; case 3: return 0 == (c + d) % 3; case 4: return 0 == (Math.floor(c / 2) + Math.floor(d / 3)) % 2; case 5: return 0 == c * d % 2 + c * d % 3; case 6: return 0 == (c * d % 2 + c * d % 3) % 2; case 7: return 0 == (c * d % 3 + (c + d) % 2) % 2; default: throw Error("bad maskPattern:" +
                                              a);
                                      }
                                  }, getErrorCorrectPolynomial: function(a) {for (var c = new q([1], 0), d = 0; d < a; d++)c = c.multiply(new q([1, l.gexp(d)], 0)); return c}, getLengthInBits: function(a, c) {
                                      if (1 <= c && 10 > c) switch (a) {case 1: return 10; case 2: return 9; case s: return 8; case 8: return 8; default: throw Error("mode:" + a);} else if (27 > c) switch (a) {case 1: return 12; case 2: return 11; case s: return 16; case 8: return 10; default: throw Error("mode:" + a);} else if (41 > c) switch (a) {
                                          case 1: return 14; case 2: return 13; case s: return 16; case 8: return 12; default: throw Error("mode:" +
                                              a);
                                      } else throw Error("type:" + c);
                                  }, getLostPoint: function(a) {
                                      for (var c = a.getModuleCount(), d = 0, b = 0; b < c; b++)for (var e = 0; e < c; e++) {for (var f = 0, i = a.isDark(b, e), g = -1; 1 >= g; g++)if (!(0 > b + g || c <= b + g)) for (var h = -1; 1 >= h; h++)0 > e + h || c <= e + h || 0 == g && 0 == h || i == a.isDark(b + g, e + h) && f++; 5 < f && (d += 3 + f - 5)} for (b = 0; b < c - 1; b++)for (e = 0; e < c - 1; e++)if (f = 0, a.isDark(b, e) && f++ , a.isDark(b + 1, e) && f++ , a.isDark(b, e + 1) && f++ , a.isDark(b + 1, e + 1) && f++ , 0 == f || 4 == f) d += 3; for (b = 0; b < c; b++)for (e = 0; e < c - 6; e++)a.isDark(b, e) && !a.isDark(b, e + 1) && a.isDark(b, e +
                                          2) && a.isDark(b, e + 3) && a.isDark(b, e + 4) && !a.isDark(b, e + 5) && a.isDark(b, e + 6) && (d += 40); for (e = 0; e < c; e++)for (b = 0; b < c - 6; b++)a.isDark(b, e) && !a.isDark(b + 1, e) && a.isDark(b + 2, e) && a.isDark(b + 3, e) && a.isDark(b + 4, e) && !a.isDark(b + 5, e) && a.isDark(b + 6, e) && (d += 40); for (e = f = 0; e < c; e++)for (b = 0; b < c; b++)a.isDark(b, e) && f++; a = Math.abs(100 * f / c / c - 50) / 5; return d + 10 * a
                                  }
                          }, l = {
                              glog: function(a) {if (1 > a) throw Error("glog(" + a + ")"); return l.LOG_TABLE[a]}, gexp: function(a) {for (; 0 > a;)a += 255; for (; 256 <= a;)a -= 255; return l.EXP_TABLE[a]}, EXP_TABLE: Array(256),
                              LOG_TABLE: Array(256)
                          }, m = 0; 8 > m; m++)l.EXP_TABLE[m] = 1 << m; for (m = 8; 256 > m; m++)l.EXP_TABLE[m] = l.EXP_TABLE[m - 4] ^ l.EXP_TABLE[m - 5] ^ l.EXP_TABLE[m - 6] ^ l.EXP_TABLE[m - 8]; for (m = 0; 255 > m; m++)l.LOG_TABLE[l.EXP_TABLE[m]] = m; q.prototype = {
                              get: function(a) {return this.num[a]}, getLength: function() {return this.num.length}, multiply: function(a) {for (var c = Array(this.getLength() + a.getLength() - 1), d = 0; d < this.getLength(); d++)for (var b = 0; b < a.getLength(); b++)c[d + b] ^= l.gexp(l.glog(this.get(d)) + l.glog(a.get(b))); return new q(c, 0)}, mod: function(a) {
                                  if (0 >
                                      this.getLength() - a.getLength()) return this; for (var c = l.glog(this.get(0)) - l.glog(a.get(0)), d = Array(this.getLength()), b = 0; b < this.getLength(); b++)d[b] = this.get(b); for (b = 0; b < a.getLength(); b++)d[b] ^= l.gexp(l.glog(a.get(b)) + c); return (new q(d, 0)).mod(a)
                              }
                          }; p.RS_BLOCK_TABLE = [[1, 26, 19], [1, 26, 16], [1, 26, 13], [1, 26, 9], [1, 44, 34], [1, 44, 28], [1, 44, 22], [1, 44, 16], [1, 70, 55], [1, 70, 44], [2, 35, 17], [2, 35, 13], [1, 100, 80], [2, 50, 32], [2, 50, 24], [4, 25, 9], [1, 134, 108], [2, 67, 43], [2, 33, 15, 2, 34, 16], [2, 33, 11, 2, 34, 12], [2, 86, 68], [4, 43, 27],
                          [4, 43, 19], [4, 43, 15], [2, 98, 78], [4, 49, 31], [2, 32, 14, 4, 33, 15], [4, 39, 13, 1, 40, 14], [2, 121, 97], [2, 60, 38, 2, 61, 39], [4, 40, 18, 2, 41, 19], [4, 40, 14, 2, 41, 15], [2, 146, 116], [3, 58, 36, 2, 59, 37], [4, 36, 16, 4, 37, 17], [4, 36, 12, 4, 37, 13], [2, 86, 68, 2, 87, 69], [4, 69, 43, 1, 70, 44], [6, 43, 19, 2, 44, 20], [6, 43, 15, 2, 44, 16], [4, 101, 81], [1, 80, 50, 4, 81, 51], [4, 50, 22, 4, 51, 23], [3, 36, 12, 8, 37, 13], [2, 116, 92, 2, 117, 93], [6, 58, 36, 2, 59, 37], [4, 46, 20, 6, 47, 21], [7, 42, 14, 4, 43, 15], [4, 133, 107], [8, 59, 37, 1, 60, 38], [8, 44, 20, 4, 45, 21], [12, 33, 11, 4, 34, 12], [3, 145, 115, 1, 146,
                              116], [4, 64, 40, 5, 65, 41], [11, 36, 16, 5, 37, 17], [11, 36, 12, 5, 37, 13], [5, 109, 87, 1, 110, 88], [5, 65, 41, 5, 66, 42], [5, 54, 24, 7, 55, 25], [11, 36, 12], [5, 122, 98, 1, 123, 99], [7, 73, 45, 3, 74, 46], [15, 43, 19, 2, 44, 20], [3, 45, 15, 13, 46, 16], [1, 135, 107, 5, 136, 108], [10, 74, 46, 1, 75, 47], [1, 50, 22, 15, 51, 23], [2, 42, 14, 17, 43, 15], [5, 150, 120, 1, 151, 121], [9, 69, 43, 4, 70, 44], [17, 50, 22, 1, 51, 23], [2, 42, 14, 19, 43, 15], [3, 141, 113, 4, 142, 114], [3, 70, 44, 11, 71, 45], [17, 47, 21, 4, 48, 22], [9, 39, 13, 16, 40, 14], [3, 135, 107, 5, 136, 108], [3, 67, 41, 13, 68, 42], [15, 54, 24, 5, 55, 25], [15,
                              43, 15, 10, 44, 16], [4, 144, 116, 4, 145, 117], [17, 68, 42], [17, 50, 22, 6, 51, 23], [19, 46, 16, 6, 47, 17], [2, 139, 111, 7, 140, 112], [17, 74, 46], [7, 54, 24, 16, 55, 25], [34, 37, 13], [4, 151, 121, 5, 152, 122], [4, 75, 47, 14, 76, 48], [11, 54, 24, 14, 55, 25], [16, 45, 15, 14, 46, 16], [6, 147, 117, 4, 148, 118], [6, 73, 45, 14, 74, 46], [11, 54, 24, 16, 55, 25], [30, 46, 16, 2, 47, 17], [8, 132, 106, 4, 133, 107], [8, 75, 47, 13, 76, 48], [7, 54, 24, 22, 55, 25], [22, 45, 15, 13, 46, 16], [10, 142, 114, 2, 143, 115], [19, 74, 46, 4, 75, 47], [28, 50, 22, 6, 51, 23], [33, 46, 16, 4, 47, 17], [8, 152, 122, 4, 153, 123], [22, 73, 45,
                              3, 74, 46], [8, 53, 23, 26, 54, 24], [12, 45, 15, 28, 46, 16], [3, 147, 117, 10, 148, 118], [3, 73, 45, 23, 74, 46], [4, 54, 24, 31, 55, 25], [11, 45, 15, 31, 46, 16], [7, 146, 116, 7, 147, 117], [21, 73, 45, 7, 74, 46], [1, 53, 23, 37, 54, 24], [19, 45, 15, 26, 46, 16], [5, 145, 115, 10, 146, 116], [19, 75, 47, 10, 76, 48], [15, 54, 24, 25, 55, 25], [23, 45, 15, 25, 46, 16], [13, 145, 115, 3, 146, 116], [2, 74, 46, 29, 75, 47], [42, 54, 24, 1, 55, 25], [23, 45, 15, 28, 46, 16], [17, 145, 115], [10, 74, 46, 23, 75, 47], [10, 54, 24, 35, 55, 25], [19, 45, 15, 35, 46, 16], [17, 145, 115, 1, 146, 116], [14, 74, 46, 21, 75, 47], [29, 54, 24, 19,
                              55, 25], [11, 45, 15, 46, 46, 16], [13, 145, 115, 6, 146, 116], [14, 74, 46, 23, 75, 47], [44, 54, 24, 7, 55, 25], [59, 46, 16, 1, 47, 17], [12, 151, 121, 7, 152, 122], [12, 75, 47, 26, 76, 48], [39, 54, 24, 14, 55, 25], [22, 45, 15, 41, 46, 16], [6, 151, 121, 14, 152, 122], [6, 75, 47, 34, 76, 48], [46, 54, 24, 10, 55, 25], [2, 45, 15, 64, 46, 16], [17, 152, 122, 4, 153, 123], [29, 74, 46, 14, 75, 47], [49, 54, 24, 10, 55, 25], [24, 45, 15, 46, 46, 16], [4, 152, 122, 18, 153, 123], [13, 74, 46, 32, 75, 47], [48, 54, 24, 14, 55, 25], [42, 45, 15, 32, 46, 16], [20, 147, 117, 4, 148, 118], [40, 75, 47, 7, 76, 48], [43, 54, 24, 22, 55, 25], [10,
                              45, 15, 67, 46, 16], [19, 148, 118, 6, 149, 119], [18, 75, 47, 31, 76, 48], [34, 54, 24, 34, 55, 25], [20, 45, 15, 61, 46, 16]]; p.getRSBlocks = function(a, c) {var d = p.getRsBlockTable(a, c); if (void 0 == d) throw Error("bad rs block @ typeNumber:" + a + "/errorCorrectLevel:" + c); for (var b = d.length / 3, e = [], f = 0; f < b; f++)for (var h = d[3 * f + 0], g = d[3 * f + 1], j = d[3 * f + 2], l = 0; l < h; l++)e.push(new p(g, j)); return e}; p.getRsBlockTable = function(a, c) {
                                  switch (c) {
                                      case 1: return p.RS_BLOCK_TABLE[4 * (a - 1) + 0]; case 0: return p.RS_BLOCK_TABLE[4 * (a - 1) + 1]; case 3: return p.RS_BLOCK_TABLE[4 *
                                          (a - 1) + 2]; case 2: return p.RS_BLOCK_TABLE[4 * (a - 1) + 3]
                                  }
                              }; t.prototype = {get: function(a) {return 1 == (this.buffer[Math.floor(a / 8)] >>> 7 - a % 8 & 1)}, put: function(a, c) {for (var d = 0; d < c; d++)this.putBit(1 == (a >>> c - d - 1 & 1))}, getLengthInBits: function() {return this.length}, putBit: function(a) {var c = Math.floor(this.length / 8); this.buffer.length <= c && this.buffer.push(0); a && (this.buffer[c] |= 128 >>> this.length % 8); this.length++}}; "string" === typeof h && (h = {text: h}); h = r.extend({}, {
                                  render: "canvas", width: 256, height: 256, typeNumber: -1,
                                  correctLevel: 2, background: "#ffffff", foreground: "#000000"
                              }, h); return this.each(function() {
                                  var a; if ("canvas" == h.render) {
                                      a = new o(h.typeNumber, h.correctLevel); a.addData(h.text); a.make(); var c = document.createElement("canvas"); c.width = h.width; c.height = h.height; for (var d = c.getContext("2d"), b = h.width / a.getModuleCount(), e = h.height / a.getModuleCount(), f = 0; f < a.getModuleCount(); f++)for (var i = 0; i < a.getModuleCount(); i++) {
                                          d.fillStyle = a.isDark(f, i) ? h.foreground : h.background; var g = Math.ceil((i + 1) * b) - Math.floor(i * b),
                                              j = Math.ceil((f + 1) * b) - Math.floor(f * b); d.fillRect(Math.round(i * b), Math.round(f * e), g, j)
                                      }
                                  } else {
                                      a = new o(h.typeNumber, h.correctLevel); a.addData(h.text); a.make(); c = r("<table></table>").css("width", h.width + "px").css("height", h.height + "px").css("border", "0px").css("border-collapse", "collapse").css("background-color", h.background); d = h.width / a.getModuleCount(); b = h.height / a.getModuleCount(); for (e = 0; e < a.getModuleCount(); e++) {
                                          f = r("<tr></tr>").css("height", b + "px").appendTo(c); for (i = 0; i < a.getModuleCount(); i++)r("<td></td>").css("width",
                                              d + "px").css("background-color", a.isDark(e, i) ? h.foreground : h.background).appendTo(f)
                                      }
                                  } a = c; jQuery(a).appendTo(this)
                              })
                      }
                  })(jQuery);
              </script>
              <script>
                 ${qrContents}
              </script>
          </body>

          </html>`
    );
    setTimeout(() => {
      popupWin.document.close();
    }, 1000);
  };

  // Chuyển tab
  changeTab = (tab) => {
    if (this.tabActive == "commentTab") {

    }

    if (this.tabActive == "documentTab") {
      setTimeout(() => {
        if (this.documentContentGrid) {
          for (let i = 0; i < this.lstContents.length; i++) {
            this.lstContents[i].list_documents = _.sortBy(this.lstContents[i].list_documents, "orders");
          }
          this.documentContentGrid.dataSource = new DataManager(this.lstContents);
        }
      }, 100);

    }
    if (this.tabActive == "noteTab") {
      if (this.model.status == 24) {
        this.noteForm.disable();
      }
    }
  }

  // Bắt đầu cuộc hop
  startMeeting() {
    this.modalService.open("confirm-start-meeting-modal");
  }

  // Xác nhận bắt đầu cuộc họp
  confirmStartMeeting(type) {
    let token  = localStorage.getItem("token");
    if (type === "cancel") {
      this.modalService.close("confirm-start-meeting-modal");
    } else {
      this._coreService.Post("/meeting/start", {
        id: this.model.id
      }).subscribe((res) => {
        if (res && res.code && res.code == "200") {
          this.model.status = 23;
          // gửi socket thông báo họp
          this._socketService.sendEmit("start-meeting", {
            room: "room-system",
            data: {
              userIds: _.map(this.lstDelegates, "user_id"),
              modelMeeting: this.model
            }
          });
          this.notification.success("Bắt đầu cuộc họp thành công!");
          this.modelAddComment.full_name = "";
         // đóng modal
          this.modalService.close("confirm-start-meeting-modal");
          //Lấy URL websocket -trả ra tính năng voicetotext
          this._coreService
          .getSK("meeting/start", this.paramId,token)
          .subscribe(({ data }) => {
            this.model.url_socket = data.url;
          });
        }else{
          this.notification.error("Bắt đầu cuộc họp thất bại!");
          // đóng modal
           this.modalService.close("confirm-start-meeting-modal");
        }
      });
    }
  }

  startRecordingMettingComment(){
    this.flag_close = true;

    if(this.model.status == 23){
      let list_contents = [];
      for (let i = 0; i < this.modelAddComment.content_ids.length; i++) {
        list_contents.push({
          content_id: this.modelAddComment.content_ids[i]
        })
      }
      let content = "";

      list_contents.forEach(item => {
        var dataContent = this.lstContents.find((element: any) => element.id == item.content_id);

        if (dataContent) {
          let content = dataContent.content !== undefined ? dataContent.content : "";

          if (dataContent.content_child && dataContent.content_child.length > 0) {
            for (const childId of dataContent.content_child) {
              const childContent = this.lstContents.find((element: any) => element.id == childId).content;
              if (childContent) {
                if (content !== "") {
                  content += ", ";
                }
                content += childContent;
              }
            }
          }

          if (content !== "") {
            if (content !== "") {
              content += ", ";
            }
            content += dataContent.content !== undefined ? dataContent.content : "";
          }
        }
      });
      this.modelRealtimeComment.content_name = content;
      this.modelAddComment.content_name = content;
      var data = this.lstDelegateTabComments.find((element: any) =>  element.user_id == this.modelAddComment.user_id);
      this.modelRealtimeComment.full_name =   data.full_name + " "  +(data.title_name  ? " - " + data.title_name : "") + " " +(data.org_name != undefined ? data.org_name: "");
      this.modelAddComment.full_name = this.modelRealtimeComment.full_name
    }

    this.flagEnd = true;
    if(this.isSave == true ) {
      this.notification.warning("Bạn chưa lưu ý kiến ghi âm, vui lòng chọn 'Hủy' hoặc 'Lưu ý kiến' để tiếp tục!");
      return;
    }
    if (!this.modelAddComment.user_id && this.model.status == 23) {
      this.notification.warning("Bạn chưa chọn đại biểu nêu ý kiến!");
      return;
    }
    if (!this.modelAddComment.content_ids) {
      this.notification.warning("Bạn chưa chọn nội dung cuộc họp!");
      return;
    }
    this.getSS(1);
  }


  //Lấy session
async getSS(type : any){
  this.Key_type = type;
  this.isSave = true;

  let timeline: number; //Phân biệt thời gian trước cuộc họp và sau cuộc họp
  let Authorization : any;

  let token  = localStorage.getItem("token");

  let  user_id: any;
  if (this.model.status == 23) { //status = 23 => ý kiến trước cuộc họp
    timeline = 1; ///Trong cuộc họp
    this.is_timeline = false;
    user_id = this.modelAddComment.user_id; //Trong cuộc họp thì lấy user_id của thư ký chọn đại biểu
    this.flag_save = false;
  } else {
    timeline = 2;   //Trước cuộc họp
    this.is_timeline = true;  ////Nhận biết xem ý kiến đó là trước hay trong cuộc họp
    user_id = this.modelUser.id; //Trước cuộc họp sẽ lấy user_id của tài khoản đang đăng nhập
    Authorization = token;
    this.flag_save = false;
  }

  let body : any;

  if(type == 1){
    this.modelAddComment.content = "";
    body = {
      meetingid: this.paramId,
      userid: user_id,
      content_id: this.modelAddComment.content_ids[0],
      list_contents: this.modelAddComment.content_ids,
      file: this.modelAddComment.files,
      type: type,
      timeline: timeline,
    };
  }else{
    this.modelAddComment2.content = "";
    body = {
      meetingid: this.paramId,
      userid: this.modelUser.id,
      content_id: this.modelContentDocumentAdd.id,
      document_id: this.modelAdd.id,
      file: this.modelAddComment2.files,
      type: type,
      timeline: timeline,
    };
  }

  const url = "meeting/session/create";
  await this._coreService.CrSession(url, body,token).subscribe((res) => {
    if (res.data !== undefined) {
      this.dataSession = res.data;
      if(this.dataSession.is_transcrible == 0){
        this.flag_trans = false;
      }else if(this.dataSession.is_transcrible != 0){
        this.flag_trans = true;
      }
      this.getConnectWebSK(res.data.url,type);
    }
  },
  (error) => {
    return this.notification.warning("Chức năng ghi âm đang gặp sự cố. Vui lòng thử lại sau!");
  }
  );
}

///Connect tới socket
async getConnectWebSK(url_socket: any,type : any) {
  this.flagSelfComment = true;
  this.flagRealComment = true;
  this.isContent = true;

  this.websocket = new WebSocket(url_socket);
  this.websocket.onopen = () => {
    this.writeRecordComment(type);
  };

  this.websocket.onmessage = (e) => {
    let text = JSON.parse(e.data);
    if(type == 1){
      if(text.partial != undefined ){
        document.getElementById("contentPar").innerHTML = text.partial;
      }else{
        document.getElementById("contentPar").innerHTML = "";
      }
    }
    else{
      if(text.partial != undefined ){
        document.getElementById("contentDocumentPart").innerHTML = text.partial;
      }else{
        document.getElementById("contentDocumentPart").innerHTML = " ";
      }
    }
    // handle text result
    // call api lấy dữ liệu chuẩn hóa
    if (this.check == true && this.is_check == true) {
      this.is_check = false;
      this.setTime = setInterval(() => {
        this.getVoice2Text(type);
      }, 2000);
      this.interval.push(this.setTime);
      if(type == 1 && this.model.status == 23){
        this._socketService.sendEmit("start-comment", {
          room: this.model.id,
          data: this.modelAddComment
        });
      }
    }
  };
}

getStreamFromUrl(session_id: any , type : any) {
  let url = "/readrecord/getMedia";
 if(type == 1){
 this.file_path =  this.globals.apiURL + url + `?SessionId=${session_id}&Type=${type}`;
  return this.file_path ;
 }else if(type == 2){
  this.audioSource =  this.globals.apiURL + url + `?SessionId=${session_id}&Type=${type}`;
  return this.audioSource ;
 }
 else if(type == 0){
  return this.globals.apiURL + url + `?SessionId=${session_id}&Type=${type}`;
 }
}

handleStartVideoOrAudio() {}

// API chuẩn hóa text
getVoice2Text(type : any) {
let url = "meeting/session/voice2text";
let user : any
let token  = localStorage.getItem("token");
if(type == 2){
  user = this.modelUser.id
}else{
  user = this.modelAddComment.user_id
}
this._coreService
  .getVoice2Text(
    url, //url
    this.paramId, //metting_id
    user, //user_id
    1, //getall = 1
    this.dataSession.session, //session
    token
  )
  .subscribe(( res ) => {
    if (res.data !== null || res.data !== undefined) {
      let text = "";
      let datatext = res.data
      if(res.is_done == true){
        this.interval.forEach((intervalId) => {
          clearInterval(intervalId);
         });
      }
      datatext.map((x) => {
        text = text.concat(x.text);
      });
      if(type == 1){
        this.setCKEditorData(text)
        if(this.flagEnd == true && this.model.status == 23 && this.flag_close == true){
          this.updateRecordComment(text);
        }
      }else{
        this.modelAddComment2.content = text;
      }
    }
  });
}
  // Kết thúc cuộc họp
  stopMeeting() {
    // Tìm nội dung đang thảo luận

    let index = _.findIndex(this.lstContents, (item) => {
      return item.status == 27;
    });
    if (index > -1) {
      return this.notification.warning("Bạn chưa hoàn thành thảo luận nội dung " + this.lstContents[index].content + ", không thể kết thúc cuộc họp!");
    } else {
      this.modalService.open("confirm-stop-meeting-modal");
    }
  }

  confirmStopMeeting(type) {
    let token  = localStorage.getItem("token");
    if (type === "cancel") {
      this.modalService.close("confirm-stop-meeting-modal");
    } else {
      this._coreService.Post("/meeting/close", {
        id: this.model.id
      }).subscribe((res) => {
        if (res && res.code && res.code == "200") {
          // gửi socket thông báo họp
          this._socketService.sendEmit("end-meeting", {
            room: this.model.id
          });
          this.getDataMeeting();
          // đóng modal
          this.modalService.close("confirm-stop-meeting-modal");

          //Ngắt quyền nắm giữ streaming
          let url = "meeting/stop";
          this._coreService
            .stopMeetting(url, this.paramId,token)
            .subscribe(({ data }) => {
            });
        }
      })
    }
  }

  // Tạo biểu quyết
  createVoting() {
    this.tabActive = "votingTab";
  }

  // Bắt đầu nội dung
  startContent = (data) => {
    let index = _.findIndex(this.lstContents, (item: any) => {
      return item.status == 27;
    });

    if (index > -1) {
      return this.notification.warning("Hiện đang thảo luận nội dung " + this.lstContents[index].content + " bạn không thể thảo luận nội dung khác");
    }

    let indexBefore = _.findIndex(this.lstContents, (item: any) => {
      return item.status == 26 && (moment(item.meeting_time2, "DD/MM/YYYY HH:mm.ss").isBefore(moment(item.meeting_time2, "DD/MM/YYYY HH:mm:ss")));
    });

    if (indexBefore > -1) {
      return this.notification.warning("Nội dung trước đó chưa thảo luận!");
    }

    this._coreService.Post("/meetingcontent/changeStatus", {
      content_id: data.id,
      status: 27
    }).subscribe((res) => {
      if (res && res.code && res.code == "200") {
        // Cập nhật data
        this.lstContents[data.index].status = 27;

        if (this.contentGrid) {
          this.contentGrid.dataSource = new DataManager(this.lstContents);
        }

        if (this.contentModalGrid) {
          this.contentModalGrid.dataSource = new DataManager(this.lstContents);
        }
        // Gửi API update
        this._socketService.sendEmit("update-content", {
          room: this.model.id,
          data: {
            type: "START",
            data: data
          }
        })
      }
    })
  }

  // Kết thúc nội dung
  stopContent = (data) => {
    this._coreService.Post("/meetingcontent/changeStatus", {
      content_id: data.id,
      status: 28
    }).subscribe((res) => {
      if (res && res.code && res.code == "200") {
        this.lstContents[data.index].status = 28;
        this.isContent = true;

        if (this.contentGrid) {
          this.contentGrid.dataSource = new DataManager(this.lstContents);
        }

        if (this.contentModalGrid) {
          this.contentModalGrid.dataSource = new DataManager(this.lstContents);
        }
        // Gửi API update
        this._socketService.sendEmit("update-content", {
          room: this.model.id,
          data: {
            type: "STOP",
            data: data
          }
        })
      }
    })
  }

  // Kết thúc thảo luận - Kết luật
  endContent = (data) => {
    this._coreService.Post("/meetingcontent/changeClose", {
      content_id: data.id
    }).subscribe((res) => {
      if (res && res.code && res.code == "200") {
        this.lstContents[data.index].is_close = true;

        if (this.contentGrid) {
          this.contentGrid.dataSource = new DataManager(this.lstContents);
        }

        if (this.contentModalGrid) {
          this.contentModalGrid.dataSource = new DataManager(this.lstContents);
        }
        // Gửi API update
        this._socketService.sendEmit("update-content", {
          room: this.model.id,
          data: {
            type: "CLOSE",
            data: data
          }
        })
      }
    })
  }

  // Upload file kết luận
  uploadConclusionFile(files: FileList) {
    setTimeout(() => {
      if (files.length > 0) {
        this._configService.loadingSubject.next("true");
        const allowedExtensions = ['.pdf', '.doc', '.docx', '.ppt', '.pptx', '.xlsx', '.xls'];
        const file = files[0];
        const fileName = file.name;
        const fileExtension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
          this.notification.warning("Không thể tải tài liệu lên");
          this._configService.loadingSubject.next("false");
          return;
        }
        for (let i = 0; i < files.length; i++) {
          // check file > 5MB
          const fsize = files.item(i).size;
          const file = Math.round(fsize / 1024);
        }
        let data = new FormData();
        data.append("files", files[0]);
        this._coreService.uploadFile(data).subscribe((res) => {
          if (res && res.status && res.status == 200) {
            this.model.conclusion_file = res.data[0].url;
            this._coreService.Post("/meeting/saveClusion", {
              id: this.model.id,
              conclusion_file: this.model.conclusion_file
            }).subscribe((res) => {
              if (res && res.code && res.code == "200") {
                this._socketService.sendEmit("update-content", {
                  room: this.model.id,
                  data: this.model
                });
                this.notification.success("Tải tài liệu kết luận thành công!");
              }
              this._configService.loadingSubject.next("false");
            })
          } else {
            this._configService.loadingSubject.next("false");
            this.notification.warning("Tải files không thành công");
          }
          let x: any = document.getElementById("conclusionFile");
          x.value = null;
          return;
        }, (err) => {
          this._configService.loadingSubject.next("false");
          this.notification.warning("Tải files không thành công");
        });
      }
    }, 200);
  }

  removeConclusionFile = (model) => {
    this.model.conclusion_file = null;
    this._coreService.Post("/meeting/saveClusion", {
      id: this.model.id,
      conclusion_file: this.model.conclusion_file
    }).subscribe((res) => {
      if (res && res.code && res.code == "200") {
        this._socketService.sendEmit("update-content", {
          room: this.model.id,
          data: this.model
        });
        this.notification.success("Xóa tài liệu kết luận thành công!");
      }
      this._configService.loadingSubject.next("false");
    })
  }

  // Hủy ý kiến
  clearContentComment = () => {
    this.flag_close = false;
    this.interval.forEach((intervalId) => {
      clearInterval(intervalId);
     });
      this.modelAddComment = new MeetingContentComment();
      this.modelAddComment.user_id = null;
      this.modelAddComment.content = "";
      this.modelAddComment.content_ids = null;
      this.commentForm.markAsUntouched();
      if(this.flag_save == false){
        this._coreService.Post("/meetingcontentcomment/deleteBySessionId", {
          session_id : this.dataSession.session,
        }).subscribe((res) => {
          if (res && res.code && res.code === "200") {
            this.isSave = false;
            this.notification.success("Hủy ý kiến thành công!");
          } else {
            this.notification.warning("Hủy ý kiến không thành công!")
          }
        })
      }
  }

  // Lưu ý kiến
  saveContentComment = () => {
    this.interval.forEach((intervalId) => {
      clearInterval(intervalId);
     });
      if (!this.modelAddComment.content_ids) {
        this.notification.warning("Bạn vui lòng chọn nội dung ý kiến!");
        this.documentCommentForm.markAllAsTouched();
        return;
      }

      if (!this.modelAddComment.content && this.flag_trans == true) {
        this.notification.warning("Bạn vui lòng nhập nội dung!");
        this.documentCommentForm.markAllAsTouched();
        return;
      }

     if (this.model.status == 22) {
      this.modelAddComment.user_id = this.modelUser.id;
    }

     let list_contents = [];
     if(this.modelAddComment.content_ids.length > 0){
      for (let i = 0; i < this.modelAddComment.content_ids.length; i++) {
        list_contents.push({
          content_id: this.modelAddComment.content_ids[i],
        })
      }
     }

    if
    (this.flag_save == true){
      this._coreService.Post("/meetingcontentcomment/create", {
        meeting_id: this.model.id,
        list_contents: list_contents,
        content: this.modelAddComment.content.replace(/<\/?p>/g, ''),
        user_id: this.modelAddComment.user_id,
        files: this.modelAddComment.files,
        record_files: null
      }).subscribe((res) => {
        if (res && res.code && res.code === "200") {
          this.notification.success("Lưu ý kiến thành công!");
          this.isSave = false;
          this.modelAddComment = new MeetingContentComment();
          this.modelAddComment.content = "";
          this.modelAddComment.content_ids = null;
          this.modelAddComment.user_id = null;
          this.commentForm.markAsUntouched();
          this.getContentComment();
          this._socketService.sendEmit("end-comment", {
            room: this.model.id,
            data: this.modelAddComment
          });

          this._socketService.sendEmit("update-content", {
            room: this.model.id,
            data: {
              action: "update-comment"
            }
          })
        } else {
          this.notification.warning("Lưu ý kiến không thành công!")
        }
      })
    }else{
      this._coreService.Post("/meetingcontentcomment/updateBySessionId", {
        session_id : this.dataSession.session,
        content: this.modelAddComment.content.replace(/<\/?p>/g, ''),
        list_contents: list_contents,
        files: this.modelAddComment.files,
        record_file : this.modelAddComment.record_files,
        user_id: this.modelAddComment.user_id,
      }).subscribe((res) => {
        if (res && res.code && res.code === "200") {
          this.notification.success("Lưu ý kiến thành công!");
          this.flag_save = true;
          this.isSave = false;
          this.modelAddComment = new MeetingContentComment();
          this.modelAddComment.content = "";
          this.modelAddComment.content_ids = null;
          this.modelAddComment.user_id = null;
          this.commentForm.markAsUntouched();
          this.getContentComment();
         this._socketService.sendEmit("update-content", {
            room: this.model.id,
            data: {
              action: "update-comment"
            }
          })
        } else {
          this.notification.warning("Lưu ý kiến không thành công!")
        }
      })
    }
  }

  onClick1(type : any){
    if(type == 1){
      this.file_path = "";
      this.modalService.close("edit-content-comment-modal");
    }else if(type == 2){
      this.audioSource = " ";
      this.modalService.close("edit-document-comment-modal");
    }
    if(type == 3){
      this.audioSource2 = "";
      this.modalService.close("edit-history-detail-modal")
      this.modalService.open("content-history-modal")
      const htmlElement = document.getElementsByTagName('html')[0];
  htmlElement.style.overflow = '';
    }
  }

  // Lấy danh sách ý kiến

  getContentComment = () => {
    this._coreService.Get("/meetingcontentcomment/list/" + this.model.id).subscribe((res) => {
      if (res && res.code && res.code == "200") {
        let userFollowId = this.model.user_follow_id;
        let userSecretaryId = this.model.user_secretary_id
          // let userFollowId = this.model.user_follow_id;
          let matchingElement = this.lstRoomPositionTabInfos.find(obj => obj.user_id === userFollowId);
          let matchingElement2 = this.lstRoomPositionTabInfos.find(obj => obj.user_id === userSecretaryId);
          if (matchingElement) {
            matchingElement.is_special_position = true
          }
          if (matchingElement2) {
            matchingElement2.is_special_position = true
          }

        let data = res && res.data && res.data.data ? res.data.data : [];
        if (this.flagDelegate && (this.model.status == 22 || this.model.status == 24)) {
          this.lstAllComments = _.filter(data, (item: any) => {
            return item.user_id == this.modelUser.id;
          });
        }

        if (this.flagChair || this.flagFollow || this.flagSecretary || this.model.status == 23) {
          this.lstAllComments = data;
        }

        this.lstMyComments = _.filter(this.lstAllComments, (item: any) => {
          return item.user_id == this.modelUser.id;
        });

        if (this.allCommentGrid) {
          this.allCommentGrid.dataSource = new DataManager(this.lstAllComments);
        }

        if (this.myCommentGrid) {
          this.myCommentGrid.dataSource = new DataManager(this.lstMyComments);
        }

        setTimeout(() => {
          if (this.tabActive == "commentTab") {
            if (this.autoSlideComment) {
              this.scrollToBottom("allCommentDiv");
              this.scrollToBottom("myCommentDiv");
            }
          }
        }, 50);
      }
    })
  }

  // Click Grid thảo luận
  clickAllCommentRecord = (data, status) => {
    this.getStreamFromUrl(data.session_id,1);
    if (data && status === "view") {
      this.modelAdd = data;
      this.flagState = 'view'
      this.flagStateModal = "view";
      this.modalService.open("edit-content-comment-modal");
      if (this.modelAdd.content_id) {
        let objContent = _.find(this.lstContents, (item: any) => {
          return item.id == this.modelAdd.content_id;
        });
        if (objContent) {
          this.lstDelegateTabCommentModals = _.cloneDeep(objContent.list_members);

          if (this.model.user_follow_id) {
            var indexFollow = _.findIndex(this.lstDelegateTabCommentModals, (item: any) => {
              return item.user_id == this.model.user_follow_id;
            });

            if (indexFollow == -1) {
              this.lstDelegateTabCommentModals.unshift({
                user_id: this.model.user_follow_id,
                full_name: this.model.user_follow_name
              });
            }
          }

          var indexSecretary = _.findIndex(this.lstDelegateTabCommentModals, (item: any) => {
            return item.user_id == this.model.user_secretary_id;
          });

          if (indexSecretary == -1) {
            this.lstDelegateTabCommentModals.unshift({
              user_id: this.model.user_secretary_id,
              full_name: this.model.user_secretary_name
            });
          }

          var indexChair = _.findIndex(this.lstDelegateTabCommentModals, (item: any) => {
            return item.user_id == this.model.user_chair_id;
          });

          if (indexChair == -1) {
            this.lstDelegateTabCommentModals.unshift({
              user_id: this.model.user_chair_id,
              full_name: this.model.user_chair_name
            });
          }
        } else {
          this.lstDelegateTabCommentModals = [];
        }
    }
  }
}

  // Mở modal sửa ý kiến
  clickMyCommentRecord = (data, status) => {
    this.getStreamFromUrl(data.session_id,1);
    if (data && status === "edit") {
      this.modelAdd = data;
      if (this.modelAdd.content_id) {
        let objContentChoose = _.find(this.lstContentTabComments, (item: any) => {
          return item.id == this.modelAdd.content_id;
        });

        let objContent;
        let content_position_id = this.modelAdd.content_id;

        if (objContentChoose && objContentChoose.is_child) {
          objContent = _.find(this.lstContents, (item: any) => {
            return item.id == objContentChoose.parent_id;
          });
          content_position_id = objContentChoose.parent_id;
        } else {
          objContent = _.find(this.lstContents, (item: any) => {
            return item.id == this.modelAdd.content_id;
          });
        }

        if (objContent) {
          this.lstDelegateTabCommentModals = _.cloneDeep(objContent.list_members);

          if (this.model.user_follow_id) {
            var indexFollow = _.findIndex(this.lstDelegateTabCommentModals, (item: any) => {
              return item.user_id == this.model.user_follow_id;
            });

            if (indexFollow == -1) {
              this.lstDelegateTabCommentModals.unshift({
                user_id: this.model.user_follow_id,
                full_name: this.model.user_follow_name
              });
            }
          }

          var indexSecretary = _.findIndex(this.lstDelegateTabCommentModals, (item: any) => {
            return item.user_id == this.model.user_secretary_id;
          });

          if (indexSecretary == -1) {
            this.lstDelegateTabCommentModals.unshift({
              user_id: this.model.user_secretary_id,
              full_name: this.model.user_secretary_name
            });
          }

          var indexChair = _.findIndex(this.lstDelegateTabCommentModals, (item: any) => {
            return item.user_id == this.model.user_chair_id;
          });

          if (indexChair == -1) {
            this.lstDelegateTabCommentModals.unshift({
              user_id: this.model.user_chair_id,
              full_name: this.model.user_chair_name
            });
          }
        } else {
          this.lstDelegateTabCommentModals = [];
        }
      }

      this.flagStateModal = "edit";
      this.modalService.open("edit-content-comment-modal");
    }

    if (data && status === "edit1") {
      this.modelAdd = data;
      if (this.modelAdd.content_id) {
        let objContent = _.find(this.lstContents, (item: any) => {
          return item.id == this.modelAdd.content_id;
        });
        if (objContent) {
          this.lstDelegateTabCommentModals = _.cloneDeep(objContent.list_members);

          if (this.model.user_follow_id) {
            var indexFollow = _.findIndex(this.lstDelegateTabCommentModals, (item: any) => {
              return item.user_id == this.model.user_follow_id;
            });

            if (indexFollow == -1) {
              this.lstDelegateTabCommentModals.unshift({
                user_id: this.model.user_follow_id,
                full_name: this.model.user_follow_name
              });
            }
          }

          var indexSecretary = _.findIndex(this.lstDelegateTabCommentModals, (item: any) => {
            return item.user_id == this.model.user_secretary_id;
          });

          if (indexSecretary == -1) {
            this.lstDelegateTabCommentModals.unshift({
              user_id: this.model.user_secretary_id,
              full_name: this.model.user_secretary_name
            });
          }

          var indexChair = _.findIndex(this.lstDelegateTabCommentModals, (item: any) => {
            return item.user_id == this.model.user_chair_id;
          });

          if (indexChair == -1) {
            this.lstDelegateTabCommentModals.unshift({
              user_id: this.model.user_chair_id,
              full_name: this.model.user_chair_name
            });
          }
        } else {
          this.lstDelegateTabCommentModals = [];
        }
      }

      this.flagStateModal = "edit1";
      this.modalService.open("edit-content-comment-modal");
    }
    if (data && status === "delete") {
      this.modelDelete = data;
      this.modalService.open("confirm-delete-my-comment-modal");
    }
  }


  // Đóng modal sửa ý kiến
  closeEditCommentModal = (status) => {
    this.file_path = "";
    if (status == 'cancel') {
      this.modalService.close("edit-content-comment-modal");
    } else {
      if(this.modelAdd.content.length == 0) {
        this.notification.warning("Lưu ý kiến không thành công!")
      } else {
        this._coreService.Post("/meetingcontentcomment/update", {
          id: this.modelAdd.id,
          user_id: this.modelAdd.user_id,
          content: this.modelAdd.content,
          files: this.modelAdd.files
        }).subscribe((res) => {
          if (res && res.code && res.code === "200") {
            this.notification.success("Lưu ý kiến thành công!");
            this.modalService.close("edit-content-comment-modal");
            this.getContentComment();
            this._socketService.sendEmit("update-content", {
              room: this.model.id,
              data: this.modelAdd
            })
          } else {
            this.notification.warning("Lưu ý kiến không thành công!")
          }
        })
      }
    }
  }

  // Upload file ý kiến tab ý kiến
  uploadCommentFile(files: FileList) {
    setTimeout(() => {
      if (files.length > 0) {
        this._configService.loadingSubject.next("true");
        const allowedExtensions = ['.pdf', '.doc', '.docx', '.ppt', '.pptx', '.xlsx', '.xls'];
        const file = files[0];
        const fileName = file.name;
        const fileExtension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
          this.notification.warning("Không thể tải tài liệu lên");
          this._configService.loadingSubject.next("false");
          return;
        }
        for (let i = 0; i < files.length; i++) {
          // check file > 5MB
          const fsize = files.item(i).size;
          const file = Math.round(fsize / 1024);
        }
        let data = new FormData();
        data.append("files", files[0]);
        this._coreService.uploadFile(data).subscribe((res) => {
          if (res && res.status && res.status == 200) {
            this.modelAddComment.files = res.data[0].url;
            this._configService.loadingSubject.next("false");
          } else {
            this._configService.loadingSubject.next("false");
            this.notification.warning("Tải files không thành công");
          }
          let x: any = document.getElementById("commentFiles");
          x.value = null;
          return;
        }, (err) => {
          this._configService.loadingSubject.next("false");
          this.notification.warning("Tải files không thành công");
        });
      }
    }, 200);
  }
  // Upload file ý kiến modal
  uploadCommentFile2(files: FileList) {
    setTimeout(() => {
      if (files.length > 0) {
        this._configService.loadingSubject.next("true");
        const allowedExtensions = ['.pdf', '.doc', '.docx', '.ppt', '.pptx', '.xlsx', '.xls'];
        const file = files[0];
        const fileName = file.name;
        const fileExtension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
          this.notification.warning("Không thể tải tài liệu lên");
          this._configService.loadingSubject.next("false");
          return;
        }
        for (let i = 0; i < files.length; i++) {
          // check file > 5MB
          const fsize = files.item(i).size;
          const file = Math.round(fsize / 1024);
        }
        let data = new FormData();
        data.append("files", files[0]);
        this._coreService.uploadFile(data).subscribe((res) => {
          if (res && res.status && res.status == 200) {
            this.modelAdd.files = res.data[0].url;
            this._configService.loadingSubject.next("false");
          } else {
            this._configService.loadingSubject.next("false");
            this.notification.warning("Tải files không thành công");
          }
          let x: any = document.getElementById("commentFile2s");
          x.value = null;
          return;
        }, (err) => {
          this._configService.loadingSubject.next("false");
          this.notification.warning("Tải files không thành công");
        });
      }
    }, 200);
  }

  removeCommentFile = (model) => {
    this.modelAddComment[model] = null;
  }

  removeCommentFile2 = (model) => {
    this.modelAdd[model] = null;
  }

  // Xác nhận xóa ý kiến
  confirmDeleteMyComment = (status): void => {
    if (status === "cancel") {
      this.modalService.close("confirm-delete-my-comment-modal");
    } else {
      // gọi API xóa ở đây

      if (this.modelDelete) {
        this._coreService.Post("/meetingcontentcomment/delete", {
          id: this.modelDelete.id
        }).subscribe(
          (res) => {
            if (res.code === "200") {
              this.notification.deleteSuccess();
              this.getContentComment();
            } else {
              this.notification.deleteError();
            }
          },
          (err) => {
            this.notification.deleteError();
          }
        );
      }
      this.modalService.close("confirm-delete-my-comment-modal");
    }
  };

  scrollToTop = (id) => {
    var objDiv = document.getElementById(id);
    objDiv.scrollTop = 0;
  }

  scrollToBottom = (id) => {
    var objDiv = document.getElementById(id);
    objDiv.scrollTop = objDiv.scrollHeight;
  }

  // Mở modal Tổng hợp ý kiến
  summaryContentComment = () => {
    this.lstSummaryComments = _.cloneDeep(this.lstAllComments);

    this.modalService.open("summary-content-comment-modal");

    setTimeout(() => {
      this.summaryCommentGrid.dataSource = new DataManager(this.lstSummaryComments);
    }, 50);
  }

  // Tìm kiếm tổng hợp ý kiến
  searchSummaryContentComment = (e) => {
    if (e.isInteracted) {
      setTimeout(() => {
        this.lstSummaryComments = _.filter(this.lstAllComments, (item: any) => {
          return (this.searchSummaryContent.time_id != null ? (this.searchSummaryContent.time_id == 1 ? item.is_before : !item.is_before) : (1 == 1))
            && (this.searchSummaryContent.user_id != null ? (item.user_id == this.searchSummaryContent.user_id) : (1 == 1))
            && (this.searchSummaryContent.content_id != null ? (item.content_id == this.searchSummaryContent.content_id) : (1 == 1));
        });

        this.summaryCommentGrid.dataSource = new DataManager(this.lstSummaryComments);

      }, 100);
    }
  }

  // Đóng modal tổng hợp ý kiến
  closeSummaryContentCommentModal = () => {
    this.searchSummaryContent.time_id = null;
    this.searchSummaryContent.user_id = null;
    this.searchSummaryContent.content_id = null;
    this.modalService.close("summary-content-comment-modal");
  }

   // Tải xuống tất cả tài liệu
   downloadAllContentDocument = () => {
    let lstDocumentDownloads = [];
    for (let i = 0; i < this.lstContents.length; i++) {
      for (let j = 0; j < this.lstContents[i].list_documents.length; j++) {
        if (this.flagChair || this.flagFollow || this.flagSecretary) {
          lstDocumentDownloads.push(this.lstContents[i].list_documents[j].file_name);
        } else {
          if (this.model.status == 22 && this.lstContents[i].list_documents[j].is_before_download) {
            lstDocumentDownloads.push(this.lstContents[i].list_documents[j].file_name);
          }

          if (this.model.status == 23 && this.lstContents[i].list_documents[j].is_inmeet_download) {
            lstDocumentDownloads.push(this.lstContents[i].list_documents[j].file_name);
          }

          if (this.model.status == 24 && this.lstContents[i].list_documents[j].is_after_download) {
            lstDocumentDownloads.push(this.lstContents[i].list_documents[j].file_name);
          }
        }
      }
    }

    if (lstDocumentDownloads && lstDocumentDownloads.length > 0) {
      this._coreService.PostExport("/print/exportAllDocument", {
        list_files: lstDocumentDownloads,
        meetingId: this.model.id
      }).subscribe((data) => {
        const blob = new Blob([data],
          { type: "application/zip" });

        saveAs(blob, "TaiLieuCuocHop.zip");
      }, (error) => {
        this.notification.warning("Xuất file thất bại!");
      })
    }
  }

  // Bổ sung tài liệu
  addContentDocument = () => {
    this.searchContentAddDocument = this.lstContentUploads && this.lstContentUploads.length > 0 ? this.lstContentUploads[0].id : null;
    this.lstContentDocuments = [];
    this.lstContentDocuments = this.lstContentUploads && this.lstContentUploads.length > 0 ? this.lstContentUploads[0].list_documents : null;

    this.modalService.open("add-content-document-modal");
  }


  uploadDocumentContent(files: FileList) {
    if (!this.searchContentAddDocument) {
      return this.notification.warning("Bạn chưa chọn nội dung tải tài liệu!");
    }
    setTimeout(() => {
      if (files.length > 0) {
        const allowedExtensions = ['.pdf', '.doc', '.docx', '.ppt', '.pptx', '.xlsx', '.xls'];
        const file = files[0];
        const fileName = file.name;
        const fileExtension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
          this.notification.warning("Không thể tải tài liệu lên");
          this._configService.loadingSubject.next("false");
          return;
        }

        this._configService.loadingSubject.next("true");
        for (let i = 0; i < files.length; i++) {
          // check file > 5MB
          const fsize = files.item(i).size;
          const fileSize = Math.round(fsize / 1024);
        }
        let data = new FormData();
        data.append("files", files[0]);
        this._coreService.uploadFile(data).subscribe((res) => {
          if (res && res.status && res.status == 200) {
            if (!this.lstContentDocuments) {
              this.lstContentDocuments = [];
            }
            this.lstContentDocuments.push({
              meeting_id: this.model.id,
              content_id: this.searchContentAddDocument,
              file_name: res.data[0].name,
              links: res.data[0].url,
              orders: this.lstContentDocuments.length + 1,
              is_before_view: true,
              is_before_download: true,
              is_inmeet_view: true,
              is_inmeet_download: true,
              is_after_view: true,
              is_after_download: true
            });
            this._configService.loadingSubject.next("false");
          } else {
            this._configService.loadingSubject.next("false");
            this.notification.warning("Tải files không thành công");
          }
          let x: any = document.getElementById("addDocument");
          x.value = null;
          return;
        }, (err) => {
          this._configService.loadingSubject.next("false");
          this.notification.warning("Tải files không thành công");
        });
      }
    }, 200);
  }

  // Xóa tài liệu
  deleteContentDocumentModal = (index: any) => {
    if (index > -1) {
      this.lstContentDocuments.splice(index, 1);
    };
  }

  // Đóng modal thêm tài liệu
  closeContentDocumentModal = () => {
    this.modalService.close("add-content-document-modal");
  }

  // Lưu tài liệu
  saveContentDocumentModal = () => {
    if (!this.searchContentAddDocument) {
      return this.notification.warning("Bạn chưa chọn nội dung tải tài liệu!");
    }

    var objContent = _.find(this.lstContents, (item) => {
      return item.id == this.searchContentAddDocument;
    });

    if (objContent) {
      objContent.list_documents = _.sortBy(this.lstContentDocuments, "orders");
    }

    this._coreService.Post("/meetingcontentdocument/createContentDocument", {
      content_id: this.searchContentAddDocument,
      list_items: this.lstContentDocuments
    }).subscribe((res) => {
      if (res && res.code && res.code === "200") {
        this.notification.success("Bổ sung tài liệu thành công!")
        // this.documentContentGrid.refreshColumns();
        this.getDataMeeting();
        this._socketService.sendEmit("update-document", {
          room: this.model.id
        })
        this.modalService.close("add-content-document-modal");
      } else {
        this.notification.warning("Bổ sung tài liệu không thành công!")
      }
    });
  }

  // Đổi sự kiện nội dung
  changeContentAddDocument = (event) => {
    if (event.isInteracted) {
      setTimeout(() => {
        this.lstContentDocuments = [];

        var objContent = _.find(this.lstContents, (item) => {
          return item.id == this.searchContentAddDocument;
        });

        if (objContent) {
          this.lstContentDocuments = objContent.list_documents;
        }
      })
    }
  }

  // Góp ý tài liệu
  clickContentDocument = (contentData, data, status) => {
    this._configService.loadingSubject.next("true");
    if (data && status === "comment") {
      this.modelContentDocumentAdd = contentData;
      this.modelAdd = data;
      this.modelAdd.file_type = "";
      this.modelAddComment2 = new MeetingContentComment();
      this.modelAddComment2.content = ""
      if (data && data.links) {
        let lstSplit = data.links.split(".");
        this.modelAdd.file_type = (lstSplit && lstSplit.length > 0) ? lstSplit[lstSplit.length - 1] : "";
      }
      this.flagStateModal = "new";
      this.documentCommentForm.markAsUntouched();
      let file_name = this.globals.replaceFileLink(this.modelAdd.links);
      let file_name_array = file_name.split(".");
      let file_type_origin = file_name_array[file_name_array.length - 1];
      let file_type = "";
      let file_type_convert = "";
      if (file_type_origin == "xlsx") {
        file_type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        file_type_convert = "application/pdf";
      } else if (file_type_origin == "xls") {
        file_type = "application/vnd.ms-excel";
        file_type_convert = "application/pdf";
      } else if (file_type_origin == "doc") {
        file_type = "application/msword";
        file_type_convert = "application/pdf";
      } else if (file_type_origin == "docx") {
        file_type = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        file_type_convert = "application/pdf";
      } else if (file_type_origin == "ppt") {
        file_type = "application/vnd.ms-powerpoint";
        file_type_convert = "application/pdf";
      } else if (file_type_origin == "pptx") {
        file_type = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
        file_type_convert = "application/pdf";
      } else if (file_type_origin == "pdf") {
        file_type = "application/pdf";
        file_type_convert = "application/pdf";
      } else if (file_type_origin == "png") {
        file_type = "image/png";
        file_type_convert = "image/png";
      } else if (file_type_origin == "jpeg") {
        file_type = "image/jpeg";
        file_type_convert = "image/jpeg";
      } else if (file_type_origin == "gif") {
        file_type = "image/gif";
        file_type_convert = "image/gif";
      }
      this._coreService.PostExport("/upload/downloadViewFile", {
        file_name: file_name,
        file_type: file_type,
        meetingId: this.model.id
      }).subscribe((data) => {
        if(data) {
          setTimeout(() => {
            const blob = new Blob([data],
              { type: file_type_convert });
            const fileReader = new FileReader();
            fileReader.onload = () => {
              this.modelAdd.url = new Uint8Array(fileReader.result as ArrayBuffer);
            };
            fileReader.readAsArrayBuffer(blob);
            this.modalService.open("add-document-comment-modal");
          this._configService.loadingSubject.next("false");

          }, 800)
        } else {
          setTimeout(() => {
            this.notification.warning("Hiển thị file không thành công");
            this._configService.loadingSubject.next("false");
          }, 800)
        }
      })
    }
    if (data && status === "download") {
      if (data && data.links) {
        // window.open(data.links, "_blank");
        let file_name = this.globals.replaceFileLink(data.links);
        let file_name_array = data.links.split(".");
        let file_type_origin = file_name_array[file_name_array.length - 1];
        let file_type = "";
        if (file_type_origin == "xlsx") {
          file_type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        } else if (file_type_origin == "xls") {
          file_type = "application/vnd.ms-excel";
        } else if (file_type_origin == "doc") {
          file_type = "application/msword";
        } else if (file_type_origin == "docx") {
          file_type = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        } else if (file_type_origin == "ppt") {
          file_type = "application/vnd.ms-powerpoint";
        } else if (file_type_origin == "pptx") {
          file_type = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
        } else if (file_type_origin == "pdf") {
          file_type = "application/pdf";
        } else if (file_type_origin == "png") {
          file_type = "image/png";
        } else if (file_type_origin == "jpeg") {
          file_type = "image/jpeg";
        } else if (file_type_origin == "gif") {
          file_type = "image/gif";
        }
        this._coreService.PostExport("/upload/downloadFile", {
          file_name: file_name,
          file_type: file_type
        }).subscribe((data) => {
          const blob = new Blob([data],
            { type: file_type });
            saveAs(blob, file_name);
            this._configService.loadingSubject.next("false");
        })
      }
    }
  }

  // Upload file góp ý modal góp ý
  uploadDocumentCommentFile(files: FileList) {
    setTimeout(() => {
      if (files.length > 0) {
        this._configService.loadingSubject.next("true");
        for (let i = 0; i < files.length; i++) {
          // check file > 5MB
          const fsize = files.item(i).size;
          const file = Math.round(fsize / 1024);
        }
        let data = new FormData();
        data.append("files", files[0]);
        this._coreService.uploadFile(data).subscribe((res) => {
          if (res && res.status && res.status == 200) {
            this.modelAddComment2.files = res.data[0].url;
            this._configService.loadingSubject.next("false");
          } else {
            this._configService.loadingSubject.next("false");
            this.notification.warning("Tải files không thành công");
          }
          let x: any = document.getElementById("documentCommentFiles");
          x.value = null;
          return;
        }, (err) => {
          this._configService.loadingSubject.next("false");
          this.notification.warning("Tải files không thành công");
        });
      }
    }, 200);
  }

  // Upload file sửa góp ý
  uploadDocumentCommentFile2(files: FileList) {
    setTimeout(() => {
      if (files.length > 0) {
        this._configService.loadingSubject.next("true");
        for (let i = 0; i < files.length; i++) {
          // check file > 5MB
          const fsize = files.item(i).size;
          const file = Math.round(fsize / 1024);
        }
        let data = new FormData();
        data.append("files", files[0]);
        this._coreService.uploadFile(data).subscribe((res) => {
          if (res && res.status && res.status == 200) {
            this.modelAdd.files = res.data[0].url;
            this._configService.loadingSubject.next("false");
          } else {
            this._configService.loadingSubject.next("false");
            this.notification.warning("Tải files không thành công");
          }
          let x: any = document.getElementById("commentFile2s");
          x.value = null;
          return;
        }, (err) => {
          this._configService.loadingSubject.next("false");
          this.notification.warning("Tải files không thành công");
        });
      }
    }, 200);
  }

  // Xóa file đính kèm modal góp ý
  removeDocumentCommentFile = (model) => {
    this.modelAddComment[model] = null;
  }

  // Xóa file đính kèm modal sửa góp ý
  removeDocumentCommentFile2 = (model) => {
    this.modelAddComment2.files = null;

    this.modelAdd[model] = null;
  }

  change(env : any){

    this.modelAddComment2.content = env;
  }

  // Đóng modal góp ý
  closeDocumentComment = () => {
    this.interval.forEach((intervalId) => {
      clearInterval(intervalId);
     });
    this.modelAddComment2 = new MeetingContentComment();
    this.modelAdd = null;
    this.modelContentDocumentAdd = null;
    this.modelAddComment2.content = "";
    this.modelAddComment2.content_id = null;
    if(this.flag_save == false){
      this._coreService
      .Post("/meetingcontentdocument/deleteDocumentCommentBySessionId", {
        session_id : this.dataSession.session,
      })
      .subscribe((res) => {
        if (res && res.code && res.code == "200") {
          this.isSave = false;
          this.notification.success("Hủy góp ý tài liệu thành công!");
          this.modalService.close("add-document-comment-modal");
          this.getDocumentComment();
        }else{
          this.notification.error("Hủy góp ý tài liệu thất bại!");
          this.modalService.close("add-document-comment-modal");
        }
      });
    }else{
      this.modalService.close("add-document-comment-modal");
    }
  }

  startRecordingDocumentComment(){
    if(this.isSave == true ) {
      this.notification.warning("Bạn chưa lưu ý kiến ghi âm, nếu thực hiện tiếp tục ghi âm sẽ xóa ý kiến vừa ghi âm!");
      return;
    }
    this.getSS(2);
  }
  // Góp ý tài liệu
  saveDocumentComment = () => {
    this.interval.forEach((intervalId) => {
      clearInterval(intervalId);
     });
     if(this.flag_trans != false){
      if (!this.modelAddComment2.content) {
        this.notification.warning("Bạn vui lòng nhập góp ý!");
        this.documentCommentForm.markAllAsTouched();
        return;
      }
     }
    var body = {
        session_id: this.dataSession && this.dataSession.session ? this.dataSession.session : null,
        meeting_id: this.model.id,
        content_id: this.modelContentDocumentAdd.id,
        document_id: this.modelAdd.id,
        content: this.modelAddComment2.content.replace(/<\/?p>/g, ''),
        files: this.modelAddComment2.files,
      }
    if(this.flag_save == false){
      this._configService.loadingSubject.next("true");
      this._coreService.Post("/meetingcontentdocument/updateDocumentCommentBySessionId", body).subscribe((res) => {
        if (res && res.code && res.code == "200") {
          this.flag_save = true;
          this.isSave = false;
          this.notification.success("Thêm góp ý tài liệu thành công!");
          this.modelAddComment2 = new MeetingContentComment();
          this.modelAddComment2.content = "";
          this.modelAdd = null;
          this.modelContentDocumentAdd = null;
          this.modalService.close("add-document-comment-modal");
          this.getDocumentComment();
          this.getDataMeeting().then(() => {
            // Socket cập nhật tài liệu
            this._socketService.sendEmit("update-document", {
              // data: this.model
              room: this.model.id
            })
          });
        }
        else{
          this.notification.error("Thêm góp ý tài liệu thất bại!");
        }
        this._configService.loadingSubject.next("false");
      });
    }else{
      var body1 = {
        meeting_id: this.model.id,
        content_id: this.modelContentDocumentAdd.id,
        document_id: this.modelAdd.id,
        content: this.modelAddComment2.content.replace(/<\/?p>/g, ''),
        files: this.modelAddComment2.files,
        record_file: null
      }
      this._coreService.Post("/meetingcontentdocument/createComment", body1).subscribe((res) => {
        if (res && res.code && res.code == "200") {
          this.isSave = false;
          this.notification.success("Thêm góp ý tài liệu thành công!");
          this.modelAddComment2 = new MeetingContentComment();
          this.modelAddComment2.content = "";
          this.modelAdd = null;
          this.modelContentDocumentAdd = null;
          this.modalService.close("add-document-comment-modal");
          this.getDocumentComment();
          this.getDataMeeting().then(() => {
            // Socket cập nhật tài liệu
            this._socketService.sendEmit("update-document", {
              room: this.model.id
            })
          });
        }
        this._configService.loadingSubject.next("false");
      })
    }
  }

  // Lấy danh sách góp ý tài liệu
  getDocumentComment = () => {
    this._coreService.Post("/meetingcontentdocument/listContentDocumentComment", {
      meeting_id: this.model.id
    }).subscribe((res) => {
      if (res && res.code && res.code == "200") {
        let allComment = res && res.data && res.data.data ? res.data.data : [];
        if (this.model.status == 24) {
          if (this.flagChair || this.flagFollow || this.flagSecretary) {
            this.lstAllDocumentComments = allComment;
          } else {
            this.lstAllDocumentComments = _.filter(allComment, (item) => {
              return item.user_id == this.modelUser.id;
            })
          }
        } else {
          this.lstAllDocumentComments = allComment;
        }

        this.lstMyDocumentComments = _.filter(this.lstAllDocumentComments, (item: any) => {
          return item.user_id == this.modelUser.id;
        });

        this.lstMyDocumentComments = _.filter(this.lstAllDocumentComments, (item: any) => {
          return item.user_id == this.modelUser.id;
        });

        if (this.allDocumentCommentGrid) {
          this.allDocumentCommentGrid.dataSource = new DataManager(this.lstAllDocumentComments);
        }
        if (this.myDocumentCommentGrid) {
          this.myDocumentCommentGrid.dataSource = new DataManager(this.lstMyDocumentComments);
        }
      }
    })
  }

  // Click Grid thảo luận
  clickAllDocumentCommentRecord = (data, status) => {
    this.getStreamFromUrl(data.session_id,2);
    if (data && status === "view") {
      this.modelAdd = data;
      this.flagStateModal = "view";
      this.modalService.open("edit-document-comment-modal");
    }
  }

  // Mở modal sửa góp ý tài liệu
  clickMyDocumentCommentRecord = (data, status) => {
    if (data && status === "edit") {
      this.getStreamFromUrl(data.session_id,2);
      this.modelAdd = data;
      this.flagStateModal = "edit";
      this.modalService.open("edit-document-comment-modal");
    }
    if (data && status === "delete") {
      this.modelDelete = data;
      this.modalService.open("confirm-delete-document-comment-modal");
    }
  }

  // Đóng modal sửa góp ý tài liệu
  closeEditDocumentCommentModal = (status) => {
    this.audioSource = "";
    if (status == 'cancel') {
      this.modalService.close("edit-document-comment-modal");
    } else {
      if(this.modelAdd.content) {
        this.notification.warning("Lưu góp ý không thành công!")
      } else {
        this._coreService.Post("/meetingcontentdocument/updateComment", {
          id: this.modelAdd.id,
          meeting_id: this.model.id,
          content: this.modelAdd.content,
          files: this.modelAdd.files
        }).subscribe((res) => {
          if (res && res.code && res.code === "200") {
            this.notification.success("Lưu góp ý thành công!");
            this._socketService.sendEmit("update-document", {
              room: this.model.id,
              data: this.modelDelete
            });
            this.modalService.close("edit-document-comment-modal");
            // this.getContentComment();
            this.getDocumentComment()
          } else {
            this.notification.warning("Lưu góp ý không thành công!")
          }
        })
      }
    }
  }

  closeEditDocumentCommentModal1 = (status) => {
    this._configService.loadingSubject.next("true");

    // Gọi hàm để cập nhật dữ liệu sau khi thành công
    this.viewContentHistory(this.modelRealContent);

    // this.modelAdd = "";
    if (status === 'cancel') {
      this.audioSource2 = "";
      this._configService.loadingSubject.next("false");
      this.modalService.close("edit-history-detail-modal");
      this.modalService.open("content-history-modal");
      const htmlElement = document.getElementsByTagName('html')[0];
  htmlElement.style.overflow = '';
    } else {
      this._coreService.Post("/meetingcontentcomment/update", {
        id: this.modelAdd.id,
        content: this.modelAdd.content,
        user_id: this.modelAdd.user_id,
        files: this.modelAdd.files
      }).subscribe((res) => {
        if (res && res.code && res.code === "200") {
          this.notification.success("Lưu góp ý thành công!");

          // Gọi hàm để cập nhật dữ liệu sau khi thành công
          this.viewContentHistory(this.modelRealContent);

          setTimeout(() => {
            if (this.contentHistoryGrid) {
              this._configService.loadingSubject.next("false");
              this.modalService.close("edit-history-detail-modal");
              const htmlElement = document.getElementsByTagName('html')[0];
  htmlElement.style.overflow = '';
            }
          }, 1000);

          this.modalService.open("content-history-modal");
        } else {
          this.notification.warning("Lưu góp ý không thành công!");
        }
      });
    }
  }

  // Xác nhận xóa góp ý
  confirmDeleteDocumentComment = (status): void => {
    if (status === "cancel") {
      this.modalService.close("confirm-delete-document-comment-modal");
    } else {
      // gọi API xóa ở đây

      if (this.modelDelete) {
        this._coreService.Post("/meetingcontentdocument/deleteComment", {
          id: this.modelDelete.id
        }).subscribe(
          (res) => {
            if (res.code === "200") {
              this.notification.deleteSuccess();
              this.getDocumentComment();
              // Socket cập nhật tài liệu
              this._socketService.sendEmit("update-document", {
                data: this.model
              })
            } else {
              this.notification.deleteError();
            }
          },
          (err) => {
            this.notification.deleteError();
          }
        );
      }
      this.modalService.close("confirm-delete-document-comment-modal");
    }
  };

  // Mở modal Tổng hợp ý kiến
  summaryDocumentComment = () => {
    this.lstSummaryDocumentComments = _.cloneDeep(this.lstAllDocumentComments);

    this.modalService.open("summary-document-comment-modal");

    setTimeout(() => {
      this.summaryDocumentCommentGrid.dataSource = new DataManager(this.lstSummaryDocumentComments);
    }, 50);
  }

  contentIdCommentContent: string
  // Tìm kiếm tổng hợp ý kiến
  searchSummaryDocumentComment = (e) => {
    if (e.isInteracted) {
      setTimeout(() => {
        this.lstSummaryDocumentComments = _.filter(this.lstAllDocumentComments, (item: any) => {
          return (this.searchSummaryDocument.time_id != null && this.searchSummaryDocument.time_id !== 0 ? (this.searchSummaryDocument.time_id == 1 ? item.is_before : !item.is_before) : (1 == 1))
            && (this.searchSummaryDocument.user_id != null ? (item.user_id == this.searchSummaryDocument.user_id) : (1 == 1))
            && (this.searchSummaryDocument.content_id != null ? (item.content_id == this.searchSummaryDocument.content_id) : (1 == 1));
        });

        this.summaryDocumentCommentGrid.dataSource = new DataManager(this.lstSummaryDocumentComments);

      }, 100);
    }
    this.contentIdCommentContent = e.value
  }

  // Xuất excel
  exportDocumentExcel = () => {
    this._coreService.PostExport("/print/printSummaryDocumentComment2", {
      meeting_id: this.model.id,
      content_id: this.contentIdCommentContent,
      time_id: this.searchSummaryContent.time_id,
      user_id: this.searchSummaryContent.user_id
    }).subscribe((data) => {
      const blob = new Blob([data],
        { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });
      saveAs(blob, "NoiDungTongHopGopY.docx");
    }, (error) => {
      this.notification.warning("Xuất file thất bại!");
    })
  }

  // Đóng modal tổng hợp ý kiến
  closeSummaryDocumentCommentModal = () => {
    this.searchSummaryDocument.content_id = null;
    this.searchSummaryDocument.user_id = null;
    this.searchSummaryDocument.time_id = null;
    this.modalService.close("summary-document-comment-modal");
  }

  openFile = (file) => {
    let file_name = this.globals.replaceFileLink(file);
    let file_name_array = file.split(".");
    let file_type_origin = file_name_array[file_name_array.length - 1];
    let file_type = "";
    if (file_type_origin == "xlsx") {
      file_type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    } else if (file_type_origin == "xls") {
      file_type = "application/vnd.ms-excel";
    } else if (file_type_origin == "doc") {
      file_type = "application/msword";
    } else if (file_type_origin == "docx") {
      file_type = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    } else if (file_type_origin == "ppt") {
      file_type = "application/vnd.ms-powerpoint";
    } else if (file_type_origin == "pptx") {
      file_type = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
    } else if (file_type_origin == "pdf") {
      file_type = "application/pdf";
    } else if (file_type_origin == "png") {
      file_type = "image/png";
    } else if (file_type_origin == "jpeg") {
      file_type = "image/jpeg";
    } else if (file_type_origin == "gif") {
      file_type = "image/gif";
    }
    this._coreService.PostExport("/upload/downloadFile", {
      file_name: file_name,
      file_type: file_type,
      meetingId: this.model.id
    }).subscribe((data) => {
      const blob = new Blob([data],
        { type: file_type });

      saveAs(blob, file_name);
    })
  }

  // Lấy dữ liệu tài liệu
  getDocumentData = () => {
    this._coreService.Get("/meetingcontentdocument/listDocument/" + this.model.id).subscribe((res) => {
      if (res && res.code && res.code == "200") {
        this.lstDocuments = res.data && res.data.data ? res.data.data : [];
        // Tài liệu cá nhân
        this.lstPrivateDocuments = _.filter(this.lstDocuments, (item: any) => {
          return item.is_private == true && item.user_id == this.modelUser.id;
        });
        if (this.privateDocumentGrid) {
          this.privateDocumentGrid.dataSource = new DataManager(this.lstPrivateDocuments);
        }

        // Tài liệu được chia sẻ
        this.lstShareDocuments = _.filter(this.lstDocuments, (item: any) => {
          return item.is_share == true;
        });

        if (this.shareDocumentGrid) {
          this.shareDocumentGrid.dataSource = new DataManager(this.lstPrivateDocuments);
        }

        // Tài liệu khách mời
        this.lstGuestDocuments = _.filter(this.lstDocuments, (item: any) => {
          return item.is_private == false && item.is_share == false;
        });

        if (this.guestDocumentGrid) {
          this.guestDocumentGrid.dataSource = new DataManager(this.lstGuestDocuments);
        }
      }
    })
  }

  // Thêm tài liệu cá nhân
  uploadPrivateDocument(files: FileList) {
    setTimeout(() => {
      if (files.length > 0) {
        this._configService.loadingSubject.next("true");

        const allowedExtensions = ['.pdf', '.doc', '.docx', '.ppt', '.pptx', '.xlsx', '.xls'];
        const file = files[0];
        const fileName = file.name;
        const fileExtension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
          this.notification.warning("Không thể tải tài liệu lên");
          this._configService.loadingSubject.next("false");
          return;
        }
        for (let i = 0; i < files.length; i++) {
          // check file > 5MB
          const fsize = files.item(i).size;
          const file = Math.round(fsize / 1024);
        }
        let data = new FormData();
        data.append("files", files[0]);
        this._coreService.uploadFile(data).subscribe((res) => {
          if (res && res.status && res.status == 200) {
            this._coreService.Post("/meetingcontentdocument/createDocument", {
              meeting_id: this.model.id,
              links: res.data[0].url,
              file_name: res.data[0].name,
              orders: 1,
              is_private: true
            }).subscribe((res) => {
              if (res && res.code && res.code == "200") {
                this.notification.success("Tải tài liệu cá nhân thành công!");
                this._socketService.sendEmit("update-document", {
                  room: this.model.id,
                  data: this.model
                });
                this.getDocumentData();
              } else {
                this.notification.success("Tải tài liệu cá nhân thất bại!");
              }
              this._configService.loadingSubject.next("false");
            })
          } else {
            this._configService.loadingSubject.next("false");
            this.notification.warning("Tải files không thành công");
          }
          let x: any = document.getElementById("privateDocument");
          x.value = null;
          return;
        }, (err) => {
          this._configService.loadingSubject.next("false");
          this.notification.warning("Tải files không thành công");
        });
      }
    }, 200);
  }

  // Thêm tài liệu khách mời
  uploadGuestDocument(files: FileList) {
    setTimeout(() => {
      if (files.length > 0) {
        this._configService.loadingSubject.next("true");
        const allowedExtensions = ['.pdf', '.doc', '.docx', '.ppt', '.pptx', '.xlsx', '.xls'];
        const file = files[0];
        const fileName = file.name;
        const fileExtension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
          this.notification.warning("Không thể tải tài liệu lên");
          this._configService.loadingSubject.next("false");
          return;
        }
        for (let i = 0; i < files.length; i++) {
          // check file > 5MB
          const fsize = files.item(i).size;
          const file = Math.round(fsize / 1024);
        }
        let data = new FormData();
        data.append("files", files[0]);
        this._coreService.uploadFile(data).subscribe((res) => {
          if (res && res.status && res.status == 200) {
            this._coreService.Post("/meetingcontentdocument/createDocument", {
              meeting_id: this.model.id,
              links: res.data[0].url,
              file_name: res.data[0].name,
              orders: 1,
              is_private: false
            }).subscribe((res) => {
              if (res && res.code && res.code == "200") {
                this._socketService.sendEmit("update-document", {
                  room: this.model.id,
                  data: this.modelDelete
                });
                this.notification.success("Tải tài liệu cá nhân thành công!");
                this.getDocumentData();
              } else {
                this.notification.success("Tải tài liệu cá nhân thất bại!");
              }
              this._configService.loadingSubject.next("false");
            })
          } else {
            this._configService.loadingSubject.next("false");
            this.notification.warning("Tải files không thành công");
          }
          let x: any = document.getElementById("guestDocument");
          x.value = null;
          return;
        }, (err) => {
          this._configService.loadingSubject.next("false");
          this.notification.warning("Tải files không thành công");
        });
      }
    }, 200);
  }

  // Xóa tài liệu cá nhân
  deletePrivateDocument = (data) => {
    this.modelDelete = data;
    this.modalService.open("confirm-delete-private-document-modal");
  }

  confirmDeletePrivateDocument = (status) => {
    if (status == 'cancel') {
      this.modalService.close("confirm-delete-private-document-modal");
    } else {
      this._coreService.Post("/meetingcontentdocument/deleteDocument", {
        id: this.modelDelete.id
      }).subscribe((res) => {
        if (res && res.code && res.code === "200") {
          this.notification.success("Xóa tài liệu cá nhân thành công!");
          this.modalService.close("confirm-delete-private-document-modal");
          this._socketService.sendEmit("update-document", {
            room: this.model.id,
            data: this.modelDelete
          });
          this.getDocumentData();
        } else {
          this.notification.warning("Xóa tài liệu không cá nhân thành công!");
        }
      })
    }
  }

  // Xóa tài liệu được chia sẻ
  deleteShareDocument = (data) => {
    this.modelDelete = data;
    this.modalService.open("confirm-delete-share-document-modal");
  }

  confirmDeleteShareDocument = (status) => {
    if (status == 'cancel') {
      this.modalService.close("confirm-delete-share-document-modal");
    } else {
      this._coreService.Post("/meetingcontentdocument/deleteShareDocument", {
        id: this.modelDelete.id,
        user_id: this.modelUser.id
      }).subscribe((res) => {
        if (res && res.code && res.code === "200") {
          this.notification.success("Xóa tài liệu được chia sẻ thành công!");
          this.modalService.close("confirm-delete-share-document-modal");
          this._socketService.sendEmit("update-document", {
            room: this.model.id,
            data: this.modelDelete
          });
          this.getDocumentData();
        } else {
          this.notification.warning("Xóa tài liệu được chia sẻ thành công!");
        }
      })
    }
  }

  // Xóa tài liệu khách mời
  deleteGuestDocument = (data) => {
    this._coreService.Post("/meetingcontentdocument/deleteDocument", {
      id: data.id
    }).subscribe((res) => {
      if (res && res.code && res.code === "200") {
        this._socketService.sendEmit("update-document", {
          room: this.model.id,
          data: this.modelDelete
        });
        this.notification.success("Xóa tài liệu cá nhân thành công!");
        this.getDocumentData();
      } else {
        this.notification.warning("Xóa tài liệu không cá nhân thành công!");
      }
    })
  }

  // Chia sẻ tài liệu cá nhân
  shareDocument = (data) => {
    this.modelShare = data;
    this.lstShareChooses = data.list_delegate_shares;

    if (this.memberShareChooseGrid) {
      this.memberShareChooseGrid.dataSource = new DataManager(this.lstShareChooses);
    }
    this.modalService.open("share-document-modal");
    setTimeout(() => {
      let indexs = [];
      let ids = _.map(this.lstShareChooses, "user_id");
      let lstDelegates = this.memberShareGrid.currentViewData["records"];
      for (let i = 0; i < ids.length; i++) {
        let index = _.findIndex(lstDelegates, (item) => {
          return item.user_id === ids[i];
        });

        if (index > -1) {
          indexs.push(index);
        }
      }

      if (indexs.length > 0) {
        this.memberShareGrid.selectRows(indexs);
      }
    }, 100);

  }

  // Sự kiện chọn đại biểu
  selectShare = (event) => {
    setTimeout(() => {
      if ((event.name === "rowSelecting" && event.isCtrlPressed) || (event.name === "rowDeselecting" && event.isInteracted)) {
        if (event.name === "rowSelecting" && event.isCtrlPressed) {
          const rowSelects: any = this.memberShareGrid.getSelectedRecords();
          if (rowSelects && rowSelects.length > 0) {
            for (let i = 0; i < rowSelects.length; i++) {
              let index = _.findIndex(this.lstShareChooses, (item) => {
                return item.user_id == rowSelects[i].user_id;
              });

              if (index == -1) {
                this.lstShareChooses.push(rowSelects[i]);
              }
            }
          }
        } else if (event.name === "rowDeselecting" && event.isInteracted) {
          if (event.data && event.data.length > 0) {
            for (let i = 0; i < event.data.length; i++) {
              let index = _.findIndex(this.lstShareChooses, (item) => {
                return item.user_id == event.data[i].user_id;
              });

              if (index > -1) {
                this.lstShareChooses.splice(index, 1);
              }
            }
          }
        }
      } else if (event.name === "rowDeselecting" && !event.isInteracted && event.target && event.target.className && (event.target.className.indexOf("e-uncheck") > -1 || event.target.className.indexOf("e-checkbox-wrapper") > -1)) {
        if (event.data && event.data.length > 0) {
          let rowDeselect = event.data;
          for (let i = 0; i < rowDeselect.length; i++) {
            let index = _.findIndex(this.lstShareChooses, (item) => {
              return item.user_id == rowDeselect[i].user_id;
            });

            if (index > -1) {
              this.lstShareChooses.splice(index, 1);
            }
          }
        }
      }
      this.memberShareChooseGrid.dataSource = new DataManager(this.lstShareChooses);
    }, 200);
  };

  // Lưu chia sẻ tài liệu
  closeShareDocument = (status) => {
    if (status == "cancel") {
      this.modalService.close("share-document-modal");
    } else {
      let selectRecords = this.lstShareChooses;

      let lstUserIds = _.map(selectRecords, "user_id");

      let list_shares = [];

      for (let i = 0; i < lstUserIds.length; i++) {
        list_shares.push({
          user_receive_id: lstUserIds[i]
        })
      }

      this._coreService.Post("/meetingcontentdocument/sharePrivateDocument", {
        id: this.modelShare.id,
        list_shares: list_shares
      }).subscribe((res) => {
        if (res && res.code && res.code === "200") {
          this.notification.success("Chia sẻ tài liệu cá nhân thành công!");
          this.getDocumentData();
          if (this.memberShareGrid) {
            this.memberShareGrid.refresh();
          }

          if (this.memberShareChooseGrid) {
            this.memberShareChooseGrid.refresh();
          }
          this.modalService.close("share-document-modal");
          // Socket cập nhật tài liệu
          this._socketService.sendEmit("update-document", {
            room: this.model.id,
            data: this.model
          })
        } else {
          this.notification.warning("Chia sẻ tài liệu cá nhân không thành công!");
        }
      })
    }
  }

  // Lấy danh sách ghế chờ
  getWaitingPosition = () => {
    let rowWaiting = this.modelPosition.is_hall_room ? (this.modelPosition.vertical_number + this.modelPosition.horizontal_table_number + 3) : (this.modelPosition.vertical_number * 2 + this.modelPosition.horizontal_table_number + 4);
    let maxChairNumber = this.lstRoomPositions && this.lstRoomPositions.length > 0 ? _.maxBy(this.lstRoomPositions, "table_number").table_number : 1;
    maxChairNumber = maxChairNumber + 1;

    var lstDelegateSeats = _.map(_.filter(this.lstRoomPositions, (item: any) => {
      return item.user_id;
    }), "user_id");
    var lstDelegateGuestSeats = _.map(_.filter(this.lstRoomPositions, (item: any) => {
      return item.is_guest == true && item.full_name;
    }), "full_name");

    let lstDelegateContents = [];
    if (this.searchContentTabRoom) {
      let objContent = _.find(this.lstContents, (item: any) => {
        return item.id == this.searchContentTabRoom;
      });

      if (objContent && objContent.list_members) {
        lstDelegateContents = _.cloneDeep(objContent.list_members);

        for (let i = 0; i < lstDelegateContents.length; i++) {
          var objMember = _.find(this.model.list_delegates, (item: any) => {
            return lstDelegateContents[i].user_id == item.user_id;
          });

          if (objMember) {
            if (objMember.is_same_chair) {
              lstDelegateContents[i].is_chair = true;
              lstDelegateContents[i].is_special_position = true;
            } else {
              lstDelegateContents[i].is_chair = false;
              lstDelegateContents[i].is_special_position = false;
            }
          }
        }

        if (objContent.list_guests && objContent.list_guests.length > 0) {
          let lstGuestContents = [];

          for (let i = 0; i < objContent.list_guests.length; i++) {
            lstGuestContents.push({
              user_id: objContent.list_guests[i].id,
              is_guest: true,
              is_chair: false,
              is_special_position: false,
              full_name: objContent.list_guests[i].full_name,
              status: objContent.list_guests[i].attendance_status ? 31 : 32
            })
          }
          lstDelegateContents = lstDelegateContents.concat(lstGuestContents);
        }
      }

      if (this.model.user_follow_id) {
        let indexFollow = _.findIndex(lstDelegateContents, (item: any) => {
          return item.user_id == this.model.user_follow_id;
        });

        if (indexFollow == -1) {
          lstDelegateContents.unshift({
            user_id: this.model.user_follow_id,
            full_name: this.model.user_follow_name,
            meeting_status: 2,
            is_chair: false,
            is_special_position: false,
            status: 31
          });
        } else {
          lstDelegateContents[indexFollow].is_special_position = false;
        }
      }


      let indexSecretary = _.findIndex(lstDelegateContents, (item: any) => {
        return item.user_id == this.model.user_secretary_id;
      });

      if (indexSecretary == -1) {
        lstDelegateContents.unshift({
          user_id: this.model.user_secretary_id,
          full_name: this.model.user_secretary_name,
          is_chair: true,
          is_special_position: false,
          status: 31
        });
      } else {
        lstDelegateContents[indexSecretary].is_special_position = false;
      }

      let indexChair = _.findIndex(lstDelegateContents, (item: any) => {
        return item.user_id == this.model.user_chair_id;
      });

      if (indexChair == -1) {
        lstDelegateContents.unshift({
          user_id: this.model.user_chair_id,
          full_name: this.model.user_chair_name,
          is_chair: true,
          is_special_position: true,
          status: 31
        });
      } else {
        lstDelegateContents[indexChair].is_special_position = true;
      }
    }
    var lstDelegateWaitings = _.filter(lstDelegateContents, (item: any) => {
      // return lstDelegateSeats.indexOf(item.user_id) == -1;
      return ((item.is_guest == false || !item.is_guest) && lstDelegateSeats.indexOf(item.user_id) == -1) || (item.is_guest == true && lstDelegateGuestSeats.indexOf(item.full_name) == -1);
    });

    if (lstDelegateWaitings.length > 0) {
      let x = 0;
      let y = rowWaiting;
      this.lstWaitingPositions = [];
      let maxWaitingY = 0;
      let distance = rowWaiting - this.seatsMapHeight - this.seatsMapStartY;
      this.truncateDistance = distance;
      for (let i = 0; i < lstDelegateWaitings.length; i++) {
        x += 1;
        if(maxWaitingY <= y) {
          maxWaitingY = y;
        }
        if(i % (this.seatsMapWidth ) == 0){
          x = 0;
          y += 1;
        }
        this.lstWaitingPositions.push({
          meeting_id: this.model.id,
          content_id: this.searchContentTabRoom,
          table_number: maxChairNumber + i,
          y: y,
          x: x,
          user_id: lstDelegateWaitings[i].user_id,
          full_name: lstDelegateWaitings[i].full_name,
          is_chair: lstDelegateWaitings[i].is_chair ? lstDelegateWaitings[i].is_chair : false,
          // is_special_position: lstDelegateWaitings[i].is_special_position ? lstDelegateWaitings[i].is_special_position : false,
          is_special_position: lstDelegateWaitings[i].is_special_position ? lstDelegateWaitings[i].is_special_position : (lstDelegateWaitings[i].is_same_chair ? true : false),
          is_guest: lstDelegateWaitings[i].is_guest ? lstDelegateWaitings[i].is_guest : false,
          meeting_status: lstDelegateWaitings[i].status == 31 ? 2 : (lstDelegateWaitings[i].status == 32 ? 3 : 1)
        });
      }
      this.seatsMapHeight += maxWaitingY - this.seatsMapHeight - this.seatsMapStartY;
    } else {
      this.lstWaitingPositions = [];
    }

    // Lấy danh sách ghế trống
    this.lstBlankSeats = _.filter(this.lstRoomPositions, (item: any) => {
      return !item.user_id;
    })
  }
  // Chọn ghế
  choosePositionItem = (event, data) => {
    event.preventDefault();
    if (this.flagState == 'view' || (!this.flagSecretary && !this.flagFollow)) {
      return;
    }
    this.dataItem = data;
    this.flagShowItem = true;
  }

  chooseSeat = (waitingItem, blankSeatItem) => {
    let objRoom = _.find(this.lstRoomPositions, (item) => {
      return item.table_number == blankSeatItem.table_number;
    });

    if (objRoom) {
      objRoom.user_id = waitingItem.user_id;
      objRoom.full_name = waitingItem.full_name;
      objRoom.meeting_status = waitingItem.meeting_status;
      objRoom.is_chair = waitingItem.is_chair;
      objRoom.is_special_position = waitingItem.is_special_position;
      objRoom.is_guest = waitingItem.is_guest;
    }

    this.getWaitingPosition();
    this.dataItem = null;
    this.flagShowItem = false;
  }

  // Chọn đại biểu
  chooseDelegateItem = (event, data) => {
    event.preventDefault();
    if (this.flagState == 'view' || (!this.flagSecretary && !this.flagFollow)) {
      return;
    }
    if (data.user_id) {
      return;
    }
    this.dataItem = data;
    this.flagShowItem = true;
  }

  chooseDelegate = (blankSeatItem, waitingItem) => {
    blankSeatItem.user_id = waitingItem.user_id;
    blankSeatItem.full_name = waitingItem.full_name;
    blankSeatItem.meeting_status = waitingItem.meeting_status;
    blankSeatItem.is_special_position = waitingItem.is_special_position ? waitingItem.is_special_position : false;
    blankSeatItem.is_guest = waitingItem.is_guest ? waitingItem.is_guest : false;
    this.getWaitingPosition();
    this.dataItem = null;
    this.flagShowItem = false;
  }

  clearDelegateItem = (it) => {
    let index = _.findIndex(this.lstRoomPositions, (item: any) => {
      return item.table_number == it.table_number;
    });

    if (index > -1) {
      this.lstRoomPositions[index].user_id = null;
      this.lstRoomPositions[index].full_name = null;
      this.lstRoomPositions[index].status = null;
      this.lstRoomPositions[index].meeting_status = null;
      this.lstRoomPositions[index].is_special_position = false;
      this.lstRoomPositions[index].is_guest = false;
    }
    this.getWaitingPosition();
  }

  // Lưu sơ đồ phòng
  saveRoomPosition = () => {
    this._coreService.Post("/meetingposition/saveRoomPosition", {
      list_items: this.lstRoomPositions
    }).subscribe((res) => {
      if (res && res.code && res.code == "200") {
        this._socketService.sendEmit("update-position", {
          room: this.model.id,
          data: this.lstRoomPositions
        })
        this.getDataPosition().then(() => {
          this.getWaitingPosition();
        });
        this.notification.success("Lưu sơ đồ phòng thành công!");
      } else {
        this.notification.warning("Lưu sơ đồ không phòng thành công!");
      }
    })
  }

  // Làm mới sơ đồ phòng
  renewRoomPostion = () => {
    for (let i = 0; i < this.lstRoomPositions.length; i++) {
      this.lstRoomPositions[i].user_id = null;
      this.lstRoomPositions[i].full_name = null;
      this.lstRoomPositions[i].meeting_status = null;
      this.lstRoomPositions[i].is_guest = false;
      this.lstRoomPositions[i].is_special_position = false;
    }
    this._coreService.Post("/meetingposition/saveRoomPosition", {
      list_items: this.lstRoomPositions
    }).subscribe((res) => {
      if (res && res.code && res.code == "200") {
        this.notification.success("Làm mới sơ đồ phòng thành công!");
        this.getDataPosition().then(() => {
          this.getWaitingPosition();
        });
        this._socketService.sendEmit("update-position", {
          room: this.model.id
        });
      } else {
        this.notification.warning("Làm mới sơ đồ không phòng thành công!");
      }
    })
  }

  // Tự động xếp ghế
  autoSaveRoomPosition = () => {
    let lstDelegateContents = [];
    if (this.searchContentTabRoom) {
      let objContent = _.find(this.lstContents, (item: any) => {
        return item.id == this.searchContentTabRoom;
      });

      if (objContent && objContent.list_members) {
        lstDelegateContents = _.cloneDeep(objContent.list_members);
        if (objContent.list_guests && objContent.list_guests.length > 0) {
          let lstGuestContents = [];

          for (let i = 0; i < objContent.list_guests.length; i++) {
            lstGuestContents.push({
              user_id: objContent.list_guests[i].id,
              is_guest: true,
              is_chair: false,
              is_special_position: false,
              full_name: objContent.list_guests[i].full_name,
              status: objContent.list_guests[i].attendance_status ? 31 : 32
            })
          }
          lstDelegateContents = lstDelegateContents.concat(lstGuestContents);
        }
      }

      if (this.model.user_follow_id) {
        let indexFollow = _.findIndex(lstDelegateContents, (item: any) => {
          return item.user_id == this.model.user_follow_id;
        });

        if (indexFollow == -1) {
          lstDelegateContents.unshift({
            user_id: this.model.user_follow_id,
            full_name: this.model.user_follow_name,
            meeting_status: 2,
            is_chair: true,
            is_special_position: false,
            is_guest: false
          });
        } else {
          lstDelegateContents[indexFollow].is_guest = false;
          lstDelegateContents[indexFollow].is_special_position = false;
        }
      }

      let indexSecretary = _.findIndex(lstDelegateContents, (item: any) => {
        return item.user_id == this.model.user_secretary_id;
      });

      if (indexSecretary == -1) {
        lstDelegateContents.unshift({
          user_id: this.model.user_secretary_id,
          full_name: this.model.user_secretary_name,
          meeting_status: 2,
          is_chair: true,
          is_special_position: true,
          is_guest: false
        });
      } else {
        lstDelegateContents[indexSecretary].is_guest = false;
        lstDelegateContents[indexSecretary].is_special_position = false;
      }

      let indexChair = _.findIndex(lstDelegateContents, (item: any) => {
        return item.user_id == this.model.user_chair_id;
      });

      if (indexChair == -1) {
        lstDelegateContents.unshift({
          user_id: this.model.user_chair_id,
          full_name: this.model.user_chair_name,
          meeting_status: 2,
          is_chair: true,
          is_special_position: true,
          is_guest: false
        });
      } else {
        lstDelegateContents[indexChair].is_guest = false;
        lstDelegateContents[indexChair].is_special_position = true;
      }
    }

    for (let i = 0; i < this.lstRoomPositions.length; i++) {
      this.lstRoomPositions[i].user_id = null;
      this.lstRoomPositions[i].full_name = null;
      this.lstRoomPositions[i].meeting_status = null;
      if (this.lstRoomPositions.length <= lstDelegateContents.length) {
        this.lstRoomPositions[i].user_id = lstDelegateContents[i].user_id;
        this.lstRoomPositions[i].full_name = lstDelegateContents[i].full_name;
        this.lstRoomPositions[i].meeting_status = lstDelegateContents[i].meeting_status;
        this.lstRoomPositions[i].is_special_position = lstDelegateContents[i].is_special_position;
        this.lstRoomPositions[i].is_guest = lstDelegateContents[i].is_guest;
      }
    }

    if (this.lstRoomPositions.length > lstDelegateContents.length) {
      for (let i = 0; i < lstDelegateContents.length; i++) {
        this.lstRoomPositions[i].user_id = lstDelegateContents[i].user_id;
        this.lstRoomPositions[i].full_name = lstDelegateContents[i].full_name;
        this.lstRoomPositions[i].meeting_status = lstDelegateContents[i].meeting_status || 1;
        this.lstRoomPositions[i].is_special_position = lstDelegateContents[i].is_special_position;
        this.lstRoomPositions[i].is_guest = lstDelegateContents[i].is_guest;
      }
    }

    this._coreService.Post("/meetingposition/saveRoomPosition", {
      list_items: this.lstRoomPositions
    }).subscribe((res) => {
      if (res && res.code && res.code == "200") {
        this.notification.success("Tự động xếp ghế thành công!");
        this.lstWaitingPositions = [];
        this.getDataPosition().then(() => {
          this.getWaitingPosition();
        });
        this._socketService.sendEmit("update-position", {
          room: this.model.id
        });
        this.flagShowItem = false
      } else {
        this.notification.warning("Tự động xếp ghế không thành công!");
      }
    })
  }
  // Lấy dữ liệu đại biểu, khách mời
  getContentMember = () => {
    this._coreService.Get("/meeting/detailHappening/" + this.model.id).subscribe((res) => {
      if (res && res.code && res.code == "200") {
        let data = res.data;
        // data.list_contents = _.cloneDeep(this.lstContents1);
        let dataDelegate = data.list_delegates
        this.lstContents = [];
        setTimeout(() => {
          for (let i = 0; i < data.list_contents.length; i++) {
            for (let j = 0; j < data.list_contents[i].list_members.length; j++) {
              data.list_contents[i].list_members[j].user_type_name = "Đại biểu";
            }

            for (let k = 0; k < data.list_contents[i].list_guests.length; k++) {
              data.list_contents[i].list_guests[k].user_type_name = "Khách mời";
            }

            let lstMembers = _.filter(data.list_contents[i].list_members, (item: any) => {
              return (1 == 1);
              // return item.attendance_status == true;
            });

            let lstGuests = _.filter(data.list_contents[i].list_guests, (item: any) => {
              return (1 == 1);
              // return item.attendance_status == true;
            })
            data.list_contents[i].list_member_summarys = lstMembers.concat(lstGuests);
          }
          this.lstContents = data.list_contents;

          this.lstContentAttendances = _.filter(this.lstContents, (item) => {
            return item.content !== "Khai mạc" && item.content !== "Bế mạc";
          });

          this.lstContentTabComments = [];
          for (let i = 0; i < this.lstContents.length; i++) {
            this.lstContents[i].list_documents = _.sortBy(this.lstContents[i].list_documents, "orders");

            this.lstContentTabComments.push(this.lstContents[i]);

            if (this.lstContents[i].content_childs && this.lstContents[i].content_childs.length > 0) {
              for (let j = 0; j < this.lstContents[i].content_childs.length; j++) {
                this.lstContents[i].content_childs[j].is_child = true;
                // this.lstContentTabComments.push(this.lstContents[i].content_childs[j]);
                const item = _.cloneDeep(this.lstContents[i].content_childs[j]);
                item.content = " - " + this.lstContents[i].content_childs[j].content
                this.lstContentTabComments.push(item);
              }
            }
          }

          if (this.contentMemberSummaryGrid) {
            this.contentMemberSummaryGrid.refresh();
          }

          if (this.contentMemberGrid) {
            this.contentMemberGrid.refresh();
          }
        });
      }
    })
  }

  // Cập nhật dòng đại biểu
  updateMemberRow = (rowIndex, model, data, contentData) => {
    let objContent = _.find(this.lstContents, (item: any) => {
      return item.id == contentData.id;
    });

    if (objContent) {
      setTimeout(() => {

        var objUpdate = _.find(objContent.list_members, (item: any) => {
          return item.id == data.id;
        });

        if (objUpdate) {
          objUpdate[model] = data[model];
        }
      }, 100);
    }

  }

  // Cập nhật dòng khách mời
  updateGuestRow = (rowIndex, model, data, contentData) => {
    let objContent = _.find(this.lstContents, (item: any) => {
      return item.id == contentData.id;
    });

    if (objContent) {
      setTimeout(() => {

        var objUpdate = _.find(objContent.list_guests, (item: any) => {
          return item.id == data.id;
        });

        if (objUpdate) {
          objUpdate[model] = data[model];
        }
      }, 100);
    }

  }
  // Điểm danh tất cả đại biểu
  testdisable: boolean = false;

  // Vĩnh phúc thì thêm [expanded]="testdisable" vào <e-accordionitem *ngFor="let item of lstContentAttendances">  delegateItemTab
  attendanceAllMember = () => {
    this.testdisable = true
    this._coreService.Post("/meetingcontent/updateAllMemberAttandance", {
      meeting_id: this.model.id
    }).subscribe((res) => {
      if (res && res.code && res.code === "200") {
        this.notification.success("Điểm danh thành công!");
        // this.getMeetingInfo().then(() => {
        //   this.getContentMember();
        // });
        this.tabActive = "";
        setTimeout(() => {
          this.getContentMember();
          // this.getListContent(this.paramId);
          this.testdisable = false
          this._socketService.sendEmit("update-content", {
            room: this.model.id
          });
          this.tabActive = "delegateTab";
        }, 500);

      } else {
        this.notification.warning("Điểm danh không thành công!");
      }
    })
  }

  // Điểm danh tất cả đại biểu nội dung
  attendanceAllMemberContent = (contentData) => {
    let objContent = _.find(this.lstContents, (item: any) => {
      return item.id == contentData.id;
    });
    if (contentData) {
      this._coreService.Post("/meetingcontent/updateAllMemberAttandanceContent", {
        content_id: contentData.id
      }).subscribe((res) => {
        if (res && res.code && res.code === "200") {
          this.notification.success("Điểm danh thành công!");
          this.getContentMember();
          // this.getListContent(this.paramId);
          this.tabActive = "";
          setTimeout(() => {
            this.tabActive = "delegateTab";
          }, 500);
          this._socketService.sendEmit("update-content", {
            room: this.model.id
          });
        } else {
          this.notification.warning("Điểm danh không thành công!");
        }
      })
    } else {
      this.notification.warning("Không tìm thấy nội dung!");
    }

  }

  // Ghi nhận Điểm danh đại biểu
  saveMemberAttendance = (contentData) => {
    this._configService.loadingSubject.next("true");
    this.testdisable = false;

    let objContent = _.find(this.lstContents, (item: any) => {
      return item.id == contentData.id;
    });
    if (contentData) {
      this._coreService.Post("/meetingcontent/updateMemberAttendance", {
        content_id: contentData.id,
        list_members: contentData.list_members
      }).subscribe((res) => {
        if (res && res.code && res.code === "200") {
          this.notification.success("Ghi nhận điểm danh thành công!");
          // this.getMeetingInfo().then(() => {
          //   this.getContentMember();
          // });
          this.tabActive = "";
        setTimeout(() => {
          this.getContentMember();
          // this.getListContent(this.paramId);
          // this.getMeetingInfo().then(() => {
          //   });
          this.tabActive = "delegateTab";
          this._socketService.sendEmit("update-content", {
            room: this.model.id,
          });
        }, 500);
        this.testdisable = false
    this._configService.loadingSubject.next("false");
        } else {
          this.notification.warning("Ghi nhận điểm danh không thành công!");
        }
      })
    } else {
      this.notification.warning("Không tìm thấy nội dung!");
    }

  }

  // Ghi nhận Điểm danh khách mời
  saveGuestAttendance = (contentData) => {
    let objContent = _.find(this.lstContents, (item: any) => {
      return item.id == contentData.id;
    });

    if (objContent) {
      this._coreService.Post("/meetingcontent/updateGuestAttendance", {
        content_id: objContent.id,
        list_guests: objContent.list_guests
      }).subscribe((res) => {
        if (res && res.code && res.code === "200") {
          this.notification.success("Ghi nhận điểm danh thành công!");
          this.getMeetingInfo().then(() => {
            this.getContentMember();
            this.tabActive = "";
            setTimeout(() => {
              this.tabActive = "delegateTab";
            }, 500);
          });
        } else {
          this.notification.warning("Ghi nhận điểm danh không thành công!");
        }
      })
    } else {
      this.notification.warning("Không tìm thấy nội dung!");
    }

  }

  // Điểm danh tất cả đại biểu nội dung
  attendanceAllGuestContent = (contentData) => {
    let objContent = _.find(this.lstContents, (item: any) => {
      return item.id == contentData.id;
    });
    if (objContent) {
      this._coreService.Post("/meetingcontent/updateAllGuestAttandanceContent", {
        content_id: objContent.id
      }).subscribe((res) => {
        if (res && res.code && res.code === "200") {
          this.notification.success("Điểm danh thành công!");
          this.getMeetingInfo().then(() => {
            this.getContentMember();
          });
          this.tabActive = "";
          setTimeout(() => {
            this.tabActive = "delegateTab";
          }, 500);
          this._socketService.sendEmit("update-content", {
            room: this.model.id
          });
        } else {
          this.notification.warning("Điểm danh không thành công!");
        }
      })
    } else {
      this.notification.warning("Không tìm thấy nội dung!");
    }

  }


  // Thêm nhanh đại biểu
  quickAddMemberContent = (contentData) => {
    let objContent = _.find(this.lstContents, (item: any) => {
      return item.id == contentData.id;
    });

    this.lstContentDelegates = [];

    if (objContent) {
      this.dataContentUpdateMember = objContent;
      this.userIds = [];
      if (objContent.list_members && objContent.list_members.length > 0) {
        for (let i = 0; i < objContent.list_members.length; i++) {
          this.userIds.push({
            id: objContent.list_members[i].user_id?objContent.list_members[i].user_id:objContent.list_members[i].id,
            full_name: objContent.list_members[i].full_name,
            title_name: objContent.list_members[i].title_name,
            org_name: objContent.list_members[i].org_name
          })
        }
      }
            this.modalService.open("add-delegate-modal");
    }
  }

  // Thêm nhanh khách mời
  quickAddGuestContent = (contentData) => {
    let objContent = _.find(this.lstContents, (item: any) => {
      return item.id == contentData.id;
    });

    this.lstContentGuests = [];

    if (objContent) {
      this.dataContentUpdateMember = objContent;
      this.lstContentGuests = _.cloneDeep(this.dataContentUpdateMember.list_guests ? this.dataContentUpdateMember.list_guests : []);
      this.contentGuestModalGrid.dataSource = new DataManager(this.lstContentGuests);

      this.modalService.open("add-content-guest-modal");
    }
  }

  // Thêm khách mời
  addGuestModal = () => {
    this.lstContentGuests.push({
      id: uuidv4(),
      full_name: "",
      phone: "",
      email: "",
      org_name: ""
    });

    this.contentGuestModalGrid.dataSource = new DataManager(this.lstContentGuests);
  }

  // Cập nhật dữ liệu modal khách mời
  updateGuestModalRow = (index, model, dataColumn) => {
    setTimeout(() => {
      this.lstContentGuests[index][model] = dataColumn[model];
    }, 50);
  }

  // Lưu thêm đại biểu
  saveContentMemberModal = (status) => {
    if (status === "cancel") {
      this.modalService.close("add-content-member-modal");
    } else {
      let selectRecords: any = this.contentMemberModalGrid.getSelectedRecords();

      if (selectRecords.length > 0) {
        let list_members = [];
        for (let i = 0; i < selectRecords.length; i++) {
          list_members.push({
            user_id: selectRecords[i].user_id
          });
        }

        this._coreService.Post("/meetingcontent/addMemberContent", {
          content_id: this.dataContentUpdateMember.id,
          list_members: list_members
        }).subscribe((res) => {
          if (res && res.code && res.code === "200") {
            this.notification.success("Thêm đại biểu vào nội dung thành công!");
            this.getContentMember();
            this.tabActive = "";
            setTimeout(() => {
              this.tabActive = "delegateTab";
            }, 500);
          } else {
            this.notification.warning("Thêm đại biểu vào nội dung không thành công!");
          }
          this.modalService.close("add-content-member-modal");
        })
      }

    }
  }
  // Lưu thêm đại biểu
  saveContentGuestModal = (status) => {
    if (status === "cancel") {
      this.modalService.close("add-content-guest-modal");
    } else {
      let list_guests = [];
      for (let i = 0; i < this.lstContentGuests.length; i++) {
        if (!this.lstContentGuests[i].full_name) {
          this.notification.warning("Họ và tên chưa được nhập tại dòng " + (i + 1) + "!");
          return;
        }
        const validMobile = this.globals.checkMobile(this.lstContentGuests[i].phone);
        if (!validMobile) {
          this.notification.warning("Số điện thoại không đúng định dạng tại dòng " + (i + 1) + "!");
          return;
        }
        const validEmail = this.globals.validateEmail(this.lstContentGuests[i].email);
        if (!validEmail) {
          this.notification.warning("Email không đúng định dạng tại dòng " + (i + 1) + "!");
          return;
        }
        if (this.lstContentGuests[i].full_name && this.lstContentGuests[i].phone) {
          list_guests.push({
            full_name: this.lstContentGuests[i].full_name,
            phone: this.lstContentGuests[i].phone,
            email: this.lstContentGuests[i].email,
            org_name: this.lstContentGuests[i].org_name
          });
        }
      }

      this._coreService.Post("/meetingcontent/addGuestContent", {
        content_id: this.dataContentUpdateMember.id,
        list_guests: list_guests
      }).subscribe((res) => {
        if (res && res.code && res.code === "200") {
          this.notification.success("Thêm đại biểu vào nội dung thành công!");
          this.getContentMember();
          this.tabActive = "";
          setTimeout(() => {
            this.tabActive = "delegateTab";
          }, 500);

        } else {
          this.notification.warning("Thêm đại biểu vào nội dung không thành công!");
        }
        this.modalService.close("add-content-guest-modal");
      });
      this.modalService.close("add-content-guest-modal");

    }
  }

  deleteRecordGuestModal = (data) => {
    const index = data.index;

    this.lstContentGuests.splice(index, 1);

    // Cập nhật lại dữ liệu hiển thị trong <ejs-grid>
    this.contentGuestModalGrid.dataSource = new DataManager(this.lstContentGuests);
    this.delegateGrid1.dataSource = new DataManager(this.lstContentGuests);
  };

  // Danh sách biểu quyết
  getListVoting = () => {
    this._coreService.Get("/meetingvoting/getList/" + this.model.id).subscribe((res) => {
      if (res && res.code && res.code === "200") {
        let lstVotings = res.data && res.data.data ? res.data.data : [];
        for (let i = 0; i < lstVotings.length; i++) {
          let lstDatas = lstVotings[i].data_voting ? lstVotings[i].data_voting.split("||") : [];

          let lstDataVotings = [];
          if (lstDatas.length > 0) {
            for (let j = 0; j < lstDatas.length; j++) {
              let objVoting = lstDatas[j].indexOf("??") > -1 ? lstDatas[j].split("??") : [];
              if (objVoting.length > 0) {
                lstDataVotings.push({
                  user_id: objVoting[0],
                  voting: objVoting[1]
                });
              }
            }
          }

          var total_accept = _.filter(lstDataVotings, (item: any) => {
            return item.voting === "1";
          }).length;

          var total_denied = _.filter(lstDataVotings, (item: any) => {
            return item.voting === "2";
          }).length;

          var total_not_join = _.filter(lstDataVotings, (item: any) => {
            return item.voting === "3";
          }).length;

          lstVotings[i].total_accept = total_accept || 0;
          lstVotings[i].total_denied = total_denied || 0;
          lstVotings[i].total_not_join = total_not_join || 0;
          lstVotings[i].total_join = total_accept + total_denied + total_not_join || 0;

          lstVotings[i].percent_join = Math.round(lstVotings[i].total_join * 100 / lstVotings[i].total_users) || 0;
          lstVotings[i].percent_accept = Math.round(lstVotings[i].total_accept * 100 / lstVotings[i].total_users) || 0;
          lstVotings[i].percent_denied = Math.round(lstVotings[i].total_denied * 100 / lstVotings[i].total_users) || 0;
          lstVotings[i].percent_not_join = Math.round(lstVotings[i].total_not_join * 100 / lstVotings[i].total_users) || 0;
        }

        this.lstVotings = lstVotings;

        if (this.votingGrid) {
          this.votingGrid.dataSource = new DataManager(this.lstVotings);
        }
      }
    })
  }

  // Thêm biểu quyết
  addVoting = () => {
    this.modelVoting = new MeetingVoting();
    this.modelVoting.content_id = null;
    this.flagStateModal = "new";
    this.votingForm.enable();
    this.votingMemberGrid.refreshColumns();
    this.votingForm.markAsUntouched();
    this.lstDelegateVotings = [];
    this.modalService.open("add-voting-modal");
    this.votingMemberGrid.showColumns(["Chọn"]);


    this.votingMemberGrid.allowSelection = true;
    this.votingMemberGrid.refreshColumns();
  }

  // Sự kiện load danh sách đại biểu thuộc nội dung
  changeVotingContent = (event: any) => {
    if (event.isInteracted) {
      setTimeout(() => {
        if (this.modelVoting.content_id) {
          let index = _.findIndex(this.lstContents, (item) => {
            return item.id == this.modelVoting.content_id;
          });

          if (index > -1) {
            this.lstDelegateVotings = this.lstContents[index].list_members;
            let rowSelects = [];
            for (let i = 0; i < this.lstDelegateVotings.length; i++) {
              rowSelects.push(i);
            }
            this.votingMemberGrid.dataSource = new DataManager(this.lstDelegateVotings);

            setTimeout(() => {
              this.votingMemberGrid.selectRows(rowSelects);
            }, 50);
          }
        }
      })
    }
  }

  // Click Record Voting
  clickVotingRecord = (data, status) => {
    if (status === "view") {
      this.flagStateModal = "view";
      this._coreService.Get("/meetingvoting/detail/" + data.id).subscribe((res) => {
        if (res && res.code && res.code === "200") {
          this.modelVoting = res.data;
          this.votingForm.disable();
          let ids = _.map(this.modelVoting.list_items, "user_id");
          var indexs = [];
          this.lstDelegateVotings = _.filter(this.lstDelegates, (item) => {
            return ids.indexOf(item.user_id) > -1;
          });

          this.modalService.open("add-voting-modal");
          setTimeout(() => {
            this.votingMemberGrid.hideColumns(["Chọn"]);
            this.votingMemberGrid.allowSelection = false;
            this.votingMemberGrid.refreshColumns();
          }, 100);
        }
      })
    }

    if (status === "edit") {
      this.flagStateModal = "edit";
      this._coreService.Get("/meetingvoting/detail/" + data.id).subscribe((res) => {
        if (res && res.code && res.code === "200") {
          this.modelVoting = res.data;
          this.votingForm.enable();
          let ids = _.map(this.modelVoting.list_items, "user_id");
          var indexs = [];
          let index = _.findIndex(this.lstContents, (item) => {
            return item.id == this.modelVoting.content_id;
          });

          if (index > -1) {
            this.lstDelegateVotings = this.lstContents[index].list_members;
            this.votingMemberGrid.dataSource = new DataManager(this.lstDelegateVotings);
          }

          let lstVotingMembers = this.votingMemberGrid.currentViewData["records"]

          for (let i = 0; i < ids.length; i++) {
            let index = _.findIndex(lstVotingMembers, (item: any) => {
              return item.user_id == ids[i];
            });

            if (index > -1) {
              indexs.push(index);
            }
          }

          this.modalService.open("add-voting-modal");
          setTimeout(() => {
            if (indexs.length > 0) {
              this.votingMemberGrid.showColumns(["Chọn"]);
              this.votingMemberGrid.allowSelection = true;
              setTimeout(() => {
                this.votingMemberGrid.selectRows(indexs);
              }, 100);
            }
          }, 10);
        }
      })
    }

    if (status === "viewResult") {
      this.modelVoting = data;

      this.modalService.open("view-result-voting-modal");
    }

    if (status === "start") {
      let index = _.findIndex(this.lstVotings, (item: any) => {
        return item.status == 53;
      });

      if (index > -1) {
        return this.notification.warning("Đang có 1 cuộc biểu quyết khác đang diễn ra");
      }
      this.modelRealVoting = data;
      this.countdownVoting = 0;

      this.modalService.open("confirm-start-voting-modal");
    }

    if (status === "delete") {
      this.modelVoting = data;

      this.modalService.open("confirm-delete-voting-modal");
    }
  }

  // Lưu biểu quyết
  saveVoting(status) {
    if (status == "cancel") {
      this.modelVoting = new MeetingVoting();
      this.modelVoting.content_id = null;
      this.flagStateModal = "new";

      this.modalService.close("add-voting-modal");
    } else {
      let selectMembers: any = this.votingMemberGrid.getSelectedRecords();

      if (this.votingForm.invalid || selectMembers.length == 0) {
        this.notification.warning("Bạn chưa nhập đủ thông tin tạo biểu quyết")
        return;
      }

      let list_items = [];
      for (let i = 0; i < selectMembers.length; i++) {
        list_items.push({
          user_id: selectMembers[i].user_id
        })
      }

      this.modelVoting.list_items = list_items;
      this.modelVoting.meeting_id = this.model.id;
      let url = "/meetingvoting/create";
      if (this.flagStateModal == "edit") {
        url = "/meetingvoting/update";
      }

      this._coreService.Post(url, this.modelVoting).subscribe((res) => {
        if (res && res.code && res.code == "200") {
          this.notification.success("Cập nhật biểu quyết thành công!");
          this.getListVoting();
          this.modelVoting = new MeetingVoting();
          this.modelVoting.content_id = null;
          this.flagStateModal = "new";

          this._socketService.sendEmit("update-voting", {
            room: this.model.id
          });

          this.modalService.close("add-voting-modal");
        } else {
          this.notification.warning("Cập nhật biểu quyết không thành công!");
        }
      })
    }
  }

  // Bắt đầu biểu quyết
  confirmStartVoting = (status) => {
    if (status == "cancel") {
      this.modalService.close("confirm-start-voting-modal");
    } else {
      this._coreService.Post("/meetingvoting/start", {
        id: this.modelRealVoting.id
      }).subscribe((res) => {
        if (res && res.code && res.code == "200") {
          if (this.setIntervalVoting) {
            clearInterval(this.setIntervalVoting);
          }
          this.countdownVoting = this.modelRealVoting.minutes * 60 + 1;
          // tạo count down
          this.setIntervalVoting = setInterval(() => {
            if (this.countdownVoting > 0) {
              this.countdownVoting--;
              this.minuteVoting = Math.floor(this.countdownVoting / 60);
              this.sencondVoting = this.countdownVoting - this.minuteVoting * 60;
              this.minuteVotingDisplay = this.minuteVoting.toString().padStart(2, "0");
              this.secondVotingDisplay = this.sencondVoting.toString().padStart(2, "0");
            } else {
              this.minuteVoting = 0;
              this.sencondVoting = 0;
              this.minuteVotingDisplay = "00";
              this.secondVotingDisplay = "00";
              clearInterval(this.setIntervalVoting);
              this.endVoting();
            }
            this._socketService.sendEmit("update-voting", {
              room: this.model.id,
              data: this.modelRealVoting,
              countdownVoting: this.countdownVoting,
              minuteVotingDisplay: this.minuteVotingDisplay,
              secondVotingDisplay: this.secondVotingDisplay,
              isEndVoting: this.countdownVoting == 0 ? true : false
            });

          }, 1000);
          this._socketService.sendEmit("update-voting", {
            room: this.model.id
          });
          // Mở modal theo dõi - Thư ký
          // this.flagIsVoting = true;
          this.modalService.open("realtime-voting-modal");

          // Socket bắt đầu biểu quyết
          this.modelRealVoting.status = 53;
          this._socketService.sendEmit("start-voting", {
            room: this.model.id,
            data: this.modelRealVoting,
            countdownVoting: this.countdownVoting,
            minuteVotingDisplay: this.minuteVotingDisplay,
            secondVotingDisplay: this.secondVotingDisplay,
            isEndVoting: this.countdownVoting == 0 ? true : false

          });
          this.flagCreateVoting = true;
          this.notification.success("Bắt đầu biểu quyết thành công!");
          this.getListVoting();

          this.modalService.close("confirm-start-voting-modal");
        } else {
          this.notification.warning("Bắt đầu biểu quyết không thành công!");
        }
      })
    }
  }

  // Xóa biểu quyết
  confirmDeleteVoting = (status) => {
    if (status == "cancel") {
      this.modalService.close("confirm-delete-voting-modal");
    } else {
      this._coreService.Post("/meetingvoting/delete", {
        id: this.modelVoting.id
      }).subscribe((res) => {
        if (res && res.code && res.code == "200") {
          this.notification.success("Xóa biểu quyết thành công!");
          this.getListVoting();

          this.modalService.close("confirm-delete-voting-modal");
        } else {
          this.notification.warning("Xóa biểu quyết không thành công!");
        }
      })
    }
  }

  // Lấy kết quả biểu quyết đang diễn ra
  getDataResultVoting = (meeting_voting_id) => {
    return new Promise((resolve, reject) => {
      this._coreService.Get('/meetingvoting/detail/' + meeting_voting_id).subscribe((res) => {
        if (res && res.code && res.code == "200") {
          this.modelRealVoting = res.data;
          let lstDatas = this.modelRealVoting.data_voting ? this.modelRealVoting.data_voting.split("||") : [];

          let lstDataVotings = [];
          if (lstDatas.length > 0) {
            for (let j = 0; j < lstDatas.length; j++) {
              let objVoting = lstDatas[j].indexOf("??") > -1 ? lstDatas[j].split("??") : [];
              if (objVoting.length > 0) {
                lstDataVotings.push({
                  user_id: objVoting[0],
                  voting: objVoting[1]
                });
              }
            }
          }

          var total_accept = _.filter(lstDataVotings, (item: any) => {
            return item.voting === "1";
          }).length;

          var total_denied = _.filter(lstDataVotings, (item: any) => {
            return item.voting === "2";
          }).length;

          var total_not_join = _.filter(lstDataVotings, (item: any) => {
            return item.voting === "3";
          }).length;

          this.modelRealVoting.total_accept = total_accept || 0;
          this.modelRealVoting.total_denied = total_denied || 0;
          this.modelRealVoting.total_not_join = total_not_join || 0;
          this.modelRealVoting.total_join = total_accept + total_denied + total_not_join || 0;

          this.modelRealVoting.percent_join = Math.round(this.modelRealVoting.total_join * 100 / this.modelRealVoting.total_users) || 0;
          this.modelRealVoting.percent_accept = Math.round(this.modelRealVoting.total_accept * 100 / this.modelRealVoting.total_users) || 0;
          this.modelRealVoting.percent_denied = Math.round(this.modelRealVoting.total_denied * 100 / this.modelRealVoting.total_users) || 0;
          this.modelRealVoting.percent_not_join = Math.round(this.modelRealVoting.total_not_join * 100 / this.modelRealVoting.total_users) || 0;

        }
        resolve(true);
      })
    })
  }

  // Kết thúc biểu quyết
  endVoting = () => {
    this._coreService.Post("/meetingvoting/end", {
      id: this.modelRealVoting.id
    }).subscribe((res) => {
      if (res && res.code && res.code == "200") {
        this.modelRealVoting.status = 54;
        this.notification.success("Biểu quyết đã kết thúc!");
        this.getListVoting();
        // Socket bắt đầu biểu quyết
        this._socketService.sendEmit("end-voting", {
          room: this.model.id,
          data: this.modelRealVoting
        });
        this.flagCreateVoting = false;
      }
    })
  }

  // Biểu quyết
  voting = (status) => {
    if (this.countdownVoting == 0) {
      this.notification.warning("Đã hết thời gian biểu quyết!");
    } else if (this.flagIsVoting) {
      this.notification.warning("Bạn đã tiến hành biểu quyết!");
    } else {
      this._coreService.Post("/meetingvoting/voting", {
        meeting_voting_id: this.modelRealVoting.id,
        voting: status
      }).subscribe((res) => {
        if (res && res.code && res.code == "200") {
          this.notification.success("Biểu quyết thành công!");
          this.flagIsVoting = true;
          this.getListVoting();
          this.getDataResultVoting(this.modelRealVoting.id);

        } else {
          this.notification.warning("Biểu quyết thành công!");
        }
      })
    }
  }

  // Xem kết quả từ modal
  viewVotingResult = () => {
    this.modalService.close("realtime-voting-modal");

    this._coreService.Get('/meetingvoting/detail/' + this.modelRealVoting.id).subscribe((res) => {
      if (res && res.code && res.code == "200") {
        this.modelVoting = res.data;
        let lstDatas = this.modelVoting.data_voting ? this.modelVoting.data_voting.split("||") : [];

        let lstDataVotings = [];
        if (lstDatas.length > 0) {
          for (let j = 0; j < lstDatas.length; j++) {
            let objVoting = lstDatas[j].indexOf("??") > -1 ? lstDatas[j].split("??") : [];
            if (objVoting.length > 0) {
              lstDataVotings.push({
                user_id: objVoting[0],
                voting: objVoting[1]
              });
            }
          }
        }

        var total_accept = _.filter(lstDataVotings, (item: any) => {
          return item.voting === "1";
        }).length;

        var total_denied = _.filter(lstDataVotings, (item: any) => {
          return item.voting === "2";
        }).length;

        var total_not_join = _.filter(lstDataVotings, (item: any) => {
          return item.voting === "3";
        }).length;

        this.modelVoting.total_accept = total_accept || 0;
        this.modelVoting.total_denied = total_denied || 0;
        this.modelVoting.total_not_join = total_not_join || 0;
        this.modelVoting.total_join = total_accept + total_denied + total_not_join || 0;

        this.modelVoting.percent_join = Math.round(this.modelVoting.total_join * 100 / this.modelVoting.total_users) || 0;
        this.modelVoting.percent_accept = Math.round(this.modelVoting.total_accept * 100 / this.modelVoting.total_users) || 0;
        this.modelVoting.percent_denied = Math.round(this.modelVoting.total_denied * 100 / this.modelVoting.total_users) || 0;
        this.modelVoting.percent_not_join = Math.round(this.modelVoting.total_not_join * 100 / this.modelVoting.total_users) || 0;
      }
      this.modalService.open("view-result-voting-modal");
    })
  }

  // Lấy danh sách ghi chú
  getListContentNote = () => {
    this._coreService.Get("/meetingcontentnote/listContentNote/" + this.model.id).subscribe((res) => {
      if (res && res.code && res.code == "200") {
        this.lstNotes = res.data && res.data.data ? _.filter(res.data.data, (item) => {
          return item.user_created == this.modelUser.username;
        }) : [];

        if (this.noteGrid) {
          this.noteGrid.dataSource = new DataManager(this.lstNotes);
        }
      }
    })
  }

  // Tạo/Sửa ghi chú
  saveContentNote() {
    if (this.noteForm.invalid) {
      this.noteForm.markAllAsTouched();
      return this.notification.warning("Bạn chưa nhập đủ dữ liệu!");
    }

    let url = "/meetingcontentnote/createNote";
    let state = 'create';
    if (this.modelNote.id) {
      url = "/meetingcontentnote/updateNote";
      state = 'update';
    }

    this.modelNote.meeting_id = this.model.id;
    this._coreService.Post(url, {
      id: this.modelNote.id,
      meeting_id: this.modelNote.meeting_id,
      content_id: this.modelNote.content_id,
      note: this.modelNote.note
    }).subscribe((res) => {
      if (res && res.code && res.code === "200") {
        this.notification.success("Cập nhật ghi chú thành công!");
        this.getListContentNote();
        this.modelNote = new MeetingContentNote();
        this.modelNote.content_id = null;
        this.noteForm.markAsUntouched();
      } else {
        this.notification.warning("Cập nhật ghi chú không thành công!");
      }
    })
  }
  // Sửa ghi chú
  editNote = (data) => {
    this.modelNote = data;
  }

  // Xóa ghi chú
  deleteNote = (data) => {
    this.modelDelete = data;
    this.modelName = data.note
    this.modalService.open("confirm-delete-note-modal");
  }

  // Confirm xóa ghi chú
  confirmDeleteNote = (status) => {
    if (status == "cancel") {
      this.modalService.close("confirm-delete-note-modal");
    } else {
      this._coreService.Post("/meetingcontentnote/deleteNote", {
        id: this.modelDelete.id
      }).subscribe((res) => {
        if (res && res.code && res.code === "200") {
          this.notification.success("Xóa ghi chú thành công!");
          this.getListContentNote();
          this.modalService.close("confirm-delete-note-modal");
        } else {
          this.notification.warning("Xóa ghi chú không thành công!");
        }
      })
    }

  }

  // Lưu Văn thư
  saveMeetingContent = () => {
    if (this.contentForm.invalid) {
      this.contentForm.markAllAsTouched();
      return this.notification.warning("Bạn chưa nhập đủ thông tin!");
    } else {
      this._coreService.Post("/meeting/saveContent", {
        id: this.model.id,
        content: this.model.content.replace(/<\/?p>/gi, ""),
        content_template_id: this.model.content_template_id
      }).subscribe((res) => {
        if (res && res.code && res.code === "200") {
          this.notification.success("Lưu nội dung thành công!");
        } else {
          this.notification.warning("Lưu nội dung không thành công!");
        }
      })
    }
  }

  // xuất file văn thư
  exportMeetingContent = () => {
    if (this.contentForm.invalid) {
      this.contentForm.markAllAsTouched();
      return this.notification.warning("Bạn chưa nhập đủ thông tin!");
    } else {
      this._coreService.Post("/meeting/saveContent", {
        id: this.model.id,
        content: this.model.content,
        content_template_id: this.model.content_template_id
      }).subscribe((res) => {
        if (res && res.code && res.code === "200") {
          this._coreService.PostExport("/print/printMeeting1", {
            id: this.model.id,
            content_template_id: this.model.content_template_id
          }).subscribe((data) => {
            const blob = new Blob([data],
              { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });

            saveAs(blob, "Noidungcuochop.docx");
          })
        } else {
          this.notification.warning("Xuất file nội dung không thành công!");
        }
      })
    }
  }

  // Điểm danh
  attandanceMeeting = () => {
    this.tabActive = "delegateTab";
  }

  // Lấy danh sách công việc cuộc họp
  getListWorking = () => {
    this._coreService.Post("/taskmanager/list", {
      is_in_meeting: true,
      meeting_id: this.model.id
    }).subscribe((res) => {
      if (res && res.code && res.code === "200") {
        this.lstWorkings = res.data && res.data.data ? res.data.data : [];

        if (this.kanbanObj) {
          this.kanbanObj.dataSource = this.lstWorkings;
          this.kanbanObj.refresh();
        }
      }
    })
  }

  // Thêm mới công việc
  addWorking = () => {
    this.workingForm.enable();
    this.modelWorking = new TaskManager();
    this.modelWorking.content_id = null;
    this.modelWorking.priority_id = null;
    this.modelWorking.user_follow_id = null;
    this.modelWorking.user_leader_id = null;
    this.modelWorking.date_created = moment().format("DD/MM/YYYY HH:mm:ss");
    this.modelWorking.list_items = [];
    this.modelWorking.list_files = [];
    this.modelWorking.list_comments = [];
    this.workingForm.markAsUntouched();
    this.lstWorkingMeetings = [{
      id: this.model.id,
      name: this.model.name
    }];

    this.lstWorkingMeetingContents = this.lstContents;
    this.modelWorking.meeting_id = this.model.id;
    this.modelWorking.status = 37;
    this.workingForm.get("status").disable();
    this.workingForm.get("meeting_id").disable();
    this.workingMemberGrid.dataSource = new DataManager(this.modelWorking.list_items);
    this.flagStateModal = "new";
    this.modalService.open("add-working-modal");
  }

  // Lưu thông tin công việc
  saveWorking = (status) => {
    if (status === "cancel") {
      this.modalService.open("confirm-close-task-modal");
    } else {
      this._configService.loadingSubject.next("true");
      if (this.workingForm.invalid) {
        for (const key of Object.keys(this.workingForm.controls)) {
          if (this.workingForm.controls[key].invalid) {
            const invalidControl = this.el.nativeElement.querySelector(
              '[formcontrolname="' + key + '"]'
            );
            if (invalidControl) {
              if (invalidControl.querySelector("input")) {
                invalidControl.querySelector("input").focus();
              } else {
                invalidControl.focus();
              }
              break;
            }
          }
        }

        this.workingForm.markAllAsTouched();
        this._configService.loadingSubject.next("false");
        return this.notification.warning("Bạn chưa nhập đủ thông tin!");
      }

      if (!this.modelWorking.list_items || this.modelWorking.list_items.length == 0) {
        this._configService.loadingSubject.next("false");
        return this.notification.warning("Bạn chưa nhập thành phần xử lý công việc!");
      }
      if (this.flagStateModal == "edit") {
        if ((this.modelOriginWorking.status == 37 && (this.modelWorking.status !== 37 && this.modelWorking.status !== 38 && this.modelWorking.status !== 40))
          || (this.modelOriginWorking.status == 38 && (this.modelWorking.status !== 38 && this.modelWorking.status !== 39))
          || (this.modelOriginWorking.status == 39 && (this.modelWorking.status !== 39 && this.modelWorking.status !== 38 && this.modelWorking.status !== 41))
          || (this.modelOriginWorking.status == 40 && (this.modelWorking.status !== 40 && this.modelWorking.status !== 37))
          || (this.modelOriginWorking.status == 41 && (this.modelWorking.status !== 41))
        ) {
          this._configService.loadingSubject.next("false");
          return this.notification.warning("Trạng thái không thể cập nhật!")
        }
      }

      let url = this.flagStateModal === "new" ? "/taskmanager/create" : "/taskmanager/update";

      let objAPI = _.cloneDeep(this.modelWorking);

      if (objAPI.start_date) {
        objAPI.start_date = this.globals.convertDateString(objAPI.start_date);
      }

      if (objAPI.deadline) {
        objAPI.deadline = this.globals.convertDateString(objAPI.deadline);
      }

      delete objAPI.date_created;

      this._coreService.Post(url, objAPI).subscribe((res) => {
        if (res && res.code && res.code === "200") {
          this.notification.success("Cập nhật công việc thành công!");
          this._configService.loadingSubject.next("false");
          this.getListWorking();
          this._socketService.sendEmit("update-work", {
            room: this.model.id
          });
          this.modalService.close("add-working-modal");
        } else {
          this._configService.loadingSubject.next("false");
          this.notification.warning("Cập nhật công việc không thành công!");
        }
      })
    }
  }

  // Xác nhận đóng không lưu giao việc
  confirmCloseTaskModal = (status) => {
    if (status == "cancel") {
      this.modalService.close("confirm-close-task-modal");
    } else {
      this.modalService.close("confirm-close-task-modal");
      this.modalService.close("add-working-modal");
    }
  }

  // CheckDate
  checkDateVsDateNowWorking = (modelName) => {
    setTimeout(() => {
      //Convert current time to moment object with utc time
      let from_date = this.modelWorking.start_date ? moment(this.modelWorking.start_date, "DD/MM/YYYY") : null;
      let to_date = this.modelWorking.deadline ? moment(this.modelWorking.deadline, "DD/MM/YYYY") : null;
      let dateNow = moment(new Date(), "DD/MM/YYYY").startOf('day');
      if (
        this.flagState !== "view" &&
        dateNow &&
        from_date &&
        dateNow.isAfter(from_date)
      ) {
        const control = this.workingForm.get("start_date");
        const err: ValidationErrors = { 'incorrectVsDateNow': true };
        setTimeout(() => {
          control.markAsDirty();
          control.markAsTouched();
          control.setErrors(err);
        }, 300)
        return;
      }

      if (this.flagState !== "view" &&
        from_date &&
        to_date &&
        from_date.isAfter(to_date)) {
        const control = this.workingForm.get("deadline");
        const err: ValidationErrors = { 'incorrectVsFromDate': true };
        setTimeout(() => {
          control.markAsDirty();
          control.markAsTouched();
          control.setErrors(err);
        }, 300)
        return;
      }
    }, 300)
  }

  // Upload file công việc
  uploadWorkingFile(files: FileList) {
    setTimeout(() => {
      if (files.length > 0) {
        this._configService.loadingSubject.next("true");
        for (let i = 0; i < files.length; i++) {
          // check file > 5MB
          const fsize = files.item(i).size;
          const file = Math.round(fsize / 1024);
        }
        let data = new FormData();
        for (let i = 0; i < files.length; i++) {
          data.append("files", files[i]);
        }
        this._coreService.uploadFile(data).subscribe((res) => {
          if (res && res.status && res.status == 200) {
            // this.model.files = res.data[0].url;
            if (!this.modelWorking.list_files) {
              this.modelWorking.list_files = [];
            }

            for (let i = 0; i < res.data.length; i++) {
              this.modelWorking.list_files.push({
                file_name: res.data[i].name,
                links: res.data[i].url
              })
            }

            this._configService.loadingSubject.next("false");
          } else {
            this._configService.loadingSubject.next("false");
            this.notification.warning("Tải files không thành công");
          }
          let x: any = document.getElementById("workingFile");
          x.value = null;
          return;
        }, (err) => {
          this._configService.loadingSubject.next("false");
          this.notification.warning("Tải files không thành công");
        });
      }
    }, 200);
  }

  removeWorkingFile = (index) => {
    this.modelWorking.list_files.splice(index, 1);
  }

  uploadDirectiveFile(files: FileList) {
    setTimeout(() => {
      if (files.length > 0) {
        this._configService.loadingSubject.next("true");
        const allowedExtensions = ['.pdf', '.doc', '.docx', '.ppt', '.pptx', '.xlsx', '.xls'];
        const file = files[0];
        const fileName = file.name;
        const fileExtension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
          this.notification.warning("Không thể tải tài liệu lên");
          this._configService.loadingSubject.next("false");
          return;
        }
        for (let i = 0; i < files.length; i++) {
          // check file > 5MB
          const fsize = files.item(i).size;
          const file = Math.round(fsize / 1024);
        }
        let data = new FormData();
        for (let i = 0; i < files.length; i++) {
          data.append("files", files[i]);
        }

        this._coreService.uploadFile(data).subscribe((res) => {
          if (res && res.status && res.status == 200) {
            this.modelWorking.directive_file = res.data[0].url;

            this._configService.loadingSubject.next("false");
          } else {
            this._configService.loadingSubject.next("false");
            this.notification.warning("Tải files không thành công");
          }
          let x: any = document.getElementById("directiveFile");
          x.value = null;
          return;
        }, (err) => {
          this._configService.loadingSubject.next("false");
          this.notification.warning("Tải files không thành công");
        });
      }
    }, 200);
  }

  removeDirectiveFile = () => {
    this.modelWorking.directive_file = null;
  }

  showTask = (data) => {
    this._coreService.Post("/taskmanager/updateShowHide", {
      id: data.id,
      is_hide: false
    }).subscribe((res) => {
      if (res && res.code && res.code == "200") {
        this.notification.success("Hiện công việc thành công!");

        this.getListWorking();
      }
    })
  }

  hideTask = (data) => {
    this._coreService.Post("/taskmanager/updateShowHide", {
      id: data.id,
      is_hide: true
    }).subscribe((res) => {
      if (res && res.code && res.code == "200") {
        this.notification.success("Ẩn công việc thành công!");

        this.getListWorking();
      }
    })
  }


  // Sửa chi tiết dữ liệu
  editWorking = (data) => {
    this._configService.loadingSubject.next("true");
    this._coreService.Get("/taskmanager/" + data.id).subscribe((res) => {
      if (res && res.code && res.code === "200") {
        this.flagStateModal = "edit";
        this.modelWorking = res.data;
        this.modelOriginWorking = _.cloneDeep(res.data);

        // Check trạng thái
        let userAccept = _.findIndex(this.modelWorking.list_items, (item) => {
          return item.user_id == this.modelUser.id;
        });
        if (userAccept == -1 && this.modelWorking.user_leader_id !== this.modelUser.id && this.modelWorking.user_follow_id !== this.modelUser.id && this.modelWorking.user_created !== this.modelUser.username) {
          return;
        }

        this.workingForm.markAsUntouched();
        this.lstWorkingMeetings = [{
          id: this.model.id,
          name: this.model.name
        }];

        this.lstWorkingMeetingContents = this.lstContents;
        this.modelWorking.meeting_id = this.model.id;
        this.workingMemberGrid.dataSource = new DataManager(this.modelWorking.list_items);

        this.lstWorkingComments = _.filter(this.modelWorking.list_comments, (item: any) => {
          return item.is_history == false;
        });

        this.lstWorkingHistorys = _.filter(this.modelWorking.list_comments, (item: any) => {
          return item.is_history == true;
        });
        if (this.modelWorking.status == 38 || this.modelWorking.status == 39 || this.modelWorking.status == 40 || this.modelWorking.status == 41) {
          this.workingForm.disable();
          this.workingForm.get("comment").enable();
        } else {
          this.workingForm.get("meeting_id").disable();
        }

        this.modalService.open("add-working-modal");
    this._configService.loadingSubject.next("false");

      }
    })

  }

  // Xem lịch sử trạng thái
  viewWorkingHistory = () => {
    this.modalService.open("view-task-history-modal");
  }

  editWorkingClick = (event: any) => {
    event.cancel = true;
    this.editWorking(event.data);
  }

  // Cập nhật trạng thái công việc
  updateKanbanCard = (event: any) => {
    setTimeout(() => {
      if (event && event.requestType && event.requestType === "cardChanged") {
        // Check trạng thái
        let userAccept = _.findIndex(event.changedRecords[0].list_user_ids, (item) => {
          return item == this.modelUser.id;
        });

        if (userAccept == -1 && event.changedRecords[0].user_leader_id !== this.modelUser.id && event.changedRecords[0].user_follow_id !== this.modelUser.id) {
          let objWorking = _.find(this.lstWorkings, (item: any) => {
            return item.id === event.changedRecords[0].id;
          });
          objWorking.status_2 = event.changedRecords[0].status.toString();
          this.kanbanObj.refresh();
          return;
        }

        if ((event.changedRecords[0].status == 37 && (event.changedRecords[0].status_2 !== "40" && event.changedRecords[0].status_2 !== "38"))
          || (event.changedRecords[0].status == 38 && (event.changedRecords[0].status_2 !== "39"))
          || (event.changedRecords[0].status == 39 && (event.changedRecords[0].status_2 !== "41" && event.changedRecords[0].status_2 !== "38"))
          || (event.changedRecords[0].status == 40 && (event.changedRecords[0].status_2 !== "37"))
          || (event.changedRecords[0].status == 41)
        ) {
          let objWorking = _.find(this.lstWorkings, (item: any) => {
            return item.id === event.changedRecords[0].id;
          });

          if (objWorking) {
            objWorking.status_2 = event.changedRecords[0].status.toString();
            this.kanbanObj.refresh();
          }
          return;
        }
        if ((event.changedRecords[0].status == 37 || event.changedRecords[0].status == 38) && event.changedRecords[0].deadline_status == 60) {
          this.modelDelete = event.changedRecords[0];
          this.modalService.open("confirm-explanation-working-modal");
        } else if (event.changedRecords[0] && event.changedRecords[0].status_2 == "40") {
          this.modelDelete = event.changedRecords[0];
          this.modelName = this.modelDelete.name
          this.modalService.open("confirm-denied-working-modal");
        } else {
          this.updateWorkingStatus(event.changedRecords[0]);
        }
      }
    }, 50)
  }

  // Xác nhận từ chối công việc
  confirmDeniedWorking(status) {
    if (status === "cancel") {
      let objWorking = _.find(this.lstWorkings, (item: any) => {
        return item.id === this.modelDelete.id;
      });

      if (objWorking) {
        objWorking.status_2 = this.modelDelete.status.toString();
        this.kanbanObj.refresh();
      }
      this.modalService.close("confirm-denied-working-modal");
    } else {
      if (!this.reason_denied_working) {
        this.notification.warning("Bạn chưa nhập lý do từ chối!");
      } else {
        this.updateWorkingStatus(this.modelDelete);
        this.modalService.close("confirm-denied-working-modal");
      }
    }
  }

  // Xác nhận giải trình công việc
  confirmExplanationWorking(status) {
    if (status === "cancel") {
      let objWorking = _.find(this.lstWorkings, (item: any) => {
        return item.id === this.modelDelete.id;
      });

      if (objWorking) {
        objWorking.status_2 = this.modelDelete.status.toString();
        this.kanbanObj.refresh();
      }
      this.modalService.close("confirm-explanation-working-modal");
    } else {
      if (!this.reason_explanation) {
        this.notification.warning("Bạn chưa nhập lý do giải trình!");
      } else {
        this._coreService.Post("/taskmanager/updateExplanation", {
          id: this.modelDelete.id,
          user_explanation_id: this.modelUser.id,
          reason_explanation: this.reason_explanation
        }).subscribe((res) => {
          if (res && res.code && res.code == "200") {
            this.reason_explanation = "";
            this.updateWorkingStatus(this.modelDelete);
          } else {
            this.notification.warning("Giải trình không thành công!");
            this.reason_explanation = "";
            let objWorking = _.find(this.lstWorkings, (item: any) => {
              return item.id === this.modelDelete.id;
            });

            if (objWorking) {
              objWorking.status_2 = this.modelDelete.status.toString();
              this.kanbanObj.refresh();
            }
          }
          this.modalService.close("confirm-explanation-working-modal");
        })
      }
    }
  }

  // Chuyển đổi trạng thái
  updateWorkingStatus = (data) => {
    // Check trạng thái
    let userAccept = _.findIndex(data.list_user_ids, (item) => {
      return item == this.modelUser.id;
    });

    if (userAccept == -1 && data.user_leader_id !== this.modelUser.id && data.user_follow_id !== this.modelUser.id) {
      return;
    }

    this._coreService.Post("/taskmanager/updateStatus", {
      id: data.id,
      status: parseInt(data.status_2),
      comment: this.reason_denied_working ? this.reason_denied_working : null
    }).subscribe((res) => {
      if (res && res.code && res.code === "200") {
        this.notification.success("Cập nhật trạng thái công việc thành công!");
        if (this.reason_denied_working) {
          this.reason_denied_working = null;
        }
        this.getListWorking();
        this._socketService.sendEmit("update-work", {
          room: this.model.id
        });
      } else {
        this.notification.warning("Cập nhật trạng thái công việc không thành công!");
      }
    })
  }

  // Click xóa công việc
  deleteWorking = (data) => {
    // Check trạng thái
    let userAccept = _.findIndex(data.list_user_ids, (item) => {
      return item == this.modelUser.id;
    });

    if (userAccept == -1 && data.user_leader_id !== this.modelUser.id && data.user_follow_id !== this.modelUser.id) {
      return;
    }

    this.modelDelete = data;
    this.modelName = data.name

    this.modalService.open("confirm-delete-working");
  }

  // Xác nhận xóa công việc
  confirmDeleteWorking = (status) => {
    if (status === "cancel") {
      this.modalService.close("confirm-delete-working");
    } else {
      this._coreService.Post("/taskmanager/delete", {
        id: this.modelDelete.id
      }).subscribe((res) => {
        if (res && res.code && res.code === "200") {
          this.notification.success("Xóa công việc thành công!");
          this.modalService.close("confirm-delete-working");
          this.getListWorking();
        } else {
          this.notification.warning("Xóa công việc không thành công!");
        }
      })
    }
  }

  // Thêm mới thành phần xử lý công việc
  addWorkingMember = () => {
    // this.dataChooseWorking = this.modelWorking.list_items;
    this.dataChooseWorking = _.cloneDeep(this.modelWorking.list_items);
    let indexs = [];
    let index2s = [];
    let ids = _.map(this.dataChooseWorking, "user_id");
    for (let i = 0; i < ids.length; i++) {
      let data = this.workingMemberModalGrid.currentViewData["records"];
      let index = _.findIndex(data, (item) => {
        return item.user_id === ids[i];
      });

      if (index > -1) {
        indexs.push(index);
      }
      let data2 = this.workingOrgModalGrid.currentViewData["records"];
      let index2 = _.findIndex(data2, (item) => {
        return item.user_id === ids[i];
      });

      if (index2 > -1) {
        index2s.push(index2);
      }

    }

    if (this.workingChooseMemberModalGrid) {
      this.workingChooseMemberModalGrid.dataSource = new DataManager(this.dataChooseWorking);
    }
    this.modalService.open("add-working-member-modal");
    setTimeout(() => {
      if (indexs.length > 0) {
        this.workingMemberModalGrid.selectRows(indexs);
      }
      if (index2s.length > 0) {
        this.workingOrgModalGrid.selectRows(index2s);
      }

    }, 100);

  }

  // Xóa đại biểu đã chọn
  deleteMemberWorking = (index) => {
    this.modelWorking.list_items.splice(index, 1);

    this.workingMemberGrid.dataSource = new DataManager(this.modelWorking.list_items);
  }

  // Chọn đại biểu ở popup
  selectMemberOrgWorking = (event) => {
    setTimeout(() => {
      if (((event.name === "rowSelecting" && event.isCtrlPressed) || (event.name === "rowDeselecting" && event.isInteracted))) {
        if (event.name === "rowSelecting" && event.isCtrlPressed) {
          const rowSelects: any = this.workingOrgModalGrid.getSelectedRecords();
          if (rowSelects && rowSelects.length > 0) {
            for (let i = 0; i < rowSelects.length; i++) {
              let index = _.findIndex(this.dataChooseWorking, (item: any) => {
                return item.user_id == rowSelects[i].user_id;
              });

              if (index == -1) {
                this.dataChooseWorking.push(rowSelects[i]);
              }
            }
          }
        } else if (event.name === "rowDeselecting" && event.isInteracted) {
          if (event.data && event.data.length > 0) {
            for (let i = 0; i < event.data.length; i++) {
              let index = _.findIndex(this.dataChooseWorking, (item: any) => {
                return item.user_id == event.data[i].user_id;
              });

              if (index > -1) {
                this.dataChooseWorking.splice(index, 1);
              }
            }
          }
        }
      }
      this.workingChooseMemberModalGrid.dataSource = new DataManager(this.dataChooseWorking);
    }, 50);
  };
  selectMemberWorking = (event) => {
    setTimeout(() => {
      if (((event.name === "rowSelecting" && event.isCtrlPressed) || (event.name === "rowDeselecting" && event.isInteracted))) {
        if (event.name === "rowSelecting" && event.isCtrlPressed) {
          const rowSelects: any = this.workingMemberModalGrid.getSelectedRecords();
          if (rowSelects && rowSelects.length > 0) {
            for (let i = 0; i < rowSelects.length; i++) {
              let index = _.findIndex(this.dataChooseWorking, (item: any) => {
                return item.user_id == rowSelects[i].user_id;
              });

              if (index == -1) {
                this.dataChooseWorking.push(rowSelects[i]);
              }
            }
          }
        } else if (event.name === "rowDeselecting" && event.isInteracted) {
          if (event.data && event.data.length > 0) {
            for (let i = 0; i < event.data.length; i++) {
              let index = _.findIndex(this.dataChooseWorking, (item: any) => {
                return item.user_id == event.data[i].user_id;
              });

              if (index > -1) {
                this.dataChooseWorking.splice(index, 1);
              }
            }
          }
        }
      }
      this.workingChooseMemberModalGrid.dataSource = new DataManager(this.dataChooseWorking);
    }, 50);
  };

  // Xóa đại biểu đã chọn ở Modal
  deleteMemberWorkingModal = (index) => {
    this.dataChooseWorking.splice(index, 1);

    this.workingChooseMemberModalGrid.dataSource = new DataManager(this.dataChooseWorking);
  }

  // Lưu thành phần
  saveWorkingMemberModal = (status) => {
    if (status === "cancel") {
      this.dataChooseWorking = [];
      // this.workingOrgModalGrid.clearSelection();
      this.workingMemberModalGrid.clearSelection();

      this.modalService.close("add-working-member-modal");
    } else {
      this.modelWorking.list_items = _.cloneDeep(this.dataChooseWorking);
      this.workingMemberGrid.dataSource = new DataManager(this.modelWorking.list_items);
      this.workingMemberModalGrid.clearSelection();
      this.modalService.close("add-working-member-modal");
    }
  }

  // Xem lịch sử nội dung
  viewContentHistory = (data) => {
    this.modelRealContent = data;
    this._coreService.Get("/meetingcontent/getContentHistory/" + data.id).subscribe((res) => {
      if (res && res.code == "200") {
        this.lstContentHistorys = res.data;
        this.lstContentHistorys.forEach((item : any) => {
          item.audio = this.getStreamFromUrl(item.session_id,0);
        })

        if (this.contentHistoryGrid) {
          this.contentHistoryGrid.dataSource = new DataManager(this.lstContentHistorys);
        }

        this.modalService.open("content-history-modal");
      }
    })
  }


  editCommentHistory = (data) => {
    this.modelAdd = {
      id: data.comment_id,
      content: data.comment,
      record_file: data.record_file,
      files: data.files,
      user_id: data.user_id,
      full_name: data.full_name,
      title_name: data.title_name,
      content_id: data.content_id,
      content_name: data.content_name,
      url: null,
      links: "",
      file_type: "",
      is_before_view: false,
      is_inmeet_view: false,
      is_after_view: false,
      file_name: ""
    };

    let objContentChoose = _.find(this.lstContentTabComments, (item: any) => {
      return item.id == this.modelAdd.content_id;
    });

    let objContent;
    let content_position_id = this.modelAdd.content_id;

    if (objContentChoose && objContentChoose.is_child) {
      objContent = _.find(this.lstContents, (item: any) => {
        return item.id == objContentChoose.parent_id;
      });
      content_position_id = objContentChoose.parent_id;
    } else {
      objContent = _.find(this.lstContents, (item: any) => {
        return item.id == this.modelAdd.content_id;
      });
    }

    if (objContent) {
      this.lstDelegateTabCommentModals = _.cloneDeep(objContent.list_members);

      if (this.model.user_follow_id) {
        var indexFollow = _.findIndex(this.lstDelegateTabCommentModals, (item: any) => {
          return item.user_id == this.model.user_follow_id;
        });

        if (indexFollow == -1) {
          this.lstDelegateTabCommentModals.unshift({
            user_id: this.model.user_follow_id,
            full_name: this.model.user_follow_name
          });
        }
      }

      var indexSecretary = _.findIndex(this.lstDelegateTabCommentModals, (item: any) => {
        return item.user_id == this.model.user_secretary_id;
      });

      if (indexSecretary == -1) {
        this.lstDelegateTabCommentModals.unshift({
          user_id: this.model.user_secretary_id,
          full_name: this.model.user_secretary_name
        });
      }

      var indexChair = _.findIndex(this.lstDelegateTabCommentModals, (item: any) => {
        return item.user_id == this.model.user_chair_id;
      });

      if (indexChair == -1) {
        this.lstDelegateTabCommentModals.unshift({
          user_id: this.model.user_chair_id,
          full_name: this.model.user_chair_name
        });
      }
    } else {
      this.lstDelegateTabCommentModals = [];
    }

    if (this.flagChair || this.flagSecretary || this.flagFollow) {
      this.flagStateModal = "edit";
    } else {
      this.flagStateModal = "view";
    }
    this.modalService.open("edit-content-comment-modal");
  }
  isDisabled: boolean;
  testDef: boolean= false
  editDetailHistory = (data) => {
    this.audioSource2 = data.audio;
    this.modelAdd = {
      id: data.comment_id,
      content: data.comment,
      record_file: data.record_file,
      files: data.files,
      user_id: data.user_id,
      full_name: data.full_name,
      title_name: data.title_name,
      content_id: data.content_id,
      content_name: data.content_name,
      audio : data.audio ,
    };
    let idUser = localStorage.getItem('userId')

    if (idUser == this.modelAdd.user_id || idUser == this.model.user_chair_id || idUser == this.model.user_secretary_id || idUser == this.model.user_follow_id) {
      this.testDef = true
      this.isDisabled = false;
    } else {
      this.flagStateModal = "view";
      this.isDisabled = true;
    }
    this.modalService.close('content-history-modal')
    this.modalService.open("edit-history-detail-modal");
    const htmlElement = document.getElementsByTagName('html')[0];
  htmlElement.style.overflow = 'hidden';
  }
  // Highlight diễn biến
  rowDataBoundContent(args: any): void {
    if (args.row) {
      if (args.data.status == 27) {
        args.row.classList.add('row-active');
      }
    }
  }
  rowDataBoundContent2(args: any): void {
    if (args.row) {
      if (args.data.status == 27) {
        args.row.classList.add('row-active');
      }
    }
  }

   // Xuất excel tổng hợp ý kiến
   exportCommentExcel = () => {
    this._coreService.PostExport("/print/printSummaryContent2", {
      meeting_id: this.model.id,
      content_id: this.searchSummaryContent.content_id,
      time_id: this.searchSummaryContent.time_id,
      user_id: this.searchSummaryContent.user_id
    }).subscribe((data) => {
      const blob = new Blob([data],
        { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });

      saveAs(blob, "NoidungTongHopYKien.docx");
    }, (error) => {
      this.notification.warning("Xuất file thất bại!");
    })
  }
  changeContentTabRoom = (event) => {
    if (event.isInteracted) {
      setTimeout(() => {
        if (this.searchContentTabRoom) {
          this.getDataPosition().then(() => {
            this.getWaitingPosition();
          });
        }
      }, 50)
    }
  }

  @ViewChild('dropdowntemplate', { static: false }) public dropdowntemplate: DropDownListComponent;

  lstTabCommentsFocsus(){
    setTimeout(() => {
    }, 100)
  }
  userUpdate:any
  onChangeValue = (event) => {
    this.userUpdate = event.value;
    this.modelAddComment.user_id = this.userUpdate;
  }
  changeContentTabComment = (event) => {
    if (event.isInteracted) {
      setTimeout(() => {
        if (this.modelAddComment.content_ids && this.modelAddComment.content_ids.length > 0) {
          if (this.modelAddComment.content_ids.length > 1) {
            let indexRealContent = _.findIndex(this.modelAddComment.content_ids, (item: any) => {
              return item == this.modelRealContent.id;
            });
            if (indexRealContent > -1) {
              if (this.modelRealContent.content == "Khai mạc") {
                this.getDataPositionTabComment(this.lstContentAttendances[0].id);
              } else if (this.modelRealContent.content == "Bế mạc") {
                this.getDataPositionTabComment(this.lstContentAttendances[this.lstContentAttendances.length - 1].id);
              } else {
                this.getDataPositionTabComment(this.modelRealContent.id);
              }

              this.modelAddComment.user_id = null;
              this.modelAddComment.full_name = "";
              this.lstDelegateTabComments = _.cloneDeep(this.modelRealContent.list_members);

              if (this.model.user_follow_id) {
                var indexFollow = _.findIndex(this.lstDelegateTabComments, (item: any) => {
                  return item.user_id == this.model.user_follow_id;
                });

                if (indexFollow == -1) {
                  this.lstDelegateTabComments.unshift({
                    user_id: this.model.user_follow_id,
                    full_name: this.model.user_follow_name
                  });
                }
              }

              var indexSecretary = _.findIndex(this.lstDelegateTabComments, (item: any) => {
                return item.user_id == this.model.user_secretary_id;
              });

              if (indexSecretary == -1) {
                this.lstDelegateTabComments.unshift({
                  user_id: this.model.user_secretary_id,
                  full_name: this.model.user_secretary_name
                });
              }

              var indexChair = _.findIndex(this.lstDelegateTabComments, (item: any) => {
                return item.user_id == this.model.user_chair_id;
              });

              if (indexChair == -1) {
                this.lstDelegateTabComments.unshift({
                  user_id: this.model.user_chair_id,
                  full_name: this.model.user_chair_name
                });
              }
            } else {
              if (this.modelAddComment.content_ids && this.modelAddComment.content_ids.length > 0) {
                let objContentChoose = _.find(this.lstContentTabComments, (item: any) => {
                  return item.id == this.modelAddComment.content_ids[0];
                });

                let objContent;
                let content_position_id = this.modelAddComment.content_ids[0];

                if (objContentChoose && objContentChoose.is_child) {
                  objContent = _.find(this.lstContents, (item: any) => {
                    return item.id == objContentChoose.parent_id;
                  });
                  content_position_id = objContentChoose.parent_id;
                } else {
                  objContent = _.find(this.lstContents, (item: any) => {
                    return item.id == this.modelAddComment.content_ids[0];
                  });
                }

                if (objContent) {
                  if (objContent.content == "Khai mạc") {
                    this.getDataPositionTabComment(this.lstContentAttendances[0].id);
                  } else if (objContent.content == "Bế mạc") {
                    this.getDataPositionTabComment(this.lstContentAttendances[this.lstContentAttendances.length - 1].id);
                  } else {
                    this.getDataPositionTabComment(content_position_id);
                  }

                  this.modelAddComment.user_id = null;
                  this.modelAddComment.full_name = "";
                  this.lstDelegateTabComments = _.cloneDeep(objContent.list_members);

                  if (this.model.user_follow_id) {
                    var indexFollow = _.findIndex(this.lstDelegateTabComments, (item: any) => {
                      return item.user_id == this.model.user_follow_id;
                    });

                    if (indexFollow == -1) {
                      this.lstDelegateTabComments.unshift({
                        user_id: this.model.user_follow_id,
                        full_name: this.model.user_follow_name
                      });
                    }
                  }

                  var indexSecretary = _.findIndex(this.lstDelegateTabComments, (item: any) => {
                    return item.user_id == this.model.user_secretary_id;
                  });

                  if (indexSecretary == -1) {
                    this.lstDelegateTabComments.unshift({
                      user_id: this.model.user_secretary_id,
                      full_name: this.model.user_secretary_name
                    });
                  }

                  var indexChair = _.findIndex(this.lstDelegateTabComments, (item: any) => {
                    return item.user_id == this.model.user_chair_id;
                  });

                  if (indexChair == -1) {
                    this.lstDelegateTabComments.unshift({
                      user_id: this.model.user_chair_id,
                      full_name: this.model.user_chair_name
                    });
                  }
                }
              }
            }
          } else {
            if (this.modelAddComment.content_ids && this.modelAddComment.content_ids.length > 0) {
              let objContentChoose = _.find(this.lstContentTabComments, (item: any) => {
                return item.id == this.modelAddComment.content_ids[0];
              });

              let objContent;
              let content_position_id = this.modelAddComment.content_ids[0];

              if (objContentChoose && objContentChoose.is_child) {
                objContent = _.find(this.lstContents, (item: any) => {
                  return item.id == objContentChoose.parent_id;
                });
                content_position_id = objContentChoose.parent_id;
              } else {
                objContent = _.find(this.lstContents, (item: any) => {
                  return item.id == this.modelAddComment.content_ids[0];
                });
              }

              if (objContent) {
                if (objContent.content == "Khai mạc") {
                  this.getDataPositionTabComment(this.lstContentAttendances[0].id);
                } else if (objContent.content == "Bế mạc") {
                  this.getDataPositionTabComment(this.lstContentAttendances[this.lstContentAttendances.length - 1].id);
                } else {
                  this.getDataPositionTabComment(content_position_id);
                }

                this.modelAddComment.user_id = null;
                this.modelAddComment.full_name = "";
                this.lstDelegateTabComments = _.cloneDeep(objContent.list_members);

                if (this.model.user_follow_id) {
                  var indexFollow = _.findIndex(this.lstDelegateTabComments, (item: any) => {
                    return item.user_id == this.model.user_follow_id;
                  });

                  if (indexFollow == -1) {
                    this.lstDelegateTabComments.unshift({
                      user_id: this.model.user_follow_id,
                      full_name: this.model.user_follow_name
                    });
                  }
                }

                var indexSecretary = _.findIndex(this.lstDelegateTabComments, (item: any) => {
                  return item.user_id == this.model.user_secretary_id;
                });

                if (indexSecretary == -1) {
                  this.lstDelegateTabComments.unshift({
                    user_id: this.model.user_secretary_id,
                    full_name: this.model.user_secretary_name
                  });
                }

                var indexChair = _.findIndex(this.lstDelegateTabComments, (item: any) => {
                  return item.user_id == this.model.user_chair_id;
                });

                if (indexChair == -1) {
                  this.lstDelegateTabComments.unshift({
                    user_id: this.model.user_chair_id,
                    full_name: this.model.user_chair_name
                  });
                }
              }
            }
          }
        }
      }, 50)
    }
      this.dropdowntemplate.clear();

  }
  @ViewChild('majorMultiselect', { static: false }) multiselect: MultiSelectComponent;
  spanClearX: boolean = false;

  onMajorSelect(event){
    const data = event.itemData;
  this.spanClearX = true;

    if(!this.modelAddComment.content_ids){
      this.modelAddComment.content_ids = [];
    }
    if(!this.modelAddComment.content_ids.includes(data.id)){
      this.modelAddComment.content_ids.push(data.id)
    }
    this.getDataPositionTabComment1();
  }

  onMajorRemoving(event){
    const data = event.itemData;
    this.modelAddComment.content_ids = this.modelAddComment.content_ids.filter(item => item !== data.id);
    this.getDataPositionTabComment1();
    this.multiselect.dataSource = this.lstContentTabComments
    if(this.modelAddComment.content_ids.length === 0){
      this.multiselect.clear()
    }
  }

  getDataPositionTabComment1(){
    if (this.modelAddComment.content_ids && this.modelAddComment.content_ids.length > 0) {
      if (this.modelAddComment.content_ids.length > 1) {
        let indexRealContent = _.findIndex(this.modelAddComment.content_ids, (item: any) => {
          return item == this.modelRealContent.id;
        });
        if (indexRealContent > -1) {
          if (this.modelRealContent.content == "Khai mạc") {
            this.getDataPositionTabComment(this.lstContentAttendances[0].id);
          } else if (this.modelRealContent.content == "Bế mạc") {
            this.getDataPositionTabComment(this.lstContentAttendances[this.lstContentAttendances.length - 1].id);
          } else {
            this.getDataPositionTabComment(this.modelRealContent.id);
          }

          this.modelAddComment.user_id = null;
          this.modelAddComment.full_name = "";
          this.lstDelegateTabComments = _.cloneDeep(this.modelRealContent.list_members);

          if (this.model.user_follow_id) {
            var indexFollow = _.findIndex(this.lstDelegateTabComments, (item: any) => {
              return item.user_id == this.model.user_follow_id;
            });

            if (indexFollow == -1) {
              this.lstDelegateTabComments.unshift({
                user_id: this.model.user_follow_id,
                full_name: this.model.user_follow_name
              });
            }
          }

          var indexSecretary = _.findIndex(this.lstDelegateTabComments, (item: any) => {
            return item.user_id == this.model.user_secretary_id;
          });

          if (indexSecretary == -1) {
            this.lstDelegateTabComments.unshift({
              user_id: this.model.user_secretary_id,
              full_name: this.model.user_secretary_name
            });
          }

          var indexChair = _.findIndex(this.lstDelegateTabComments, (item: any) => {
            return item.user_id == this.model.user_chair_id;
          });

          if (indexChair == -1) {
            this.lstDelegateTabComments.unshift({
              user_id: this.model.user_chair_id,
              full_name: this.model.user_chair_name
            });
          }
        } else {
          if (this.modelAddComment.content_ids && this.modelAddComment.content_ids.length > 0) {
            let objContentChoose = _.find(this.lstContentTabComments, (item: any) => {
              return item.id == this.modelAddComment.content_ids[0];
            });

            let objContent;
            let content_position_id = this.modelAddComment.content_ids[0];

            if (objContentChoose && objContentChoose.is_child) {
              objContent = _.find(this.lstContents, (item: any) => {
                return item.id == objContentChoose.parent_id;
              });
              content_position_id = objContentChoose.parent_id;
            } else {
              objContent = _.find(this.lstContents, (item: any) => {
                return item.id == this.modelAddComment.content_ids[0];
              });
            }

            if (objContent) {
              if (objContent.content == "Khai mạc") {
                this.getDataPositionTabComment(this.lstContentAttendances[0].id);
              } else if (objContent.content == "Bế mạc") {
                this.getDataPositionTabComment(this.lstContentAttendances[this.lstContentAttendances.length - 1].id);
              } else {
                this.getDataPositionTabComment(content_position_id);
              }

              this.modelAddComment.user_id = null;
              this.modelAddComment.full_name = "";
              this.lstDelegateTabComments = _.cloneDeep(objContent.list_members);

              if (this.model.user_follow_id) {
                var indexFollow = _.findIndex(this.lstDelegateTabComments, (item: any) => {
                  return item.user_id == this.model.user_follow_id;
                });

                if (indexFollow == -1) {
                  this.lstDelegateTabComments.unshift({
                    user_id: this.model.user_follow_id,
                    full_name: this.model.user_follow_name
                  });
                }
              }

              var indexSecretary = _.findIndex(this.lstDelegateTabComments, (item: any) => {
                return item.user_id == this.model.user_secretary_id;
              });

              if (indexSecretary == -1) {
                this.lstDelegateTabComments.unshift({
                  user_id: this.model.user_secretary_id,
                  full_name: this.model.user_secretary_name
                });
              }

              var indexChair = _.findIndex(this.lstDelegateTabComments, (item: any) => {
                return item.user_id == this.model.user_chair_id;
              });

              if (indexChair == -1) {
                this.lstDelegateTabComments.unshift({
                  user_id: this.model.user_chair_id,
                  full_name: this.model.user_chair_name
                });
              }
            }
          }
        }
      } else {
        if (this.modelAddComment.content_ids && this.modelAddComment.content_ids.length > 0) {
          let objContentChoose = _.find(this.lstContentTabComments, (item: any) => {
            return item.id == this.modelAddComment.content_ids[0];
          });

          let objContent;
          let content_position_id = this.modelAddComment.content_ids[0];

          if (objContentChoose && objContentChoose.is_child) {
            objContent = _.find(this.lstContents, (item: any) => {
              return item.id == objContentChoose.parent_id;
            });
            content_position_id = objContentChoose.parent_id;
          } else {
            objContent = _.find(this.lstContents, (item: any) => {
              return item.id == this.modelAddComment.content_ids[0];
            });
          }

          if (objContent) {
            if (objContent.content == "Khai mạc") {
              this.getDataPositionTabComment(this.lstContentAttendances[0].id);
            } else if (objContent.content == "Bế mạc") {
              this.getDataPositionTabComment(this.lstContentAttendances[this.lstContentAttendances.length - 1].id);
            } else {
              this.getDataPositionTabComment(content_position_id);
            }

            this.modelAddComment.user_id = null;
            this.modelAddComment.full_name = "";
            this.lstDelegateTabComments = _.cloneDeep(objContent.list_members);

            if (this.model.user_follow_id) {
              var indexFollow = _.findIndex(this.lstDelegateTabComments, (item: any) => {
                return item.user_id == this.model.user_follow_id;
              });

              if (indexFollow == -1) {
                this.lstDelegateTabComments.unshift({
                  user_id: this.model.user_follow_id,
                  full_name: this.model.user_follow_name
                });
              }
            }

            var indexSecretary = _.findIndex(this.lstDelegateTabComments, (item: any) => {
              return item.user_id == this.model.user_secretary_id;
            });

            if (indexSecretary == -1) {
              this.lstDelegateTabComments.unshift({
                user_id: this.model.user_secretary_id,
                full_name: this.model.user_secretary_name
              });
            }

            var indexChair = _.findIndex(this.lstDelegateTabComments, (item: any) => {
              return item.user_id == this.model.user_chair_id;
            });

            if (indexChair == -1) {
              this.lstDelegateTabComments.unshift({
                user_id: this.model.user_chair_id,
                full_name: this.model.user_chair_name
              });
            }
          }
        }
      }
    }

  }


  // Lấy danh sách chỗ ngồi hiện tại
  getDataPosition = () => {
    return new Promise((resolve, reject) => {
      this.lstRoomPositions = [];
      this.lstRoomPositionTabInfos = [];
      // Chi tiết Sơ đồ phòng kèm trạng thái chỗ ngồi
      this._coreService.Post("/meetingposition/detailRoomPosition", {
        meeting_id: this.model.id,
        content_id: this.searchContentTabRoom
      }).subscribe((res) => {
        if (res && res.code === "200" && res.data && res.data.data) {
          let dataPositions = res.data.data ? _.sortBy(res.data.data, "table_number") : [];
          if (dataPositions && dataPositions.length > 0) {
            if (this.modelRealContent && this.modelRealContent.list_members && this.modelRealContent.list_members.length > 0) {
              this.getDataPositionTabInfo();
            } else {
              // let modelRealContent = _.find(this.lstContents, (item: any) => {
              let modelRealContent = _.find(this.lstContents, (item: any) => {
                return item.id == this.searchContentTabRoom;
              });
              for (let j = 0; j < dataPositions.length; j++) {
                let flagCheck = false;
                for (let i = 0; i < modelRealContent.list_members.length; i++) {
                  if (dataPositions[j] && dataPositions[j].user_id && dataPositions[j].user_id == modelRealContent.list_members[i].user_id) {
                    if (modelRealContent.list_members[i].is_athority) {
                      flagCheck = true;
                      dataPositions[j].meeting_status = 3;
                    } else if (modelRealContent.list_members[i].attendance_status == true) {
                      flagCheck = true;
                      dataPositions[j].meeting_status = 2;
                    }
                    continue;
                  }
                }
                if (!flagCheck && dataPositions[j].meeting_status !== 2 && dataPositions[j].meeting_status !== 3) {
                  dataPositions[j].meeting_status = 1;
                }
              }
              this.lstRoomPositionTabInfos = _.cloneDeep(dataPositions);
            }
            this.lstRoomPositions = _.cloneDeep(dataPositions);
            const seatsPosition = this.lstRoomPositions.map(seat => {return {x: seat.x, y: seat.y}});
            const minSeatX = Math.min(...seatsPosition.map(seat => seat.x));
            const maxSeatX = Math.max(...seatsPosition.map(seat => seat.x));
            const minSeatY = Math.min(...seatsPosition.map(seat => seat.y));
            const maxSeatY = Math.max(...seatsPosition.map(seat => seat.y));
            this.seatsMapWidth = maxSeatX + 1;
            this.seatsMapHeight = maxSeatY - minSeatY + 1
            this.seatsMapStartX = minSeatX;
            this.seatsMapStartY = minSeatY;
            this.lstWaitingPositions.forEach((waitingSeat, index) => {
            })

            let userFollowIdRooms = this.model.user_follow_id;
            let userSecretaryIdRooms = this.model.user_secretary_id
              let matchingElementRooms = this.lstRoomPositions.find(obj => obj.user_id === userFollowIdRooms);
              let matchingElementRooms2 = this.lstRoomPositions.find(obj => obj.user_id === userSecretaryIdRooms);
              if (matchingElementRooms) {
                matchingElementRooms.is_special_position = true
              }
              if (matchingElementRooms2) {
                matchingElementRooms2.is_special_position = true
              }
          } else {
            if (this.lstRoomPositions.length == 0) {
              for (let i = 0; i < this.lstRoomPositionOrigins.length; i++) {
                this.lstRoomPositions.push({
                  meeting_id: this.model.id,
                  content_id: this.searchContentTabRoom,
                  x: this.lstRoomPositionOrigins[i].x,
                  y: this.lstRoomPositionOrigins[i].y,
                  table_number: this.lstRoomPositionOrigins[i].table_number,
                  meeting_status: null,
                  attandance_status: null,
                  is_chair: this.lstRoomPositionOrigins[i].is_chair,
                  user_id: null,
                  full_name: ""
                })
              }

            }
          }
        }
        resolve(true);
      }, (error) => {
        resolve(true);
      });
    })
  }


  // Lấy danh sách dữ liệu sơ đồ tab điều hành
  getDataPositionTabInfo = () => {
    if (this.modelRealContent) {
      return new Promise((resolve, reject) => {
        this.lstRoomPositionTabInfos = [];
        // Chi tiết Sơ đồ phòng kèm trạng thái chỗ ngồi
        this._coreService.Post("/meetingposition/detailRoomPosition", {
          meeting_id: this.model.id,
          content_id: this.modelRealContent.id
        }).subscribe((res) => {
          if (res && res.code === "200" && res.data && res.data.data) {
            let dataPositions = res.data.data ? _.sortBy(res.data.data, "table_number") : [];
            if (dataPositions && dataPositions.length > 0) {
              if (this.modelRealContent && this.modelRealContent.list_members && this.modelRealContent.list_members.length > 0) {
                for (let j = 0; j < dataPositions.length; j++) {
                  let flagCheck = false;
                  for (let i = 0; i < this.modelRealContent.list_members.length; i++) {
                    if (dataPositions[j] && dataPositions[j].user_id && dataPositions[j].user_id == this.modelRealContent.list_members[i].user_id) {
                      if (this.modelRealContent.list_members[i].is_athority) {
                        flagCheck = true;
                        dataPositions[j].meeting_status = 3;
                      } else if (this.modelRealContent.list_members[i].attendance_status == true) {
                        flagCheck = true;
                        dataPositions[j].meeting_status = 2;
                      }
                      continue;
                    }
                  }
                  if (!flagCheck && dataPositions[j].meeting_status !== 2 && dataPositions[j].meeting_status !== 3) {
                    dataPositions[j].meeting_status = 1;
                  }
                }
              }
              this.lstRoomPositionTabInfos = _.cloneDeep(dataPositions);
            }

          }
          resolve(true);
        }, (error) => {
          resolve(true);
        });
      })
    }
  }

  // Lấy danh sách dữ liệu sơ đồ tab ý kiến
  getDataPositionTabComment = (content_id) => {
    if (content_id) {
      return new Promise((resolve, reject) => {
        this.lstRoomPositionTabComments = [];
        // this.lstRoomPositionTabInfos = []
        // Chi tiết Sơ đồ phòng kèm trạng thái chỗ ngồi
        this._coreService.Post("/meetingposition/detailRoomPosition", {
          meeting_id: this.model.id,
          content_id: content_id
        }).subscribe((res) => {
          if (res && res.code === "200" && res.data && res.data.data) {
            let dataPositions = res.data.data ? _.sortBy(res.data.data, "table_number") : [];
            if (dataPositions && dataPositions.length > 0) {
              let modelRealContent = _.find(this.lstContents, (item) => {
                return item.id == content_id;
              });

              if (modelRealContent && modelRealContent.list_members && modelRealContent.list_members.length > 0) {
                for (let j = 0; j < dataPositions.length; j++) {
                  let flagCheck = false;
                  for (let i = 0; i < modelRealContent.list_members.length; i++) {
                    if (dataPositions[j] && dataPositions[j].user_id && dataPositions[j].user_id == modelRealContent.list_members[i].user_id) {
                      if (modelRealContent.list_members[i].is_athority) {
                        flagCheck = true;
                        dataPositions[j].meeting_status = 3;
                      } else if (modelRealContent.list_members[i].attendance_status == true) {
                        flagCheck = true;
                        dataPositions[j].meeting_status = 2;
                      }
                      continue;
                    }
                  }
                  if (!flagCheck && dataPositions[j].meeting_status !== 2 && dataPositions[j].meeting_status !== 3) {
                    dataPositions[j].meeting_status = 1;
                  }
                }
              }
              this.lstRoomPositionTabComments = dataPositions;

              let userFollowIdContent = this.model.user_follow_id;
              let userSecretaryIdContent = this.model.user_secretary_id
                let matchingElementContent = this.lstRoomPositionTabComments.find(obj => obj.user_id === userFollowIdContent);
                let matchingElementContent2 = this.lstRoomPositionTabComments.find(obj => obj.user_id === userSecretaryIdContent);
                if (matchingElementContent) {
                  matchingElementContent.is_special_position = true
                }
                if (matchingElementContent2) {
                  matchingElementContent2.is_special_position = true
                }
            }
          }
          resolve(true);
        }, (error) => {
          resolve(true);
        });
      })
    }
  }
  // Xuất danh sách điểm danh
  exportDelegate = () => {
    this._coreService.PostExport("/print/exportDelegate", {
      meeting_id: this.model.id
    }).subscribe((data) => {
      const blob = new Blob([data],
        { type: 'application/vnd.ms-excel' });

      saveAs(blob, "DanhSachDaiBieu.xls");
    })
  }

  closeModal () {
    this.modalService.close("content-grid-modal")
  }

  closeHistory() {
    this.modalService.close("view-task-history-modal")
  }
  closeVotingResults(){
    this.modalService.close("view-result-voting-modal")

  }

  closeVote() {
    this.modalService.close("realtime-voting-modal")
  }

  hideXepghe() {
    this.flagShowItem = false
  }

  closeHistoryView() {
    this.modalService.close('content-history-modal')
  }


data1:any
dataUserID: any
  testModal(){
    let id  = localStorage.getItem("userId");
    this._coreService.Get("/user/" + id).subscribe((res) => {
      if (res && res.code && res.code == "200") {
        this.data1 = res.data;
      }
    })
  }
  ngAfterViewInit() {
    this.getComboBox().then(() => {
      if (this.paramId) {
        this.getMeetingInfo().then(() => {
          this.getContentMember();
          this.getDataChartAcceptInfo();
          this.getDataChartAttendanceInfo();
          this.getContentComment();
          this.getDocumentComment();
          this.getDocumentData();
          this.getWaitingPosition();
          this.getListVoting();
          this.getListContentNote();
          this.getListWorking();
          this.qrContentAttandance = this.model.id;
          this.qrContentDocument = Consts.BACKEND_URL_DOCUMENT + this.model.id;
          if (this.attendanceStatus && this.attendanceStatus == "1") {
            this._coreService.Post("/meeting/attandance", {
              meeting_id: this.paramId
            }).subscribe((res) => {
              this._socketService.sendEmit("update-content", {
                room: this.model.id
              })
            })
          }
        });
      }
    });

    // Audio
    this.recordAudio = () => {
      return new Promise(resolve => {
        navigator.mediaDevices.getUserMedia({ audio: true })
          .then(stream => {
            const mediaRecorder = new MediaRecorder(stream, {
              mimeType: 'audio/webm',
              numberOfAudioChannels: 1,
              audioBitsPerSecond: 16000,
            });
            const audioChunks = [];

            mediaRecorder.addEventListener("dataavailable", event => {
              audioChunks.push(event.data);
            });

            const start = () => {
              mediaRecorder.start();
            };

            const stop = () => {
              return new Promise(resolve => {
                mediaRecorder.addEventListener('stop', () => {
                  const audioBlob = new Blob(audioChunks, { 'type': 'audio/wav; codecs=MS_PCM' });
                  const reader = new FileReader();
                  reader.readAsDataURL(audioBlob);
                  reader.addEventListener('load', () => {
                    const base64data = reader.result;
                    this.sendObj.audio = base64data;
                  }, false);
                  const audioUrl = URL.createObjectURL(audioBlob);
                  const audio = new Audio(audioUrl);
                  const play = () => {
                    audio.play();
                  };
                  resolve({ audioBlob, audioUrl, play });
                });

                mediaRecorder.stop();
              });
            };
            resolve({ start, stop });
          });
      });
    };

    // Nhận message socket
    // Thư ký bắt đầu cuộc họp / Cập nhật điểm danh đại biểu
    this._socketService.updateDelegate().subscribe((data) => {
      this.getDataMeeting();
    });
    // Đại biểu khác bắt đầu ý kiến
    this._socketService.startComment().subscribe((data) => {
      this.flagRealComment = true;
      setTimeout(() => {
        this.modelRealtimeComment = data.data;
        this.modalService.open("real-comment-modal");
      }, 20);
    });

    // Đại biểu khác cập nhật ý kiến
    this._socketService.updateComment().subscribe((data) => {
      this.flagRealComment = true;
      setTimeout(() => {
        this.modelRealtimeComment = data.data;

        const scrollHeight = this.realTimeCommentTextArea.nativeElement.scrollHeight;
        this.realTimeCommentTextArea.nativeElement.scrollTop = scrollHeight;
        if (this.flagShowRealComment) {
          this.modalService.open("real-comment-modal");
        }
      }, 50);
    });


    // Đại biểu kết thúc ý kiến
    this._socketService.endComment().subscribe((data) => {
      this.flagRealComment = false;
      this.modelRealtimeComment = data.data;
      this.modalService.close("real-comment-modal");
    });

    // Nhận được biểu quyết
    this._socketService.startVoting().subscribe((data) => {
      this.flagCreateVoting = true;
      let list_items = data.data.list_items ? data.data.list_items : [];

      let objUser = _.find(list_items, (item: any) => {
        return item.user_id == this.modelUser.id;
      });

      if (objUser) {
        this.getListVoting();
        this.modelRealVoting = data.data;
        this.minuteVotingDisplay = data.minuteVotingDisplay;
        this.secondVotingDisplay = data.secondVotingDisplay;
        this.countdownVoting = data.countdownVoting;
        this.modalService.open("realtime-voting-modal");
      }
    });

    // Nhận được cập nhật biểu quyết
    this._socketService.updateVoting().subscribe((data) => {
      this.getListVoting();
      let list_items = data && data.data && data.data.list_items ? data.data.list_items : [];

      let objUser = _.find(list_items, (item: any) => {
        return item.user_id == this.modelUser.id;
      });

      if (objUser) {
        this.minuteVotingDisplay = data.minuteVotingDisplay;
        this.secondVotingDisplay = data.secondVotingDisplay;
        this.countdownVoting = data.countdownVoting;
        if (data.isEndVoting) {
          this.modelRealVoting.status = 54;
          this.flagIsVoting = false;
        }
      }
    });

    // Nhận được kết thúc biểu quyết
    this._socketService.endVoting().subscribe((data) => {
      // this.modelRealVoting = data.data;
      if (this.modelRealVoting) {
        let list_items = this.modelRealVoting.list_items ? this.modelRealVoting.list_items : [];

        let objUser = _.find(list_items, (item: any) => {
          return item.user_id == this.modelUser.id;
        });

        if (objUser) {
          this.minuteVoting = 0;
          this.sencondVoting = 0;
          this.minuteVotingDisplay = "00";
          this.secondVotingDisplay = "00";
          clearInterval(this.setInterval);
          this.getListVoting();
          this.getDataResultVoting(this.modelRealVoting.id);
        }
      }
    });

    // Cập nhật diễn biến
    this._socketService.updateContent().subscribe((data) => {
      this.getMeetingInfo().then(() => {
        this.getContentComment();
      });
    });

    // Cập nhật tài liệu
    this._socketService.updateDocument().subscribe((data) => {
      this.getDataMeeting().then(() => {
        this.getDocumentData();
        this.getDocumentComment();
      });
    });

    // Cập nhật vị trí
    this._socketService.updatePosition().subscribe((data) => {
      this.getMeetingInfo().then(() => {
        this.getDataPosition().then(() => {
          this.getWaitingPosition();
        })
      });
    });

    // Cập nhật ghi chú
    this._socketService.updateNote().subscribe((data) => {
      this.getListContentNote();
    })

    // Cập nhật công việc
    this._socketService.updateWork().subscribe((data) => {
      this.getListWorking();
    })

    // Bắt đầu trong cuộc họp
    this._socketService.startInMeeting().subscribe((data) => {
      this.getDataMeeting();
    });
     // Kết thúc trong cuộc họp
     this._socketService.endMeeting().subscribe((data) => {
      this.getDataMeeting();
    });


    this.groupOptions = {
      showDropArea: false,
      columns: ["org_name"],
      captionTemplate: '<span style="color:black">${key}</span>',
    };

    this.groupMemberOptions = {
      showDropArea: false,
      columns: ["content"],
      captionTemplate: '<span style="color:black">${key}</span>',
    };


    this.groupContentNameOptions = {
      showDropArea: false,
      columns: ["content_name"],
      captionTemplate: '<span style="color:black">${key}</span>',
    };

    this.groupUserTypeOptions = {
      showDropArea: false,
      columns: ["user_type_name"],
      captionTemplate: '<span style="color:black">${key}</span>',
    };
    this.modalService.modalStatus.subscribe(data => {
      if (data && data.type === "addMemberGrid") {
        if (data.objMemberGrid && data.objMemberGrid.length > 0) {
          let list_members = [];

          for (let i = 0; i < data.objMemberGrid.length; i++) {
              let index = _.findIndex(this.dataContentUpdateMember.list_members, (item) => {
              return item.user_id === data.objMemberGrid[i].id;
            });
            if (index === -1) {
              list_members.push({
                user_id: data.objMemberGrid[i].id
              });
              this.dataContentUpdateMember.list_members.push(data.objMemberGrid[i])
            }
          }
            this._coreService.Post("/meetingcontent/addMemberContent", {
            content_id: this.dataContentUpdateMember.id,
            list_members: list_members
          }).subscribe((res) => {
            if (res && res.code && res.code === "200") {
              this.notification.success("Thêm đại biểu vào nội dung thành công!");
              this._socketService.sendEmit("update-content", {
                room: this.model.id
              });
              this.tabActive = "";
              setTimeout(() => {
                this.tabActive = "delegateTab";
              }, 500);
            } else {
              this.notification.warning("Thêm đại biểu vào nội dung không thành công!");
            }
            this.modalService.close("add-delegate-modal");
          })

        }
      }
    });
  }

  removeSelected(data: any) {
    const value = this.modelAddComment.content_ids; // Lấy danh sách các phần tử đã chọn từ modelAddComment
    const index = value.indexOf(data.value); // Tìm vị trí của phần tử cần xóa trong danh sách
    if (index > -1) {
        value.splice(index, 1); // Xóa phần tử khỏi danh sách
        this.modelAddComment.content_ids = value; // Cập nhật lại danh sách trong modelAddComment
    }
}

  removeShareChoose = (data) => {
    let index = _.findIndex(this.lstShareChooses, (item: any) => {
      return item.user_id == data.user_id;
    });

    if (index > -1) {
      this.lstShareChooses.splice(index, 1);
    }

    let indexs = [];
    let ids = _.map(this.lstShareChooses, "user_id");
    let lstDelegates = this.memberShareGrid.currentViewData["records"];
    for (let i = 0; i < ids.length; i++) {
      let index2 = _.findIndex(lstDelegates, (item) => {
        return item.user_id === ids[i];
      });

      if (index2 > -1) {
        indexs.push(index2);
      }
    }

    if (this.memberShareChooseGrid) {
      this.memberShareChooseGrid.dataSource = new DataManager(this.lstShareChooses);
    }

    setTimeout(() => {
      if (indexs.length > 0) {
        this.memberShareGrid.selectRows(indexs);
      }
    }, 100);
  }

  onBlur(event){
    this.multiselect.value = this.modelAddComment.content_ids
  }

  clearDropdownList() {
    this.modelAddComment.content_ids = null
    this.spanClearX = false
    this.modelAddComment.user_id
  }

  resetContent = () => {
    if (this.model.list_contents && this.model.list_contents.length > 0) {
      for (let i = 0; i < this.model.list_contents.length - 1; i++) {
        this.model.list_contents[i].list_members = _.cloneDeep(this.model.list_delegates);
        this.model.list_contents[i].list_guests = _.cloneDeep(this.model.list_guests);
        this.model.list_contents[i].list_shares = [];
        this.model.list_contents[i].list_presents = [];
        this.model.list_contents[i].list_rooms = [];
        this.model.list_contents[i].list_votings = [];
      }
      this.contentGrid.dataSource = new DataManager(this.model.list_contents);
    }
  }

  getListContent = (meeting_id) => {
    this._coreService.Get("/meeting/content/" + meeting_id).subscribe((res) => {
      if (res && res.code && res.code == "200") {
        this.model.list_contents = res.data;
        this.lstContents = this.model.list_contents;
        // this.lstContents1 = _.cloneDeep(this.lstContents)

        let objContent;
        // objContent = _.find(this.lstContents, (item: any) => {
        //   return item.id == this.modelAddComment.content_ids[0];
        // });
        if (objContent) {
          if (objContent.content == "Khai mạc") {
            this.getDataPositionTabComment(this.lstContentAttendances[0].id);
          } else if (objContent.content == "Bế mạc") {
            this.getDataPositionTabComment(this.lstContentAttendances[this.lstContentAttendances.length - 1].id);
          }

          this.modelAddComment.user_id = null;
          this.modelAddComment.full_name = "";
          this.lstDelegateTabComments = _.cloneDeep(objContent.list_members);

          if (this.model.user_follow_id) {
            var indexFollow = _.findIndex(this.lstDelegateTabComments, (item: any) => {
              return item.user_id == this.model.user_follow_id;
            });

            if (indexFollow == -1) {
              this.lstDelegateTabComments.unshift({
                user_id: this.model.user_follow_id,
                full_name: this.model.user_follow_name
              });
            }
          }

          var indexSecretary = _.findIndex(this.lstDelegateTabComments, (item: any) => {
            return item.user_id == this.model.user_secretary_id;
          });

          if (indexSecretary == -1) {
            this.lstDelegateTabComments.unshift({
              user_id: this.model.user_secretary_id,
              full_name: this.model.user_secretary_name
            });
          }

          var indexChair = _.findIndex(this.lstDelegateTabComments, (item: any) => {
            return item.user_id == this.model.user_chair_id;
          });

          if (indexChair == -1) {
            this.lstDelegateTabComments.unshift({
              user_id: this.model.user_chair_id,
              full_name: this.model.user_chair_name
            });
          }
        }
        this.lstContentTabComments = [];
          for (let i = 0; i < this.lstContents.length; i++) {
            this.lstContents[i].list_documents = _.sortBy(this.lstContents[i].list_documents, "orders");
            this.lstContentTabComments.push(this.lstContents[i]);

            if (this.lstContents[i].content_childs && this.lstContents[i].content_childs.length > 0) {
              for (let j = 0; j < this.lstContents[i].content_childs.length; j++) {
                this.lstContents[i].content_childs[j].is_child = true;
                const item = _.cloneDeep(this.lstContents[i].content_childs[j]);
                item.content = " - " + this.lstContents[i].content_childs[j].content
                this.lstContentTabComments.push(item);
              }
            }
          }

          let indexRealContent = _.findIndex(this.lstContents, (item) => {
            return item.status == 27;
          });

          if (indexRealContent > -1) {
            this.modelRealContent = this.lstContents[indexRealContent];
            this.getDataPositionTabInfo();
            let lst_delegates = this.modelRealContent.list_members;
            this.total_users = lst_delegates.length;
            this.total_online = _.filter(lst_delegates, (item) => {
              return item.attendance_status == true;
            }).length;
            this.total_offline = this.total_users - this.total_online;
          } else {
            this.total_users = 0;
            this.total_online = 0;
            this.total_offline = 0;
          }
        async.each(this.model.list_contents, (item, callback) => {
          this._coreService.Post("/meetingposition/detailRoomPosition", {
            meeting_id: this.model.id,
        content_id: this.searchContentTabRoom
          }).subscribe((res) => {
            if (res && res.code && res.code == "200") {
              item.list_rooms = res.data.data;
            }
            return callback();
          });
        });
      }
    })
  }
}

export class Meeting {
    id?: string;
    name?: string;
    meet_type_id?: string;
    meet_group_id?: string;
    meet_room_id?: string;
    user_chair_id?: string;
    user_approve_id?: string;
    user_secretary_id?: string;
    user_follow_id?: string;
    from_date?: string;
    to_date?: string;
    recall_reason?: string;
    return_reason?: string;
    denied_reason?: string;
    grant_permission_reason?: string;
    return_date?: string;
    recall_date?: string;
    denied_date?: string;
    grant_permission_date?: string;
    approve_date?: string;
    invite_files?: string;
    start_user?: string;
    close_user?: string;
    recall_user?: string;
    return_user?: string;
    approve_user?: string;
    cancel_user?: string;
    content?: string;
    content_template_id?: string;
    save_type?: string;
    cancel_date?: string;
    cancel_reason?: string;
    is_cancel?: Boolean;
    user_chair_name?: string;
    user_chair_title?: string;
    conclusion_file?: string;
    status_name?: string;
    status?: number;
    meeting_method_id?: number;
    user_created_name?: string;
    user_approve_name?: string;
    user_secretary_name?: string;
    meet_room_name?: string;
    user_follow_name?: string;

    is_return?: Boolean;
    is_recall?: Boolean;
    is_self_recall?: Boolean;
    is_me?: Boolean;

    list_contents?: Array<MeetingContent>;
    list_delegates?: Array<MeetingDelegate>;
    list_documents?: Array<MeetingDocument>;
    list_guests?: Array<MeetingGuest>;
    list_room_positions?: Array<MeetingRoomPosition>;
    list_secretary_follows?: Array<MeetingSecretaryFollow>;
    list_authoritys?: Array<MeetingAuthority>;

    url_socket? : string;
}

export class MeetingDelegate {
    id?: string;
    meeting_id?: string;
    user_id?: string;
    full_name?: string;
    title_name?: string;
    org_id?: string;
    org_name?: string;
    links?: string;
    is_same_chair?: Boolean;
    status?: number;
    attendance_status?: number;
    accept_date?: string;
    denied_reason?: string;
    attendance_date?: string;
    list_content_not?: Array<any>;
}

export class MeetingDocument {
    id?: string;
    meeting_id?: string;
    file_name?: string;
    links?: string;
    orders?: number;
    user_id?: string;
    is_private?: Boolean;
    is_delete?: Boolean;
    list_shares?: Array<MeetingDocumentShare>;
    list_rooms?: Array<MeetingRoomPosition>;
    list_votings?: Array<MeetingVoting>;
}

export class MeetingDocumentShare {
    id?: string;
    meeting_id?: string;
    meeting_document_id?: string;
    user_receive_id?: string;
}

export class MeetingGuest {
    id?: string;
    meeting_id?: string;
    full_name?: string;
    phone?: string;
    email?: string;
    org_name?: string;
    list_content_not?: Array<any>;
}

export class MeetingRoomPosition {
    id?: string;
    meeting_id?: string;
    user_id?: string;
    full_name?: string;
    description?: string;
    table_number?: number;
    x?: number;
    y?: number;
    meeting_status?: number;
    table_status?: number;
    is_special_position?: boolean = false;
    is_chair?: boolean = false;
    is_guest?: boolean = false;
}

export class MeetingSecretaryFollow {
    id?: string;
    meeting_id?: string;
    leader_user_id?: string;
    secretary_user_id?: string;
}

export class MeetingAuthority {
    id?: string;
    meeting_id?: string;
    leader_user_id?: string;
    authority_user_id?: string;
}

export class MeetingContent {
    id?: string;
    meeting_id?: string;
    content_id?: string;
    meeting_time?: string;
    meeting_time2?: string;
    orders?: number;
    parent_id?: string;
    parent_name?: string;
    content?: string;
    status?: number;
    list_comments?: Array<MeetingContentComment>;
    list_documents?: Array<MeetingContentDocument>;
    list_members?: Array<MeetingContentMember>;
    list_guests?: Array<MeetingContentGuest>;
    list_notes?: Array<MeetingContentNote>;
    list_presents?: Array<MeetingContentPresent>;
    list_shares?: Array<MeetingContentShare>;
    list_rooms?: Array<MeetingRoomPosition>;
    list_votings?: Array<MeetingVoting>;
    content_childs?: Array<MeetingContent>;
}

export class MeetingContentComment {
    id?: string;
    meeting_id?: string;
    content_id?: string;
    content_ids?: Array<any>;
    content_name?: string;
    user_id?: string;
    full_name?: string;
    content?: string;
    files?: string;
    record_files?: string;
    is_delete?: Boolean;
    list_documents?: Array<MeetingContentCommentDocument>;
}

export class MeetingContentCommentDocument {
    id?: string;
    meeting_id?: string;
    comment_id?: string;
    user_share_id?: string;
    links?: string;
}

export class MeetingContentDocument {
    id?: string;
    meeting_id?: string;
    content_id?: string;
    user_upload_id?: string;
    file_name?: string;
    links?: string;
    orders?: number;
    is_before_view?: Boolean;
    is_before_download?: Boolean;
    is_inmeet_view?: Boolean;
    is_inmeet_download?: Boolean;
    is_after_view?: Boolean;
    is_after_download?: Boolean;
    is_delete?: Boolean;
}

export class MeetingContentMember {
    id?: string;
    meeting_id?: string;
    content_id?: string;
    user_id?: string;
    full_name?: string;
    title_name?: string;
    org_id?: string;
    org_name?: string;
    attendance_status?: Boolean;
    is_authority?: Boolean;
    is_athority?: Boolean;
    is_delete?: boolean = false;
    is_special_position?: boolean = false;
}

export class MeetingContentGuest {
    id?: string;
    meeting_id?: string;
    content_id?: string;
    full_name?: string;
    phone?: string;
    email?: string;
    org_name?: string;
}

export class MeetingContentNote {
    id?: string;
    meeting_id?: string;
    content_id?: string;
    note?: string;
}

export class MeetingContentPresent {
    id?: string;
    meeting_id?: string;
    content_id?: string;
    type_id?: number;
    user_id?: string;
    org_id?: string;
}

export class MeetingContentShare {
    id?: string;
    meeting_id?: string;
    content_id?: string;
    user_id?: string;
}

export class MeetingVoting {
    id?: string;
    meeting_id?: string;
    content_id?: string;
    content?: string;
    content_name?: string
    content_voting?: string;
    data_voting?: string;
    minutes?: number;
    total_users?: number;
    total_accept?: number;
    total_denied?: number;
    total_not_join?: number;
    total_join?: number;
    percent_join?: number;
    percent_not_join?: number;
    percent_accept?: number;
    percent_denied?: number;
    status?: number;
    minuteVoting?: number;
    sencondVoting?: number;
    countdownVoting?: number;
    minuteVotingDisplay?: string;
    secondVotingDisplay?: string;
    list_items?: Array<MeetingVotingDetail>;
}

export class MeetingVotingDetail {
    id?: string;
    meeting_id?: string;
    meeting_voting_id?: string;
    user_id?: string;
    voting?: number;
}

export enum Consts {
  // API_URL_LOCAL = "https://localhost:44366/api",
  // API_URL_PRODUCTION = "https://localhost:44366/api",
  API_URL_LOCAL = "https://localhost:44366/api",
  API_URL_PRODUCTION = "https://api-phkg.digipro.com.vn/api",
  BACKEND_PROTOCOL = "http://",
  BACKEND_URL_LOCAL = "localhost:4200",
  // BACKEND_URL_LOCAL = "https://phkg.cnttvietnam.com.vn/",
  BACKEND_URL_PRODUCTION = "https://phkg.cnttvietnam.com.vn/",
  BACKEND_URL_DOCUMENT = "https://phkg.digipro.com.vn/auth/tai-lieu-khach-moi/",

  BASE_URL_PRODUCTION = "https://voicemaster.cnttvietnam.com.vn",

  // UPLOAD_LINK = "https://phkg.digipro.com.vn/download/ecabinet/"
  UPLOAD_LINK = "https://phkg.digipro.com.vn/download/ecabinet/"
}


// // // Vĩnh Phúc
// export enum Consts {
//   API_URL_LOCAL = "https://i-phkgapi.cnttvietnam.com.vn/api",
//   API_URL_PRODUCTION = "https://i-phkgapi.cnttvietnam.com.vn/api",
//   // API_URL_LOCAL = "https://localhost:44366/api",
//   // API_URL_PRODUCTION = "https://i-phkgapi.cnttvietnam.com.vn/api",
//   BACKEND_PROTOCOL = "http://",
//   BACKEND_URL_LOCAL = "localhost:4200",
//   // BACKEND_URL_LOCAL = "https://phkg.cnttvietnam.com.vn/api",
//   BACKEND_URL_PRODUCTION = "https://phkg.cnttvietnam.com.vn/api",
//   BACKEND_URL_DOCUMENT = "https://i-phkgvoice.cnttvietnam.com.vn/auth/tai-lieu-khach-moi/",

//   BASE_URL_PRODUCTION = "https://voicemaster1.cnttvietnam.com.vn",

//   // UPLOAD_LINK = "https://i-phkgvoice.cnttvietnam.com.vn/download/ecabinet/"
//   UPLOAD_LINK = "https://i-phkgvoice.cnttvietnam.com.vn/download/ecabinet/"
// }
